import React, { Fragment, useEffect } from 'react';
import './Pricing.css';
import eCheck from '../../../assets/images/e-check.svg';
import iResults from '../../../assets/images/i-results.svg';
import gainVisi from '../../../assets/images/gain-visi.svg';
import { Controller, useForm } from 'react-hook-form';
import { userService } from '../../../services';
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAddressCard,
  faBuilding,
  faEnvelope,
  faPhoneAlt,
  faUser,
  faUserAlt,
} from '@fortawesome/free-solid-svg-icons';
import MuiPhoneNumber from 'material-ui-phone-number';
import SupportHeader from '../../../components/Header/SupportHeader';
import { Footer } from '../../../components';
import { handleError } from '../../../utils/config';
import { useHistory } from 'react-router-dom';
// import {useUserDataActions} from '../../../context/GlobalUserData';

interface REQUESTPRICING {
  FirstName: string;
  LastName: string;
  CompanyName: string;
  ContactNo: number;
  EmailAddress: string;
  EditType: string;
  RequestType: string;
}

export const Pricing: React.FC = () => {
  const [requestPricingData, setrequestPricingData] = React.useState({
    FirstName: '',
    LastName: '',
    CompanyName: '',
    ContactNo: '',
    EmailAddress: '',
    EditType: 'registration',
  });

  const [errorMsg, setErrorMsg] = React.useState('');
  const [successMsg, setSuccessMsg] = React.useState('');
  const [networkError, setNetworkError] = React.useState('');
  const [counter, setCounter] = React.useState(10);
  const history = useHistory();

  const { register, handleSubmit, control, errors } = useForm({
    mode: 'onChange',
  });

  const handleFieldChange = (target: any) => {
    setrequestPricingData({
      ...requestPricingData,
      [target.name]: target.value,
    });
  };

  const onSubmit = async (data: REQUESTPRICING) => {
    try {
      data.RequestType = 'Request Pricing';
      const result = await userService.postRequestPricingData(data);
      if (result) {
        setErrorMsg('');
        setSuccessMsg(result);
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
        setErrorMsg(e.error.message);
      }
    }
  };
  useEffect(() => {
    successMsg &&
      counter > 0 &&
      setTimeout(() => {
        setCounter(counter - 1);
        if (counter === 1) {
          window.location.reload();
        }
      }, 1000);
  }, [counter, successMsg]);

  if (networkError) {
    throw new Error(networkError);
  }

  return (
    <React.Fragment>
      <div className="outerContainer scrollContainer">
        <SupportHeader />
        <div className="supportBanner pricingBanner">
          <div className="innerContainer12 p-relative">
            <div className="pBannerText">
              <h1>CloudForestX:</h1>
              <h4>Your Path to Complete Clarity</h4>
              <p className="mt-20 w-80 font-20px fw-300">
                Enter your request for pricing and get our enterprise or partner
                development team to help you out.
                <br />
                <br />
                Let’s get started!
              </p>
            </div>
            <div className="RequestPricingForm">
              {/* <h4 className="fw-300">Request CloudForest<sup>x</sup> Pricing</h4> */}

              <div className="rpFormCont d-flex">
                {successMsg && (
                  <div className="pt-20">
                    <h3 className=" text-darkBlue d-middle-center text-center fw-500 text-capitalize">
                      {successMsg}
                    </h3>
                    <h6 className="text-Black fw-500 text-center mt-20">
                      Page will be refreshed in
                      <br /> {counter} seconds
                    </h6>
                  </div>
                )}

                <form
                  noValidate
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="off"
                  className={successMsg ? 'd-none' : 'd-block'}
                >
                  <Box>
                    <Grid container spacing={1} alignItems="flex-end">
                      <Grid item className="w100">
                        <FormControl
                          variant="outlined"
                          className="bEmail mb-20"
                        >
                          <InputLabel
                            htmlFor="business-email"
                            className="inputLegend"
                          >
                            BUSINESS EMAIL
                          </InputLabel>
                          <OutlinedInput
                            id="email"
                            name="EmailAddress"
                            label="BUSINESS EMAIL"
                            className="outlineInputArea"
                            autoComplete="off"
                            type="email"
                            onChange={(e) => handleFieldChange(e.target)}
                            inputRef={register({
                              required: true,
                              pattern: /[a-zA-z_.+0-9-]+@[a-zA-Z0-9-]+([.][a-zA-Z0-9]+)+/,
                            })}
                            endAdornment={
                              <InputAdornment position="end">
                                <span>
                                  <FontAwesomeIcon icon={faEnvelope} />
                                </span>
                              </InputAdornment>
                            }
                          />
                          {errors.EmailAddress && (
                            <div className="regMsg">Enter valid email id</div>
                          )}
                        </FormControl>

                        <FormControl
                          variant="outlined"
                          className="bEmail mb-20"
                        >
                          <InputLabel
                            htmlFor="business-email"
                            className="inputLegend"
                          >
                            First Name
                          </InputLabel>
                          <OutlinedInput
                            id="firstname"
                            name="FirstName"
                            label="First Name"
                            className="outlineInputArea"
                            autoComplete="off"
                            type="text"
                            onChange={(e) => handleFieldChange(e.target)}
                            inputRef={register({
                              required: true,
                              pattern: /^\S*$/,
                            })}
                            endAdornment={
                              <InputAdornment position="end">
                                <span>
                                  <FontAwesomeIcon icon={faAddressCard} />
                                </span>
                              </InputAdornment>
                            }
                          />
                          {errors.FirstName && (
                            <div className="regMsg">Enter first name.</div>
                          )}
                        </FormControl>

                        <FormControl
                          variant="outlined"
                          className="bEmail mb-20"
                        >
                          <InputLabel
                            htmlFor="business-email"
                            className="inputLegend"
                          >
                            Last Name
                          </InputLabel>
                          <OutlinedInput
                            id="lastname"
                            name="LastName"
                            label="Last Name"
                            className="outlineInputArea"
                            autoComplete="off"
                            type="text"
                            onChange={(e) => handleFieldChange(e.target)}
                            inputRef={register({
                              required: true,
                              pattern: /^\S*$/,
                            })}
                            endAdornment={
                              <InputAdornment position="end">
                                <span>
                                  <FontAwesomeIcon icon={faAddressCard} />
                                </span>
                              </InputAdornment>
                            }
                          />
                          {errors.LastName && (
                            <div className="regMsg">Enter last name.</div>
                          )}
                        </FormControl>

                        <FormControl
                          variant="outlined"
                          className="bEmail mb-20"
                        >
                          <InputLabel
                            htmlFor="business-email"
                            className="inputLegend"
                          >
                            Company Name
                          </InputLabel>
                          <OutlinedInput
                            id="companyname"
                            name="CompanyName"
                            label="Company Name"
                            className="outlineInputArea"
                            autoComplete="off"
                            type="text"
                            onChange={(e) => handleFieldChange(e.target)}
                            inputRef={register({
                              required: true,
                              pattern: /\S/,
                            })}
                            endAdornment={
                              <InputAdornment position="end">
                                <span>
                                  <FontAwesomeIcon icon={faBuilding} />
                                </span>
                              </InputAdornment>
                            }
                          />
                          {errors.CompanyName && (
                            <div className="regMsg">
                              Company Name is required.
                            </div>
                          )}
                        </FormControl>

                        <FormControl
                          variant="outlined"
                          className="bEmail mb-10"
                        >
                          <Controller
                            className="bEmail my-10"
                            as={
                              <MuiPhoneNumber
                                defaultCountry={'us'}
                                onChange={handleFieldChange}
                                countryCodeEditable={false}
                              />
                            }
                            id="phone-number"
                            name="ContactNo"
                            control={control}
                            variant="outlined"
                            label="ENTER PHONE NUMBER"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <span>
                                    <FontAwesomeIcon icon={faPhoneAlt} />
                                  </span>
                                </InputAdornment>
                              ),
                            }}
                          >
                            {errors.ContactNo && (
                              <div className="regMsg">
                                Enter 10 digit Valid numbers.
                              </div>
                            )}
                          </Controller>
                        </FormControl>

                        {errorMsg && <div className="regMsg">{errorMsg}</div>}

                        {/* {regMsg ? regMsgElement() : ''} */}

                        <div className="registrationLoginBtn mt-5">
                          <input
                            type="submit"
                            className="formLoginBtn w-100"
                            value="SUBMIT"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="supportContent pContent">
          <div className="innerContainer12">
            <div className="pKeyPointsCont">
              <div className="pKeyPoints">
                <div className="kpIcon">
                  <img src={gainVisi} width="40" />
                </div>
                <h5 className="kpTitle fw-500">
                  Receive End-to-End Visibility
                </h5>
                <p className="mt-10">
                  CloudForestX helps users manage their end-to-end
                  infrastructure- in one place.
                </p>
              </div>
              <div className="pKeyPoints">
                <div className="kpIcon">
                  <img src={iResults} width="40" />
                </div>
                <h5 className="kpTitle fw-500">
                  Agile Start with Immediate Results
                </h5>
                <p className="mt-10">
                  Ensure hassle-free organization onboarding with CloudForestX's
                  flexible architecture and get results in days.
                </p>
              </div>
              <div className="pKeyPoints">
                <div className="kpIcon">
                  <img src={eCheck} width="40" />
                </div>
                <h5 className="kpTitle">Backed by Enterprises</h5>
                <p className="mt-10">
                  CloudForestX is deployed in highly demanding environments.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="footerContainer">
          <div className="innerContainer12">
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Pricing;
