import {
  AllInstanceI,
  BasicAPIRequest,
  // ServerHealthPrimaryKeyI,
} from '../../pages/HealthMonitoring/config';
import { APIS } from '../../utils/apiConfig';
import { createRequestUtility } from '../../utils/httpUtils';
import { HttpService } from '../HttpService/HttpService';

export class HealthMonitoringService {
  // constructor(private httpService: HttpService) {}
  private makeRequest: ReturnType<typeof createRequestUtility>;

  constructor(private httpService: HttpService) {
    // Initialize makeRequest with the httpService
    this.makeRequest = createRequestUtility(this.httpService);
  }

  // example for other request
  // async getPlatformBrowserData(params: getPlatformBrowserDataParams): Promise<any> {
  //   return this.makeRequest('get', APIS.GET_PLATFORM_BROSWER_DATA(), { params });
  // }

  // async removeUnusedResource(params: any): Promise<any> {
  //   return this.makeRequest('delete', APIS.UNUSED_REMOVE(), params);
  // }

  // async resizeInstance(params: any): Promise<any> {
  //   return this.makeRequest('patch', APIS.RESIZE_INSTANCE(), params);
  // }

  async getAllInstance(params: AllInstanceI): Promise<any> {
    return this.makeRequest('post', APIS.GET_ALL_INSTANCE(), params);
  }

  async getQuickCpuMemoryDisk(params: BasicAPIRequest): Promise<any> {
    return this.makeRequest('post', APIS.GET_QUICK_CPU_MEMORY_DISK(), params);
  }

  async getBasicCPUUsage(params: BasicAPIRequest): Promise<any> {
    return this.makeRequest('post', APIS.GET_BASIC_CPU_USAGE(), params);
  }

  async getBasicMemoryUsage(params: BasicAPIRequest): Promise<any> {
    return this.makeRequest('post', APIS.GET_BASIC_MEMORY_USAGE(), params);
  }

  async getBasicNetworkTrafficUsage(params: BasicAPIRequest): Promise<any> {
    return this.makeRequest(
      'post',
      APIS.GET_BASIC_NETWORK_TRAFFIC_USAGE(),
      params
    );
  }

  async getBasicDiskSpaceUsage(params: BasicAPIRequest): Promise<any> {
    return this.makeRequest('post', APIS.GET_BASIC_DISK_SPACE_USAGE(), params);
  }

  async getCPUMemoryNetworkDisk(params: BasicAPIRequest): Promise<any> {
    return this.makeRequest('post', APIS.GET_CPU_MEMORY_NETWORK_DISK(), params);
  }

  async getMemoryStack(params: BasicAPIRequest): Promise<any> {
    return this.makeRequest('post', APIS.GET_MEMORY_STACK(), params);
  }

  async getDiskSpaceUsed(params: BasicAPIRequest): Promise<any> {
    return this.makeRequest('post', APIS.GET_DISK_SPACE_USED(), params);
  }

  async getDiskIops(params: BasicAPIRequest): Promise<any> {
    return this.makeRequest('post', APIS.GET_DISK_IOPS(), params);
  }

  async getIoUsageReadWrite(params: BasicAPIRequest): Promise<any> {
    return this.makeRequest('post', APIS.GET_IO_USAGE_READ_WRITE(), params);
  }

  async getIoUtilization(params: BasicAPIRequest): Promise<any> {
    return this.makeRequest('post', APIS.GET_IO_UTILIZATION(), params);
  }

  // memory section

  async getActiveInactive(params: BasicAPIRequest): Promise<any> {
    return this.makeRequest('post', APIS.GET_MEMORY_ACTIVE_INACTIVE(), params);
  }

  async getCommitted(params: BasicAPIRequest): Promise<any> {
    return this.makeRequest('post', APIS.GET_MEMORY_COMMITTED(), params);
  }
  async getActiveInactiveDetail(params: BasicAPIRequest): Promise<any> {
    return this.makeRequest(
      'post',
      APIS.GET_MEMORY_ACTIVE_INACTIVE_DETAIL(),
      params
    );
  }
  async getWriteBackDirty(params: BasicAPIRequest): Promise<any> {
    return this.makeRequest('post', APIS.GET_MEMORY_WRITEBACK_DIRTY(), params);
  }
  async getSharedMapped(params: BasicAPIRequest): Promise<any> {
    return this.makeRequest('post', APIS.GET_MEMORY_SHARED_MAPPED(), params);
  }
  async getSlab(params: BasicAPIRequest): Promise<any> {
    return this.makeRequest('post', APIS.GET_MEMORY_SLAB(), params);
  }
  async getVmalloc(params: BasicAPIRequest): Promise<any> {
    return this.makeRequest('post', APIS.GET_MEMORY_VMALLOC(), params);
  }
  async getAnonymous(params: BasicAPIRequest): Promise<any> {
    return this.makeRequest('post', APIS.GET_MEMORY_ANONYMOUS(), params);
  }
  async getKernelCpu(params: BasicAPIRequest): Promise<any> {
    return this.makeRequest('post', APIS.GET_MEMORY_KERNEL_CPU(), params);
  }
  async getHugePagesSize(params: BasicAPIRequest): Promise<any> {
    return this.makeRequest('post', APIS.GET_MEMORY_HUGE_PAGES_SIZE(), params);
  }
  async getDirectMap(params: BasicAPIRequest): Promise<any> {
    return this.makeRequest('post', APIS.GET_MEMORY_DIRECT_MAP(), params);
  }
  async getUnevictableMlocked(params: BasicAPIRequest): Promise<any> {
    return this.makeRequest(
      'post',
      APIS.GET_MEMORY_UNEVICTABLE_MLOCKED(),
      params
    );
  }

  async getServerHealthCreds(): Promise<any> {
    return this.makeRequest('get', APIS.GET_SERVER_HEALTH_CREDS(), {});
  }

  async addServerHealthCreds(params: any): Promise<any> {
    return this.makeRequest('post', APIS.ADD_SERVER_HEALTH_CREDS(), params);
  }
}
