import React, { useEffect, useState } from 'react';
import '../IdleInstances/IdleInstances.css';
import angleRight from '../../assets/images/angle-right.svg';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableCell,
  TableRow,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import './TagBillingDetail.css';
import '../IdleInstances/IdleInstances.css';
import { useHistory } from 'react-router';

import { useSelector } from 'react-redux';
import { accountState } from '../../store/index';
import { TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { config, IGNORED_RESOURCE_COLUMN_FIELD, searchConfig } from './config';
import { CLOUD_PROVIDER_SERVICES } from '../../utils/constants';
import { formatData } from '../../utils/tagBillingDetail/formattedData';
import { costFormatter2, handleError } from '../../utils/config';
/*-----Redirect to 403 page---------- */

import { ALL_VALUE } from '../Invoice/config';
import Loading from '../../components/Loading/Loading';

const storageKeys = {
  IR_SELECTED_SUBSCRIPTION: 'IR_SELECTED_SUBSCRIPTION',
  IR_TYPE_SELECTED_SUBSCRIPTION: 'IR_TYPE_SELECTED_SUBSCRIPTION',
};

export const getSelectedSubscription = (): string => {
  return localStorage.getItem(storageKeys.IR_SELECTED_SUBSCRIPTION) || 'all';
};

export const setSelectedSubscription = (value: string): void => {
  localStorage.setItem(storageKeys.IR_SELECTED_SUBSCRIPTION, value);
};

export const setSelectedResource = (value: string): void => {
  localStorage.setItem(storageKeys.IR_TYPE_SELECTED_SUBSCRIPTION, value);
};

export const TagBillingDetail: React.FC = (props: any) => {
  const history = useHistory();
  const [loader, setLoader] = React.useState(false);
  const selectedAccount = useSelector(accountState);

  const selectedCurrency = selectedAccount.defaultCurrency;

  const [currency, setCurrency] = React.useState('');
  const [tagResourceList, setTagResourceList] = React.useState<any[]>([]);
  const [resourceList, setResourceList] = React.useState<any>({});
  const [resourcePayload, setResourcePayload] = React.useState<any[]>([]);

  const [isNetworkError, setNetworkError] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [selectedResourceName, setSelectedResourceName] = React.useState<any>(
    null
  );
  const [updatedCurrency, setUpdatedCurrency] = useState('USD');

  /*-----Redirect to 403 page---------- */
  // const [pageLoad, setPagDone] = React.useState(false);

  // const checkRemainingDays = () => {
  //   alert(
  //     "Your Trial Period is over. Please contact support team at 'support@cloudforestx.com'."
  //   );
  //   history.push('/login');
  // };

  const getTagResourceCostDetails = async () => {
    try {
      setLoader(true);
      let data;
      const cloudProvider = selectedAccount.selectedAccount?.CloudProvider.toUpperCase();
      const selectedAccountId = props.location.state.selectedAccountId;
      const selectedSubscriptionId =
        props.location.state.selectedSubscriptionId;
      if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
        data = {
          tagKey: props.location.state.tagKey,
          tagValue: props.location.state.tagValue,
          billingPeriod: props.location.state.billingPeriod,
          ...(selectedAccountId === ALL_VALUE
            ? {}
            : { accountId: selectedAccountId }),
          ...(selectedCurrency !== 'USD' && { selectedCurrency }),
        };
      } else if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
        data = {
          tagKey: props.location.state.tagKey,
          tagValue: props.location.state.tagValue,
          billingPeriod: props.location.state.billingPeriod,
          ...(selectedAccountId === ALL_VALUE
            ? {}
            : selectedSubscriptionId === ALL_VALUE &&
              selectedAccountId !== ALL_VALUE
            ? { azureId: selectedAccountId }
            : { subscriptionId: selectedSubscriptionId }),
          ...(selectedCurrency !== 'USD' && { selectedCurrency }),
        };
      }

      // cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE
      //   ? (data = {
      //       subscriptionId: props.location.state.SubscriptionId,
      //       azureId: props.location.state.AzureId,
      //       tagKey: props.location.state.tagKey,
      //       tagValue: props.location.state.tagValue,
      //       billingPeriod: props.location.state.billingPeriod,
      //     })
      //   : cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
      //   ? (data = {
      //       accountId: props.location.state.AccountId,
      //       tagKey: props.location.state.tagKey,
      //       tagValue: props.location.state.tagValue,
      //       billingPeriod: props.location.state.billingPeriod,
      //     })
      //   : (data = {
      //       accountId: props.location.state.AccountId,
      //       tagKey: props.location.state.tagKey,
      //       tagValue: props.location.state.tagValue,
      //       billingPeriod: props.location.state.billingPeriod,
      //     });

      let result;
      if (cloudProvider) {
        const output = await config[cloudProvider].tagDetail(data);
        result = formatData(output, cloudProvider);
      }
      if (result) {
        setUpdatedCurrency(selectedCurrency);
        setCurrency(result[0].Currency);
        setTagResourceList(result);
        const newResourceList: { [key: string]: any } = {
          All: null,
        };
        result.map((service: any) => {
          if (service.TotalCost.toFixed(2) > 0.0)
            newResourceList[service.ResourceName] = service.ResourceName;
        });
        setResourcePayload(result);
        setResourceList(newResourceList);
      }
      setTagResourceList(result);
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const handleSearchChange = (e: any) => {
    setSearch(e.target.value);
  };

  const handleResourceClick = (
    event: React.ChangeEvent<Record<string, unknown>>
  ) => {
    const resourceName = event.target.value as string;
    setSelectedResourceName(resourceName);
    if (!resourceName) {
      setResourcePayload(tagResourceList);
      return;
    }

    const filterResults: any[] = tagResourceList.filter(
      (detail: any) => detail.ResourceName === resourceName
    );
    if (filterResults?.length > 0) {
      setResourcePayload(filterResults);
    }
  };

  useEffect(() => {
    getTagResourceCostDetails();
    setSelectedResourceName(null);
  }, [selectedAccount.isAccountSelected, selectedCurrency]);

  // useEffect(() => {
  //   setPagDone(false);
  //   setTimeout(() => {
  //     const name = userPlans?.includes(FEATURES.BILLING_SIMPLIFIED);
  //     if (name) {
  //       setPagDone(true);
  //     }
  //   }, 1000);
  // }, [userPlans]);

  if (isNetworkError) {
    throw new Error('Network Error');
  }
  const company = localStorage.getItem('CompanyName');
  const path = '/' + company + '/' + 'billing';
  return (
    <>
      <div className="pageNav font-25px">
        <Link className="text-decoration-none" to={path}>
          <p className="tagDetailCellLink">Billing Simplified</p>
        </Link>
        <img src={angleRight} alt="" />
        <p>{props.location.state.tagKey}</p>
      </div>
      <div className="rsTableHeader inventoryHeader">
        <div className="tagHeading">
          <div className="d-inline-block">
            {props.location &&
              props.location.state &&
              props.location.state.tagValue}
            <sup className="inventoryDetailCount font-15px fw-500 ml-20">
              {tagResourceList?.length}
            </sup>
          </div>
        </div>

        <div className="w-55 mr-0 inventoryFlexContent">
          <TextField
            id="searchInventory"
            className=" w-80 inventoryFlexItem"
            label={
              (selectedResourceName &&
                searchConfig[selectedResourceName] &&
                searchConfig[selectedResourceName].placeholder) ||
              ''
            }
            variant="outlined"
            value={search ? search : ''}
            onChange={
              selectedResourceName && searchConfig[selectedResourceName]
                ? handleSearchChange
                : () => {
                    return;
                  }
            }
            disabled={!selectedResourceName}
          />
        </div>
      </div>
      <div>
        <div className="d-flex flex-rowReverse mb-10">
          <div className="resourceNameSelectBox ml-10 ">
            <label className="resourceNameLabel">Resource Name</label>
            <FormControl variant="outlined" className="w-100 mt-0 p-0">
              <InputLabel shrink id="demo-simple-select-outlined-label">
                Resource Name
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selectedResourceName}
                onChange={handleResourceClick}
                defaultValue="all"
                label="Low Cost Tags"
                displayEmpty
                className="p-0"
              >
                {Object.keys(resourceList)?.map((resource: any, index: any) => (
                  <MenuItem value={resourceList[resource]} key={'subs' + index}>
                    {resource}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>

      {loader ? (
        <Loading style={{ height: '60vh' }} />
      ) : tagResourceList && tagResourceList?.length > 0 ? (
        <Grid container className="mb-50">
          <Grid className="innerContainer p-0">
            <div className="accContainer tagDetailAccordion">
              {resourcePayload?.map((row: any, i: any) =>
                row.TotalCost.toFixed(2) > 0.0 ? (
                  <Accordion
                    // className="accordionContainer"
                    key={'acc' + i}
                  >
                    <AccordionSummary
                      aria-controls={'panel1d-content' + i}
                      id={'panel1d-header' + i}
                      className="accordionContainerHead"
                      key={'instanced' + i}
                    >
                      <Grid container>
                        <Grid
                          item
                          md={11}
                          sm={10}
                          xs={12}
                          className="accordionTitle tagDetailTitle"
                        >
                          <span id={`tag ${row.ResourceName}`}>
                            <FontAwesomeIcon icon={faCaretRight} />
                          </span>
                          <p>
                            {row.ResourceName}
                            <i className="listCount">({row.Count})</i>
                          </p>
                        </Grid>
                        <Grid
                          item
                          md={1}
                          sm={2}
                          xs={12}
                          className="accordionTitle tagDetailTitle"
                        >
                          <p>
                            {costFormatter2(updatedCurrency, row.TotalCost)}
                          </p>
                        </Grid>
                      </Grid>
                    </AccordionSummary>

                    <AccordionDetails className="accordionContentBox ">
                      <Grid container>
                        <Grid
                          item
                          md={12}
                          sm={12}
                          xs={12}
                          className="accordionContent"
                        >
                          <Grid className="accDetails">
                            <TableContainer>
                              <Table
                                stickyHeader
                                className="table adminTable adminPanelTable"
                                size="small"
                                aria-label="a dense table"
                              >
                                <TableHead className="admintableHead">
                                  <TableRow>
                                    {row.Details &&
                                      row.Details[0] &&
                                      Object.keys(row.Details[0])?.map(
                                        (keyList: any, k: any) => {
                                          if (
                                            IGNORED_RESOURCE_COLUMN_FIELD.includes(
                                              keyList
                                            )
                                          ) {
                                            return null;
                                          }
                                          if (keyList === 'IsActive') {
                                            return (
                                              <TableCell
                                                className="adminTableheading"
                                                align="center"
                                                key={'IsActive' + k}
                                              >
                                                Resource Status
                                              </TableCell>
                                            );
                                          } else if (keyList === 'Cost') {
                                            return (
                                              <TableCell
                                                className="adminTableheading"
                                                align="center"
                                                key={'IsActive' + k}
                                              >
                                                MTD Cost
                                              </TableCell>
                                            );
                                          }
                                          return (
                                            <TableCell
                                              className="adminTableheading"
                                              align="center"
                                              key={'disabled' + k}
                                            >
                                              {keyList.split('_').join(' ')}
                                            </TableCell>
                                          );
                                        }
                                      )}
                                  </TableRow>
                                </TableHead>
                                {row.Details?.length ? (
                                  <TableBody>
                                    {(selectedResourceName && search
                                      ? row.Details?.filter(
                                          (detail: any) =>
                                            searchConfig[
                                              selectedResourceName
                                            ] &&
                                            detail[
                                              searchConfig[selectedResourceName]
                                                .field
                                            ] &&
                                            detail[
                                              searchConfig[selectedResourceName]
                                                .field
                                            ]
                                              .toLowerCase()
                                              .includes(search.toLowerCase())
                                        )
                                      : row.Details
                                    ).map((details: any, k: any) => (
                                      <TableRow
                                        className="accDetailRow"
                                        key={'details' + k}
                                      >
                                        {Object.entries(details)?.map(
                                          ([key, value]: any) => {
                                            if (
                                              IGNORED_RESOURCE_COLUMN_FIELD.includes(
                                                key
                                              )
                                            ) {
                                              return null;
                                            }
                                            if (
                                              key == 'Predicted Monthly Cost' ||
                                              key ===
                                                'Predicted_Monthly_Cost' ||
                                              key === 'cost' ||
                                              key === 'Cost' ||
                                              key === 'DiskMonthlyCost'
                                            ) {
                                              return (
                                                <TableCell
                                                  align="center"
                                                  className={key}
                                                  key={key}
                                                >
                                                  {/* <span className="fas pr-5">
                                                          {details.Currency_Symbol ||
                                                            details.CurrencySymbol}
                                                        </span> */}
                                                  <span className="fas pr-5">
                                                    {value
                                                      ? costFormatter2(
                                                          updatedCurrency,
                                                          +value
                                                        )
                                                      : costFormatter2(
                                                          updatedCurrency,
                                                          0
                                                        )}
                                                  </span>
                                                </TableCell>
                                              );
                                            }
                                            if (key === 'IsActive') {
                                              return (
                                                <TableCell
                                                  align="center"
                                                  className={key}
                                                  key={key}
                                                >
                                                  {value === 1 ? (
                                                    <span className="successColor">
                                                      Active
                                                    </span>
                                                  ) : (
                                                    <span className="errorColor">
                                                      Deleted
                                                    </span>
                                                  )}
                                                </TableCell>
                                              );
                                            }
                                            if (
                                              key === 'InstancePeakCPU' ||
                                              key === 'VMAverageUsage' ||
                                              key === 'VMPeakCPU'
                                            ) {
                                              return (
                                                <TableCell
                                                  align="center"
                                                  className={key}
                                                  key={key}
                                                >
                                                  {value || value === 0
                                                    ? `${(+value).toFixed(2)} %`
                                                    : ''}
                                                </TableCell>
                                              );
                                            }

                                            if (
                                              key === 'Disk_Size' ||
                                              key === 'InstanceDiskSize' ||
                                              key === 'DiskSize' ||
                                              key === 'VMDiskSize'
                                            ) {
                                              return (
                                                <TableCell
                                                  align="center"
                                                  className={key}
                                                  key={key}
                                                >
                                                  {value || value === 0 ? (
                                                    <span>{value} GB</span>
                                                  ) : (
                                                    <span className="pl-15">
                                                      -
                                                    </span>
                                                  )}
                                                </TableCell>
                                              );
                                            } else {
                                              return (
                                                <TableCell
                                                  align="center"
                                                  className={key}
                                                  key={key}
                                                >
                                                  {value || value === 0 ? (
                                                    <span>{value}</span>
                                                  ) : (
                                                    <span className="pl-15">
                                                      -
                                                    </span>
                                                  )}
                                                </TableCell>
                                              );
                                            }
                                          }
                                        )}
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                ) : (
                                  <TableCell colSpan={12}>
                                    <div className="noRecordsFound">
                                      No Records Found
                                    </div>
                                  </TableCell>
                                )}
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ) : null
              )}
            </div>
          </Grid>
        </Grid>
      ) : (
        <div className="noRecordsFound">
          No Resources Found. Please Visit Later
        </div>
      )}
    </>
  );
};

export default React.memo(TagBillingDetail);
