import React from 'react'; // Import the custom tooltip
import { Link } from 'react-router-dom';
import { generateTooltipText } from './config';
import { useSelector } from 'react-redux';
import { uiState, userState } from '../../store';
import CustomSideNavTooltip from './CustomTooltip';
import { FEATURES } from '../../utils/constants';

interface SidebarItemProps {
  featureKey: keyof ReturnType<typeof generateTooltipText>;
  feature?: string;
  route: string;
  isActive: boolean;
  icon: React.ReactNode;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  featureKey,
  feature,
  route,
  isActive,
  icon,
}) => {
  const userData = useSelector(userState);
  const { collapsed } = useSelector(uiState);

  const user = userData.userData;

  const enabledFeatures = user?.plan.features;
  const extraFeatures = [
    FEATURES.UPTIME_MONITORING,
    FEATURES.HEALTH_MONITORING,
    FEATURES.REAL_USER_MONITORING,
    FEATURES.UPLOADED_DOCUMENTS,
  ];

  const shouldRenderDiv =
    extraFeatures.includes(feature as string) &&
    !(enabledFeatures as readonly string[])?.includes(feature as string);

  const isAdmin = user?.RoleId === 1;

  const isFeatureEnabled = isAdmin || enabledFeatures?.includes(feature);

  const tooltipText = generateTooltipText();

  const tooltipContent = isFeatureEnabled
    ? tooltipText[featureKey].default
    : tooltipText[featureKey].disabled;

  const linkStyle = isFeatureEnabled
    ? {}
    : {
        color: '#bfb6b6',
        cursor: 'not-allowed',
        textDecoration: 'none',
      };

  // The list item content
  const listItemContent = (
    <li className={`${isActive ? 'activeTab' : ''}`}>
      <Link
        to={route}
        style={linkStyle}
        onClick={(e) => !isFeatureEnabled && e.preventDefault()}
      >
        <div className="icon">{icon}</div>
        <div className="menu-name">{tooltipContent}</div>
      </Link>
    </li>
  );

  return shouldRenderDiv ? (
    <></>
  ) : (
    <div className={!isFeatureEnabled && !isAdmin ? 'leftNavDeactive' : ''}>
      {collapsed ? (
        <CustomSideNavTooltip title={tooltipContent} placement="right" arrow>
          {listItemContent}
        </CustomSideNavTooltip>
      ) : (
        listItemContent
      )}
    </div>
  );
};

export default SidebarItem;
