import React from 'react';
import { Grid, Skeleton } from '@mui/material';

const QuickSectionSkeleton: React.FC = () => {
  return (
    <>
      {/* Gauge Skeletons */}
      <Grid
        container
        className="quickCPUSkeletonContainer"
        spacing={1}
        sx={{
          // boxSizing: 'border-box',
          flexWrap: 'nowrap',
          maxWidth: '100%',
          justifyContent: 'center', // Center the grid
        }}
      >
        <Grid item>
          <Skeleton variant="rectangular" height={184} />
        </Grid>
        <Grid item>
          <Skeleton variant="rectangular" height={184} />
        </Grid>
        <Grid item>
          <Skeleton variant="rectangular" height={184} />
        </Grid>
        <Grid item>
          <Skeleton variant="rectangular" height={184} />
        </Grid>
        <Grid item>
          <Skeleton variant="rectangular" height={184} />
        </Grid>

        {/* Additional Info Skeletons */}
        <Grid item>
          <Skeleton variant="rectangular" height={184} />
        </Grid>
      </Grid>
    </>
  );
};

export default QuickSectionSkeleton;
