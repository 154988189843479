import React, { CSSProperties } from 'react';
import './Loading.css';

interface Loading {
  style?: CSSProperties;
}

const Loading: React.FC<Loading> = (props) => {
  const { style } = props;
  return (
    <div className="loadingContainer" style={style}>
      <div className="loadingWrapper">
        <div className="loadingDot firstDot"></div>
        <div className="loadingDot secondDot"></div>
        <div className="loadingDot thirdDot"></div>
        <div className="loadingDot fourthDot"></div>
      </div>
    </div>
  );
};

export default Loading;
