import React, { useEffect, useState } from 'react';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Box, Typography } from '@mui/material';
import { TextField } from '@material-ui/core';
import './DateRangePicker.css';

interface DateRangePickerProps {
  startDate: Date | null;
  endDate: Date | null;
  onStartDateChange: (date: Date | null) => void;
  onEndDateChange: (date: Date | null) => void;
  visibleDays?: number; // Number of days/months visible in the calendar
  rangeInDays?: number; // Optional range for the end date
  startLabel?: string;
  endLabel?: string;
}

const CustomDateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  visibleDays = 90, // Default to 3 months
  rangeInDays = 7,
  startLabel = 'Start Date',
  endLabel = 'End Date',
}) => {
  const currentDateTime = new Date();

  // States
  const [internalStartDate, setInternalStartDate] = useState<Date | null>(
    startDate || new Date(currentDateTime.getTime() - 60 * 60 * 1000) // Default: 1 hour before now
  );
  const [internalEndDate, setInternalEndDate] = useState<Date | null>(
    endDate || currentDateTime // Default: now
  );

  const [error, setError] = useState<string | null>(null);

  // Set minimum and maximum dates for calendar
  const minSelectableDate = new Date(
    currentDateTime.getTime() - visibleDays * 24 * 60 * 60 * 1000
  ); // Current date - visibleDays
  const maxSelectableDate = currentDateTime;

  // Effect: Update end date when start date changes
  useEffect(() => {
    if (internalStartDate) {
      let adjustedEndDate = new Date(internalStartDate);
      adjustedEndDate.setDate(adjustedEndDate.getDate() + rangeInDays);

      // Ensure end date does not exceed the current date and time
      if (adjustedEndDate > maxSelectableDate) {
        adjustedEndDate = maxSelectableDate;
      }

      // Update end date if it is less than start date or not within range
      if (!internalEndDate || internalEndDate < internalStartDate) {
        setInternalEndDate(adjustedEndDate);
        onEndDateChange(adjustedEndDate);
      }
    }
  }, [internalStartDate, rangeInDays]);

  // Handle Start Date Change
  const handleStartDateChange = (date: Date | null) => {
    if (date && date <= maxSelectableDate) {
      setInternalStartDate(date);
      onStartDateChange(date);

      // Auto-shift end date to 7 days after start date
      let newEndDate = new Date(date);
      newEndDate.setDate(newEndDate.getDate() + rangeInDays);

      // Cap the end date to the current date and time
      if (newEndDate > maxSelectableDate) {
        newEndDate = maxSelectableDate;
      }

      setInternalEndDate(newEndDate);
      onEndDateChange(newEndDate);
      setError(null);
    }
  };
  // Handle End Date Change with Validation
  const handleEndDateChange = (date: Date | null) => {
    if (date && internalStartDate) {
      if (date < internalStartDate) {
        setError('End time must be greater than start time.');
      } else if (date > maxSelectableDate) {
        setError('End time cannot be in the future.');
      } else {
        setInternalEndDate(date);
        onEndDateChange(date);
        setError(null);
      }
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box display="flex" flexDirection="column">
        {/* Date Pickers Container */}
        <Box display="flex" justifyContent="space-between" gap={2}>
          {/* Start Date Picker */}
          <DateTimePicker
            label={startLabel}
            value={internalStartDate}
            onChange={handleStartDateChange}
            minDate={minSelectableDate}
            maxDate={maxSelectableDate}
            disableFuture={true}
            format="dd/MM/yyyy, hh:mm a"
            TextFieldComponent={(props) => (
              <TextField
                {...props}
                variant="outlined"
                className="dateInputBox"
              />
            )}
          />

          {/* End Date Picker */}
          <DateTimePicker
            label={endLabel}
            value={internalEndDate}
            onChange={handleEndDateChange}
            minDate={internalStartDate || minSelectableDate}
            maxDate={maxSelectableDate}
            disableFuture={true}
            format="dd/MM/yyyy, hh:mm a"
            TextFieldComponent={(props) => (
              <TextField
                {...props}
                variant="outlined"
                className="dateInputBox"
              />
            )}
          />
        </Box>

        {/* Validation Error Message */}
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
      </Box>
    </MuiPickersUtilsProvider>
  );
};

export default CustomDateRangePicker;
