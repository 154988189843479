import { useState, useCallback, useEffect, useRef } from 'react';
import { handleError } from '../utils/config';
import { ERRORS } from '../utils/constants';
import { useHistory } from 'react-router-dom';

interface UseFetchDataOptions<T, Args extends any[]> {
  fetchData: (...args: Args) => Promise<T>;
  dependencies?: any[]; // Optional dependencies to trigger auto-fetch
  fetchOnMount?: boolean;
}

export const useFetchData = <T, Args extends any[] = []>({
  fetchData,
  dependencies = [],
  fetchOnMount,
}: UseFetchDataOptions<T, Args>) => {
  const [data, setData] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<any | null>(null);
  const history = useHistory();
  const isFetching = useRef(false); // Prevent overlapping fetch calls

  const fetch = useCallback(
    async (...args: any) => {
      if (isFetching.current) return; // Skip if already fetching
      isFetching.current = true; // Mark fetching as active
      setLoader(true);
      setError(null); // Reset error state before new fetch
      try {
        const result = await fetchData(...args);
        setData(result);
      } catch (e: any) {
        setError(e); // Capture and set error
        if (e === ERRORS.NETWORK_ERROR) {
          setError(e); // Handle specific network errors
        } else if (e?.error?.message) {
          handleError(e, history); // Handle general API errors
        }
      } finally {
        setLoader(false);
        isFetching.current = false; // Reset fetching flag
      }
    },
    [fetchData, history]
  );

  // Automatically fetch when dependencies change (if provided)
  useEffect(() => {
    if (fetchOnMount || dependencies.length > 0) {
      fetch();
    }
  }, dependencies); // Add fetch to dependencies to prevent stale closures

  return { data, loader, error, fetch };
};
