import React from 'react';
import { Link } from 'react-router-dom';
import WelcomePageSVG from '../../assets/images/welcomePageImg.png';
import '../Errors/404.css';

export const TrialPlanConfirmation: React.FC = () => {
  const company = localStorage.getItem('CompanyName');
  return (
    <>
      {localStorage.getItem('CompanyName') != null ? (
        <div className="outerContainer  error container">
          <div className="justify-Center">
            <img src={WelcomePageSVG} width={500} height={500} alt="" />
          </div>
          <div className="innerContainer12">
            <div className="container-404  ">
              <div className="freeTrialPlanWelcome">
                Welcome To CloudForestX!
              </div>
              <div className=" confirmText mt-20">
                Please Confirm to begin your 7 day free trail.
              </div>
              <Link
                className="home-link mt-10"
                to={{ pathname: `/${company}/accounts` }}
              >
                <button className="btn btn-primary">Get Started</button>
              </Link>

              <p className="mt-10">
                <i>
                  <b>No credit card required.</b>
                </i>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default TrialPlanConfirmation;
