import { inventoryReportService } from '../../services';

export const config: any = {
  getServiceResourceCost: (params: any) =>
    inventoryReportService.getServiceResourceCost(params),
  getServiceWiseCost: (params: any) =>
    inventoryReportService.getServiceWiseCost(params),
  getServiceReportCsv: (params: any) =>
    inventoryReportService.getServiceReportCsv(params),
  getBillingSummary: (params: any) =>
    inventoryReportService.getBillingSummary(params),
};
export const awsRegions = [
  'us-east-1',
  'us-east-2',
  'us-west-1',
  'us-west-2',
  'ca-central-1',
  'eu-central-1',
  'eu-west-1',
  'eu-west-2',
  'eu-west-3',
  'eu-north-1',
  'eu-south-1',
  'ap-east-1',
  'ap-south-1',
  'ap-northeast-1',
  'ap-northeast-2',
  'ap-northeast-3',
  'ap-southeast-1',
  'ap-southeast-2',
  'sa-east-1',
  'me-south-1',
];

export const azureLocations = [
  'DE West Central',
  'Central India',
  // 'East US',
  'IN South',
  'centralindia',
  // 'WESTUS',
  'US West 2',
  'Intercontinental',
  // 'West US',
  'Asia',
  'US West Central',
  // 'Unknown',
  'US South Central',
  'US Central',
  'IN Central',
  'US North Central',
  'US East',
  'westus',
  'US West',
  // 'eastus',
  'northeurope',
  'US East 2',
  'CENTRALINDIA',
  'NORTHEUROPE',
  // 'EASTUS',
];

//   [
//   'Central US',
//   'East US',
//   'East US 2',
//   'North Central US',
//   'South Central US',
//   'West Central US',
//   'West US',
//   'West US 2',
//   'West US 3',
//   'South Africa North',
//   'East Asia',
//   'Southeast Asia',
//   'Australia Central',
//   'Australia East',
//   'Australia Southeast',
//   'Brazil South',
//   'Canada Central',
//   'Canada East',
//   'China Non-Regional',
//   'China East',
//   'China East 2',
//   'China North',
//   'China North 2',
//   'China North 3',
//   'North Europe',
//   'West Europe',
//   'France Central',
//   'Germany West Central',
//   'Central India',
//   'South India',
//   'Israel Central',
//   'Italy North',
//   'Japan East',
//   'Japan West',
//   'Korea Central',
//   'Norway East',
//   'Poland Central',
//   'Qatar Central',
//   'Sweden Central',
//   'Switzerland North',
//   'UAE North',
//   'UK South',
//   'UK West',
// ];

export const customColors = [
  '#d04d6d',
  '#7ee38b',
  '#ffe119',
  '#8298e5',
  '#f58231',
  '#b85ed3',
  '#46f0f0',
  '#e17ddc',
  '#bcf60c',
  '#fabebe',
  '#4bd3d3',
  '#e6beff',
  '#ce9e67',
  '#fffac8',
  '#b04646',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#4040a4',
  '#808080',
  '#664b4b',
  '#201f1f',
  '#3A66A3',
  '#FFD700',
  '#4a954a',
  '#f4a341',
  '#8e49bf',
  '#9370DB',
  '#6A5ACD',
  '#20B2AA',
  '#87CEFA',
  '#00FA9A',
  '#a25ae6',
  '#00FF00',
  '#f67a64',
  '#4682B4',
  '#FF4500',
  '#ADFF2F',
  '#D2691E',
  '#00CED1',
];
