import { createSlice } from '@reduxjs/toolkit';

interface UIState {
  collapsed: boolean;
}

const initialState: UIState = {
  collapsed: false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleCollapsed: (state) => {
      state.collapsed = !state.collapsed;
    },
    setCollapsed: (state, action) => {
      state.collapsed = action.payload;
    }
  },
});

export const { toggleCollapsed, setCollapsed } = uiSlice.actions;
export default uiSlice.reducer;