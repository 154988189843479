import { useContext } from 'react';
import { ZoomContext } from '../components/HealthMonitoring/ZoomContext';

// Custom hook to use the ZoomContext
export const useZoomContext = () => {
  const context = useContext(ZoomContext);
  if (!context) {
    throw new Error('useZoomContext must be used within a ZoomProvider');
  }
  return context;
};
