import React, { useState, useEffect } from 'react';
import { formatBytes, formatToFiveDecimals } from '../../utils/config';
import { useZoomContext } from '../../hooks/useModalZoomContext';

interface TableLegendProps {
  chartInstance: echarts.ECharts | null;
  seriesData: any[];
  isMemoryGraph: boolean;
  displayUnit: string;
}

type SortField = 'name' | 'mean' | 'last' | 'max' | 'min';
type SortDirection = 'asc' | 'desc';

interface TableRow {
  name: string;
  mean: number;
  last: number;
  max: number;
  min: number;
  color: string;
  visible: boolean;
  rawMean: number;
  rawLast: number;
  rawMax: number;
  rawMin: number;
}

export const TableLegend: React.FC<
  TableLegendProps & {
    initialSort?: { field: SortField; direction: SortDirection };
  }
> = ({
  chartInstance,
  seriesData,
  isMemoryGraph,
  displayUnit,
  initialSort = { field: 'last', direction: 'desc' }, // Default to sorting by last value, descending
}) => {
  const [tableData, setTableData] = useState<TableRow[]>([]);
  const [sortField, setSortField] = useState<SortField>(initialSort.field);
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    initialSort.direction
  );
  const { isZoomedIn } = useZoomContext();

  // Calculate statistics for the table
  useEffect(() => {
    if (!seriesData?.length) return;

    const newTableData = seriesData.map((series) => {
      // Filter out NaN values
      const validData = series.data.filter(
        (value: any) => !isNaN(Number(value))
      );

      // Calculate statistics
      const mean =
        validData.length > 0
          ? validData.reduce((sum: any, val: any) => sum + Number(val), 0) /
            validData.length
          : 0;
      const last =
        series.data.length > 0
          ? Number(series.data[series.data.length - 1])
          : 0;
      const max =
        validData.length > 0
          ? Math.max(...validData.map((val: any) => Number(val)))
          : 0;
      const min =
        validData.length > 0
          ? Math.min(...validData.map((val: any) => Number(val)))
          : 0;

      return {
        name: series.name,
        color: series.color,
        visible: true,
        rawMean: mean,
        rawLast: last,
        rawMax: max,
        rawMin: min,
        mean,
        last,
        max,
        min,
      };
    });

    // Sort the data initially before setting it
    const sortedData = sortTableData(newTableData, sortField, sortDirection);

    setTableData(sortedData);
  }, [seriesData]);

  // Helper function to sort the table data
  const sortTableData = (
    data: TableRow[],
    field: SortField,
    direction: SortDirection
  ): TableRow[] => {
    return [...data].sort((a: any, b: any) => {
      let valueA, valueB;

      // Select the raw numeric values for sorting
      if (field === 'name') {
        valueA = a.name;
        valueB = b.name;
        return direction === 'asc'
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        valueA = a[`raw${field.charAt(0).toUpperCase() + field.slice(1)}`];
        valueB = b[`raw${field.charAt(0).toUpperCase() + field.slice(1)}`];
        return direction === 'asc' ? valueA - valueB : valueB - valueA;
      }
    });
  };

  // Format values for display
  const formatValue = (value: number) => {
    if (isMemoryGraph) {
      const formatted = formatBytes(value);
      return `${Number(formatted.value).toFixed(2)} ${formatted.unit}`;
    }
    return formatToFiveDecimals(value) + displayUnit;
  };

  // Toggle series visibility - fixed version
  const toggleSeries = (seriesName: string) => {
    if (!chartInstance) return;

    // First update our local state
    setTableData((prevData) =>
      prevData.map((item) =>
        item.name === seriesName ? { ...item, visible: !item.visible } : item
      )
    );

    // Then dispatch the action to the chart
    // This is the key fix - using the proper legend action
    chartInstance.dispatchAction({
      type: 'legendToggleSelect',
      name: seriesName,
    });
  };

  // Handle column sorting
  const handleSort = (field: SortField) => {
    const newDirection =
      sortField === field ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'desc';

    setSortField(field);
    setSortDirection(newDirection);

    // Sort the data
    const sortedData = sortTableData(tableData, field, newDirection);

    setTableData(sortedData);
  };

  // Render sort indicator
  const renderSortIndicator = (field: SortField) => {
    if (sortField !== field) return null;
    return sortDirection === 'asc' ? ' ↑' : ' ↓';
  };

  return (
    <div
      className="custom-table-legend"
      style={{
        width: '100%', // Ensure full width
      }}
    >
      <div
        style={{
          width: '100%',
          maxHeight: isZoomedIn ? '200px' : '136px', // Set max height for scrolling
          overflow: 'auto', // Enable scrolling
          position: 'relative', // For sticky header
        }}
      >
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead
            style={{
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
              // zIndex: 10,
            }}
          >
            <tr>
              <th
                onClick={() => handleSort('name')}
                style={{
                  cursor: 'pointer',
                  padding: '8px',
                  textAlign: 'left',
                  borderBottom: '1px solid #ddd',
                }}
              >
                Name{renderSortIndicator('name')}
              </th>
              <th
                onClick={() => handleSort('mean')}
                style={{
                  cursor: 'pointer',
                  padding: '8px',
                  textAlign: 'right',
                  borderBottom: '1px solid #ddd',
                  // Set width to ensure columns are properly sized
                }}
              >
                Mean{renderSortIndicator('mean')}
              </th>
              <th
                onClick={() => handleSort('last')}
                style={{
                  cursor: 'pointer',
                  padding: '8px',
                  textAlign: 'right',
                  borderBottom: '1px solid #ddd',
                  width: '20%',
                }}
              >
                Last{renderSortIndicator('last')}
              </th>
              <th
                onClick={() => handleSort('max')}
                style={{
                  cursor: 'pointer',
                  padding: '8px',
                  textAlign: 'right',
                  borderBottom: '1px solid #ddd',
                }}
              >
                Max{renderSortIndicator('max')}
              </th>
              <th
                onClick={() => handleSort('min')}
                style={{
                  cursor: 'pointer',
                  padding: '8px',
                  textAlign: 'right',
                  borderBottom: '1px solid #ddd',
                }}
              >
                Min{renderSortIndicator('min')}
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row) => (
              <tr
                key={row.name}
                onClick={() => toggleSeries(row.name)}
                style={{
                  cursor: 'pointer',
                  opacity: row.visible ? 1 : 0.5,
                  backgroundColor: row.visible ? 'transparent' : '#f5f5f5',
                }}
              >
                <td style={{ padding: '8px', borderBottom: '1px solid #eee' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: '12px',
                        height: '12px',
                        backgroundColor: row.color,
                        marginRight: '8px',
                        display: 'inline-block',
                        borderRadius: '3px',
                      }}
                    />
                    {row.name}
                  </div>
                </td>
                <td className="systemHealthTableData">
                  {formatValue(row.rawMean)}
                </td>
                <td className="systemHealthTableData">
                  {formatValue(row.rawLast)}
                </td>
                <td className="systemHealthTableData">
                  {formatValue(row.rawMax)}
                </td>
                <td className="systemHealthTableData">
                  {formatValue(row.rawMin)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
