import { useState, useEffect } from 'react';
import { config, plansInfo } from './config';
import '../../index.css';
import chip from '../../assets/images/chip.png';
import cardBg from '../../assets/images/cardBg.png';
import DeleteIcon from '@mui/icons-material/Delete';
import Radio from '@mui/material/Radio';
import {
  Card,
  CardContent,
  Button,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import './Billing.css';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useDispatch, useSelector } from 'react-redux';
import { userState } from '../../store';
import { handleError } from '../../utils/config';
import { useHistory } from 'react-router';
import CarIcon from '../../assets/images/car.png';
import PlanIcon from '../../assets/images/airplane.png';
import RocketIcon from '../../assets/images/rocket.png';
import AlertModal from '../../components/Modals/Alert/Alert';
import { userActions } from '../../store/userSlice';
import BuyNow from '../../components/Modals/BuyNow/BuyNow';
import { Plan } from '../../schemas/billing.schema';
import { RoutesFun } from '../../schemas';
import './Billing.css';
import Loading from '../../components/Loading/Loading';

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },

  titleBox: {
    display: 'flex',
    alignItems: 'top',
  },
  cardBorder: {
    width: '400px',
    height: '240px',
    backgroundColor: '#F6F6F6',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    padding: '15px 15px',
    borderRadius: '15px',
    // border: `1px solid ${theme.palette.grey[300]}`,
  },
  activeCardBorder: { border: '1px solid #34A853' },
  iconSize: { width: '20%', height: '20%', marginRight: '40px' },
  card: {
    width: '30%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(1),
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
  },
  bgGreen: {
    background: '#E2FFF2',
    padding: '15px 15px 0px 15px',
    // borderRadius: '12px',
  },

  buttonWidth: { width: '100%' },
  bgBlue: {
    background: '#CAE2FF',
    padding: '15px 15px 0px 15px',
    // borderRadius: '12px',
  },
  content: {
    flex: 1,
  },
  currency: {
    color: 'green',
  },
  title: {
    fontSize: '25px',
    fontWeight: 'bold',
    marginLeft: '20px',
  },
  cardTitle: {
    fontSize: '20px',
    fontWeight: 400,
  },
  feature: {
    // marginBottom: theme.spacing(1),
    width: '75%',
    margin: '4% auto',
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: '24px',
  },
  button: {
    alignItems: 'center',
    fontSize: '16px',
    // marginBottom: theme.spacing(3),
    backgroundColor: '#0078DB',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#1565c0',
    },
  },

  cancelSubButton: {
    width: '75%',
    alignItems: 'center',
    marginLeft: '12%',
    marginBottom: theme.spacing(3),
    backgroundColor: '#DC3545',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: 'red',
    },
  },
  disabledButton: {
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    backgroundColor: '#4BB543',
    color: 'white',
    cursor: 'default',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'green',
    },
  },
  loadingButton: {
    alignItems: 'center',
    // marginBottom: theme.spacing(2),
    // backgroundColor: '#4BB543',
    // color: 'white',
    cursor: 'default',
    fontWeight: 600,
    // '&:hover': {
    //   backgroundColor: 'green',
    // },
  },
  infoButton: {
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    cursor: 'help',
    backgroundColor: '#0078DB',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#1976d2',
    },
  },
  editButton: {
    width: '40%',
    alignItems: 'center',
    // marginLeft: '10%',
    marginBottom: theme.spacing(2),
    color: theme.palette.common.white,
    background: 'slategrey',
    '&:hover': {
      backgroundColor: '#5B707F',
    },
  },
  deleteButton: {
    width: '2vw',
    alignItems: 'center',
    // fontSize: '20px',
    // marginRight: '10%',
    marginBottom: theme.spacing(2),
    color: theme.palette.common.white,
    background: '#dc4545',
    '&:hover': {
      backgroundColor: 'red',
    },
  },
  // updateButton: {
  //   width: '40%',
  //   alignItems: 'center',
  //   marginBottom: theme.spacing(2),
  //   backgroundColor: '#1976d2',
  //   color: theme.palette.common.white,
  //   '&:hover': {
  //     backgroundColor: '#1565c0',
  //   },
  // },
  subscriptionButton: {
    // width: '80%',
    alignItems: 'center',
    // marginLeft: '10%',
    marginBottom: theme.spacing(2),
    // color: 'red',
    // border: '1px outset gray',
    fontWeight: 700,
  },
  root: {
    width: '400px',
    height: '240px',
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(1),
    color: '#b7b7b7',
  },
  cardImg: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: `url(${cardBg})`,
    borderRadius: '15px',
  },
  cardLogo: {
    marginBottom: theme.spacing(2),
    // textAlign: 'right',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'center',
    height: 'auto',
    margin: 'auto',
  },
  cardNumber: {
    fontSize: '19px',
    marginBottom: theme.spacing(3),
    fontWeight: 600,
    display: 'flex',
    letterSpacing: '4px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  font700: {
    fontWeight: 700,
  },
  cardBox: {
    height: '250px',
    display: 'flex',

    // flexDirection: 'row',
    overflowX: 'scroll',

    // justifyContent: 'center',
  },
  marchantLogo: {
    width: '34%',
    aspectRatio: '3/2',
    objectFit: 'contain',
    mixBlendMode: 'color',
  },
  pendingButton: {
    width: '80%',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 600,
    alignItems: 'center',
    height: '35px',
    alignContent: 'center',
    marginBottom: theme.spacing(2),
    backgroundColor: '#ffffd7',
    borderRadius: '5px',
    color: '#d3b200',
  },
  insidePendingText: {
    margin: '2.8% 25%',
  },
  planWithPriceSection: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    width: '70%',
  },
}));

const getIconForPlan: any = {
  Basic: CarIcon,
  Growth: PlanIcon,
  'Growth+': RocketIcon,
};

const useRadioStyles = makeStyles(() => ({
  largeRadio: {
    '& svg': {
      fontSize: '2rem', // Increase the font size for the radio icon
    },
    '& span': {
      fontSize: '1.5rem', // Increase the font size for the radio label
    },
  },
}));
const Billing = () => {
  const [networkError, setNetworkError] = useState('');
  const history = useHistory();
  const user = useSelector(userState)?.userData;
  const [loader, setLoader] = useState(false);
  const [cardsData, setCardsData] = useState<any>({});
  const [deleteCardId, setDeleteCardId] = useState<string>('');
  const [plan, setPlan] = useState<any>();
  const [plansData, setPlansData] = useState<Plan[]>([]);
  const [buyModal, setBuyModal] = useState(false);
  const [cancelSubsModal, setCancelSubsModal] = useState(false);
  const [deleteCardModal, setDeleteCardModal] = useState<boolean>(false);
  const [requestQuoteModal, setRequestQuoteModal] = useState<boolean>(false);
  const [companyDetail, setCompanyDetail] = useState<any>();
  const [autoRenewal, setAutoRenewal] = useState(false);
  const [planChange, setPlanChange] = useState(false);
  const Routes = RoutesFun();
  const dispatch = useDispatch();
  const userDataState = useSelector(userState).userData;

  const onCloseBuyModal = () => setBuyModal(false);
  const onCloseCancelSubsModal = () => setCancelSubsModal(false);
  const onDeleteCardModal = () => setDeleteCardModal(false);
  const onRequestQuoteModal = () => setRequestQuoteModal(false);

  const getCardDetails = async () => {
    try {
      const stripId = user?.stripe_customer_id;
      setLoader(true);
      if (stripId) {
        const result = await config.getCard(stripId);
        if (
          result?.result &&
          Object.keys(result?.result).length &&
          typeof result?.result !== 'string'
        ) {
          if (result?.result.defaultCard)
            result.result.defaultCard = {
              ...result.result.defaultCard,
              IsActive: true,
            };
          setCardsData(result.result);
        }
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }

      throw e;
    } finally {
      setLoader(false);
    }
  };

  const setAsDefault = async (PaymentMethodId: string) => {
    try {
      setLoader(true);

      const params = {
        stripe_customer_id: user?.stripe_customer_id,
        PaymentMethodId,
      };
      const result = await config.setAsDefault(params);
      if (result.message) {
        dispatch(
          userActions.replaceUserState({
            successMessage: result.message,
          })
        );
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }

      throw e;
    } finally {
      getCardDetails();
      setLoader(false);
    }
  };

  const getPlanDetails = async () => {
    try {
      const companyName = user?.CompanyName;
      const result = await config.getPlans(companyName);
      setPlansData(result);
    } catch (e: any) {
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      throw e;
    } finally {
      setLoader(false);
    }
  };

  const classes = useStyles();
  const radioClasses = useRadioStyles();

  useEffect(() => {
    if (user) {
      getCardDetails();
      getPlanDetails();
      getCompanyDetails();
    }
  }, [user]);

  const getCompanyDetails = async () => {
    try {
      const result = await config.companyDetails();
      if (result) {
        setAutoRenewal(
          result.SubscriptionMode === 'Auto Renewal' ? true : false
        );
        setCompanyDetail(result);
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }

      throw e;
    } finally {
      setLoader(false);
    }
  };

  const handleBillingCheckout = async (selectedAutoRenew: boolean) => {
    try {
      setLoader(true);

      const planData: Plan[] = plansData?.filter(
        (planObj: any) => planObj.name === plan
      );

      const result = await config.createCheckout(
        user?.stripe_customer_id,
        planData[0]?.stripe_plan_id,
        user?.PlanType,
        companyDetail?.PlanSubscriptionId,
        !selectedAutoRenew,
        companyDetail?.SubscriptionStatus
      );

      if (result) {
        if (result.sessionMessage) window.open(result.sessionMessage, '_self');

        // Open the URL in a new tab
        if (result.noCardMsg) {
          dispatch(
            userActions.replaceUserState({
              errorMessage: result.noCardMsg,
            })
          );
          return;
        }
        setBuyModal(false);
        dispatch(
          userActions.replaceUserState({
            successMessage: result.message,
          })
        );

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        dispatch(
          userActions.replaceUserState({
            errorMessage: e.error.message.message,
          })
        );
        handleError(e, history);
      }

      throw e;
    } finally {
      setLoader(false);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      setLoader(true);
      const result = await config.cancelSubscription(
        companyDetail?.PlanSubscriptionId
      );
      if (result) {
        setCancelSubsModal(false);

        dispatch(
          userActions.replaceUserState({
            successMessage: result.message,
          })
        );

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }

      throw e;
    } finally {
      setLoader(false);
    }
  };

  if (networkError) {
    throw new Error(networkError);
  }

  // const handleRetryPayment = async () => {
  //   try {
  //     const stripId = user?.stripe_customer_id;
  //     setLoader(true);
  //     const result = await config.retryPayment(
  //       stripId,
  //       companyDetail?.PlanSubscriptionId
  //     );
  //     if (result) {
  //       dispatch(
  //         userActions.replaceUserState({
  //           successMessage: result.message,
  //         })
  //       );

  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 1000);
  //     }
  //   } catch (e: any) {
  //     if (e && e.error && e.error.message) {
  //       dispatch(
  //         userActions.replaceUserState({
  //           errorMessage: e.error.message.message,
  //         })
  //       );
  //       handleError(e, history);
  //     }
  //     throw e;
  //   } finally {
  //     setLoader(false);
  //     // if (user) {
  //     //   user?.RemainingDays === undefined ||
  //     //   user?.RemainingDays === 0 ||
  //     //   user?.RemainingDays < 0
  //     //     ? checkRemainingDays()
  //     //     : '';
  //     // }
  //   }
  // };

  const handleAddCard = async () => {
    try {
      const stripId = user?.stripe_customer_id;
      setLoader(true);
      const result = await config.addCard(stripId);
      if (result) {
        window.open(result, '_self');
      }
    } catch (e: any) {
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
      throw e;
    } finally {
      setLoader(false);
    }
  };

  const handleRequestQuoteModal = async () => {
    try {
      setLoader(true);
      const companyName = user?.CompanyName;
      const result = await config.requestQuoteModal(companyName, plan);
      if (result) {
        setRequestQuoteModal(false);
        dispatch(
          userActions.replaceUserState({
            successMessage: result.message,
          })
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }

      throw e;
    } finally {
      setLoader(false);
    }
  };

  const handleDeleteCard = async () => {
    try {
      setLoader(true);
      const result = await config.deleteCard(deleteCardId);
      if (result) {
        setDeleteCardModal(false);
        dispatch(
          userActions.replaceUserState({
            successMessage: result.message,
          })
        );

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }

      throw e;
    } finally {
      setLoader(false);
    }
  };
  return (
    <>
      {/* {loader ? <LoadingScreen /> : ''} */}
      <div className="mb-10">
        {/* <span className="securityHeading ml-25">Card Details</span> */}
        <div
          className="inventoryHeader mb-10"
          style={{ borderBottom: '1px solid #eaeaea' }}
        >
          <span className="inventoryHeading font-26px fw-300">
            Card Details
          </span>
        </div>
        {loader ? (
          <Loading style={{ height: '30vh' }} />
        ) : (
          <div className=" ml-25 cardOuterCont">
            <div className="flex">
              {cardsData?.defaultCard &&
              Object.keys(cardsData?.defaultCard).length ? (
                <div>
                  <Card className={`${classes.root} ${classes.cardImg} mr-20`}>
                    <CardContent>
                      <div className={classes.cardLogo}>
                        <img
                          style={{ marginTop: '10px' }}
                          src={chip}
                          alt="chip logo"
                          height="30"
                        />
                        <img
                          className={classes.marchantLogo}
                          src={`https://logo.clearbit.com/${cardsData?.defaultCard?.card.brand}.com`}
                          alt={`${cardsData?.defaultCard?.card.brand} logo`}
                          height="50"
                        />
                      </div>
                      <p className={classes.cardNumber}>
                        **** **** **** {cardsData?.defaultCard?.card.last4}
                      </p>
                      <div className={classes.cardInfo}>
                        <p className={classes.font700}>Exp:</p>
                        <p className={classes.font700}>
                          {cardsData?.defaultCard?.card.exp_month}/
                          {cardsData?.defaultCard?.card.exp_year}
                        </p>
                      </div>
                      <div className={classes.cardInfo}>
                        <p className={classes.font700}>Card Brand:</p>
                        <p className={classes.font700}>
                          {cardsData?.defaultCard?.card.brand?.toUpperCase()}
                        </p>
                      </div>
                    </CardContent>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                      }}
                    >
                      <Button
                        className={classes.deleteButton}
                        onClick={() => {
                          setDeleteCardId(cardsData?.defaultCard.id);
                          setDeleteCardModal(true);
                        }}
                      >
                        <DeleteIcon style={{ fontSize: '18px' }} />
                      </Button>
                    </div>
                  </Card>
                  <div className="ml-90">
                    <Tooltip title="Default payment card">
                      <Radio
                        className={`ml-90 mt-10 ${radioClasses.largeRadio}`}
                        checked={cardsData?.defaultCard.IsActive}
                      />
                    </Tooltip>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {Object.keys(cardsData).length &&
              cardsData.nonDefaultCards.length ? (
                cardsData.nonDefaultCards.map(
                  (eachCard: any, index: number) => (
                    <div key={eachCard.id + index}>
                      <Card
                        className={`${classes.root} ${classes.cardImg} mr-20`}
                      >
                        <CardContent>
                          <div className={classes.cardLogo}>
                            <img
                              style={{ marginTop: '10px' }}
                              src={chip}
                              alt="chip logo"
                              height="30"
                            />
                            <img
                              className={classes.marchantLogo}
                              src={`https://logo.clearbit.com/${eachCard.card.brand}.com`}
                              alt={`${eachCard.card.brand} logo`}
                              height="50"
                            />
                          </div>
                          <p className={classes.cardNumber}>
                            **** **** **** {eachCard.card.last4}
                          </p>
                          <div className={classes.cardInfo}>
                            <p className={classes.font700}>Exp:</p>
                            <p className={classes.font700}>
                              {eachCard.card.exp_month}/{eachCard.card.exp_year}
                            </p>
                          </div>
                          <div className={classes.cardInfo}>
                            <p className={classes.font700}>Card Brand:</p>
                            <p className={classes.font700}>
                              {eachCard.card.brand?.toUpperCase()}
                            </p>
                          </div>
                        </CardContent>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                          }}
                        >
                          <Button
                            className={classes.deleteButton}
                            onClick={() => {
                              setDeleteCardId(eachCard.id);
                              setDeleteCardModal(true);
                            }}
                          >
                            <DeleteIcon style={{ fontSize: '18px' }} />
                          </Button>
                        </div>
                      </Card>
                      <div className="ml-90">
                        <Tooltip title="Set as default">
                          <Radio
                            className={`ml-90 mt-10 ${radioClasses.largeRadio}`}
                            onClick={() => setAsDefault(eachCard.id)}
                            checked={false}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  )
                )
              ) : (
                <></>
              )}
            </div>
            <div>
              <Card className={classes.cardBorder}>
                <CardContent>
                  <p className={`${classes.cardTitle} mb-25`}>Add A New Card</p>
                  <p className="font-16px mb-10  " style={{ color: 'gray' }}>
                    Go to add card for create a new card.
                  </p>
                  <p className="font-16px  " style={{ color: 'gray' }}>
                    You can add, edit and delete card any time.
                  </p>
                </CardContent>
                <Button
                  variant="contained"
                  onClick={handleAddCard}
                  className={`${classes.button} mt-15  mb-15 ml-15 w-90`}
                >
                  Add a new card
                </Button>
              </Card>
            </div>
          </div>
        )}
      </div>

      <div>
        <div
          className="inventoryHeader mb-10"
          style={{ borderBottom: '1px solid #eaeaea' }}
        >
          <span className="inventoryHeading font-26px fw-300">Plans</span>
        </div>
        {loader ? (
          <Loading style={{ height: '30vh' }} />
        ) : (
          <div className={classes.container}>
            {Object.entries(plansInfo).map(([plan, features]) => (
              <Card
                key={plan}
                className={
                  user?.PlanType === plan &&
                  companyDetail?.SubscriptionStatus === 'active'
                    ? `${classes.card}  ${classes.activeCardBorder}`
                    : (companyDetail?.SubscriptionStatus === 'incomplete' ||
                        companyDetail?.SubscriptionStatus === 'past due') &&
                      user?.PlanType === plan
                    ? `${classes.card} `
                    : classes.card
                }
              >
                <div className="B-outerContainer">
                  <CardContent className={classes.content}>
                    <div
                      className={
                        companyDetail?.PlanType === plan
                          ? classes.bgGreen
                          : classes.bgBlue
                      }
                    >
                      <div className={`${classes.titleBox} ${classes.title}`}>
                        <div className={classes.buttonWidth}>
                          <div className="mb-20 mt-10">{plan}</div>
                          <div className="mb-25 mt-10">
                            {!user || loader ? (
                              <>
                                <Button
                                  variant="outlined"
                                  className={classes.loadingButton}
                                >
                                  loading...
                                </Button>
                              </>
                            ) : user?.SubscriptionStatus &&
                              user?.PlanType === plan ? (
                              <div
                                className=""
                                style={{ justifyContent: 'space-around' }}
                              >
                                {companyDetail?.SubscriptionStatus ===
                                  'past due' ||
                                companyDetail?.SubscriptionStatus ===
                                  'incomplete' ? (
                                  <div className={classes.pendingButton}>
                                    <AccessTimeIcon className="font-20px" />
                                    <span className="font-20px">
                                      {' '}
                                      Payment processing...
                                    </span>
                                  </div>
                                ) : companyDetail?.SubscriptionMode ===
                                  'Auto Renewal' ? (
                                  <Button
                                    variant="text"
                                    className={classes.button}
                                    onClick={() => {
                                      history.push({
                                        pathname: Routes.PlanDetails,
                                        state: {
                                          ActiveFeatures:
                                            userDataState?.plan?.features,
                                          PlanPrice:
                                            +plansData?.find(
                                              (value) => value.name === plan
                                            )?.PlanPrice / 100,
                                          PlanImage: getIconForPlan[plan],
                                          PlanName: plan,
                                          PlanDate: companyDetail.PlanDate,
                                          PlanExpiryDays:
                                            companyDetail.PlanDays,
                                        },
                                      });
                                    }}
                                  >
                                    Plan Details
                                  </Button>
                                ) : (
                                  <div>
                                    <p
                                      className="font-18px fw-600"
                                      style={{ color: 'red' }}
                                    >
                                      Ends{' '}
                                      {user?.RemainingDays === 0
                                        ? 'today'
                                        : `in ${
                                            user?.RemainingDays === 1
                                              ? `${user?.RemainingDays} day`
                                              : `${user?.RemainingDays} days`
                                          }`}{' '}
                                    </p>
                                  </div>
                                )}
                              </div>
                            ) : plansData?.some(
                                (value) => value.name === plan
                              ) ? (
                              plansData?.some(
                                (value) =>
                                  value.name === plan &&
                                  value.status === 'active'
                              ) ? (
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    setPlanChange(true);
                                    setBuyModal(true);
                                    setPlan(plan);
                                    setAutoRenewal(false);
                                  }}
                                  className={classes.button}
                                >
                                  Buy Now
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  className={classes.disabledButton}
                                >
                                  Quotation Requested
                                </Button>
                              )
                            ) : (
                              <Tooltip
                                title="Get a detailed plan quotation from our support team."
                                placement="bottom"
                              >
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    setRequestQuoteModal(true);
                                    setPlan(plan);
                                  }}
                                  className={classes.infoButton}
                                >
                                  Request Quote
                                </Button>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                        {/* <div className={classes.currency}>
                        $
                        {+plansData?.find((value) => value.name === plan)
                          ?.PlanPrice / 100}
                      </div> */}
                        {/* {getIconForPlan[plan]} */}
                        <img
                          src={getIconForPlan[plan]}
                          className={`${classes.iconSize} mt-10`}
                          alt=""
                        />
                      </div>
                    </div>
                    {features.map((feature, index) => (
                      <div key={index} className={classes.feature}>
                        {feature}
                        {index === features.length - 1 ? (
                          <></>
                        ) : (
                          <hr color="#F3E7FF" />
                        )}
                      </div>
                    ))}

                    {companyDetail?.PlanType === plan &&
                    companyDetail?.SubscriptionMode === 'Auto Renewal' ? (
                      <Button
                        variant="text"
                        className={classes.cancelSubButton}
                        onClick={() => {
                          setCancelSubsModal(true);
                          setPlan(plan);
                        }}
                      >
                        Cancel Subscription
                      </Button>
                    ) : (
                      <></>
                    )}
                  </CardContent>
                </div>
              </Card>
            ))}
          </div>
        )}
      </div>
      {requestQuoteModal && (
        <AlertModal
          show={requestQuoteModal}
          headerMsg="Request Quote"
          onClose={onRequestQuoteModal}
          metaData={'Are you sure you want to request for quotation'}
          alertFunction={() => handleRequestQuoteModal()}
          buttonMsg="Yes"
        />
      )}
      {deleteCardModal && (
        <AlertModal
          show={deleteCardModal}
          headerMsg="Delete"
          onClose={onDeleteCardModal}
          metaData={'Are you sure you want to remove card'}
          alertFunction={() => handleDeleteCard()}
          buttonMsg="Yes"
        />
      )}
      {cancelSubsModal && (
        <AlertModal
          show={cancelSubsModal}
          headerMsg="Plan"
          onClose={onCloseCancelSubsModal}
          metaData={`Are you sure you want to cancel ${plan} Plan`}
          alertFunction={() => handleCancelSubscription()}
          buttonMsg="Yes"
        />
      )}
      {buyModal && (
        <BuyNow
          show={buyModal}
          headerMsg="Plan"
          onClose={onCloseBuyModal}
          metaData={`Are you sure you want to purchase ${plan} Plan?`}
          buyNowFun={handleBillingCheckout}
          buttonMsg="Yes"
          AutoRenewal={autoRenewal}
          BuyNow={planChange}
        />
      )}
    </>
  );
};

export default Billing;
