import { apiService } from '..';
import { APIService } from '../APIService/APIService';

export class HttpService {
  private apiInstance: any = '';

  constructor(private apiService: APIService) {}

  get(url: string, params?: any, config?: any) {
    this.apiInstance = apiService.create();
    return this.apiInstance.get(url, { params, ...config });
  }

  post(url: string, body: any, config?: any) {
    this.apiInstance = apiService.create();
    return this.apiInstance.post(url, body, config);
  }

  put(url: string, body: any, config?: any) {
    this.apiInstance = apiService.create();
    return this.apiInstance.put(url, body, config);
  }

  patch(url: string, body: any, config?: any) {
    this.apiInstance = apiService.create();
    return this.apiInstance.patch(url, body, config);
  }

  delete(url: string, body: any, config?: any) {
    this.apiInstance = apiService.create();
    return this.apiInstance.delete(url, { data: body, ...config });
  }
}
