import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from '@material-ui/core';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './SecurityScan.css';
import { useSelector } from 'react-redux';
import { formatData } from '../../utils/securityScan/formattedData';
import { Practice } from './practice';
import { useHistory } from 'react-router-dom';
import { config } from './config';
import { CLOUD_PROVIDER_SERVICES } from '../../utils/constants';
import { accountState } from '../../store/index';
import { AzurePractice } from './AzurePractice';
import { handleError } from '../../utils/config';
import { SelectedAccount } from '../../components/SelectAccount/SelectAccount';
import { ALL_VALUE } from '../Invoice/config';
import Loading from '../../components/Loading/Loading';

export const SecurityScan: React.FC = () => {
  const history = useHistory();
  const [loader, setLoader] = React.useState(false);
  const selectedAccountInfo = useSelector(accountState);
  const selectedAccount = useSelector(accountState).selectedAccount;
  const cloudProvider = selectedAccount?.CloudProvider;

  const [isNetworkError, setNetworkError] = React.useState(false);
  const [scanPayload, setScanPayload] = React.useState<any>({
    createdAt: null,
    scanInProgress: null,
    rows: {},
    practices: [],
  });
  const [createdAt, setCreatedAt] = React.useState<string>('');

  const [selectedAccountId, setSelectedAccountId] = useState(
    selectedAccountInfo.selectedAccountId
  );
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(
    selectedAccountInfo.selectedSubscriptionId
  );

  // const [pageLoad, setPagDone] = React.useState(false);

  const fetchDataFunc = async () => {
    try {
      setScanPayload({
        createdAt: null,
        scanInProgress: null,
        rows: {},
        practices: [],
      });
      if (cloudProvider) {
        setLoader(true);
        if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
          const res = await config[CLOUD_PROVIDER_SERVICES.AWS].FetchScanReport(
            {
              AccountId: selectedAccountId,
            }
          );
          const formattedData = formatData(res);
          setCreatedAt(res.createAt);
          setScanPayload({
            createdAt: res.createAt,
            scanInProgress: res.scanInProgress,
            rows: formattedData,
            practices: res.practices,
          });
        } else {
          const AzureId = selectedAccount?.AzureId;

          const res = await config[
            CLOUD_PROVIDER_SERVICES.AZURE
          ].FetchScanReport({
            AzureId,
            SubscriptionId:
              selectedSubscriptionId === 'all'
                ? selectedAccount?.SubscriptionId
                : selectedSubscriptionId,
          });
          const formattedData = formatData(res);
          setCreatedAt(res.createAt);
          setScanPayload({
            createdAt: '',
            scanInProgress: '',
            rows: formattedData,
            practices: res.practices,
          });
          // Security scan in progress. The scan report would be avaliable shortly.
        }
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  // useEffect(() => {
  //   setPagDone(false);
  //   setTimeout(() => {
  //     const name = userPlans?.includes(FEATURES.SECURITY_SCAN);
  //     if (name) {
  //       setPagDone(true);
  //     }
  //   }, 1000);
  // }, [userPlans]);

  if (isNetworkError) {
    throw new Error('Network Error');
  }

  // account selection section
  const onAccountChange = (data: any) => {
    if (data.cloudType === CLOUD_PROVIDER_SERVICES.AWS)
      setSelectedAccountId(data.accountId);
    else if (data.cloudType === CLOUD_PROVIDER_SERVICES.AZURE) {
      if (data.subscriptionName) {
        setSelectedSubscriptionId(data.subscriptionId);
      } else if (data.accountId) {
        setSelectedAccountId(data.accountId);
        setSelectedSubscriptionId(ALL_VALUE);
      }
    }
  };

  useEffect(() => {
    if (selectedAccountId !== ALL_VALUE || selectedSubscriptionId !== ALL_VALUE)
      fetchDataFunc();
  }, [selectedAccountId, selectedSubscriptionId]);

  return (
    <Fragment>
      {/* {loader ? <LoadingScreen /> : ''} */}

      <>
        <div className="sContainer mb-15">
          <Grid item md={12} sm={12} xs={12}>
            <span className="securityHeading">Security</span>
            <span className="mt-10 mr-20">
              <span className="instanceInfo ml-10 mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#3f51b5"
                  className="bi bi-question-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
                </svg>
              </span>
            </span>
          </Grid>

          <SelectedAccount onAccountChange={onAccountChange} />
        </div>
        {loader ? (
          <Loading style={{ height: '60vh' }} />
        ) : selectedAccountId === ALL_VALUE ? (
          <div className="noDataText">
            Please select a subscription or accountId
          </div>
        ) : selectedSubscriptionId === ALL_VALUE &&
          cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE ? (
          <div className="noDataText">
            Please select a subscription or accountId
          </div>
        ) : (
          <>
            <div className="securityScanHeader">
              <div className="serviceText">SERVICES</div>
              {createdAt && Object.keys(scanPayload.rows).length ? (
                <div className="lastScanText mt-10">
                  Last Scan: {moment(createdAt).fromNow()}
                </div>
              ) : (
                <></>
              )}
            </div>

            <Grid container className="mb-50">
              <Grid className="innerContainer">
                {
                  <div
                    className={
                      Object.keys(scanPayload.rows).length
                        ? 'accContainerSS'
                        : 'accContainerH'
                    }
                  >
                    {selectedAccount?.CloudProvider ===
                    CLOUD_PROVIDER_SERVICES.AWS ? (
                      <>
                        {Object.keys(scanPayload.rows).length ? (
                          Object.keys(scanPayload.rows).map(
                            (row: any, i: any) => (
                              <Accordion
                                className="accordionContainerSS"
                                key={'acc' + i}
                              >
                                <AccordionSummary
                                  expandIcon={
                                    <ExpandMoreIcon
                                      fontSize={'large'}
                                      viewBox="0 0 24 24"
                                      sx={{ fontSize: '25px' }}
                                    />
                                  }
                                  key={'instanced' + i}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  className="accordionContainerHeadSS"
                                >
                                  <p className="accordionPracTitle">{row}</p>
                                  <span
                                    className={((percentage) =>
                                      percentage <= 33
                                        ? 'accordionTitleSS mle fatal'
                                        : percentage > 33 && percentage <= 66
                                        ? 'accordionTitleSS mle lightfatal'
                                        : percentage > 66 && percentage < 99
                                        ? 'accordionTitleSS mle warn'
                                        : 'accordionTitleSS mle fine')(
                                      (scanPayload.rows[row].passed /
                                        scanPayload.rows[row].total) *
                                        100
                                    )}
                                  >
                                    {scanPayload.rows[row].passed}/
                                    {scanPayload.rows[row].total} Passed
                                  </span>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <TableContainer
                                    className="accContainer"
                                    component={Paper}
                                  >
                                    <Table
                                      className="table adminTable adminPanelTable"
                                      size="small"
                                      aria-label="collapsible table"
                                    >
                                      <TableHead className="admintableHead">
                                        <TableRow>
                                          <TableCell className="adminTableheading"></TableCell>
                                          <TableCell className="adminTableheading">
                                            Practice
                                          </TableCell>
                                          <TableCell className="adminTableheading">
                                            Description
                                          </TableCell>
                                          <TableCell className="adminTableheading">
                                            Status
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {Object.keys(
                                          scanPayload.rows[row].practices
                                        ).map((practice: any, i: any) => (
                                          <Practice
                                            key={'prac' + i}
                                            practice={
                                              scanPayload.rows[row].practices[
                                                practice
                                              ]
                                            }
                                            meta={scanPayload.practices.find(
                                              (prac: any) =>
                                                prac.Code === practice
                                            )}
                                          ></Practice>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </AccordionDetails>
                              </Accordion>
                            )
                          )
                        ) : selectedAccount &&
                          !loader &&
                          Object.keys(scanPayload.rows) &&
                          !Object.keys(scanPayload.rows).length ? (
                          <div className="noDataText">No Data Found</div>
                        ) : null}
                      </>
                    ) : (
                      <>
                        {Object.keys(scanPayload.rows).length ? (
                          Object.keys(scanPayload.rows).map(
                            (row: any, i: any) => (
                              <Accordion
                                className="accordionContainerSS"
                                key={'acc' + i}
                              >
                                <AccordionSummary
                                  expandIcon={
                                    <ExpandMoreIcon
                                      fontSize={'large'}
                                      viewBox="0 0 24 24"
                                      sx={{ fontSize: '25px' }}
                                    />
                                  }
                                  key={'instanced' + i}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  className="accordionContainerHeadSS"
                                >
                                  <p className="accordionPracTitle">{row}</p>
                                  <span
                                    className={((percentage) =>
                                      percentage <= 33
                                        ? 'accordionTitleSS mle fatal'
                                        : percentage > 33 && percentage <= 66
                                        ? 'accordionTitleSS mle lightfatal'
                                        : percentage > 66 && percentage < 99
                                        ? 'accordionTitleSS mle warn'
                                        : 'accordionTitleSS mle fine')(
                                      (scanPayload.rows[row].passed /
                                        scanPayload.rows[row].total) *
                                        100
                                    )}
                                  >
                                    {scanPayload.rows[row].passed}/
                                    {scanPayload.rows[row].total} Passed
                                  </span>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <TableContainer component={Paper}>
                                    <Table
                                      className="table"
                                      aria-label="collapsible table"
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <TableCell></TableCell>
                                          <TableCell>Practice</TableCell>
                                          <TableCell>Description</TableCell>
                                          <TableCell>Status</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {Object.keys(
                                          scanPayload.rows[row].practices
                                        ).map((practice: any, i: any) => (
                                          <AzurePractice
                                            key={'prac' + i}
                                            practice={
                                              scanPayload.rows[row].practices[
                                                practice
                                              ]
                                            }
                                            meta={scanPayload.practices.find(
                                              (prac: any) =>
                                                prac.Code === practice
                                            )}
                                          ></AzurePractice>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </AccordionDetails>
                              </Accordion>
                            )
                          )
                        ) : loader ? (
                          <div className="noDataText">Loading...</div>
                        ) : selectedAccount &&
                          !loader &&
                          Object.keys(scanPayload.rows) &&
                          !Object.keys(scanPayload.rows).length ? (
                          <div className="noDataText">No Data Found</div>
                        ) : null}
                      </>
                    )}
                  </div>
                }
              </Grid>
            </Grid>
          </>
        )}
      </>
    </Fragment>
  );
};

export default React.memo(SecurityScan);
