import { useZoomContext } from '../../hooks/useModalZoomContext';
import { formatBytes, formatToFiveDecimals } from '../../utils/config';
import {
  echartsColorPalette,
  extractGeneralSeriesData,
  extractMemorySeriesData,
} from './config';

// Common factory function to create chart options

import { EChartsOption } from 'echarts';

// Define types for the series data structure
interface SeriesData {
  name: string;
  data: number[];
  color: string;
}

// Define types for the extracted data
interface ExtractedData {
  seriesData: SeriesData[];
  legendData: string[];
  xAxisData: string[];
  displayUnit: string;
}

// Define types for the API data
type ApiData = any; // Replace with a specific type if you know the structure

export const createLineChartOptions = (
  apiData: ApiData,
  isMemoryGraph: boolean,
  isTableLegend: boolean
): EChartsOption => {
  const {
    seriesData,
    legendData,
    xAxisData,
    displayUnit,
  }: ExtractedData = isMemoryGraph
    ? extractMemorySeriesData(apiData)
    : extractGeneralSeriesData(apiData);

  const { isZoomedIn } = useZoomContext();

  // Chart series configuration
  const chartSeries: any = seriesData.map((series: SeriesData) => ({
    name: series.name,
    type: 'line',
    data: series.data,
    smooth: true,
    lineStyle: {
      color: series.color,
    },
    itemStyle: {
      color: series.color,
    },
    areaStyle: {
      color: series.color,
      opacity: 0.2,
    },
  }));

  // Define base options
  const baseOptions: EChartsOption = {
    color: seriesData.map((series) => series.color),
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        animation: true,
      },
      enterable: true,

      formatter: (params: any) => {
        const timestamp = apiData[0].data[params[0].dataIndex][0] * 1000; // Convert to milliseconds
        const dateObj = new Date(timestamp);
        const formattedDate = `${dateObj
          .getDate()
          .toString()
          .padStart(2, '0')}-${(dateObj.getMonth() + 1)
          .toString()
          .padStart(
            2,
            '0'
          )}-${dateObj.getFullYear()} ${dateObj
          .getHours()
          .toString()
          .padStart(2, '0')}:${dateObj
          .getMinutes()
          .toString()
          .padStart(2, '0')}:${dateObj
          .getSeconds()
          .toString()
          .padStart(2, '0')}`;

        const header = `<div class="echarts-tooltip-title">${formattedDate}</div>`;
        const items = params
          .map((param: any) => {
            const colorSpan = `<span class="echarts-tooltip-color" style="background-color: ${param.color}"></span>`;
            const rawValue = param.data; // Raw value in bytes
            if (isMemoryGraph) {
              const formatted = formatBytes(rawValue);
              return `${colorSpan} <span class="echarts-tooltip-name">${
                param.seriesName
              } :</span> <span class="echarts-tooltip-value">${Number(
                formatted.value
              ).toFixed(2)} ${formatted.unit}</span>`;
            } else {
              return `${colorSpan} <span class="echarts-tooltip-name">${
                param.seriesName
              } :</span> <span class="echarts-tooltip-value"> ${formatToFiveDecimals(
                Number(rawValue)
              )}${displayUnit}</span>`;
            }
          })
          .join('<br/>');
        return `<div class="echarts-tooltip">
        ${header}${items}
        </div>`;
      },
    },
    grid: {
      top: '5%',
      left: '0.5%',
      right: '1%',
      bottom: isTableLegend ? '5%' : isZoomedIn ? '7%' : '4%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xAxisData,
      axisLabel: {
        color: '#000',
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value: number) => {
          if (isMemoryGraph) {
            const formatted = formatBytes(value);
            return `${Number(formatted.value).toFixed(2)} ${formatted.unit}`;
          }
          return `${value}${displayUnit}`; // For non-memory graph, return value as-is
        },
        color: '#000',
      },
    },
    dataZoom: isZoomedIn
      ? [
          {
            type: 'inside',
            start: 0,
            end: 100,
          },
        ]
      : undefined, // Disable dataZoom when not zoomed in
    series: chartSeries,
  };

  // Return options with standard legend or custom table legend
  if (!isTableLegend) {
    return {
      ...baseOptions,
      legend: {
        show: false, // Hide built-in legend
      },
      // Add additional data for custom legend
      customLegend: {
        data: legendData,
        colors: echartsColorPalette,
      },
      series: chartSeries,
    };
  } else {
    // Return the options with the custom graphic elements
    return {
      ...baseOptions,
      legend: { show: false },
      // Include the table data for the React component to use
      tableData: {
        seriesData,
        isMemoryGraph,
        displayUnit,
      },
    };
  }
};

// Define proper types for better type safety
interface SeriesData {
  name: string;
  data: number[];
  color: string;
}

interface ExtractedData {
  seriesData: SeriesData[];
  legendData: string[];
  xAxisData: string[];
  displayUnit: string;
}

// type ApiData = Array<{
//   name: string;
//   unit: string;
//   data: Array<[number, number | string]>;
// }>;
