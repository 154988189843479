import { useSelector } from 'react-redux';
import { accountState } from '../../store';

// TooltipTextGenerator.ts
export const generateTooltipText = () => {
  const selectedAccount = useSelector(accountState).selectedAccount;
  const isAccountSelected = useSelector(accountState).isAccountSelected;

  const disabledTextHandler = (service: string) =>
    `Upgrade to Growth / Growth+ Plan for ${service} Access`;

  return {
    // Admin page Navigation
    AdminDashboard: {
      default: 'Admin Dashboard',
      disabled: 'Upgrade to Access the Admin Dashboard',
    },
    AllOrganizations: {
      default: 'Organizations',
      disabled: 'Upgrade to Access Organizations',
    },
    AdminPanelBilling: {
      default: 'Billing Requests',
      disabled: 'Upgrade to Access Billing Requests',
    },
    SystemHealth: {
      default: 'System Health',
      disabled: 'Upgrade to Access System Health',
    },
    // Users Navigation
    Inventory: {
      default: 'Inventories',
      disabled: disabledTextHandler('Inventory'),
    },
    IdleResources: {
      default: 'Idle Resources',
      disabled: disabledTextHandler('Idle Instance'),
    },
    RightSizing: {
      default: 'RightSizing',
      disabled: disabledTextHandler('RightSizing'),
    },
    ScheduleInstances: {
      default: `Schedule 
                    ${
                      !isAccountSelected
                        ? 'compute service'
                        : selectedAccount?.CloudProvider.toUpperCase() ===
                          'AZURE'
                        ? 'VMs'
                        : 'Instances'
                    }
                    `,
      disabled: disabledTextHandler(`Schedule 
                      ${
                        !isAccountSelected
                          ? ''
                          : selectedAccount?.CloudProvider.toUpperCase() ===
                            'AZURE'
                          ? 'VMs'
                          : 'Instances'
                      }`),
    },
    CostForecasting: {
      default: 'Cost Forecasting',
      disabled: disabledTextHandler('Cost Forecasting'),
    },
    CostSaving: {
      default: 'Cost Saving',
      disabled: disabledTextHandler('Cost Saving'),
    },
    BillingSimplified: {
      default: 'Billing Simplified',
      disabled: disabledTextHandler('Billing Simplified'),
    },
    SecurityScan: {
      default: 'Security',
      disabled: disabledTextHandler('Security'),
    },
    ServiceReports: {
      default: 'Service Reports',
      disabled: disabledTextHandler('Service Reports'),
    },
    UptimeMonitoring: {
      default: 'URL / PORT Monitoring',
      disabled: disabledTextHandler('URL / PORT Monitoring'),
    },
    HealthMonitoring: {
      default: 'System Health Monitoring',
      disabled: disabledTextHandler('System Health Monitoring'),
    },
    RealUserMonitoring: {
      default: 'Real User Monitoring',
      disabled: disabledTextHandler('Real User Monitoring'),
    },
    UploadedDocuments: {
      default: 'Uploaded Documents',
      disabled: disabledTextHandler('Uploaded Documents'),
    },
  };
};

export const InventoryIcon = (
  <>
    <span className="cube1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        fill="currentColor"
        className="bi bi-box"
        viewBox="0 0 16 16"
      >
        <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5 8 5.961 14.154 3.5 8.186 1.113zM15 4.239l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
      </svg>
    </span>
    <span className="cube2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        fill="currentColor"
        className="bi bi-box"
        viewBox="0 0 16 16"
      >
        <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5 8 5.961 14.154 3.5 8.186 1.113zM15 4.239l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
      </svg>
    </span>
    <span className="cube3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        fill="currentColor"
        className="bi bi-box"
        viewBox="0 0 16 16"
      >
        <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5 8 5.961 14.154 3.5 8.186 1.113zM15 4.239l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
      </svg>
    </span>
  </>
);
