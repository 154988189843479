import React from 'react';
import {
  Autocomplete,
  FormControl,
  InputLabel,
  TextField,
} from '@mui/material';

interface SearchSelectOptionProps {
  labelName: string;
  selectedValue: string | null;
  selectOptions: string[];
  onChangeSelectHandler: (event: any, value: string | null) => void;
  className?: string;
  disableClearable?: boolean;
}

const SearchSelectOption: React.FC<SearchSelectOptionProps> = ({
  labelName,
  selectedValue,
  selectOptions,
  onChangeSelectHandler,
  className = '',
  disableClearable = false,
}) => {
  return (
    <div className={`subscriptionDD ${className}`}>
      <label className="subscriptionLabel">{labelName}</label>
      <FormControl variant="outlined" className="w-100 mt-0 p-0">
        <InputLabel shrink id="demo-simple-select-outlined-label">
          {labelName}
        </InputLabel>

        <Autocomplete
          id="demo-simple-select-outlined"
          className="p-0"
          value={selectedValue}
          onChange={onChangeSelectHandler}
          options={selectOptions}
          getOptionLabel={(option) => option}
          disableClearable={disableClearable}
          renderInput={(params) => (
            <TextField
              {...params}
              label={labelName}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                autoComplete: 'off',
              }}
            />
          )}
        />
      </FormControl>
    </div>
  );
};

export default SearchSelectOption;
