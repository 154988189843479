import React, { useEffect } from 'react';
import './LoginPage.css';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { Footer, ForgetPasswordModal } from '../../components';
import { Grid, Box, FormControl, InputAdornment } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import fastForward from '../../assets/images/fastForward.png';

import { userService, accountService } from '../../services';
import { ReactComponent as Intersect } from '../../assets/images/Intersect.svg';

import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';

import { useDispatch } from 'react-redux';
import { fetchUserData } from '../../store/thunks';
import { authService } from '../../services';
import { handleError } from '../../utils/config';
import SupportHeader from '../../components/Header/SupportHeader';
import { RoutesFun } from '../../schemas';
import TextField from '@mui/material/TextField';

interface LOGINSCHEMA {
  EmailAddress: 'string';
  Password: 'string';
}

export const LoginPage: React.FC = () => {
  const history = useHistory();
  const [checked, setChecked] = React.useState(false);
  const [regMsg, setRegMsg] = React.useState('');
  const [loader, setLoader] = React.useState(false);
  const dispatch = useDispatch();
  // const Routes = RoutesFun();
  // if (
  //   localStorage.getItem('auth-state') &&
  //   !localStorage.getItem('LOGIN_ACCESS')
  // ) {
  //   const roleID = localStorage.getItem('RoleId');
  //   const RemainingDays = localStorage.getItem('RemainingDays');
  //   if (roleID == '1') {
  //     window.location.href = Routes.AdminDashboard;
  //   } else {
  //     if (RemainingDays == 'null' && roleID !== '3' && roleID !== '4') {
  //       window.location.href = Routes.Billing;
  //     } else {
  //       window.location.href = Routes.Dashboard;
  //     }
  //   }

  const encrypt = () => {
    if (loginData.Password) {
      const password = loginData.Password;
      let encryptedPassword = '';
      let val;
      for (let ze = 0; ze < password?.length; ze++) {
        val = password?.charCodeAt(ze) + 3;

        encryptedPassword += String.fromCharCode(val);
      }

      return encryptedPassword;
    }
  };
  const decrypt = () => {
    if (accountService.getLoginData('password')) {
      const pass2 = accountService.getLoginData('password');
      let decryptedPassword = '';
      let val;
      if (pass2?.length) {
        for (let zez = 0; zez < pass2?.length; zez++) {
          val = pass2?.charCodeAt(zez) - 3;
          decryptedPassword += String.fromCharCode(val);
        }

        return decryptedPassword;
      }
    } else return '';
  };
  const [loginData, setIsLoginData] = React.useState({
    EmailAddress: accountService.getLoginData('emailId'),
    Password: decrypt(),
  });
  const [openForgetPassword, setopenForgetPassword] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [isNetworkError, setNetworkError] = React.useState(false);

  const { register, handleSubmit, errors } = useForm<LOGINSCHEMA>({
    mode: 'onChange',
  });

  const ForgotPassword = () => {
    setopenForgetPassword(true);
  };

  const handleFieldChange = (target: any) => {
    setIsLoginData({ ...loginData, [target.name]: target.value });
  };
  const redirectPage = (user: any) => {
    const Routes = RoutesFun();
    if (user.IsVerified == 1) {
      if (user.RoleId === 1) {
        window.location.href = Routes.AdminDashboard;
      } else if (user.RoleId === 2) {
        if (user.RemainingDays === null || user.SubscriptionStatus === null) {
          window.location.href = '/' + user.CompanyName + '/billing-management';
        } else if (user && user.AccountId != null) {
          window.location.href = Routes.Dashboard;
        } else {
          // window.location.href = '/' + user.CompanyName + '/accounts';
          window.location.href = Routes.UptimeMonitoring;
        }
      } else if (user.RoleId === 5) {
        window.location.href = '/admin-dashboard';
      } else {
        if (user && user.AccountId != null) {
          window.location.href = Routes.Dashboard;
        } else {
          // window.location.href = '/' + user.CompanyName + '/accounts';
          window.location.href = Routes.UptimeMonitoring;
        }
      }
    } else {
      localStorage.removeItem('auth-state');
      history.push('/login');
    }
  };

  const onSubmit = async (data: LOGINSCHEMA) => {
    try {
      setLoader(true);
      localStorage.setItem('token_expiry_time', '0');
      const result = await userService.login(data);
      const auth = authService.checkAuth();
      if (result.type === 'error') {
        const err = `${result.message}`;
        setRegMsg(err);
        setLoader(false);
      } else {
        localStorage.setItem('CompanyName', result.CompanyName);
        localStorage.setItem('RoleId', result.RoleId);
        localStorage.setItem('RemainingDays', result.RemainingDays);
        if (checked) {
          accountService.setLoginData(loginData.EmailAddress, encrypt());
        }

        if (result && auth) {
          dispatch(fetchUserData());
          redirectPage(result);
          if (!checked) {
            localStorage.removeItem('emailId');
            localStorage.removeItem('password');
          }
        }
      }
    } catch (e: any) {
      setLoader(false);
      if (e === 'Network Error') {
        setNetworkError(true);
      }

      if (e && e.error && e.error.message) {
        handleError(e, history);
        const err = `${e.error.message}`;
        setRegMsg(err);
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const regMsgElement = () => {
    return <div className="regMsg mb-10">{regMsg}</div>;
  };

  const closeModal = () => {
    setopenForgetPassword(false);
  };

  useEffect(() => {
    if (localStorage.getItem('emailId')) {
      setChecked(true);
    }
  }, []);

  if (isNetworkError) {
    throw new Error('Network Error');
  }

  return (
    <React.Fragment>
      <div>
        {loader ? <LoadingScreen /> : ''}
        <div className="outerContainer scrollContainer soraFont">
          <SupportHeader />
          <div className="mainBgEllipse1" />
          <div className="mainBgEllipse2" />
          <div className="mainBgEllipse3" />
          {loader ? <LoadingScreen /> : ''}
          <div className="registrationBG">
            <div className="registrationContainer">
              <div className="leftLoginSection">
                <div className="ellipse" />

                <div className="testimonialCard">
                  <div className="testimonial">
                    <p>
                      "CloudForestX has been a lifesaver for our team. We were
                      able to reduce our cloud bills by 20% without having to
                      compromise on service quality. It's an amazing tool."
                    </p>
                  </div>
                  <div className="profile">
                    {/* <img
                      className="profile-image"
                      src={sampleAvatar}
                      alt="Profile"
                    /> */}
                    <div className="profile-info">
                      <h3 className="testimonialName">Rajiv Jain</h3>
                      <p className="testimonialPost">CEO, ThinkSys</p>
                    </div>
                  </div>
                </div>

                <Intersect className="intersect" />

                <div className="ellipse2" />
                <div className="ellipse3" />
                <div className="ellipse4" />
              </div>

              <div className="rightImage">
                <div className="trialText mt-100">
                  <h5>Welcome back</h5>
                  <p>Please enter your details</p>
                </div>
                <Grid container spacing={0} className="rightbody h-55 w-100">
                  <Grid item md={12} className="loginReg">
                    <div className="userLogin mt-10">
                      <form
                        noValidate
                        onSubmit={handleSubmit(onSubmit)}
                        autoComplete="off"
                      >
                        <Box className="registrationLogin">
                          <Grid
                            container
                            spacing={1}
                            alignItems="flex-end"
                            className="positionRelative"
                          >
                            <Grid item className="fullWidthInput w100">
                              <FormControl
                                variant="outlined"
                                className=" mb-25"
                              >
                                <TextField
                                  id="standard-basic"
                                  label={
                                    <span>
                                      Email<span className="asterisk">*</span>
                                    </span>
                                  }
                                  variant="standard"
                                  name="EmailAddress"
                                  autoComplete="off"
                                  focused
                                  type="email"
                                  defaultValue={loginData.EmailAddress}
                                  onChange={(e) => handleFieldChange(e.target)}
                                  inputRef={register({
                                    required: true,
                                    pattern: /[a-zA-z_.+0-9-]+@[a-zA-Z0-9-]+([.][a-zA-Z0-9]+)+/,
                                  })}
                                />
                                {errors.EmailAddress && (
                                  <div className="regMsg">
                                    Enter valid email id.
                                  </div>
                                )}
                              </FormControl>

                              <FormControl className="fullWidthInput mb-15 passwordInput">
                                <TextField
                                  id="standard-basic"
                                  label={
                                    <span>
                                      Password
                                      <span className="asterisk">*</span>
                                    </span>
                                  }
                                  variant="standard"
                                  autoComplete="off"
                                  focused
                                  name="Password"
                                  className="outlineInputArea"
                                  defaultValue={loginData.Password}
                                  type={showPassword ? 'text' : 'password'}
                                  onChange={(e) => handleFieldChange(e.target)}
                                  inputRef={register({ required: true })}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <span
                                          style={{ cursor: 'pointer' }}
                                          onClick={handleClickShowPassword}
                                        >
                                          {showPassword ? (
                                            <FontAwesomeIcon icon={faUnlock} />
                                          ) : (
                                            <FontAwesomeIcon icon={faLock} />
                                          )}
                                        </span>
                                      </InputAdornment>
                                    ),
                                  }}
                                />

                                {errors.Password && (
                                  <div className="regMsg">
                                    Password is required.
                                  </div>
                                )}
                              </FormControl>

                              {regMsg ? regMsgElement() : ''}

                              <div className="loginBottom">
                                <div className="checkboxf"></div>
                                <div className="forgetPassText">
                                  <a onClick={() => ForgotPassword()}>
                                    Forgot password
                                  </a>
                                </div>
                              </div>
                              <div className="registrationLoginBtn mt-10">
                                <div className="inputContainer">
                                  <input
                                    type="submit"
                                    className="formLoginBtn"
                                    value=""
                                  />
                                  <span className="inputText">Log In</span>
                                  <img
                                    className="fastForwardIcon"
                                    src={fastForward}
                                    alt="forward"
                                  />
                                </div>
                              </div>
                              <div>
                                <p className="secondaryTagline">
                                  <span>Not registered ? </span>

                                  <Link to="/registration">
                                    Start a free trial
                                  </Link>
                                </p>
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      </form>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>

        {openForgetPassword && (
          <ForgetPasswordModal show={openForgetPassword} onClose={closeModal} />
        )}
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default React.memo(LoginPage);
