import React, { useMemo } from 'react';
import './Dashboard.css';
import {
  BasicAPIRequest,
  config,
  optionIsAvailableConditionHandler,
} from './config';
import { useFetchData } from '../../hooks/useFetchData';
import SectionCard from '../../components/HealthMonitoring/SectionCard';

const BasicCPUMemoryDisk: React.FC<BasicAPIRequest> = (props) => {
  const {
    refreshTrigger,
    startTime,
    endTime,
    job,
    node,
    step,
    primaryKey,
  } = props;

  const apiDependencies = useMemo(
    () => [startTime, endTime, node, job, step, refreshTrigger, primaryKey],
    [startTime, endTime, node, job, step, refreshTrigger, primaryKey]
  );

  const reqBody = { startTime, endTime, node, job, step, primaryKey };

  const CPUUsage = useFetchData({
    fetchData: () => config.getBasicCPUUsage(reqBody),

    dependencies: apiDependencies,
  });
  const memoryUsage = useFetchData({
    fetchData: () => config.getBasicMemoryUsage(reqBody),
    dependencies: apiDependencies,
  });

  const networkTraffic = useFetchData({
    fetchData: () => config.getBasicNetworkUsage(reqBody),

    dependencies: apiDependencies,
  });

  const discSpace = useFetchData({
    fetchData: () => config.getBasicDiskSpaceUsage(reqBody),

    dependencies: apiDependencies,
  });

  const cards = [
    {
      title: 'CPU Basic',
      tooltip: 'Basic CPU info',
      options: optionIsAvailableConditionHandler(CPUUsage.data, false), // Non-memory graph
      loader: CPUUsage.loader,
      error: CPUUsage.error,
    },
    {
      title: 'Memory Usage',
      tooltip: 'Basic memory usage',
      options: optionIsAvailableConditionHandler(memoryUsage.data, true), // Memory graph
      loader: memoryUsage.loader,
      error: memoryUsage.error,
    },
    {
      title: 'Network Traffic Basic',
      tooltip: 'Basic network info per interface',
      options: optionIsAvailableConditionHandler(networkTraffic.data, true),
      loader: networkTraffic.loader,
      error: networkTraffic.error,
    },
    {
      title: 'Disk Space Used Basic',
      tooltip: 'Disk space used of all filesystems mounted',
      options: optionIsAvailableConditionHandler(discSpace.data, false),
      loader: discSpace.loader,
      error: discSpace.error,
    },
  ];

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '16px',
      }}
    >
      {cards.map((card, index) => (
        <SectionCard card={card} key={index} />
      ))}
    </div>
  );
};

export default BasicCPUMemoryDisk;
