import React, { Fragment, useEffect, useState } from 'react';
import './UserProfile.css';
import { DashboardHeader } from '../../components/Header/DashboardHeader';
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { authService, userService } from '../../services';
import { useHistory } from 'react-router';
import MuiPhoneNumber from 'material-ui-phone-number';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { Navigation } from '../../components/Navigation/Navigation';
import { useSelector, useDispatch } from 'react-redux';
import { uiState, userState } from '../../store/index';
import { MenuItem, Select } from '@mui/material';
import { userActions } from '../../store/userSlice';
import {
  EDIT_TYPE,
  ERRORS,
  // JWT,
  LOCAL_STORAGE_VARIABLES,
  // LOGIN_SESSION,
  // TRIAL_PERIOD_MESSAGE,
} from '../../utils/constants';
import { handleError } from '../../utils/config';
import Loading from '../../components/Loading/Loading';

interface EditProfile {
  FirstName: string;
  LastName: string;
  CompanyName: string;
  ContactNo: number;
  EmailAddress: string;
  IsActive: number;
  EditType: string;
}

interface ChangePassword {
  Password: 'string';
}

export const UserProfile: React.FC = () => {
  const dispatch = useDispatch();
  const userData = useSelector(userState);
  const history = useHistory();
  const user = useSelector(userState).userData;

  const [userProfileData, setuserProfileData] = React.useState({
    FirstName: '',
    LastName: '',
    CompanyName: '',
    ContactNo: 0,
    EmailAddress: '',
    IsActive: 0,
    RoleId: 0,
    EditType: EDIT_TYPE.EDIT_PROFILE,
  });

  const [updatePassword, setUpdatePassword] = React.useState({
    Password: '',
    ConfirmPassword: '',
  });
  const [networkError, setNetworkError] = React.useState('');

  const { register, handleSubmit, control, errors } = useForm<EditProfile>({
    mode: 'onChange',
  });
  const [company, setCompany] = React.useState(user?.CompanyName);

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    errors: errors2,
  } = useForm<ChangePassword>({
    mode: 'onChange',
  });

  const [formSubmitMsg, setFormSubmitMsg] = useState([]);
  const [errorMsg, setErrorMsg] = useState([]);
  const [errmsgDisplay, seterrmsgDisplay] = useState('d-none');
  const [msgDisplay, setmsgDisplay] = useState('d-none');
  const [pwdMsgDisplay, setPwdMsgDisplay] = useState('d-none');
  const [passwordSubmitMsg, setPasswordSubmitMsg] = useState([]);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showCPassword, setShowCPassword] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const { collapsed } = useSelector(uiState);

  // const checkRemainingDays = () => {
  //   alert(TRIAL_PERIOD_MESSAGE.TRIAL_PERIOD_OVER);
  //   history.push('/login');
  // };

  const fetchCompanyList = async () => {
    try {
      setLoader(true);
      const allCompanies = await userService.listCompanies();
      if (allCompanies) setCompanyList(allCompanies);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const profileData = async () => {
    try {
      setLoader(true);
      const rows = await userService.getUserDetails();
      setuserProfileData(rows);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
      // if (user) {
      //   user?.RemainingDays === undefined ||
      //   user?.RemainingDays === 0 ||
      //   user?.RemainingDays < 0
      //     ? checkRemainingDays()
      //     : '';
      // }
    }
  };

  const handleProfileChange = (target: any) => {
    setuserProfileData({ ...userProfileData, [target.name]: target.value });
  };

  const handleChangePassword = (target: any) => {
    setUpdatePassword({ ...updatePassword, [target.id]: target.value });
  };

  const onSubmit = async (data: EditProfile) => {
    try {
      setuserProfileData({
        ...userProfileData,
        IsActive: 1,
      });
      if (user!.RoleId! === 1) data.CompanyName = userProfileData.CompanyName;
      const result = await userService.patchRegistrationData(data);
      if (result) {
        setFormSubmitMsg(result.message);
        await userService.getUserDetails();
        seterrmsgDisplay('d-none');
        setmsgDisplay('d-block');
        // TODO: need a better way to handle this
        if (user!.RoleId! === 1) location.reload();
      }
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
        setErrorMsg(e.error.message.replace('ContactNo', 'Phone Number'));
        setmsgDisplay('d-none');
        seterrmsgDisplay('d-block');
      }
    } finally {
      setTimeout(() => {
        setmsgDisplay('d-none');
        seterrmsgDisplay('d-none');
      }, 2000);
    }
  };

  const onChangePassword = async (data: ChangePassword, event: any) => {
    try {
      const params = { Password: data.Password };
      const result = await userService.updatePassword(params);
      if (result) {
        setPasswordSubmitMsg(result.message);
        setPwdMsgDisplay('d-block');
        setUpdatePassword({ Password: '', ConfirmPassword: '' });
        event?.target.reset();
        localStorage.removeItem(LOCAL_STORAGE_VARIABLES.AUTH_STATE);
        authService.removeAuth();
        setTimeout(() => {
          history.push('/login');
        }, 500);
      }
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');
      if (e) {
        setPasswordSubmitMsg(e.message);
        handleError(e, history);
      }
    } finally {
      setTimeout(() => {
        setPwdMsgDisplay('d-none');
      }, 2000);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowCPassword = () => {
    setShowCPassword(!showCPassword);
  };

  const handleCompanyChange = async (event: any) => {
    setCompany(event.target.value);
    userProfileData.CompanyName = event.target.value;
    userProfileData.EditType = EDIT_TYPE.EDIT_PROFILE;
    userProfileData.RoleId = user!.RoleId!;
    dispatch(
      userActions.replaceUserState({
        userData: userProfileData,
        isUserSelected: userData.isUserSelected,
      })
    );
    setuserProfileData({
      ...userProfileData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    profileData();
    fetchCompanyList();
  }, []);

  if (networkError) {
    throw new Error(networkError);
  }

  return (
    <Fragment>
      {/* {loader ? <LoadingScreen /> : ''} */}
      <DashboardHeader />
      <Grid container className="innerContainer">
        <div className="splitLayout">
          <div className={`leftNav ${collapsed ? 'collapsed' : ''}`}>
            <Navigation />
          </div>
          <div className="rightContent">
            <div className="innerRightContent">
              <Grid item md={12} sm={12} xs={12}>
                {loader ? (
                  <Loading style={{ height: '80vh' }} />
                ) : (
                  user && (
                    <div className="userProfileContainer">
                      <form
                        noValidate
                        onSubmit={handleSubmit(onSubmit)}
                        autoComplete="off"
                      >
                        <div className="editProfileSection">
                          <div className="sectionHeader">
                            <span className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fill="currentColor"
                                className="bi bi-person-circle"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                              </svg>
                            </span>
                            <span className="text">EDIT PROFILE</span>
                          </div>
                          <div className="sectionContainer">
                            <div className="row px-20">
                              <div className="inputArea">
                                <FormControl
                                  variant="outlined"
                                  className="bEmail my-10"
                                >
                                  <InputLabel
                                    htmlFor="first-name"
                                    className="inputLegend"
                                  >
                                    FIRST NAME
                                  </InputLabel>
                                  <OutlinedInput
                                    id="first-name"
                                    name="FirstName"
                                    className="outlineInputArea"
                                    label="FIRST NAME"
                                    onChange={(e) =>
                                      handleProfileChange(e.target)
                                    }
                                    inputRef={register({
                                      required: true,
                                      pattern: /^\S*$/,
                                    })}
                                    defaultValue={user?.FirstName}
                                  />
                                  {errors.FirstName && (
                                    <div className="regMsg">
                                      Enter valid First name without spaces
                                    </div>
                                  )}
                                </FormControl>
                              </div>
                              <div className="inputArea">
                                <FormControl
                                  variant="outlined"
                                  className="bEmail my-10"
                                >
                                  <InputLabel
                                    htmlFor="last-name"
                                    className="inputLegend"
                                  >
                                    LAST NAME
                                  </InputLabel>
                                  <OutlinedInput
                                    id="last-name"
                                    name="LastName"
                                    className="outlineInputArea"
                                    label="LAST NAME"
                                    defaultValue={user?.LastName}
                                    onChange={(e) =>
                                      handleProfileChange(e.target)
                                    }
                                    inputRef={register({
                                      required: true,
                                      pattern: /^\S*$/,
                                    })}
                                  />
                                  {errors.LastName && (
                                    <div className="regMsg">
                                      Enter valid Last name without spaces.
                                    </div>
                                  )}
                                </FormControl>
                              </div>
                            </div>
                            <div className="row px-20">
                              <div className="inputArea">
                                <Controller
                                  className="bEmail my-10"
                                  as={
                                    <MuiPhoneNumber
                                      defaultCountry={'us'}
                                      onChange={handleProfileChange}
                                      // defaultValue={user?.ContactNo}
                                      countryCodeEditable={false}
                                    />
                                  }
                                  id="phone-number"
                                  name="ContactNo"
                                  control={control}
                                  defaultValue={user?.ContactNo}
                                  variant="outlined"
                                  label="PHONE NUMBER"
                                  rules={{ minLength: 10 }}
                                  helperText={
                                    errors.ContactNo && (
                                      <div className="regMsg mx-0">
                                        Enter Valid number.
                                      </div>
                                    )
                                  }
                                ></Controller>
                              </div>

                              <div className="inputArea">
                                {user?.RoleId === 1 ? (
                                  <FormControl
                                    variant="outlined"
                                    className="bEmail my-10"
                                  >
                                    <InputLabel
                                      htmlFor="company-name"
                                      className="inputLegend"
                                    >
                                      SELECT COMPANY NAME
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      name="CompanyName"
                                      className="outlineInputArea"
                                      id="demo-simple-select"
                                      value={company}
                                      onChange={handleCompanyChange}
                                      defaultValue={user?.CompanyName}
                                      displayEmpty={true}
                                    >
                                      {companyList?.map((company, i) => (
                                        <MenuItem
                                          sx={{ fontSize: '1vw' }}
                                          value={company}
                                          key={i}
                                        >
                                          {company}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                ) : (
                                  <FormControl
                                    variant="outlined"
                                    className="bEmail my-10"
                                  >
                                    <>
                                      <InputLabel
                                        htmlFor="company-name"
                                        className="inputLegend"
                                      >
                                        COMPANY NAME
                                      </InputLabel>
                                      <OutlinedInput
                                        id="company-name"
                                        name="CompanyName"
                                        className="outlineInputArea"
                                        label="COMPANY NAME"
                                        defaultValue={user?.CompanyName}
                                        onChange={(e) =>
                                          handleProfileChange(e.target)
                                        }
                                        inputRef={register({
                                          required: true,
                                          pattern: /\S/,
                                        })}
                                        readOnly
                                      />{' '}
                                    </>
                                  </FormControl>
                                )}

                                {errors.CompanyName && (
                                  <div className="regMsg">
                                    Company Name is required.
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="row px-20">
                              <div className="inputArea">
                                <FormControl
                                  variant="outlined"
                                  className="bEmail my-10"
                                >
                                  <InputLabel
                                    htmlFor="email"
                                    className="inputLegend"
                                  >
                                    EMAIL
                                  </InputLabel>
                                  <OutlinedInput
                                    id="email"
                                    name="EmailAddress"
                                    className="outlineInputArea"
                                    label="EMAIL"
                                    defaultValue={user?.EmailAddress}
                                    readOnly
                                  />
                                </FormControl>

                                <FormControl
                                  variant="outlined"
                                  className="bEmail my-10 d-none"
                                >
                                  <InputLabel
                                    htmlFor="edit-type"
                                    className="inputLegend"
                                  >
                                    Edit Type
                                  </InputLabel>
                                  <OutlinedInput
                                    id="edit-type"
                                    name="EditType"
                                    className="outlineInputArea"
                                    onChange={(e) =>
                                      handleProfileChange(e.target)
                                    }
                                    inputRef={register({
                                      required: true,
                                      pattern: /^\S*$/,
                                    })}
                                    defaultValue={'editProfile'}
                                  />
                                </FormControl>
                              </div>
                            </div>
                          </div>
                          <div className="sectionFooter">
                            <div
                              className={
                                formSubmitMsg.length
                                  ? 'alert-success p-8 px-20 mr-10 font-14px responseMsg ' +
                                    msgDisplay
                                  : ''
                              }
                            >
                              {formSubmitMsg}
                            </div>

                            <div
                              className={
                                errorMsg.length
                                  ? 'alert-danger p-8 px-20 mr-10 font-14px responseMsg ' +
                                    errmsgDisplay
                                  : ''
                              }
                            >
                              {errorMsg}
                            </div>

                            {/* <button className="btn-light resetBtn mr-10" onClick={resetValue}>RESET</button> */}
                            <button className="btn-primary saveBtn">
                              SAVE
                            </button>
                          </div>
                        </div>
                      </form>

                      <form
                        noValidate
                        onSubmit={handleSubmit2(onChangePassword)}
                        autoComplete="off"
                      >
                        <div className="editProfileSection mt-40">
                          <div className="sectionHeader">
                            <span className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                className="bi bi-lock-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                              </svg>
                            </span>
                            <span className="text">CHANGE PASSWORD</span>
                          </div>
                          <div className="sectionContainer">
                            <div className="row p-20">
                              <div className="inputArea">
                                <FormControl
                                  variant="outlined"
                                  className="bPassword my-15"
                                >
                                  <InputLabel
                                    htmlFor="password"
                                    className="inputLegend"
                                  >
                                    ENTER NEW PASSWORD
                                  </InputLabel>
                                  <OutlinedInput
                                    id="Password"
                                    name="Password"
                                    className="outlineInputArea"
                                    type={showPassword ? 'text' : 'password'}
                                    defaultValue={updatePassword.Password}
                                    onChange={(e) =>
                                      handleChangePassword(e.target)
                                    }
                                    inputRef={register2({
                                      required: true,
                                      maxLength: 16,
                                      minLength: 8,
                                      pattern: /^\S*$/,
                                    })}
                                    autoComplete="off"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <span onClick={handleClickShowPassword}>
                                          {showPassword ? (
                                            <FontAwesomeIcon icon={faUnlock} />
                                          ) : (
                                            <FontAwesomeIcon icon={faLock} />
                                          )}
                                        </span>
                                      </InputAdornment>
                                    }
                                  />
                                  {errors2.Password && (
                                    <div className="regMsg">
                                      Password length should be between 8 to 16
                                      Character without blank space
                                    </div>
                                  )}
                                </FormControl>

                                <FormControl
                                  variant="outlined"
                                  className="bPassword my-25"
                                >
                                  <InputLabel
                                    htmlFor="password"
                                    className="inputLegend"
                                  >
                                    CONFIRM NEW PASSWORD
                                  </InputLabel>
                                  <OutlinedInput
                                    id="ConfirmPassword"
                                    name="ConfirmPassword"
                                    className="outlineInputArea"
                                    type={showCPassword ? 'text' : 'password'}
                                    defaultValue={
                                      updatePassword.ConfirmPassword
                                    }
                                    onChange={(e) =>
                                      handleChangePassword(e.target)
                                    }
                                    inputRef={register2({
                                      required: true,
                                      maxLength: 16,
                                      minLength: 8,
                                      pattern: /^\S*$/,
                                    })}
                                    autoComplete="off"
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <span
                                          onClick={handleClickShowCPassword}
                                        >
                                          {showCPassword ? (
                                            <FontAwesomeIcon icon={faUnlock} />
                                          ) : (
                                            <FontAwesomeIcon icon={faLock} />
                                          )}
                                        </span>
                                      </InputAdornment>
                                    }
                                  />
                                  {updatePassword.Password ===
                                  updatePassword.ConfirmPassword ? (
                                    ''
                                  ) : (
                                    <div className="regMsg">
                                      Password Does Not Match
                                    </div>
                                  )}
                                </FormControl>
                              </div>
                              <div className="inputArea changePassword">
                                <ul className="conditionList">
                                  <li>
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      className="checkIcon"
                                    />
                                    <span className="ml-10">
                                      Password length should be between 8 to 16
                                      character long.
                                    </span>
                                  </li>
                                  <li className="mt-10">
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      className="checkIcon"
                                    />
                                    <span className="ml-10">
                                      Password must not contain blank spaces.
                                    </span>
                                  </li>
                                  <li className="mt-10 mb-10">
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      className="checkIcon"
                                    />
                                    <span className="ml-10">
                                      Ban common passwords, to keep the most
                                      valuable passwords out of your system
                                    </span>
                                  </li>
                                  <li className="mt-10 mb-10">
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      className="checkIcon"
                                    />
                                    <span className="ml-10">
                                      Don't use simple words, for example,
                                      password, or a commonly-used phrase like
                                      iloveyou
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="sectionFooter">
                            <div
                              className={
                                passwordSubmitMsg?.length
                                  ? 'alert-success p-8 px-20 mr-10 font-14px responseMsg ' +
                                    pwdMsgDisplay
                                  : ''
                              }
                            >
                              {passwordSubmitMsg}
                            </div>

                            {updatePassword.Password ===
                            updatePassword.ConfirmPassword ? (
                              <button className="btn-primary saveBtn">
                                SAVE
                              </button>
                            ) : (
                              <button className="btn-primary saveBtn" disabled>
                                SAVE
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  )
                )}
              </Grid>
            </div>
          </div>
        </div>
      </Grid>
    </Fragment>
  );
};
