import React, { Fragment, useEffect } from 'react';
import './CloudCheckup.css';
import {
  faEnvelope,
  faLock,
  faUnlock,
  faAddressCard,
  faBuilding,
  faPhoneAlt,
} from '@fortawesome/free-solid-svg-icons';
import MuiPhoneNumber from 'material-ui-phone-number';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useForm } from 'react-hook-form';
import { ForgetPasswordModal } from '../../../components';
import { Footer } from '../../../components';
import {
  Grid,
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';

import { authService, userService, accountService } from '../../../services';
import { RoutesFun } from '../../../schemas';
import CFLogo from '../../../components/cfLogo/cfLogo';
// import { useUserData } from '../../../context/GlobalUserData';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import SupportHeader from '../../../components/Header/SupportHeader';
import { handleError } from '../../../utils/config';

interface CLOUDCHECKUP {
  FirstName: string;
  LastName: string;
  CompanyName: string;
  ContactNo: number;
  EmailAddress: string;
  RequestType: string;
}

export const CloudCheckup: React.FC = () => {
  const [requestPricingData, setrequestPricingData] = React.useState({
    FirstName: '',
    LastName: '',
    CompanyName: '',
    ContactNo: '',
    EmailAddress: '',
    EditType: 'registration',
  });
  const Routes = RoutesFun();
  const [errorMsg, setErrorMsg] = React.useState('');
  const [successMsg, setSuccessMsg] = React.useState('');
  const [networkError, setNetworkError] = React.useState('');
  const [loader, setLoader] = React.useState(false);
  const [counter, setCounter] = React.useState(10);
  const history = useHistory();

  const { register, handleSubmit, control, errors } = useForm({
    mode: 'onChange',
  });

  const handleFieldChange = (target: any) => {
    setrequestPricingData({
      ...requestPricingData,
      [target.name]: target.value,
    });
  };

  const onSubmit = async (data: CLOUDCHECKUP) => {
    setLoader(true);
    try {
      const params = { ...data, RequestType: 'EnterPrise Check UP' };
      const result = await userService.postRequestPricingData(params);
      if (result) {
        setErrorMsg('');
        setSuccessMsg(result);
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
        setErrorMsg(e.error.message.replace('ContactNo', 'Phone Number'));
      }
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    successMsg &&
      counter > 0 &&
      setTimeout(() => {
        setCounter(counter - 1);
        if (counter === 1) {
          window.location.reload();
        }
      }, 1000);
  }, [counter, successMsg]);

  if (networkError) {
    throw new Error(networkError);
  }

  return (
    <React.Fragment>
      <div className="outerContainer scrollContainer">
        <SupportHeader />
        {loader ? <LoadingScreen /> : ''}
        <Grid container spacing={0} className="registrationBG">
          <div className="ccuBG">
            <div className="w-50">&nbsp;</div>
            <Grid item md={6} sm={12} xs={12} className="rightImage py-0">
              <Box>
                {/* right body */}
                <div className="checkupForm loginForm">
                  <Grid item md={12} className="loginReg">
                    <Grid item md={12} className="rightHeader">
                      <h5 className="text-center mb-20">
                        <span className="logoFont mt-5 d-inline-block">
                          ENTERPRISE CHECK UP
                        </span>
                      </h5>
                      <p className="secondaryTagline d-none">
                        <span>Not registered ? </span>
                        <Link to="/registration" className="font-13px fw-400">
                          Start a free trial
                        </Link>
                      </p>
                    </Grid>

                    <div className="userLogin mt-10">
                      <form
                        noValidate
                        onSubmit={handleSubmit(onSubmit)}
                        autoComplete="off"
                        className={successMsg ? 'd-none' : 'd-block'}
                      >
                        <Box>
                          <Grid container spacing={1} alignItems="flex-end">
                            <Grid item className="w100">
                              <FormControl
                                variant="outlined"
                                className="bEmail mb-20"
                              >
                                <InputLabel
                                  htmlFor="business-email"
                                  className="inputLegend"
                                >
                                  BUSINESS EMAIL
                                </InputLabel>
                                <OutlinedInput
                                  id="email"
                                  name="EmailAddress"
                                  label="BUSINESS EMAIL"
                                  className="outlineInputArea"
                                  autoComplete="off"
                                  type="email"
                                  onChange={(e) => handleFieldChange(e.target)}
                                  inputRef={register({
                                    required: true,
                                    pattern: /[a-zA-z_.+0-9-]+@[a-zA-Z0-9-]+([.][a-zA-Z0-9]+)+/,
                                  })}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <span>
                                        <FontAwesomeIcon icon={faEnvelope} />
                                      </span>
                                    </InputAdornment>
                                  }
                                />
                                {errors.EmailAddress && (
                                  <div className="regMsg">
                                    Enter valid email id
                                  </div>
                                )}
                              </FormControl>

                              <FormControl
                                variant="outlined"
                                className="bEmail mb-20"
                              >
                                <InputLabel
                                  htmlFor="business-email"
                                  className="inputLegend"
                                >
                                  First Name
                                </InputLabel>
                                <OutlinedInput
                                  id="firstname"
                                  name="FirstName"
                                  label="First Name"
                                  className="outlineInputArea"
                                  autoComplete="off"
                                  type="text"
                                  onChange={(e) => handleFieldChange(e.target)}
                                  inputRef={register({
                                    required: true,
                                    pattern: /^\S*$/,
                                  })}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <span>
                                        <FontAwesomeIcon icon={faAddressCard} />
                                      </span>
                                    </InputAdornment>
                                  }
                                />
                                {errors.FirstName && (
                                  <div className="regMsg">
                                    Enter first name.
                                  </div>
                                )}
                              </FormControl>

                              <FormControl
                                variant="outlined"
                                className="bEmail mb-20"
                              >
                                <InputLabel
                                  htmlFor="business-email"
                                  className="inputLegend"
                                >
                                  Last Name
                                </InputLabel>
                                <OutlinedInput
                                  id="lastname"
                                  name="LastName"
                                  label="Last Name"
                                  className="outlineInputArea"
                                  autoComplete="off"
                                  type="text"
                                  onChange={(e) => handleFieldChange(e.target)}
                                  inputRef={register({
                                    required: true,
                                    pattern: /^\S*$/,
                                  })}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <span>
                                        <FontAwesomeIcon icon={faAddressCard} />
                                      </span>
                                    </InputAdornment>
                                  }
                                />
                                {errors.LastName && (
                                  <div className="regMsg">Enter last name.</div>
                                )}
                              </FormControl>

                              <FormControl
                                variant="outlined"
                                className="bEmail mb-20"
                              >
                                <InputLabel
                                  htmlFor="business-email"
                                  className="inputLegend"
                                >
                                  Company Name
                                </InputLabel>
                                <OutlinedInput
                                  id="companyname"
                                  name="CompanyName"
                                  label="Company Name"
                                  className="outlineInputArea"
                                  autoComplete="off"
                                  type="text"
                                  onChange={(e) => handleFieldChange(e.target)}
                                  inputRef={register({
                                    required: true,
                                    pattern: /\S/,
                                  })}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <span>
                                        <FontAwesomeIcon icon={faBuilding} />
                                      </span>
                                    </InputAdornment>
                                  }
                                />
                                {errors.CompanyName && (
                                  <div className="regMsg">
                                    Company Name is required.
                                  </div>
                                )}
                              </FormControl>

                              <FormControl
                                variant="outlined"
                                className="bEmail mb-10"
                              >
                                <Controller
                                  className="bEmail my-10"
                                  as={
                                    <MuiPhoneNumber
                                      defaultCountry={'us'}
                                      onChange={handleFieldChange}
                                      countryCodeEditable={false}
                                    />
                                  }
                                  id="phone-number"
                                  name="ContactNo"
                                  control={control}
                                  variant="outlined"
                                  label="ENTER PHONE NUMBER"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <span>
                                          <FontAwesomeIcon icon={faPhoneAlt} />
                                        </span>
                                      </InputAdornment>
                                    ),
                                  }}
                                >
                                  {errors.ContactNo && (
                                    <div className="regMsg">
                                      Enter 10 digit Valid numbers.
                                    </div>
                                  )}
                                </Controller>
                              </FormControl>

                              {errorMsg && (
                                <div className="regMsg">{errorMsg}</div>
                              )}
                              {/* {regMsg ? regMsgElement() : ''} */}

                              <div className="registrationLoginBtn mt-5">
                                <input
                                  type="submit"
                                  className="formLoginBtn w-100"
                                  value="SUBMIT"
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      </form>
                      {successMsg && (
                        <div>
                          <h3 className="d-middle-center text-center fw-500 text-capitalize pl-20 pr-20 text-dBlue">
                            {successMsg}
                          </h3>
                          <h6 className="fw-500 text-center mt-20 pl-20 pr-20">
                            Page will refresh in
                            <br />
                            <span className="text-dBlue mt-10 d-inline-block">
                              <span className="font-25px">{counter}</span>
                              seconds
                            </span>
                          </h6>
                        </div>
                      )}
                    </div>
                  </Grid>
                </div>
                {/* right body */}
              </Box>
            </Grid>
          </div>
        </Grid>
        <div className="footerContainer">
          <div className="innerContainer12">
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CloudCheckup;
