import React, { createContext, useState, ReactNode, useMemo } from 'react';

interface ZoomContextType {
  isZoomedIn: boolean;
  setZoomedIn: (status: boolean) => void;
}

export const ZoomContext = createContext<ZoomContextType | undefined>(
  undefined
);

export const ZoomProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isZoomedIn, setIsZoomedIn] = useState(false);

  const contextValue = useMemo(
    () => ({
      isZoomedIn,
      setZoomedIn: setIsZoomedIn,
    }),
    [isZoomedIn]
  );

  return (
    <ZoomContext.Provider value={contextValue}>{children}</ZoomContext.Provider>
  );
};
