import { useDispatch, useSelector } from 'react-redux';
import { accountState } from '../../store';
import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { ALL_VALUE } from '../../pages/Invoice/config';
import { accountService } from '../../services';
import { CLOUD_PROVIDER_SERVICES } from '../../utils/constants';
import {
  filterAwsAccountId,
  filterAzureId,
  filterSubscription,
  handleError,
} from '../../utils/config';
import { useHistory } from 'react-router-dom';
import { accountActions } from '../../store/accountSlice';

interface SelectedAccountProps {
  onAccountChange: (value: any, azureIdData: any) => void;
}

export const SelectedAccount: React.FC<SelectedAccountProps> = (props: any) => {
  const selectedAccountInfo = useSelector(accountState);
  const selectedAccount = selectedAccountInfo.selectedAccount;
  const isAccountSelected = useSelector(accountState).isAccountSelected;
  const history = useHistory();
  const dispatch = useDispatch();
  const { loader, onAccountChange } = props;

  const [azureIdData, setAzureIdData] = React.useState<any>([]);
  const cloudProvider: any = selectedAccount?.CloudProvider.toUpperCase();
  const [selected, setSelected] = useState<any>(
    selectedAccountInfo.selectedSubscriptionName
  );
  const [selectedAzureId, setSelectedAzureId] = useState<any>(
    selectedAccountInfo.selectedAccountName
  );
  const [selectedAWSId, setSelectedAWSId] = React.useState<any>(
    selectedAccountInfo.selectedAccountName
  );
  const [awsIdData, setAWSIdData] = React.useState<any>([]);
  const [Id, setId] = React.useState(null);
  const [isNetworkError, setNetworkError] = React.useState(false);

  const [subscriptionsList, setSubscriptionsList] = React.useState<any>(
    selectedAccountInfo.selectedSubData
  );

  // fetching subscription list from selected account
  const getSubscriptionsData = async () => {
    try {
      if (selectedAccount && isAccountSelected) {
        const result = await accountService.getSubscriptionDetails(
          Id,
          selectedAccount.CloudProvider.toUpperCase()
        );
        setSubscriptionsList(result[0].subscriptions);
        dispatch(
          accountActions.replaceAccountState({
            ...selectedAccountInfo,
            selectedSubData: result[0].subscriptions,
          })
        );
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  const setSubId = () => {
    if (azureIdData) {
      azureIdData.forEach((subscription: any) => {
        if (subscription.AliasName === selectedAzureId) {
          setId(subscription.Id);
        }
      });
    }
  };

  useEffect(() => {
    getIdData();
    if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE && Id) {
      setSubId();
      getSubscriptionsData();
    }
  }, [selected, selectedAccount]);
  useEffect(() => {
    if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
      setSubId();
    }
  }, [selectedAWSId, selectedAzureId]);
  useEffect(() => {
    if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE && Id) {
      getSubscriptionsData();
    }
  }, [Id]);

  const handleSubscriptionChange = (event: any) => {
    setSelected(event.target.value);
    dispatch(
      accountActions.replaceAccountState({
        ...selectedAccountInfo,
        selectedSubscriptionName: event.target.value,
        selectedSubscriptionId:
          event.target.value === ALL_VALUE
            ? ALL_VALUE
            : filterSubscription(subscriptionsList, event.target.value),
        selectedSubData: subscriptionsList,
      })
    );

    const subscriptionData = {
      subscriptionName: event.target.value,
      subscriptionId:
        event.target.value === ALL_VALUE
          ? ALL_VALUE
          : filterSubscription(subscriptionsList, event.target.value),
      cloudType: CLOUD_PROVIDER_SERVICES.AZURE,
    };
    onAccountChange(subscriptionData);
  };

  const handleAzureIdChange = (event: any) => {
    setSelected(ALL_VALUE);
    setSelectedAzureId(event.target.value);

    dispatch(
      accountActions.replaceAccountState({
        ...selectedAccountInfo,
        selectedAccountName: event.target.value,
        selectedAccountId:
          event.target.value === ALL_VALUE
            ? ALL_VALUE
            : filterAzureId(azureIdData, event.target.value),
        selectedSubscriptionName: ALL_VALUE,
        selectedSubscriptionId: ALL_VALUE,
        selectedSubData: subscriptionsList,
      })
    );

    const azureAccountData = {
      accountName: event.target.value,
      accountId:
        event.target.value === ALL_VALUE
          ? ALL_VALUE
          : filterAzureId(azureIdData, event.target.value),
      cloudType: CLOUD_PROVIDER_SERVICES.AZURE,
    };
    onAccountChange(azureAccountData);
  };

  const handleAWSIdChange = (event: any) => {
    setSelectedAWSId(event.target.value);
    getIdData();
    dispatch(
      accountActions.replaceAccountState({
        ...selectedAccountInfo,
        selectedSubData: awsIdData,
        selectedAccountName: event.target.value,
        selectedAccountId:
          event.target.value === ALL_VALUE
            ? ALL_VALUE
            : filterAwsAccountId(awsIdData, event.target.value),
      })
    );

    const awsAccountData = {
      accountName: event.target.value,
      accountId:
        event.target.value === ALL_VALUE
          ? ALL_VALUE
          : filterAwsAccountId(awsIdData, event.target.value),
      cloudType: CLOUD_PROVIDER_SERVICES.AWS,
    };
    onAccountChange(awsAccountData);
  };

  const getIdData = async () => {
    try {
      if (selectedAccount && isAccountSelected) {
        const result = await accountService.getAccountDetails(
          selectedAccount.CloudProvider.toUpperCase()
        );
        if (
          selectedAccount.CloudProvider.toUpperCase() ===
          CLOUD_PROVIDER_SERVICES.AWS
        ) {
          setAWSIdData(result);
        } else {
          setAzureIdData(result);
        }
      }
    } catch (e: any) {
      // console.clear();
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  if (isNetworkError) {
    throw new Error('Network Error');
  }

  return (
    <>
      <div className="d-flex justify-content-end">
        {selectedAccount?.CloudProvider.toUpperCase() ===
        CLOUD_PROVIDER_SERVICES.AZURE ? (
          <>
            {' '}
            <div className="subscriptionDD mr-10">
              <label className="subscriptionLabel"> CFx_Name</label>
              <FormControl variant="outlined" className="w-100 mt-0 p-0">
                <InputLabel shrink id="demo-simple-select-outlined-label">
                  CFx Name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={selectedAzureId}
                  onChange={handleAzureIdChange}
                  label="Subscription"
                  displayEmpty
                  className="p-0"
                >
                  <MenuItem
                    value="all"
                    key="alldata"
                    disabled={azureIdData.length === 0}
                  >
                    All
                  </MenuItem>
                  <MenuItem
                    value="no"
                    key="nodata"
                    disabled={azureIdData.length !== 0}
                  >
                    {loader ? '' : 'No Active Subscription'}
                  </MenuItem>
                  {azureIdData.map((azureId: any, index: any) => (
                    <MenuItem value={azureId.AliasName} key={'data' + index}>
                      {azureId.AliasName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {selectedAzureId !== ALL_VALUE && (
              <div className="subscriptionDD">
                <label className="subscriptionLabel">Subscription</label>
                <FormControl variant="outlined" className="w-100 mt-0 p-0">
                  <InputLabel shrink id="demo-simple-select-outlined-label">
                    Subscription
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selected}
                    onChange={handleSubscriptionChange}
                    label="Subscription"
                    displayEmpty
                    className="p-0"
                  >
                    <MenuItem
                      value="no"
                      key="nodata"
                      disabled={subscriptionsList.length !== 0}
                    >
                      {loader ? '' : 'No Active Subscription'}
                    </MenuItem>
                    <MenuItem
                      value="all"
                      key="alldata"
                      disabled={subscriptionsList.length === 0}
                    >
                      All
                    </MenuItem>
                    {!loader ? (
                      subscriptionsList.map((filter: any, index: any) => (
                        <MenuItem
                          value={filter.Name || filter.SubscriptionId}
                          key={'data' + index}
                        >
                          {filter.Name || filter.SubscriptionId}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>Loading...</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            )}
          </>
        ) : (
          <div className="subscriptionDD">
            <label className="subscriptionLabel">Accounts</label>
            <FormControl variant="outlined" className="w-100 mt-0 p-0">
              <InputLabel shrink id="demo-simple-select-outlined-label">
                Accounts
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selectedAWSId}
                onChange={handleAWSIdChange}
                label="Subscription"
                displayEmpty
                className="p-0"
              >
                <MenuItem
                  value="all"
                  key="alldata"
                  disabled={awsIdData.length === 0}
                >
                  All
                </MenuItem>
                <MenuItem
                  value="no"
                  key="nodata"
                  disabled={awsIdData.length !== 0}
                >
                  {loader ? '' : 'No Active Subscription'}
                </MenuItem>
                {awsIdData.map((awsId: any, index: any) => (
                  <MenuItem value={awsId.AliasName} key={'data' + index}>
                    {awsId.AliasName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
      </div>
    </>
  );
};
