import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  // Select,
  // MenuItem,
  FormControl,
  // InputLabel,
  // SelectChangeEvent,
  TextField,
  Paper,
  Grid,
} from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import './Header.css';
import { SelectedAccount } from '../../components/SelectAccount/SelectAccount';
import { Autocomplete } from '@material-ui/lab';
import { CLOUD_PROVIDER_SERVICES } from '../../utils/constants';
import { awsRegions, azureLocations } from './config';
import { useSelector } from 'react-redux';
import { accountState } from '../../store';

// Define types for our component props and state
type TimeFrameDisplay = 'Daily' | 'Weekly' | 'Monthly';
// | 'Custom';
export type TimeFrameAPI = 'day' | 'week' | 'month';
// | 'custom';
// | 'Custom';
type Region = string;
type Service = string;

interface ServiceReportHeaderProps {
  downloadCsvHandler?: () => void;
  // onViewChange?: (view: 'table' | 'chart') => void;
  setActiveView: React.Dispatch<React.SetStateAction<'table' | 'chart'>>;
  activeView: string;
  onAccountChange?: any;
  onTimeFrameChange?: (timeFrame: TimeFrameAPI) => void;
  onRegionChange: (region: Region | null) => void;
  onServiceChange: (service: Service | null) => void;
  services: Service[];
}

const ServiceReportHeader: React.FC<ServiceReportHeaderProps> = ({
  downloadCsvHandler,
  // onViewChange,
  onTimeFrameChange,
  onRegionChange,
  onServiceChange,
  onAccountChange,
  activeView,
  setActiveView,
  services,
}) => {
  // State for selected values
  const selectedAccountInfo = useSelector(accountState);
  const [timeFrame, setTimeFrame] = useState<TimeFrameDisplay>('Daily');
  const [selectedRegion, setSelectedRegion] = useState<Region | null>(null);
  const [selectedService, setSelectedService] = useState<Service | null>(null);

  const cloudProvider = selectedAccountInfo?.selectedAccount?.CloudProvider.toUpperCase();
  // Handle time frame selection
  const handleTimeFrameClick = (newTimeFrame: TimeFrameDisplay) => {
    setTimeFrame(newTimeFrame);
    if (onTimeFrameChange) {
      onTimeFrameChange(timeFrameMapping[newTimeFrame]);
    }
  };

  // Handle region change
  const handleRegionChange = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    value: string | null
  ) => {
    const selectedRegion = value;
    setSelectedRegion(selectedRegion);
    if (onRegionChange) {
      onRegionChange(selectedRegion);
    }
  };

  // Handle service change
  const handleServiceChange = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    value: string | null
  ) => {
    const selectedService = value;
    setSelectedService(selectedService);
    if (onServiceChange) {
      onServiceChange(selectedService);
    }
  };

  // Handle view change
  const handleViewChange = (view: 'table' | 'chart') => {
    setActiveView(view);
  };

  const timeFrameMapping: Record<TimeFrameDisplay, TimeFrameAPI> = {
    Daily: 'day',
    Weekly: 'week',
    Monthly: 'month',
    // 'Custom': 'custom'
  };

  return (
    <div className="serviceReportWrapper">
      <Grid className="inventoryReportsHeading  mt-5">Service Report</Grid>

      <div className="serviceReportActions">
        <Button className="exportButton" onClick={downloadCsvHandler}>
          <FileDownloadOutlinedIcon />
        </Button>

        <ButtonGroup className="viewToggleGroup">
          <Button
            className={`timeframeButton ${
              activeView === 'table' ? 'timeframeActive' : ''
            }`}
            onClick={() => handleViewChange('table')}
          >
            <TableChartOutlinedIcon />
          </Button>
          <Button
            className={`timeframeButton ${
              activeView === 'chart' ? 'timeframeActive' : ''
            }`}
            onClick={() => handleViewChange('chart')}
          >
            <BarChartOutlinedIcon />
          </Button>
        </ButtonGroup>

        <div className="timeframeContainer">
          {([
            'Daily',
            'Weekly',
            'Monthly',
            // 'Custom'
          ] as TimeFrameDisplay[]).map((option) => (
            <Button
              key={option}
              className={`timeframeButton ${
                timeFrame === option ? 'timeframeActive' : ''
              }`}
              onClick={() => handleTimeFrameClick(option)}
            >
              {option}
            </Button>
          ))}
        </div>

        <FormControl className="selectControl">
          <Autocomplete
            id="region-autocomplete"
            options={
              cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
                ? awsRegions
                : azureLocations
            }
            value={selectedRegion || undefined}
            onChange={handleRegionChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Region"
                variant="outlined"
                className="autocompleteInput"
              />
            )}
            PaperComponent={(props) => (
              <Paper {...props} className="autocompletePaper" />
            )}
            // popupIcon={<span className="autocompleteArrow">▼</span>}
            className="selectDropdown"
          />
        </FormControl>

        <FormControl className="selectControl">
          <Autocomplete
            id="service-autocomplete"
            options={services}
            value={selectedService || undefined}
            onChange={handleServiceChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Services"
                variant="outlined"
                className="autocompleteInput"
              />
            )}
            PaperComponent={(props) => (
              <Paper {...props} className="autocompletePaper" />
            )}
            // popupIcon={<span className="autocompleteArrow">▼</span>}
            className="selectDropdown"
          />
        </FormControl>
        <SelectedAccount onAccountChange={onAccountChange} />
      </div>
    </div>
  );
};

export default ServiceReportHeader;
