import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RoutesFun } from '../../schemas/index';
import {
  ProtectedRoute,
  ProtectedRouteProps,
} from '../AuthenticatedRoute/AuthenticatedRoute';
import { Grid } from '@material-ui/core';
import { authService } from '../../services';
import { DashboardHeader } from '../Header/DashboardHeader';
import { Navigation } from '../Navigation/Navigation';
import IdleInstance from '../../pages/IdleInstances/IdleInstances';
import CostForecasting from '../../pages/CostForecasting/CostForecasting';
import RightSizing from '../../pages/RightSizing/RightSizing';
import CostSaving from '../../pages/CostSaving/CostSaving';
import Inventories from '../../pages/Inventories/Inventories';
import Inventory from '../../pages/Inventories/Inventory';
import { ScheduleVM } from '../../pages/ScheduleVM/ScheduleVM';
import { SpendingDetails } from '../../pages/CostForecasting/SpendingDetails/SpendingDetails';
import { ServiceWiseSpendingDetails } from '../../pages/CostForecasting/ServiceWiseSpendingDetails/ServiceWiseSpendingDetails';
import { UsersList } from '../../pages';
import { Invoice } from '../../pages/Invoice/Invoice';
import TagBillingDetail from '../../pages/TagBillingDetail/TagBillingDetail';
import SecurityScan from '../../pages/SecurityScan/SecurityScan';
import { InventoryReport } from '../../pages/Reports/InventoryReport';
import AppDashboard from '../../pages/AppDashboard/AppDashboard';
import NotificationManagement from '../../pages/NotificationManagement/NotificationManagement';
// import UptimeMonitoring from '../../pages/UptimeMonitoring/UptimeMonitoring';
import Billing from '../../pages/Billing/Billing';
import AdminDashboard from '../../superAdmin/pages/AdminDashboard/AdminDashboard';
import OrganizationDetails from '../../superAdmin/pages/OrganizationDetails/OrganizationDetails';
import AllOrganizations from '../../superAdmin/pages/AllOrganizations/AllOrganizations';
import DocumentUpload from '../../superAdmin/pages/DocumentUpload/DocumentUpload';
import UploadedDocuments from '../../pages/UploadedDocuments/UploadedDocuments';
import AdminPanelBilling from '../../superAdmin/pages/Billing/Billing';
import IdleResourcesTable from '../../pages/IdleInstances/IdleResourcesTable';
import OrgBillingDetails from '../../superAdmin/pages/Billing/OrgBillingDetails';
import PlanDetails from '../../pages/Billing/PlanDetails';
import TrialPlanConfirmation from '../../pages/TrialPlanConfirmation/TrialPlanConfirmation';
import InventoryResourceReport from '../../pages/ServicesReports/InventoryResourceReport';
import ServicesReports from '../../pages/ServicesReports/ServicesReports';
import HighestConsumedResources from '../../pages/HighestConsumedResources/HighestConsumedResources';
import SystemHealth from '../../superAdmin/pages/SystemHealth/SystemHealth';
import RightsizingAndIdleInput from '../../pages/RightsizingAndIdleInput/RightsizingAndIdleInput';
import URLMonitoring from '../../pages/UptimeMonitoring/URLMonitoring';
import RUMDashboard from '../../pages/RUM/Dashboard';
// import HealthMonitoringDashboard from '../../pages/HealthMonitoring/Dashboard';
import RUMDescription from '../../pages/RUM/Description';
import AddServerHealthVisualizationPage from '../../pages/HealthMonitoring/AddServerHealthVisualizationPage';
import { useSelector } from 'react-redux';
import { uiState } from '../../store';

export const Dashboard: React.FC = () => {
  const Routes = RoutesFun();
  const { collapsed } = useSelector(uiState);
  const [nChange, setnChange] = React.useState();

  const defaultProtectedRouteProps: ProtectedRouteProps = {
    isAuthenticated: authService.checkAuth(),
    authenticationPath: '/login',
  };

  return (
    <React.Fragment>
      <DashboardHeader changeStatus={setnChange} />

      <Grid container spacing={0} className="mt-0">
        <Grid className="innerContainer">
          <div className="splitLayout">
            <div className={`leftNav ${collapsed ? 'collapsed' : ''}`}>
              <Navigation />
            </div>
            <div className="rightContent">
              <div className="innerRightContent">
                <Switch>
                  <Route
                    exact
                    path={Routes.CostForecasting}
                    component={CostForecasting}
                  />
                  <Route exact path={Routes.UsersList} component={UsersList} />
                  <Route
                    exact
                    path={[
                      Routes.IdleInstance,
                      Routes.IdleInstancewithType,
                      Routes.IdleInstancewithResName,
                    ]}
                    component={IdleInstance}
                  />
                  <ProtectedRoute
                    exact
                    path={Routes.IdleInstanceDetail}
                    component={IdleResourcesTable}
                  />
                  <ProtectedRoute
                    {...defaultProtectedRouteProps}
                    exact={true}
                    path={[Routes.RightSizing, Routes.RightSizingwithType]}
                    component={(props: any) => (
                      <RightSizing {...props} nState={nChange} />
                    )}
                  />
                  <Route
                    exact
                    path={Routes.CostSaving}
                    component={CostSaving}
                  />
                  <Route
                    exact
                    path={Routes.Inventories}
                    component={Inventories}
                  />
                  <Route
                    exact
                    path={Routes.InventoryDetail}
                    component={Inventory}
                  />
                  <Route
                    exact
                    path={Routes.ScheduleVM}
                    component={ScheduleVM}
                  />
                  <Route exact path={Routes.Invoice} component={Invoice} />
                  <Route
                    exact
                    path={Routes.TagDetailWithValue}
                    component={TagBillingDetail}
                  />
                  <Route
                    exact
                    path={Routes.SpendingDetails}
                    component={SpendingDetails}
                  />
                  <Route
                    exact
                    path={Routes.ServiceWiseSpendingDetails}
                    component={ServiceWiseSpendingDetails}
                  />
                  <Route
                    exact
                    path={Routes.SecurityScan}
                    component={SecurityScan}
                  />
                  <Route
                    exact
                    path={Routes.InventoryReports}
                    component={InventoryReport}
                  />
                  <Route
                    exact
                    path={Routes.ServicesReports}
                    component={ServicesReports}
                  />
                  <Route
                    exact
                    path={Routes.InventoryResourceReports}
                    component={InventoryResourceReport}
                  />
                  <ProtectedRoute
                    exact
                    path={Routes.AppDashboard}
                    component={AppDashboard}
                  />
                  <ProtectedRoute
                    exact
                    path={Routes.NotificationManagement}
                    component={NotificationManagement}
                  />
                  <ProtectedRoute
                    exact
                    path={Routes.RightsizingAndIdle}
                    component={RightsizingAndIdleInput}
                  />
                  <ProtectedRoute
                    exact
                    path={Routes.Billing}
                    component={Billing}
                  />
                  <ProtectedRoute
                    exact
                    path={Routes.PlanDetails}
                    component={PlanDetails}
                  />
                  <ProtectedRoute
                    exact
                    path={[
                      Routes.UptimeMonitoring,
                      Routes.UptimeMonitoringWithType,
                    ]}
                    // component={UptimeMonitoring}
                    component={URLMonitoring}
                  />
                  <ProtectedRoute
                    exact
                    path={[Routes.RealUserMonitoring]}
                    component={RUMDashboard}
                  />
                  <ProtectedRoute
                    exact
                    path={Routes.SystemHealthMonitoring}
                    component={AddServerHealthVisualizationPage}
                  />
                  <ProtectedRoute
                    exact
                    path={Routes.RUMDescription}
                    component={RUMDescription}
                  />
                  <ProtectedRoute
                    exact
                    path={Routes.TrialPlanConfirmation}
                    component={TrialPlanConfirmation}
                  />
                  <Route
                    exact
                    path={Routes.UploadedDocuments}
                    component={UploadedDocuments}
                  />
                  <ProtectedRoute
                    exact
                    path={Routes.HighestConsumedResource}
                    component={HighestConsumedResources}
                  />

                  {/* admin panel */}
                  <ProtectedRoute
                    exact
                    path={Routes.AdminDashboard}
                    component={AdminDashboard}
                  />
                  <ProtectedRoute
                    exact
                    path={Routes.Organization}
                    component={OrganizationDetails}
                  />
                  <ProtectedRoute
                    exact
                    path={Routes.AllOrganizations}
                    component={AllOrganizations}
                  />
                  <ProtectedRoute
                    exact
                    path={Routes.DocumentUpload}
                    component={DocumentUpload}
                  />
                  <ProtectedRoute
                    exact
                    path={Routes.AdminPanelBilling}
                    component={AdminPanelBilling}
                  />
                  <ProtectedRoute
                    exact
                    path={Routes.AdminPanelOrganizationBillingDetails}
                    component={OrgBillingDetails}
                  />
                  <ProtectedRoute
                    exact
                    path={Routes.SystemHealth}
                    component={SystemHealth}
                  />

                  <Redirect to={Routes.Inventories} />
                </Switch>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
