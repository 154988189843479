import React from 'react';
import useECharts from '../../hooks/useEcharts';

interface ChartProps {
  option: any;
  style?: React.CSSProperties;
  className?: string;
  onChartInit?: (chart: echarts.ECharts) => void;
}

const Chart: React.FC<ChartProps> = ({
  option,
  style,
  className,
  onChartInit,
}) => {
  const { chartRef, chartInstance } = useECharts(option);

  // Notify parent when chart is initialized
  React.useEffect(() => {
    if (chartInstance && onChartInit) {
      onChartInit(chartInstance);
    }
  }, [chartInstance, onChartInit]);

  return <div ref={chartRef} style={style} className={className} />;
};

export default Chart;
