import React, { Fragment, useEffect, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import {
  faCaretRight,
  faCheck,
  faCog,
  faEdit,
  faPen,
  faPencilAlt,
  faPlusCircle,
  faSearch,
  faTimesCircle,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useForm } from 'react-hook-form';
import {
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Button,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Input,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Redirect } from 'react-router';
import { Link, useHistory } from 'react-router-dom';

import {
  authService,
  httpService,
  // toasterService,
  userService,
  accountService,
} from '../../../services';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { DashboardHeader } from '../../../components/Header/DashboardHeader';
import useStyles from './CreateAccount.style';
import helperStyles from '../../../styles/helper.styles';
import {
  AccountDetailsProps,
  AccountProps,
} from '../../../schemas/account.schema';
import { ConfigureCheckModal } from '../../../components/Modals/ConfigureCheckModal/ConfigureCheckModal';
import { RoutesFun } from '../../../schemas';
import { useLocation } from 'react-router-dom';
// import {
//   useUserData,
//   useUserDataActions,
// } from '../../../context/GlobalUserData';
import { Navigation } from '../../../components/Navigation/Navigation';
import { countries } from './Countries';
import { handleError } from '../../../utils/config';
import { uiState } from '../../../store';
import { useSelector } from 'react-redux';

interface AccountCreationProps {
  children?: React.ReactNode;
  match?: {
    params?: { string: number };
  };
  location?: {
    state?: { string: unknown };
  };
}

type AccountTypeProps = {
  Name: string;
  CloudProvider: string;
  Location: string;
  PayeeOrganization: string;
  Attributes: string;
};

export const CreateAccountPage: React.FC<AccountCreationProps> = (props) => {
  const classes = useStyles();
  const Routes = RoutesFun();
  const helperClasses = helperStyles();
  const history = useHistory();
  const [isEdit, setIsEdit] = React.useState(false);
  const [back, setBack] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState([]);
  const [errmsgDisplay, seterrmsgDisplay] = React.useState('d-none');
  const [msgDisplay, setmsgDisplay] = React.useState('d-none');
  const [type, setType] = React.useState<any>({
    Name: '',
    CloudProvider: '',
    Location: '',
    PayeeOrganization: '',
    Attributes: '',
  });
  const [updateId, setUpdateId] = React.useState(0);
  const [location, setLocation] = React.useState([]);
  const payeeTypes = [
    'Place new payees at hierarchy root',
    'Place new payees in same parent group as payer',
    'Place new payees in specific location',
  ];
  const [msg, setMsg] = React.useState(false);
  const [isNetworkError, setNetworkError] = React.useState(false);
  const loc = useLocation();
  const [currentPage, setCurrentPage] = React.useState('');
  const { collapsed } = useSelector(uiState);

  const {
    register,
    handleSubmit,
    control,
    formState,
    errors,
    setValue,
    getValues,
  } = useForm<AccountProps>({
    mode: 'onChange',
  });

  const cloudType = [
    { Id: 1, Name: 'AWS' },
    { Id: 2, Name: 'AZURE' },
    { Id: 3, Name: 'GOOGLE' },
  ];

  const handleChange = (
    event: React.ChangeEvent<{ name?: any; value: unknown }>
  ) => {
    setType((prevType: any) => {
      return {
        ...prevType,
        [event.target.name]: event.target.value,
      };
    });
  };

  const getAllLocations = async () => {
    const result = await accountService.getLocations();
    setLocation(result);
  };

  const checkIsEdit = () => {
    let param: any;

    if (props.match) {
      param = props.match.params;
    }
    if (Object.keys(param).length > 0) {
      if (param.Id) {
        getAccount(param.Id, param.cloudProvider);
      }
      setUpdateId(param.Id);
      setIsEdit(true);
    }
  };

  const checkIsBack = () => {
    if (props && props.location && props.location.state !== undefined) {
      setBack(true);
    }
  };

  const regMsgElement = () => {
    return <div className="regMsg">GOOGLE account is disabled</div>;
  };

  const onSubmit = async (data: AccountProps) => {
    try {
      if (type) {
        if (data.CloudProvider === 'GOOGLE') {
          setMsg(true);
        } else if (data.CloudProvider === 'AWS') {
          // setMsg(true);
          data.Name = type.Name;
          data.Attributes = type.Attributes;
          if (isEdit) {
            data.Id = updateId;
            const response = await accountService.editAccount(data);
            history.push(Routes.AccountDetails);
          } else {
            history.push({ pathname: Routes.ConfigureAccount, state: data });
            setBack(false);
          }
        } else {
          data.Name = type.Name;
          data.Attributes = type.Attributes;
          if (isEdit) {
            data.Id = updateId;
            const response = await accountService.editAccount(data);
            // toasterService.show(response.message, 'success');
            history.push(Routes.AccountDetails);
          } else {
            history.push({ pathname: Routes.ConfigureAccount, state: data });
            setBack(false);
          }
        }
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
        setErrorMsg(e.error.message.replace('ContactNo', 'Phone Number'));
        setmsgDisplay('d-none');
        seterrmsgDisplay('d-block');
      }
      setTimeout(() => {
        setmsgDisplay('d-none');
        seterrmsgDisplay('d-none');
      }, 2000);
    }
    // finally {
    //   setTimeout(() => {
    //     setmsgDisplay('d-none');
    //     seterrmsgDisplay('d-none');
    //   }, 2000);
    // }
  };

  const cancel = () => {
    history.push(Routes.AccountDetails);
  };

  const getAccount = async (Id: number, cloudProvider: string) => {
    const response = await accountService.getAccountData(Id, cloudProvider);
    setType(response);
  };

  useEffect(() => {
    checkIsEdit();
    checkIsBack();
    getAllLocations();
  }, []);

  useEffect(() => {
    if (type && isEdit) {
      setValue('Name', type.Name);
      setValue('Location', type.Location);
      setValue('CloudProvider', type.CloudProvider);
      setValue('PayeeOrganization', type.PayeeOrganization);
      setValue('Attributes', type.Attributes);
    }
  }, [type]);

  useEffect(() => {
    setCurrentPage(loc.pathname);
  }, [loc]);

  useEffect(() => {
    if (type && isEdit) {
    } else if (props.location && props.location.state) {
      setType(props.location.state);
      setValue('Name', type.Name);
      setValue('Location', type.Location);
      setValue('CloudProvider', type.CloudProvider);
      setValue('PayeeOrganization', type.PayeeOrganization);
      setValue('Attributes', type.Attributes);
    }
  }, [props.location, back]);

  if (isNetworkError) {
    throw new Error('Network Error');
  }

  return (
    <Fragment>
      <DashboardHeader />
      {/* <Navigation /> */}
      <Grid container className="innerContainer">
        <div className="splitLayout">
          <div className={`leftNav ${collapsed ? 'collapsed' : ''}`}>
            <Navigation />
          </div>

          <div className="rightContent">
            <div className="inner-content">
              <div className="createAccountContainer">
                <form
                  noValidate
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="off"
                >
                  <div className="innerContainer">
                    <div className="sContainer mb-15">
                      <div className="inventoryHeading pl-5">
                        <span className="font-30px fw-300">
                          {isEdit ? 'Edit Account' : 'Create Account'}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="configureAccountForm">
                    <div className="w-100">
                      <Grid container>
                        <Grid item md={6} sm={6} xs={6} className="p-10">
                          <FormControl
                            variant="outlined"
                            className="mb-10 configureFormElement"
                          >
                            {isEdit ? (
                              <InputLabel
                                id="demo-multiple-name-label"
                                shrink={Boolean(type.Name)}
                              >
                                CFx Name
                              </InputLabel>
                            ) : (
                              <InputLabel id="demo-multiple-name-label">
                                CFx Name
                              </InputLabel>
                            )}
                            <OutlinedInput
                              id="Name1"
                              name="Name"
                              type="text"
                              inputRef={register({
                                required: true,
                                pattern: /^[^-\s][a-zA-Z0-9_\s-]*$/,
                              })}
                              defaultValue={type?.Name}
                              onChange={handleChange}
                              // MenuProps={MenuProps}
                            />
                            <span className={classes.warningMessage}>
                              {(formState.touched.Name &&
                                type.Name.length === 0 &&
                                'CFX Name is required') ||
                                (errors.Name && 'Enter valid CFX Name')}
                            </span>
                          </FormControl>
                        </Grid>
                        <Grid item md={6} sm={6} xs={6} className="p-10">
                          <FormControl
                            variant="outlined"
                            className="mb-10 configureFormElement w-100"
                          >
                            <InputLabel id="CloudProvider1">
                              CLOUD PROVIDER
                            </InputLabel>
                            <Controller
                              name="CloudProvider"
                              control={control}
                              rules={{ required: true }}
                              defaultValue={
                                type.CloudProvider ? type.CloudProvider : ''
                              }
                              render={({ onChange, name, value }) => (
                                <Select
                                  label="CloudProvider"
                                  onChange={onChange}
                                  name={name}
                                  value={value}
                                >
                                  {cloudType.map((item: any) => (
                                    <MenuItem key={item.Id} value={item.Name}>
                                      {item.Name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            />

                            <span className={classes.warningMessage}>
                              {errors?.CloudProvider &&
                                'CloudProvider is required'}
                            </span>
                          </FormControl>
                        </Grid>
                        <Grid item md={6} sm={6} xs={6} className="p-10">
                          <FormControl
                            variant="outlined"
                            className="mb-10 configureFormElement w-100"
                          >
                            <InputLabel id="Location1">
                              LOCATION
                              {/* <span className={helperClasses.styleFont}>
                                Clear this field to move account to the root
                              </span> */}
                            </InputLabel>
                            <Controller
                              name="Location"
                              rules={{ required: true }}
                              control={control}
                              onChange={handleChange}
                              defaultValue={type.Location ? type.Location : ''}
                              render={({ onChange, name, value }) => (
                                <Select
                                  label="Location"
                                  name={name}
                                  onChange={onChange}
                                  value={value}
                                  MenuProps={{
                                    classes: { paper: classes.menuPaper },
                                  }}
                                >
                                  {countries.map((item: any, i: any) => (
                                    <MenuItem key={i} value={item.label}>
                                      {item.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            />
                            <span className={classes.warningMessage}>
                              {errors.Location && 'Location is required'}
                            </span>
                          </FormControl>
                        </Grid>
                        <Grid item md={6} sm={6} xs={6} className="p-10">
                          <FormControl
                            variant="outlined"
                            className="mb-10 configureFormElement w-100"
                          >
                            <InputLabel id="PayeeOrganization1">
                              PAYEE ORGANISATION
                              <span className={helperClasses.styleFont}>
                                (Only applicable if account is Payer)
                              </span>
                            </InputLabel>
                            <Controller
                              name="PayeeOrganization"
                              rules={{ required: true }}
                              control={control}
                              onChange={handleChange}
                              defaultValue={
                                type.PayeeOrganization
                                  ? type.PayeeOrganization
                                  : ''
                              }
                              render={({ onChange, name, value }) => (
                                <Select
                                  label="PayeeOrganization"
                                  onChange={onChange}
                                  name={name}
                                  value={value}
                                >
                                  {payeeTypes.map((item) => (
                                    <MenuItem key={item} value={item}>
                                      {item}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            ></Controller>
                            <span className={classes.warningMessage}>
                              {errors.PayeeOrganization &&
                                'PayeeOrganization is required'}
                            </span>
                          </FormControl>
                        </Grid>
                        <Grid item md={6} sm={6} xs={6} className="p-10">
                          <FormControl
                            variant="outlined"
                            className="mb-20 configureFormElement"
                          >
                            <InputLabel
                              id="demo-mutiple-name-label"
                              shrink={Boolean(type.Attributes)}
                            >
                              ATTRIBUTES
                            </InputLabel>
                            <OutlinedInput
                              id="Attributes1"
                              name="Attributes"
                              type="text"
                              inputRef={register}
                              defaultValue={type?.Attributes}
                              onChange={handleChange}
                            ></OutlinedInput>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        sm={12}
                        xs={12}
                        className="accountsMsg m-5 mx-10"
                      >
                        <div className="errMsg">
                          {msg ? regMsgElement() : ''}
                        </div>
                        <div
                          className={
                            errorMsg.length
                              ? 'alert-danger p-8 px-20 mr-10 font-14px responseMsg ' +
                                errmsgDisplay
                              : ''
                          }
                        >
                          {errorMsg}
                        </div>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12} className="m-5 mx-10">
                        <div className="cloud-providers">
                          {type && console.log(type)}
                        </div>
                      </Grid>
                      <Grid container className="configureAccountBtns">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={cancel}
                        >
                          CANCEL
                        </button>

                        <button type="submit" className="btn btn-primary">
                          {isEdit ? 'UPDATE' : 'SAVE'}
                        </button>
                      </Grid>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Fragment>
  );
};
