import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, Typography, Box, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomTooltip from '../MasterComponent/CustomTooltip';
import Chart from '../MasterComponent/Echarts';
import ZoomInModal from '../Modals/ZoomInModalNew/ZoomInModal';
import { TableLegend } from '../../pages/HealthMonitoring/TableLegend';

// Custom Card Component
interface SectionCardProps {
  card: {
    title: string;
    tooltip?: string;
    options: any;
    loader: boolean;
  };
}

const SectionCard: React.FC<SectionCardProps> = ({ card }) => {
  const { title, tooltip, options, loader } = card;

  // const chartRef = useRef<HTMLDivElement>(null);
  const [chartInstance, setChartInstance] = useState<echarts.ECharts | null>(
    null
  );

  // Handle chart initialization
  const handleChartInit = (chart: echarts.ECharts) => {
    setChartInstance(chart);
  };

  const hasTableLegend = card.options && (card.options as any).tableData;

  // Extract table data if available
  const tableData = hasTableLegend ? (card.options as any).tableData : null;

  const CustomLegend = ({ legendData, colors, chartInstance }: any) => {
    // Track selected state
    const [selectedSeries, setSelectedSeries] = useState<
      Record<string, boolean>
    >(
      legendData.reduce((acc: Record<string, boolean>, name: string) => {
        acc[name] = true;
        return acc;
      }, {})
    );

    const toggleSeries = (name: string) => {
      if (!chartInstance) return;

      // Update local state
      setSelectedSeries((prev) => ({
        ...prev,
        [name]: !prev[name],
      }));

      // This is the key fix - we need to use the legend-select action
      chartInstance.dispatchAction({
        type: 'legendToggleSelect',
        name: name,
      });
    };

    return (
      <div
        style={{
          width: '100%',
          maxHeight: '80px',
          overflowY: 'auto',
          display: 'flex',
          flexWrap: 'wrap',
          padding: '8px 0',
          borderTop: '1px solid #eee',
        }}
      >
        {legendData.map((name: any, index: number) => (
          <div
            key={name}
            onClick={() => toggleSeries(name)}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '2px 10px',
              cursor: 'pointer',
              marginRight: '10px',
              marginBottom: '5px',
              opacity: selectedSeries[name] ? 1 : 0.5,
            }}
          >
            <div
              style={{
                width: '14px',
                height: '14px',
                backgroundColor: colors[index % colors.length],
                marginRight: '5px',
                borderRadius: '2px',
              }}
            />
            <span>{name}</span>
          </div>
        ))}
      </div>
    );
  };
  return (
    <div
      className="sectionCard"
      style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          marginBottom: 1,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: 'bold',
            display: 'flex',
            color: 'black',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {title}
          {tooltip ? (
            <CustomTooltip title={tooltip}>
              <IconButton size="small" sx={{ marginLeft: 1 }}>
                <InfoOutlinedIcon fontSize="small" />
              </IconButton>
            </CustomTooltip>
          ) : (
            ''
          )}
        </Typography>

        <ZoomInModal
          key={title}
          chartOptions={options}
          title={title}
          tooltip={tooltip}
          hasTableLegend={hasTableLegend}
          tableData={tableData}
          // chartInstance={chartInstance}
          // onChartInit={handleChartInit}
          CustomLegend={CustomLegend}
        />
      </Box>
      {loader ? (
        <div className="noDataCenterBox">
          <CircularProgress />
        </div>
      ) : options ? (
        <>
          <Chart
            option={options}
            // style={{ minHeight: '300px', width: '100%' }}
            style={{
              minHeight: hasTableLegend ? 'calc(100% - 180px)' : '300px', // Adjust height when table is present
              // minHeight: '250px',
              width: '100%',
            }}
            onChartInit={handleChartInit}
          />
          {!hasTableLegend && options.customLegend && (
            <CustomLegend
              legendData={options.customLegend.data}
              colors={options.customLegend.colors}
              chartInstance={chartInstance}
            />
          )}
          {hasTableLegend && tableData && (
            <TableLegend
              chartInstance={chartInstance}
              seriesData={tableData.seriesData.map(
                (series: any, index: number) => ({
                  ...series,
                  // Ensure color is properly set - fallback to chart colors if needed
                  color: series.color || '007bff',
                })
              )}
              isMemoryGraph={tableData.isMemoryGraph}
              displayUnit={tableData.displayUnit}
            />
          )}
        </>
      ) : (
        <div className="noDataCenterBox">No data available</div>
      )}
    </div>
  );
};

export default SectionCard;
