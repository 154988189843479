import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as echarts from 'echarts';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import './Description.css';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import {
  BROWSERS,
  config,
  FILTER_LABELS,
  getCountryCode,
  percentageChange,
  PageData,
  formatSecondsAsTime,
  PLATFORMS,
  TIME_FILTERS_MAP,
  countryData,
  TIME_FILTERS_MAPPING,
} from './config';
import MetricCard from '../../components/RUM/MetricCard';
import TitleHeader from '../../components/MasterComponent/TitleHeader/TitleHeader';
import {
  CountryCard,
  CountryCardProps,
} from '../../components/RUM/CountryCard';
import { Divider } from '@material-ui/core';
import { TopEntityCard } from '../../components/RUM/TopEntityCard';
import {
  MetricData,
  GraphData,
  RUMLoaderState,
  RUMFilters,
} from './interfaces';
import { ERRORS } from '../../utils/constants';
import { handleError } from '../../utils/config';
import { Chart } from 'react-google-charts';
import RUMChart from '../../components/MasterComponent/Echarts';
import { Filter } from './filters';
import EnhancedTableHead from './FilterTable';
import { periodValueType } from './config';
import CustomTooltip from '../../components/MasterComponent/CustomTooltip';
import MetricSkeleton from '../../components/RUM/MetricSkeleton';
import ApdexScore from '../../components/RUM/Description/ApdexMetricCard';
import ApdexLoadtimeSkeleton from '../../components/RUM/Skeletons/ApdexLoadTimeSkeleton';
import { ApdexDataI } from './Definition';

export interface CountryData {
  name: string;
  code: string;
  value: [number, number, number] | []; // [Median load time, Pageviews, Sessions] or empty array if no data
}

const RUMDescription: React.FC = (props: any) => {
  const clientData = props.location?.state?.data;
  const [loader, setLoader] = useState<RUMLoaderState>({
    rumDataLoader: false,
    countryDataLoader: false,
    platformDataLoader: false,
    browserDataLoader: false,
    chartDataLoader: false,
    sessionsLoader: false,
    apdexDetailLoader: false,
    pageViewsLoader: false,
  });

  const history = useHistory();
  const [rumData, setRumData] = useState<MetricData[]>([]);
  const [platformLoadTimeData, setPlatformLoadTimeData] = useState<GraphData[]>(
    []
  );
  const [platformSessionData, setPlatformSessionData] = useState<GraphData[]>(
    []
  );
  const [browserLoadTimeData, setBrowserLoadTimeData] = useState<GraphData[]>(
    []
  );
  const [browserSessionData, setBrowserSessionData] = useState<GraphData[]>([]);
  const [countryCardData, setCountryCardData] = useState<CountryCardProps[]>(
    []
  );
  const [queryFilters, setQueryFilters] = useState<RUMFilters>({
    clientId: clientData.clientId,
  });
  const [selectedFilters, setSelectedFilters] = useState<RUMFilters>({
    period: TIME_FILTERS_MAP.ONE_DAY.LONG,
    browser: 'All',
    country: 'All',
    platform: 'All',
  });
  const [pageViewData, setPageViewData] = useState<PageData[]>([]);
  const [apdexData, setApdexData] = useState<ApdexDataI>();

  const [chartData, setChartData] = useState<any[]>([
    ['Country', 'Median Load Time (s)'],
  ]);
  const [sessionsData, setSessionsData] = useState<any>();
  const [networkError, setNetworkError] = useState();
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof PageData>('pageViews');
  // const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rumApiData, setRumApiData] = useState<any[]>([]);

  const rumDataHandler = async () => {
    try {
      setLoader((prevState) => ({
        ...prevState,
        rumDataLoader: true,
      }));

      const result = await config.getRUMData(queryFilters);

      setRumApiData(result);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader((prevState) => ({
        ...prevState,
        rumDataLoader: false,
      }));
    }
  };

  useMemo(() => {
    const metricData: MetricData[] = [];

    const sessionData = rumApiData[0]?.sessionsData;
    const loadTimeData = rumApiData[0]?.loadTimeData;

    const bounceRateData = rumApiData[0]?.bounceRateData;
    const apdexScoreData = rumApiData[0]?.apdexData;

    const sessionPercentageChange = percentageChange(
      sessionData?.activeSessions ?? 0,
      sessionData?.dayAgo ?? 0
    );

    const loadTimePercentageChange = percentageChange(
      loadTimeData?.loadTime ?? 0,
      loadTimeData?.dayAgo ?? 0
    );

    const METRIC_DATA_TITLE_MAP = {
      '24 hours': 'dayAgo',
      '1 hour': 'hourAgo',
      '7 days': 'weekAgo',
      '1 month': 'monthAgo',
      // '3 months': 'monhthAgo',
    };

    metricData.push({
      title: 'Active Sessions (Now)',
      tooltip:
        'The number of sessions with at least one interaction in the past 5 minutes.',
      value: sessionData?.activeSessions ?? 0.0,
      subTitle: selectedFilters.period ?? TIME_FILTERS_MAP.ONE_DAY.LONG,
      subValue:
        (sessionData &&
          sessionData[
            METRIC_DATA_TITLE_MAP[selectedFilters.period as periodValueType]
          ]) ||
        0,
      mainUnit: '',
      unit: '',
      change: Number((sessionPercentageChange?.percentage ?? 0)?.toFixed(2)),
      changeType: sessionPercentageChange?.change ?? 'NoChange',
    });

    metricData.push({
      title: 'Load Time (Now)',
      tooltip:
        'The time it takes to download and display all synchronous assets on your page. This is measured from the start of navigation to the start of the load event.',
      value: Number(((loadTimeData?.loadTime ?? 0) / 1000)?.toFixed(2)),
      subTitle: selectedFilters.period ?? TIME_FILTERS_MAP.ONE_DAY.LONG,
      subValue: (
        (loadTimeData
          ? Number(
              loadTimeData[
                METRIC_DATA_TITLE_MAP[selectedFilters.period as periodValueType]
              ]
            )
          : 0) / 1000
      )?.toFixed(2),
      change: Number(loadTimePercentageChange?.percentage ?? 0).toFixed(2),
      changeType: loadTimePercentageChange?.change ?? 'NoChange',
      mainUnit: 's',
      unit: 's',
    });

    metricData.push({
      title: 'Bounce Rate (Now)',
      tooltip:
        'The percentage of visitors who leave your site after viewing just one page.',
      value: bounceRateData?.bounceRate
        ? Number(bounceRateData?.bounceRate).toFixed(2)
        : 'No data',
      subTitle: selectedFilters.period ?? TIME_FILTERS_MAP.ONE_DAY.LONG,
      subValue:
        (bounceRateData &&
          Number(
            bounceRateData[
              METRIC_DATA_TITLE_MAP[selectedFilters.period as periodValueType]
            ]?.toFixed(2)
          )) ||
        '--',
      change: Number(bounceRateData?.bouncePercentageChange ?? 0)?.toFixed(2),
      changeType: bounceRateData?.bouncePercentageType ?? 'NoChange',
      mainUnit: bounceRateData?.bounceRate ? '%' : '',
      unit:
        bounceRateData &&
        Number(
          bounceRateData[
            METRIC_DATA_TITLE_MAP[selectedFilters.period as periodValueType]
          ]?.toFixed(2)
        )
          ? '%'
          : '',
    });
    metricData.push({
      title: 'Apdex Score (Now)',
      tooltip: {
        html: `
    <p>Apdex (Application Performance Index) measures the satisfaction of your site’s visitors by evaluating their load times.</p> 

      
      <p>It’s a performance index that ranges from 0 to 1, with higher values indicating better user experience.</p>
`,
      },
      value: apdexScoreData?.apdexScore
        ? Number(apdexScoreData?.apdexScore).toFixed(2)
        : 'No data',
      subTitle: selectedFilters.period ?? TIME_FILTERS_MAP.ONE_DAY.LONG,
      subValue:
        (apdexScoreData &&
          Number(
            apdexScoreData[
              METRIC_DATA_TITLE_MAP[selectedFilters.period as periodValueType]
            ]?.toFixed(2)
          )) ||
        '--',
      change: Number(apdexScoreData?.bouncePercentageChange)?.toFixed(2),
      changeType: apdexScoreData?.bouncePercentageType ?? 'NoChange',
      mainUnit: '',
      unit: '',
    });
    setRumData(metricData);
  }, [rumApiData]);

  if (networkError) {
    throw new Error(networkError);
  }

  const platformDataHandler = async () => {
    try {
      setLoader((prevValue) => ({
        ...prevValue,
        platformDataLoader: true,
      }));
      const result = await config.getPlatformBrowserData({
        ...queryFilters,
        datatype: 'device',
      });
      if (result) {
        const pageloadData: GraphData[] = result.pageload.map(
          (data: { avg_load: string; device: string }) => ({
            name: data.device,
            value: Number(Number(data.avg_load)?.toFixed(2)),
          })
        );
        const sessionsData: GraphData[] = result.sessionCount.map(
          (data: { device: string; totalcount: string }) => ({
            name: data.device,
            value: Number(Number(data.totalcount)?.toFixed(2)),
          })
        );
        setPlatformLoadTimeData(pageloadData);
        setPlatformSessionData(sessionsData);
      }
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader((prevState) => ({ ...prevState, platformDataLoader: false }));
    }
  };
  const browserDataHandler = async () => {
    try {
      setLoader((prevValue) => ({
        ...prevValue,
        browserDataLoader: true,
      }));
      const result = await config.getPlatformBrowserData({
        ...queryFilters,
        datatype: 'browsers',
      });
      if (result) {
        const pageloadData: GraphData[] = result.pageload.map(
          (data: { avg_load: string; browser: string }) => ({
            name: data.browser,
            value: Number(Number(data.avg_load)?.toFixed(2)),
          })
        );
        const sessionsData: GraphData[] = result.sessionCount.map(
          (data: { browser: string; totalcount: string }) => ({
            name: data.browser,
            value: Number(Number(data.totalcount)?.toFixed(2)),
          })
        );
        setBrowserLoadTimeData(pageloadData);
        setBrowserSessionData(sessionsData);
      }
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader((prevState) => ({ ...prevState, browserDataLoader: false }));
    }
  };
  const countryDataHandler = async () => {
    try {
      setLoader((prevState) => ({ ...prevState, countryDataLoader: true }));
      const result = await config.getCountryWiseSessionData(queryFilters);

      if (result) {
        let totalCount = 0;
        result.forEach((data: { sessionCount: number; country: string }) => {
          totalCount += data.sessionCount;
        });
        const countryData: CountryCardProps[] = result.map(
          (data: { sessionCount: number; country: string }) => ({
            countryName: data.country,
            countryCode: getCountryCode(data.country),
            count: data.sessionCount,
            totalCount,
          })
        );
        setCountryCardData(countryData);
      }
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader((prevState) => ({ ...prevState, countryDataLoader: false }));
    }
  };

  const filterChangeHandler = (input: RUMFilters) => {
    const [key, value] = Object.entries(input)[0];
    const inputKey = key as keyof RUMFilters;
    setSelectedFilters((prevState) => ({
      ...prevState,
      [inputKey]:
        value === null
          ? inputKey === 'period'
            ? TIME_FILTERS_MAP.ONE_DAY.LONG
            : 'All'
          : value,
    }));
    setQueryFilters((prevState) => {
      const updatedFilters: any = { ...prevState };

      // Handle if the value is null (field cleared)
      if (value === null) {
        if (inputKey === 'period') {
          updatedFilters[inputKey] =
            TIME_FILTERS_MAPPING[TIME_FILTERS_MAP.ONE_DAY.LONG];
        } else {
          updatedFilters[inputKey] = 'All';
        }
        return updatedFilters;
      }

      if (value === 'All' && !prevState[inputKey]) {
        return updatedFilters;
      } else if (value === 'All') {
        delete updatedFilters[inputKey];
        return updatedFilters;
      } else if (inputKey === 'period') {
        const periodValue = input.period as periodValueType;
        input.period = TIME_FILTERS_MAPPING[periodValue];
      }
      return { ...updatedFilters, ...input };
    });
  };

  const mapDataHandler = async () => {
    try {
      setLoader((prevLoader) => ({ ...prevLoader, chartDataLoader: true }));
      const countryWiseResponse = await config.getCountryWiseViews(
        queryFilters
      );

      if (countryWiseResponse) {
        const formatData = [
          [
            'Country',
            'Median Load Time (s)',
            { role: 'tooltip', type: 'string', p: { html: true } },
            // 'Country Name',
          ],
          ...countryWiseResponse.map((d: any) => [
            d.country,
            d.medianloadtime,
            `<div class="RUMCustomTooltip">
               <div><p>Median load time:</p> <p>${d.medianloadtime}s</p></div>
               <div><p>Pageviews:</p> <p>${d.pageviews}</p></div>
               <div><p>Sessions:</p> <p>${d.sessioncount}</p></div>
               </div>`,
            // d.country,
          ]),
        ];
        setChartData(formatData);
      }
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader((prevState) => ({ ...prevState, chartDataLoader: false }));
    }
  };

  const getSessionViews = async () => {
    try {
      setLoader((prevLoader) => ({ ...prevLoader, sessionsLoader: true }));

      const sessionsResponse = await config.getSessionViews(queryFilters);
      setSessionsData(sessionsResponse);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader((prevState) => ({ ...prevState, sessionsLoader: false }));
    }
  };

  useEffect(() => {
    platformDataHandler();
    rumDataHandler();
    browserDataHandler();
    countryDataHandler();
    mapDataHandler();
    getSessionViews();
    pageWiseDataHandler();
    apdexDetailHandler();
  }, [queryFilters]);

  const options = {
    colorAxis: {
      colors: ['#83CA19', '#FFE685', '#FFD653', '#FFB364', '#FF7D6D'],
    },

    datalessRegionColor: '#e5e5e5',
    defaultColor: '#f5f5f5',
    tooltip: {
      isHtml: true,
    },
    // keepAspectRatio: true,
    enableRegionInteractivity: true,
    legend: {
      position: 'right',
      alignment: 'center',
      textStyle: { fontSize: 12 },
    },
  };

  const medianAndSessionPieHandler = useCallback((pieData: any) => {
    const pieOptions: echarts.EChartsOption = {
      // title: {
      //   text: 'Sessions',
      //   left: 'center',

      //   textStyle: {
      //     fontSize: 16,
      //     fontWeight: 'bold',
      //   },
      // },
      tooltip: {
        textStyle: {
          align: 'left',
        },
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)',
      },
      legend: {
        orient: 'vertical',
        top: 'bottom',
        itemGap: 5, // Adjust this value to change the spacing between legend items
        padding: [50, 0, 0, 0],
        data: ['New Sessions', 'Returning Sessions'],
      },

      series: [
        {
          name: 'Sessions',
          type: 'pie',
          radius: '50%',
          top: '0',
          data: pieData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          color: ['#066bc2', '#a3ceee'],
          label: {
            show: false,
          },
        },
      ],
    };

    return pieOptions;
  }, []);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof PageData
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: 'asc' | 'desc',
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const sortedData = pageViewData.sort(getComparator(order, orderBy));

  const pageWiseDataHandler = async () => {
    try {
      setLoader((prevLoader) => ({ ...prevLoader, pageViewsLoader: true }));
      // const params = { clientId: clientData?.clientId };
      const response = await config.getPageWiseDetail(queryFilters);
      if (response) {
        const formattedData = response.map((item: any) => ({
          ...item,
          pageViews: Number(item.pageViews),
          isExitPage: Number(item.isExitPage),
          medianLoadTime: Number(item.medianLoadTime),
          totalPageLoadTime: parseFloat(
            item.totalPageLoadTime.replace(/[^\d.-]/g, '')
          ),
        }));
        setPageViewData(formattedData);
      }
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader((prevLoader) => ({ ...prevLoader, pageViewsLoader: false }));
    }
  };

  const apdexDetailHandler = async () => {
    try {
      setLoader((prevLoader) => ({ ...prevLoader, apdexDetailLoader: true }));
      // const params = { clientId: clientData?.clientId };
      const response = await config.getApdexDetail(queryFilters);

      response && setApdexData(response);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader((prevLoader) => ({ ...prevLoader, apdexDetailLoader: false }));
    }
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    setRowsPerPage(parseInt(event.target.value as string, 10));
  };

  const infoIcon = (
    <HelpOutlineIcon
      style={{
        fontSize: '16px',
        cursor: 'pointer',
        color: '#666',
      }}
    />
  );

  return (
    <>
      <TitleHeader
        extraContent={
          <Filter
            filters={[
              [...Object.values(TIME_FILTERS_MAP).map((data) => data.LONG)],
              ['All', ...PLATFORMS],
              ['All', ...BROWSERS],
              ['All', ...countryData.map((data) => data.name)],
            ]}
            filterState={selectedFilters}
            lables={FILTER_LABELS}
            filterChangeHandler={filterChangeHandler}
          />
        }
      >
        {clientData.name.toUpperCase()}
      </TitleHeader>
      <div className="RUMDetailContainer">
        <div className="RUMDetail-container">
          <Grid container spacing={1}>
            {loader.rumDataLoader
              ? rumData.map((__, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <MetricSkeleton />
                  </Grid>
                ))
              : rumData.map((metric, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <MetricCard {...metric} />
                  </Grid>
                ))}
          </Grid>
        </div>
        <div className="RUMDetail-container map-parent flex justify-between ">
          <div className="map-div">
            <div
              className="section-title descriptionCardTitle"
              style={{
                textAlign: 'left',
                marginBottom: '10px',
                paddingBottom: '0px',
              }}
            >
              Load time by countries
              <CustomTooltip title="Displays the median load time for the top 30 countries with the highest number of visitors.">
                {infoIcon}
              </CustomTooltip>
            </div>
            <Chart
              key={chartData.length}
              chartType="GeoChart"
              height="94%"
              width="100%"
              data={chartData}
              options={options}
              mapsApiKey="YOUR_GOOGLE_MAPS_API_KEY"
            />
          </div>

          <div className="session-container map-session-card">
            <div className="map-session-pie">
              {/* <div style={{ position: 'relative' }}> */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    marginRight: '8px',
                  }}
                >
                  Sessions
                </span>
                <CustomTooltip title="Shows the total number of sessions, categorized by new and returning users, along with the average visit duration and pages viewed per session.">
                  {infoIcon}
                </CustomTooltip>
              </div>
              {loader.sessionsLoader ? (
                <Skeleton
                  style={{
                    margin: 'auto',
                    width: '194px',
                    height: '280px',
                    borderRadius: '50%',
                  }}
                />
              ) : (
                <RUMChart
                  option={medianAndSessionPieHandler([
                    {
                      value: sessionsData?.newSession || 0,
                      name: 'New Sessions',
                    },
                    {
                      value: sessionsData?.olsSession || 0,
                      name: 'Returning Sessions',
                    },
                  ])}
                  style={{ height: '280px' }}
                />
              )}
              {/* </div> */}
            </div>
            <Divider className="map-session-divider" orientation="vertical" />
            <div className="map-session-data">
              <div className="sessions-total">
                {loader.sessionsLoader ? (
                  <Skeleton
                    style={{ margin: 'auto', width: '50%', height: '40px' }}
                  />
                ) : (
                  `${sessionsData?.totalSession || 0} IN TOTAL`
                )}
              </div>
              <div className="sessions-median-pageviews">
                {loader.sessionsLoader ? (
                  <Skeleton
                    style={{ margin: 'auto', width: '50%', height: '20px' }}
                  />
                ) : (
                  `Median pageviews/session: ${
                    sessionsData?.median_pageviews || 0
                  }`
                )}
              </div>
              <div className="sessions-duration">
                {loader.sessionsLoader ? (
                  <Skeleton
                    style={{ margin: 'auto', width: '50%', height: '20px' }}
                  />
                ) : (
                  `Median session duration: ${
                    sessionsData?.medianSesssionduration?.toFixed(2) ||
                    (0.0).toFixed(2)
                  }s`
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="RUMDetail-container flex justify-between">
          <div className="map-container topCards">
            <TopEntityCard
              title="Top Platforms"
              tooltip="Displays session distribution by platform along with the average load time for the selected time period."
              subtitle1="Average Load Time"
              subtitle2="Sessions"
              loader={loader.platformDataLoader}
              pageLoadData={platformLoadTimeData}
              sessionsData={platformSessionData}
            />
            <TopEntityCard
              title="Top Browsers"
              tooltip="Shows session distribution by browser along with the average load time for the selected time period."
              subtitle1="Average Load Time"
              subtitle2="Sessions"
              loader={loader.browserDataLoader}
              pageLoadData={browserLoadTimeData}
              sessionsData={browserSessionData}
            />
          </div>
          <div className="session-container country-wise-card">
            <div
              className="section-title descriptionCardTitle"
              style={{ marginLeft: '1.5rem', width: '24.5rem' }}
            >
              Top countries by sessions
              <CustomTooltip title="Displays the distribution of sessions by the geographical location of users, with bars representing the percentage of total sessions during the selected time period.">
                {infoIcon}
              </CustomTooltip>
            </div>
            <div style={{ maxHeight: '42rem', overflowY: 'auto' }}>
              {loader.countryDataLoader ? (
                <Skeleton style={{ width: '100%' }} />
              ) : countryCardData.length ? (
                countryCardData.map((data) => <CountryCard {...data} />)
              ) : (
                <div
                  style={{
                    background: '#E5E5E5',
                    borderRadius: '5px',
                    padding: '2px',
                  }}
                >
                  No Data
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="RUMDetail-container">
          {loader.apdexDetailLoader ? (
            <ApdexLoadtimeSkeleton />
          ) : (
            apdexData && <ApdexScore apdexObj={apdexData} />
          )}
        </div>
        <div className="RUMDetail-container">
          <div
            className="section-title descriptionCardTitle"
            style={{
              textAlign: 'left',
              marginBottom: '10px',
              paddingBottom: '0px',
              width: '11rem',
            }}
          >
            Top Pages
            <CustomTooltip
              title={{
                html: `
            <h5>Top pages</h5>
            <p>A list of the most visited pages on your site during the selected time period.</p>

            <h5>Pageviews</h5>
            <p>The total number of views each page received during the selected time period.</p>

            <h5>Exit rate</h5>
            <p>The percentage of pageviews that were the final one in a session.</p>
            <h5>Median load time</h5>
            <p>The median page load time for the selected time period.</p>

            <h5>Total Page load time</h5>
            <p>The cumulative time visitors spent waiting for a page to load during the selected time period.</p>

  `,
              }}
            >
              <HelpOutlineIcon />
            </CustomTooltip>
          </div>
          <TableContainer className="accContainer">
            <Table
              stickyHeader
              className="table adminTable adminPanelTable"
              size="small"
              aria-label="sticky table"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {loader.pageViewsLoader ? (
                  <>
                    <TableCell>Loading...</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </>
                ) : pageViewData.length ? (
                  sortedData.slice(0, rowsPerPage).map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="left" style={{ whiteSpace: 'initial' }}>
                        {row.pageUrl}
                      </TableCell>
                      <TableCell align="left">{row.pageViews}</TableCell>
                      <TableCell align="left">{row.isExitPage}%</TableCell>
                      <TableCell align="left">{row.medianLoadTime}s</TableCell>
                      <TableCell align="left">
                        {formatSecondsAsTime(row.totalPageLoadTime)}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <>
                    <TableCell>No data found</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <FormControl className="tableExpandSelect">
              <InputLabel id="rows-per-page-label" sx={{ fontWeight: 500 }}>
                Rows
              </InputLabel>
              <Select
                labelId="rows-per-page-label"
                id="rows-per-page-select"
                value={rowsPerPage}
                label="Rows"
                onChange={handleChangeRowsPerPage}
              >
                {[5, 10, 15, sortedData.length]
                  .sort((a: number, b: number) => a - b)
                  .map((number) => (
                    <MenuItem key={number} value={number}>
                      {number}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </div>
      </div>
    </>
  );
};

export default RUMDescription;
