import React, { useState, useEffect } from 'react';
import {
  FIELD,
  HANDLE_SEARCH,
  IDLE_RESOURCES_FULL_FORM,
  IGNORED_RESOURCE_COLUMN_FIELD,
  LONGER_DETAIL_FIELD,
  SERVICE_ID_NAME,
  config,
} from './config';
import { accountState, userState } from '../../store';
import { useSelector } from 'react-redux';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { IdleInstanceProps } from '../../schemas/idleInstance.schema';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';

import {
  Grid,
  TableCell,
  TableRow,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  OutlinedInput,
  Tooltip,
} from '@material-ui/core';

import ProtectedComponent from '../../components/ProtectedComponent/ProtectedComponent';
import {
  CLOUD_PROVIDER_SERVICES,
  CURRENCY_SYMBOL,
  PERMISSION_KEY,
  PERMISSION_LEVELS,
} from '../../utils/constants';
import { costFormatter2, handleError } from '../../utils/config';
import { useHistory } from 'react-router-dom';
import {
  IdleInstanceRequirementModal,
  NotificationModal,
  RequestFixModal,
} from '../../components';
import { InputAdornment } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Loading from '../../components/Loading/Loading';
// import { ConnectingAirportsOutlined } from '@mui/icons-material';

const IdleResourcesTable: React.FC<any> = (props) => {
  const type = props.location.state.ServiceType;
  const SubscriptionId = props.location.state.SubscriptionId;
  const AccountId = props.location.state.AccountId;

  const [idleInstanceDetails, setIdleInstanceDetails] = React.useState<
    IdleInstanceProps[]
  >([]);

  const [idleInstanceList, setIdleInstanceList] = useState<any>(null);

  const [idleInstanceDetailsKeys, setIdleInstanceDetailsKeys] = React.useState(
    {}
  );
  const [openFixUnusedModal, setOpenFixUnusedModal] = React.useState(false);

  // const [isNetworkError, setNetworkError] = React.useState(false);
  const [openFixModal, setOpenFixModal] = React.useState(false);
  const [fixModalData, setFixModalData] = React.useState({});
  const [instanceType, setInstanceType] = React.useState('');
  const [removeInstanceMessage, setRemoveInstanceMessage] = React.useState('');
  const [searchResource, setSearchResource] = React.useState('');
  const [pageNo, setPageNo] = useState(1);
  const selectedAccountInfo = useSelector(accountState);
  const selectedCurrency: string = selectedAccountInfo.defaultCurrency;
  const [openNotificationModal, setOpenNotificationModal] = React.useState(
    false
  );
  const [
    openIdleInstanceRequirementModal,
    setOpenIdleInstanceRequirementModal,
  ] = React.useState(false);

  // const cellRef = useRef<HTMLTableCellElement | null>(null);
  // const [isOverflowing, setIsOverflowing] = useState(false);

  // useEffect(() => {
  //   const cellElement = cellRef.current;

  //   if (cellElement) {
  //     setIsOverflowing(cellElement.scrollWidth > cellElement.clientWidth);
  //   }
  // }, [isOverflowing]);

  let unit: any = '';
  const history = useHistory();
  const user = useSelector(userState).userData;
  const actionPermission = user?.Role?.permissions;
  const idleInstancePermission = actionPermission?.find(
    (obj: any) => obj.Entity.Key === PERMISSION_KEY.IdleInstance
  );
  const selectedAccount = useSelector(accountState).selectedAccount;
  const cloudProvider:
    | string
    | undefined = selectedAccount?.CloudProvider.toUpperCase();
  const [loader, setLoader] = useState(false);

  const closeModal = () => {
    setOpenFixModal(false);
    setOpenFixUnusedModal(false);
    setRemoveInstanceMessage('');
    setOpenNotificationModal(false);
    setOpenIdleInstanceRequirementModal(false);
  };

  const handleRow = async (e: any, rowDetails: any, row: any) => {
    const field: { [key: string]: string } = {
      'Virtual Machines': 'Virtual_Machine_Name',
      'Storage Accounts': 'Storage_Name',
      'Managed Disks': 'Disk_Name',
      'Public IP': 'Public_IP_Name',
      'Load Balancers': 'Load_Balancer_Name',
      'Resource Groups': 'Resource_Group_Name',
      'Global Accelerator': 'Global_Accelerator_Name',
      'App Service Plans': 'App_Name',
      'Container Instances': 'Container_Instance_Name',
      'Redis Cache': 'Redis',
      'Service Bus': 'Service_Bus_Name',
      'Traffic Manager': 'Traffic_Manager_Name',
      'COSMOS DB': 'Cosmos_DB_Name',
      WorkSpaces: 'Workspace_Name',
      'SQL Server': 'SQL_Server_Name',
      'HDInSight Cluster': 'HDCluster_Name',
    };

    try {
      setLoader(true);
      let data;
      selectedAccount?.CloudProvider.toUpperCase() ===
        CLOUD_PROVIDER_SERVICES.AZURE && row.InstanceName
        ? (data = {
            Id: rowDetails.Id,
            IdleResourceName: rowDetails[field[row['InstanceName']]],
            Type: row.InstanceName,
            IsDisabled: Number(!rowDetails.IsDisabled),
            ResourceGroup: rowDetails.Resource_Group,
            SubscriptionId: rowDetails.Subscription_Id,
          })
        : selectedAccount?.CloudProvider.toUpperCase() ===
            CLOUD_PROVIDER_SERVICES.AZURE && row.ResourceName
        ? (data = {
            Id: rowDetails.Id,
            IdleResourceName: rowDetails[field[row['ResourceName']]],
            Type: row.ResourceName,
            IsDisabled: Number(!rowDetails.IsDisabled),
            ResourceGroup: rowDetails.Resource_Group,
            SubscriptionId: rowDetails.Subscription_Id,
          })
        : selectedAccount?.CloudProvider.toUpperCase() ===
            CLOUD_PROVIDER_SERVICES.AWS && row.InstanceName
        ? (data = {
            Id: rowDetails.Id,
            Type: row.InstanceName,
            IsDisabled: Number(!rowDetails.IsDisabled),
          })
        : selectedAccount?.CloudProvider.toUpperCase() ===
            CLOUD_PROVIDER_SERVICES.AWS && row.ResourceName
        ? (data = {
            Id: rowDetails.Id,
            Type: row.ResourceName,
            IsDisabled: Number(!rowDetails.IsDisabled),
          })
        : (data = {
            Id: rowDetails.Id,
            Type: row.ResourceName,
            IsDisabled: Number(!rowDetails.IsDisabled),
          });

      await config[cloudProvider as string].toggleInstanceState(data);
      const instanceDetails = [...idleInstanceDetails];
      instanceDetails.map((row: any) => {
        row.Details.forEach((detail: IdleInstanceProps) => {
          if (detail.Id == rowDetails.Id) {
            detail.IsDisabled = Number(!rowDetails.IsDisabled);
          }
        });
      });
      setIdleInstanceDetails(instanceDetails);
    } catch (e: any) {
      // if (e === 'Network Error') {
      //   setNetworkError(true);
      // }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  const handleSearchResource = (e: any) => {
    setSearchResource(e.target.value);
  };

  const requestFixModal = async (event: any, rowDetails: any, row: any) => {
    event.stopPropagation();

    const cloudProvider:
      | string
      | undefined = selectedAccount?.CloudProvider.toUpperCase();

    let data;
    cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE && row.InstanceName
      ? (data = {
          Id: rowDetails.Id,
          RequestFixType: row.InstanceName,
          ResourceName: rowDetails[FIELD[row['InstanceName']]],
          ResourceGroup: rowDetails.Resource_Group,
          SubscriptionId: rowDetails.Subscription_Id,
        })
      : cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE && row.ResourceName
      ? (data = {
          Id: rowDetails.Id,
          RequestFixType: row.ResourceName,
          ResourceName: rowDetails[FIELD[row['ResourceName']]],
          ResourceGroup: rowDetails.Resource_Group,
          SubscriptionId: rowDetails.Subscription_Id,
        })
      : cloudProvider === CLOUD_PROVIDER_SERVICES.AWS && row.InstanceName
      ? (data = {
          Id: rowDetails.Id,
          RequestFixType: row.InstanceName,
        })
      : cloudProvider === CLOUD_PROVIDER_SERVICES.AWS && row.ResourceName
      ? (data = {
          Id: rowDetails.Id,
          RequestFixType: row.ResourceName,
        })
      : (data = {
          Id: rowDetails.Id,
          RequestFixType: row.ResourceName,
        });

    try {
      const result = await config[cloudProvider as string].requestFix(data);
      setFixModalData(result);

      setOpenFixModal(true);
    } catch (e: any) {
      // if (e === 'Network Error') {
      //   setNetworkError(true);
      // }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  const getResourceDetails = async () => {
    try {
      setLoader(true);
      const params = {
        instanceType: type,
        subscriptionId: SubscriptionId,
        accountId: AccountId,
        cloudProvider,
        ...(selectedCurrency !== 'USD' && { selectedCurrency }),
      };

      const result = await config[
        cloudProvider as string
      ].getIdleInstanceDetails(params);
      if (result) setIdleInstanceDetails(result);
      if (result[0].Details.length) {
        setIdleInstanceDetailsKeys(result[0].Details[0]);
        setIdleInstanceList(result[0].Details);
      }
    } catch (e: any) {
      // if (e === 'Network Error') {
      //   setNetworkError(true);
      // }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getResourceDetails();
  }, [cloudProvider, selectedCurrency]);

  //Pagination section//

  const recordsPerPage = 10;
  const lastIndex = pageNo * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = idleInstanceList?.slice(firstIndex, lastIndex);
  const nPages = Math.ceil(idleInstanceList?.length / recordsPerPage);

  const handlePageChangeNew = (e: any, value: any) => {
    setPageNo(value);
  };

  const returnSearchedResults = (data: any) =>
    searchResource
      ? data?.filter(
          (row: any) =>
            row[HANDLE_SEARCH[type].Id]
              ?.toLowerCase()
              .includes(searchResource.toLowerCase()) ||
            row[HANDLE_SEARCH[type].Name]
              ?.toLowerCase()
              .includes(searchResource.toLowerCase())
        )
      : data;

  return (
    <>
      <>
        {/* {loader ? (
          <LoadingScreen />
        ) : ( */}
        <Grid container>
          <div className="resourceDetailsHeader">
            <div className="securityHeading mb-20">
              {IDLE_RESOURCES_FULL_FORM[type]}
            </div>
            <div className="searchDocumentUpload">
              {' '}
              <OutlinedInput
                id="resourceName"
                name="name"
                className="outlineInputArea bgcolor"
                type="email"
                autoComplete="off"
                value={searchResource ? searchResource : ''}
                onChange={handleSearchResource}
                placeholder="Search..."
                endAdornment={
                  <InputAdornment position="end">
                    <SearchOutlinedIcon />
                  </InputAdornment>
                }
              />
            </div>
          </div>
          <div className="idleTableContainer">
            <Grid item md={12} sm={12} xs={12} className="accordionContent">
              {/* {idleInstanceDetails.map((row: IdleInstanceProps, j: any) => ( */}
              <>
                <TableContainer className="accContainer ">
                  <Table
                    className="table adminTable adminPanelTable"
                    size="small"
                    aria-label="sticky table"
                  >
                    <TableHead className="admintableHead">
                      <TableRow key="tableHead">
                        {/* {Object.entries(idleInstanceDetailsKeys).forEach(
                              (entry) => {
                                if (entry[0] === 'Unit') {
                                  unit = entry[1];
                                }
                              }
                            )} */}

                        {Object.keys(idleInstanceDetailsKeys).map(
                          (keyList: any, k: any) => {
                            if (keyList[0] === 'Unit') {
                              unit = keyList[1];
                            }
                            if (
                              IGNORED_RESOURCE_COLUMN_FIELD.includes(keyList)
                            ) {
                              return null;
                            }
                            if (keyList == 'Capacity_Used') {
                              return keyList === 'IsDisabled' ? null : (
                                <TableCell
                                  key={'capacity' + k}
                                  className="adminTableheading"
                                >
                                  {keyList.split('_').join(' ')}
                                  <br />
                                  <span className="font-12px">({unit})</span>
                                </TableCell>
                              );
                            }
                            if (keyList == 'MonthlyCost') {
                              return keyList === 'IsDisabled' ? null : (
                                <TableCell
                                  key={'cpu-util' + k}
                                  className="adminTableheading"
                                >
                                  MTD Cost
                                </TableCell>
                              );
                            }

                            return IGNORED_RESOURCE_COLUMN_FIELD.includes(
                              keyList
                            ) ? null : (
                              <TableCell
                                key={'disabled' + k}
                                className="adminTableheading"
                              >
                                {keyList.split('_').join(' ')}
                              </TableCell>
                            );
                          }
                        )}
                        {/* <ProtectedComponent
                          level={PERMISSION_LEVELS.WRITE}
                          permission={idleInstancePermission}
                        > */}
                        <TableCell
                          component="th"
                          align="right"
                          className="adminTableheading"
                        >
                          Actions
                        </TableCell>
                        {/* </ProtectedComponent> */}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {!loader &&
                      idleInstanceList &&
                      !returnSearchedResults(records).length ? (
                        <TableCell>No Records Found</TableCell>
                      ) : returnSearchedResults(records)?.length ? (
                        idleInstanceDetails.map(
                          (row: IdleInstanceProps, j: any) =>
                            returnSearchedResults(records).map(
                              (details: any, k: any) => (
                                <TableRow key={'details' + k + j}>
                                  {Object.entries(details).map(
                                    ([key, value]: any, l) => {
                                      let instanceURLS = '';
                                      if (
                                        selectedAccount?.CloudProvider.toUpperCase() ===
                                        CLOUD_PROVIDER_SERVICES.AWS
                                      ) {
                                        if (key == 'InstanceId') {
                                          instanceURLS = `https://${selectedAccount?.AccountId}.signin.aws.amazon.com/console/ec2?region=${details.Region}#InstanceDetails:instanceId=${details.InstanceId}`;
                                        }
                                        if (
                                          key == 'ElasticIPName' ||
                                          key == 'IPAddress'
                                        ) {
                                          instanceURLS = `https://${selectedAccount?.AccountId}.signin.aws.amazon.com/console/vpc?region=${details.Location}#ElasticIpDetails:AllocationId=${details.AllocationId}`;
                                        }
                                        if (key == 'LoadBalancerName') {
                                          instanceURLS = `https://${selectedAccount?.AccountId}.signin.aws.amazon.com/console/ec2?region=${details.Region}#LoadBalancers:`;
                                        }
                                        if (key == 'AppArn') {
                                          instanceURLS = `https://${selectedAccount?.AccountId}.signin.aws.amazon.com/console/elasticbeanstalk?region=${details.Region}#/application/overview?applicationName=${details.AppName}`;
                                        }
                                        if (key == 'CacheClusterId') {
                                          instanceURLS = `https://${
                                            selectedAccount?.AccountId
                                          }.signin.aws.amazon.com/console/elasticache?region=${
                                            details.Region
                                          }#/redis/${
                                            details.CacheClusterId.split('-')[0]
                                          }`;
                                        }

                                        if (key == 'SqsName') {
                                          instanceURLS = `https://${selectedAccount?.AccountId}.signin.aws.amazon.com/console/sqs?region=${details.Region}#/queues/${details.SQSQueueUrl}`;
                                        }
                                        if (key == 'DBName') {
                                          instanceURLS = `https://${selectedAccount?.AccountId}.signin.aws.amazon.com/console/rds?region=${details.Region}#databases:id=${details.DBName};is-cluster=false`;
                                        }
                                        if (key == 'DynamoDBName') {
                                          instanceURLS = `https://${selectedAccount?.AccountId}.signin.aws.amazon.com/console/dynamodb?region=${details.Region}#table?name=${details.DynamoDBName}`;
                                        }
                                        if (key == 'SnsName') {
                                          const arr = details.ARN.split(':')[3];
                                          instanceURLS = `https://${selectedAccount?.AccountId}.signin.aws.amazon.com/console/sns/?region=${arr}#/topic/${details.ARN}`;
                                        }
                                        if (key == 'FunctionName') {
                                          instanceURLS = `https://${selectedAccount?.AccountId}.signin.aws.amazon.com/console/lambda/?region=${details.Region}#/function/${details.FunctionArn}`;
                                        }
                                        if (key == 'VolumeId') {
                                          instanceURLS = `https://${selectedAccount?.AccountId}.signin.aws.amazon.com/console/ec2?region=${details.Region}#VolumeDetails:volumeId=${details.VolumeId}`;
                                        }
                                        if (key == 'StorageName') {
                                          instanceURLS = `https://${selectedAccount?.AccountId}.signin.aws.amazon.com/console/s3`;
                                        }
                                        if (key == 'ResourceGroupName') {
                                          instanceURLS = `https://${details.Region}.console.aws.amazon.com/resource-groups/group/${details.ResourceGroupName}?region=${details.Region}`;
                                        }
                                        if (key == 'WorkspaceId') {
                                          instanceURLS = `https://${details.Region}.console.aws.amazon.com/prometheus/home?region=${details.Region}#/workspaces`;
                                        }
                                      } else {
                                        if (key == 'VirtualMachineId') {
                                          instanceURLS = `https://portal.azure.com/#@${selectedAccount?.AzureId}/resource/subscriptions/${details.SubscriptionId}/resourceGroups/${details.ResourceGroup}/providers/Microsoft.Compute/virtualMachines/${details.VirtualMachineName}/overview`;
                                        }
                                        if (key == 'StorageName') {
                                          instanceURLS = `https://portal.azure.com/#@${selectedAccount?.AzureId}/resource/subscriptions/${details.SubscriptionId}/resourceGroups/${details.ResourceGroup}/providers/Microsoft.Storage/storageAccounts/${details.StorageName}/overview`;
                                        }
                                        if (key == 'DiskUniqueId') {
                                          instanceURLS = `https://portal.azure.com/#@${selectedAccount?.AzureId}/resource/subscriptions/${details.SubscriptionId}/resourceGroups/${details.ResourceGroup}/providers/Microsoft.ManagedDisk/disks/${details.DiskName}/overview`;
                                        }
                                        if (key == 'PublicIPName') {
                                          instanceURLS = `https://portal.azure.com/#@${selectedAccount?.AzureId}/resource/subscriptions/${details.SubscriptionId}/resourceGroups/${details.ResourceGroup}/providers/Microsoft.Network/publicIPAddresses/${details.PublicIPName}/overview`;
                                        }
                                        if (key == 'LoadBalancerName') {
                                          instanceURLS = `https://portal.azure.com/#@${selectedAccount?.AzureId}/resource/subscriptions/${details.SubscriptionId}/resourceGroups/${details.ResourceGroup}/providers/Microsoft.Network/loadBalancers/${details.LoadBalancerName}/overview`;
                                        }
                                        if (key == 'ResourceGroupName') {
                                          instanceURLS = `https://portal.azure.com/#@${selectedAccount?.AzureId}/resource/subscriptions/${details.SubscriptionId}/resourceGroups/${details.ResourceGroupName}/overview`;
                                        }
                                        if (key == 'AppName') {
                                          instanceURLS = `https://portal.azure.com/#@${selectedAccount?.AzureId}/resource/subscriptions/${details.SubscriptionId}/resourceGroups/${details.ResourceGroup}/providers/Microsoft.Web/serverFarms/${details.AppName}/webHostingPlan`;
                                        }
                                        if (key == 'ServiceBusName') {
                                          instanceURLS = `https://portal.azure.com/#@${selectedAccount?.AzureId}/resource/subscriptions/${details.SubscriptionId}/resourceGroups/${details.ResourceGroup}/providers/Microsoft.ServiceBus/namespaces/${details.ServiceBusName}/overview`;
                                        }
                                        if (key == 'TrafficManagerName') {
                                          instanceURLS = `https://portal.azure.com/#@${selectedAccount?.AzureId}/resource/subscriptions/${details.SubscriptionId}/resourceGroups/${details.ResourceGroup}/providers/Microsoft.Network/trafficmanagerprofiles/${details.TrafficManagerName}/overview`;
                                        }
                                        if (key == 'CosmosDBName') {
                                          instanceURLS = `https://portal.azure.com/#@${selectedAccount?.AzureId}/resource/subscriptions/${details.SubscriptionId}/resourceGroups/${details.ResourceGroup}/providers/Microsoft.DocumentDb/databaseAccounts/${details.CosmosDBName}/overview`;
                                        }
                                      }
                                      if (
                                        IGNORED_RESOURCE_COLUMN_FIELD.includes(
                                          key
                                        )
                                      ) {
                                        return null;
                                      }
                                      if (key == 'MonthlyCost') {
                                        return (
                                          <TableCell key={key + l}>
                                            {value || value === 0 ? (
                                              Object.keys(
                                                CURRENCY_SYMBOL
                                              ).includes(
                                                details.CurrencyCode
                                              ) ? (
                                                costFormatter2(
                                                  selectedCurrency,
                                                  +value
                                                )
                                              ) : (
                                                costFormatter2(
                                                  selectedCurrency,
                                                  +value
                                                )
                                              )
                                            ) : (
                                              <>-</>
                                            )}
                                          </TableCell>
                                        );
                                      }

                                      if (
                                        key == 'Size' ||
                                        key === 'DiskSize' ||
                                        key === 'InstanceDiskSize' ||
                                        key === 'Unit'
                                      ) {
                                        return (
                                          <TableCell key={key}>
                                            {value || value === 0 ? (
                                              +value
                                            ) : (
                                              <>-</>
                                            )}
                                            GB
                                          </TableCell>
                                        );
                                      } else {
                                        return (
                                          <>
                                            {(() => {
                                              if (
                                                selectedAccount?.CloudProvider.toUpperCase() ===
                                                CLOUD_PROVIDER_SERVICES.AWS
                                              ) {
                                                return (
                                                  <>
                                                    {SERVICE_ID_NAME.includes(
                                                      key
                                                    ) ? (
                                                      <TableCell key={key}>
                                                        {value ||
                                                        value === 0 ? (
                                                          <a
                                                            href={instanceURLS}
                                                            target="_blank"
                                                            className="inventoryTableCellLink decore"
                                                          >
                                                            {value}
                                                          </a>
                                                        ) : (
                                                          <span className="pl-15">
                                                            -
                                                          </span>
                                                        )}
                                                      </TableCell>
                                                    ) : LONGER_DETAIL_FIELD.includes(
                                                        key
                                                      ) ? (
                                                      <Tooltip title={value}>
                                                        <TableCell
                                                          key={key}
                                                          // ref={cellRef}
                                                          style={{
                                                            maxWidth: '200px',
                                                            whiteSpace:
                                                              'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow:
                                                              'ellipsis',
                                                            cursor: 'pointer',
                                                          }}
                                                        >
                                                          {value ||
                                                          value === 0 ? (
                                                            <>{value}</>
                                                          ) : (
                                                            <span className="pl-15">
                                                              -
                                                            </span>
                                                          )}
                                                        </TableCell>
                                                      </Tooltip>
                                                    ) : (
                                                      <TableCell key={key}>
                                                        {value ||
                                                        value === 0 ? (
                                                          <>{value}</>
                                                        ) : (
                                                          '-'
                                                        )}
                                                      </TableCell>
                                                    )}
                                                  </>
                                                );
                                              }

                                              if (
                                                selectedAccount?.CloudProvider.toUpperCase() ===
                                                CLOUD_PROVIDER_SERVICES.AZURE
                                              ) {
                                                return (
                                                  <>
                                                    {SERVICE_ID_NAME.includes(
                                                      key
                                                    ) ? (
                                                      <TableCell
                                                        className={key}
                                                        key={key}
                                                        align="left"
                                                      >
                                                        {value ||
                                                        value === 0 ? (
                                                          <a
                                                            href={instanceURLS}
                                                            target="_blank"
                                                            className="inventoryTableCellLink decore"
                                                          >
                                                            {value}
                                                          </a>
                                                        ) : (
                                                          <span className="pl-15">
                                                            -
                                                          </span>
                                                        )}
                                                      </TableCell>
                                                    ) : (
                                                      <TableCell
                                                        className={key}
                                                        key={key}
                                                      >
                                                        {value ||
                                                        value === 0 ? (
                                                          value
                                                        ) : (
                                                          <span className="pl-15">
                                                            -
                                                          </span>
                                                        )}
                                                      </TableCell>
                                                    )}
                                                  </>
                                                );
                                              }

                                              return null;
                                            })()}
                                          </>
                                        );
                                      }
                                    }
                                  )}
                                  <ProtectedComponent
                                    level={PERMISSION_LEVELS.WRITE}
                                    permission={idleInstancePermission}
                                  >
                                    <TableCell align="right">
                                      <div className="d-flex float-r">
                                        <Tooltip
                                          title="Request Fix"
                                          arrow
                                          disableHoverListener={
                                            details.IsDisabled ? true : false
                                          }
                                        >
                                          <span
                                            className={`configIcon ${
                                              details.IsDisabled
                                                ? ''
                                                : 'actionIcons'
                                            }`}
                                            onClick={
                                              details.IsDisabled
                                                ? undefined
                                                : (e) =>
                                                    requestFixModal(
                                                      e,
                                                      details,
                                                      row
                                                    )
                                            }
                                          ></span>
                                        </Tooltip>

                                        <Tooltip
                                          title={`${
                                            details.IsDisabled
                                              ? 'Revive'
                                              : 'Ignore'
                                          }`}
                                          arrow
                                        >
                                          {details.IsDisabled ? (
                                            <VerifiedUserIcon
                                              className="actionIcons"
                                              onClick={(e) =>
                                                handleRow(e, details, row)
                                              }
                                            />
                                          ) : (
                                            <VerifiedUserOutlinedIcon
                                              className="actionIcons"
                                              onClick={(e) =>
                                                handleRow(e, details, row)
                                              }
                                            />
                                          )}
                                        </Tooltip>
                                      </div>
                                    </TableCell>
                                  </ProtectedComponent>
                                </TableRow>
                              )
                            )
                        )
                      ) : (
                        // <TableCell>Loading...</TableCell>
                        <TableCell>
                          <Loading
                            style={{ height: 'fit-content', padding: '2rem' }}
                          />
                        </TableCell>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* {idleInstanceDetails[0]?.Details.length === 0 ? (
                  <div className="noRecordsFound">No Records Found</div>
                ) : null} */}
              </>
              {/* ))} */}
            </Grid>
          </div>
          <div className="customizePagination">
            <Pagination
              onChange={handlePageChangeNew}
              className="mt-15"
              size="large"
              color="standard"
              variant="outlined"
              count={nPages}
              shape="rounded"
              page={pageNo}
            />
          </div>
        </Grid>
        {/* )} */}
      </>
      {openFixModal && (
        <RequestFixModal
          show={openFixModal}
          onClose={closeModal}
          data={fixModalData}
          message={removeInstanceMessage}
        />
      )}
      {openFixUnusedModal && (
        <RequestFixModal
          show={openFixUnusedModal}
          onClose={closeModal}
          data={fixModalData}
          message={removeInstanceMessage}
        />
      )}
      {openNotificationModal && (
        <NotificationModal
          show={openNotificationModal}
          onClose={closeModal}
          iType={instanceType}
          selected={SubscriptionId}
        />
      )}
      {openIdleInstanceRequirementModal && (
        <IdleInstanceRequirementModal
          show={openIdleInstanceRequirementModal}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default IdleResourcesTable;
