import React, { useEffect, useState } from 'react';
import './ResetPassword.css';
import { useForm } from 'react-hook-form';
import { Grid, Box, FormControl, InputAdornment } from '@material-ui/core';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { authService, userService } from '../../services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import SupportHeader from '../../components/Header/SupportHeader';
import { handleError } from '../../utils/config';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { ReactComponent as Intersect } from '../../assets/images/Intersect.svg';
import TextField from '@mui/material/TextField';
import fastForward from '../../assets/images/fastForward.png';

interface ChangePassword {
  Password: 'string';
}

export const ResetPassword: React.FC<RouteComponentProps> = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showCPassword, setShowCPassword] = React.useState(false);
  const [networkError, setNetworkError] = React.useState('');
  const [loader, setLoader] = React.useState(false);

  const [regMsg, setRegMsg] = React.useState('');
  const [resetPassword, setResetPassword] = React.useState({
    Password: '',
    ConfirmPassword: '',
  });

  const { register, handleSubmit, errors } = useForm<ChangePassword>({
    mode: 'onChange',
  });

  const [passwordSubmitMsg, setPasswordSubmitMsg] = useState([]);
  const history = useHistory();

  const handleChangePassword = (target: any) => {
    setResetPassword({ ...resetPassword, [target.id]: target.value });
  };

  const onSubmit = async (data: ChangePassword) => {
    try {
      setLoader(true);
      setResetPassword({ ...resetPassword });
      const result = await userService.resetPassword(data);
      if (result) {
        localStorage.removeItem('auth-state');
        authService.removeAuth();
        setPasswordSubmitMsg(result.message);
        setTimeout(() => {
          history.push('/login');
        }, 500);
        setLoader(false);
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
        const err = `${e.error.message}`;
        setRegMsg(err);
      }
    } finally {
      setLoader(false);
    }
  };

  const regMsgElement = () => {
    return <div className="regMsg mb-10">{regMsg}</div>;
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowCPassword = () => {
    setShowCPassword(!showCPassword);
  };

  if (networkError) {
    throw new Error(networkError);
  }
  useEffect(() => {
    authService.refreshToken(window.location.search.split('=')[1]);
    localStorage.setItem('LOGIN_ACCESS', 'true');
  }, []);

  return (
    <React.Fragment>
      <div className="outerContainer scrollContainer soraFont">
        <SupportHeader />
        <div className="mainBgEllipse1" />
        <div className="mainBgEllipse2" />
        <div className="mainBgEllipse3" />
        {loader ? <LoadingScreen /> : ''}
        <div className="registrationBG">
          <div className="registrationContainer">
            <div className="leftLoginSection">
              <div className="ellipse" />

              <div className="testimonialCard">
                <div className="testimonial">
                  <p>
                    "CloudForestX has been a lifesaver for our team. We were
                    able to reduce our cloud bills by 20% without having to
                    compromise on service quality. It's an amazing tool."
                  </p>
                </div>
                <div className="profile">
                  {/* <img
                      className="profile-image"
                      src={sampleAvatar}
                      alt="Profile"
                    /> */}
                  <div className="profile-info">
                    <h3 className="testimonialName">Rajiv Jain</h3>
                    <p className="testimonialPost">CEO, ThinkSys</p>
                  </div>
                </div>
              </div>

              <Intersect className="intersect" />

              <div className="ellipse2" />
              <div className="ellipse3" />
              <div className="ellipse4" />
            </div>

            <div className="rightImage">
              <div className="trialText mt-100">
                <h5>Reset Password</h5>
              </div>
              <Grid container spacing={0} className="rightbody h-55 w-100">
                <Grid item md={12} className="loginReg">
                  <div className="userLogin mt-10">
                    <form
                      noValidate
                      onSubmit={handleSubmit(onSubmit)}
                      autoComplete="off"
                    >
                      <Box className="registrationLogin">
                        <Grid
                          container
                          spacing={1}
                          alignItems="flex-end"
                          className="positionRelative"
                        >
                          <Grid item className="fullWidthInput w100">
                            <FormControl className="fullWidthInput mb-25 passwordInput">
                              <TextField
                                id="Password"
                                label={
                                  <span>
                                    ENTER NEW PASSWORD
                                    <span className="asterisk">*</span>
                                  </span>
                                }
                                variant="standard"
                                autoComplete="off"
                                focused
                                name="Password"
                                type={showPassword ? 'text' : 'password'}
                                onChange={(e) => handleChangePassword(e.target)}
                                inputRef={register({
                                  required: true,
                                  maxLength: 16,
                                  minLength: 8,
                                  pattern: /^\S*$/,
                                })}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <span
                                        style={{ cursor: 'pointer' }}
                                        onClick={handleClickShowPassword}
                                      >
                                        {showPassword ? (
                                          <FontAwesomeIcon icon={faUnlock} />
                                        ) : (
                                          <FontAwesomeIcon icon={faLock} />
                                        )}
                                      </span>
                                    </InputAdornment>
                                  ),
                                }}
                              />

                              {errors.Password && (
                                <div className="regMsg">
                                  Password length should be between 8 to 16
                                  Character without blank space
                                </div>
                              )}
                            </FormControl>
                            <FormControl className="fullWidthInput mb-25 passwordInput">
                              <TextField
                                id="ConfirmPassword"
                                label={
                                  <span>
                                    CONFIRM NEW PASSWORD
                                    <span className="asterisk">*</span>
                                  </span>
                                }
                                variant="standard"
                                autoComplete="off"
                                focused
                                name="ConfirmPassword"
                                type={showCPassword ? 'text' : 'password'}
                                onChange={(e) => handleChangePassword(e.target)}
                                inputRef={register({
                                  required: true,
                                  maxLength: 16,
                                  minLength: 8,
                                  pattern: /^\S*$/,
                                })}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <span
                                        style={{ cursor: 'pointer' }}
                                        onClick={handleClickShowCPassword}
                                      >
                                        {showCPassword ? (
                                          <FontAwesomeIcon icon={faUnlock} />
                                        ) : (
                                          <FontAwesomeIcon icon={faLock} />
                                        )}
                                      </span>
                                    </InputAdornment>
                                  ),
                                }}
                              />

                              {resetPassword.Password !=
                                resetPassword.ConfirmPassword && (
                                <div className="regMsg">
                                  Password Does Not Match
                                </div>
                              )}
                            </FormControl>

                            {regMsg ? regMsgElement() : ''}

                            {passwordSubmitMsg.length ? (
                              <div className="alert-success p-3 font-12px responseMsg mb-10">
                                {passwordSubmitMsg}
                              </div>
                            ) : (
                              ''
                            )}

                            <div className="loginBottom">
                              <div className="checkboxf"></div>
                            </div>
                            <div className="registrationLoginBtn mt-10">
                              {resetPassword.Password !=
                              resetPassword.ConfirmPassword ? (
                                <div className="inputContainer">
                                  <input
                                    type="submit"
                                    className="formLoginBtn disable"
                                    value=""
                                    disabled
                                  />
                                  <span className="inputText">Save</span>
                                  <img
                                    className="fastForwardIcon"
                                    src={fastForward}
                                    alt="forward"
                                  />
                                </div>
                              ) : (
                                <div className="inputContainer">
                                  <input
                                    type="submit"
                                    className="formLoginBtn"
                                    value=""
                                  />
                                  <span className="inputText">Save</span>
                                  <img
                                    className="fastForwardIcon"
                                    src={fastForward}
                                    alt="forward"
                                  />
                                </div>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                    </form>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
