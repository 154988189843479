import React, { useEffect, useState, useRef } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// import { DateRangePicker } from 'react-dates';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { accountState } from '../../store';
// import { Grid, TextField } from '@material-ui/core';
// import DownloadIcon from '@mui/icons-material/Download';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import './Header.css';

import './ServicesReports.css';
import * as echarts from 'echarts';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { CLOUD_PROVIDER_SERVICES, ERRORS } from '../../utils/constants';
import { costForecastingService } from '../../services';
import { costFormatter2, handleError } from '../../utils/config';
import { config, customColors } from './config';
import { ALL_VALUE } from '../Invoice/config';
import { exportToFile } from '../../utils/formateXLSX';
import GraphLoader from './GraphLoader';
import ServiceReportHeader, { TimeFrameAPI } from './Header';
import Loading from '../../components/Loading/Loading';

const ServicesReports = () => {
  const history = useHistory();
  const selectedAccountInfo = useSelector(accountState);
  const selectedCurrency: string = selectedAccountInfo.defaultCurrency;
  const cloudProvider = selectedAccountInfo?.selectedAccount?.CloudProvider.toUpperCase();
  const [updatedCurrency, setUpdatedCurrency] = useState('USD');
  // const [startDate, setStartDate] = useState<any>(moment().startOf('month'));
  // const [endDate, setEndDate] = useState<any>(moment().endOf('day'));
  const [isNetworkError, setNetworkError] = React.useState(false);
  const [selectedRegion, setSelectedRegion] = useState<any>('all');
  const [selectedService, setSelectedService] = useState<any>(ALL_VALUE);
  const [selectedGroupBy, setSelectedGroupBy] = useState<TimeFrameAPI>('day');
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(
    selectedAccountInfo.selectedSubscriptionId
  );
  const [selectedAccountId, setSelectedAccountId] = React.useState<any>(
    selectedAccountInfo.selectedAccountId
  );
  const [servicesData, setServicesData] = useState<any>({});
  const [isTableLoad, setIsTableLoad] = useState(false);
  const [tableDateColumns, setTableDateColumns] = useState<string[] | null>([]);
  const [servicesList, setServiceList] = useState<string[]>([]);
  const [sortDirections, setSortDirections] = useState<
    Record<string, 'asc' | 'desc' | null>
  >({});
  const [barChartData, setBarChartData] = useState<any>({});
  const [activeView, setActiveView] = useState<'table' | 'chart'>('table');

  const getServiceList = async () => {
    try {
      const result = await costForecastingService.getResourceList();
      if (result) {
        const resources = result.map((resource: any) => resource.resoursename);
        setServiceList(resources);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  // BarChart Data function
  interface BarChartProps {
    data: any;
  }

  const generateColors = (count: number) => customColors.slice(0, count);

  const BarChart: React.FC<BarChartProps> = ({ data }) => {
    const chartRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const chart = echarts.init(chartRef.current!);

      const legend = Object.keys(data);
      const dates = Object.keys(data[legend[0]]).filter(
        (key) => key !== 'totalCost'
      );
      const colors = generateColors(legend.length);

      const series = legend.map((service, index) => ({
        name: service,
        type: 'bar',
        stack: 'total',
        data: dates.map((date) => data[service][date]?.cost || 0),
        itemStyle: {
          color: colors[index], // Use color directly from the generated colors array
        },
      }));

      const options: echarts.EChartOption = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          // position: function (point: any) {
          //   return [point[0] + 10, '10%']; // Adjust the position here
          // },
          // Enable the scrollable option for the tooltip
          confine: true,
          enterable: true,
          formatter: (params: any) => {
            const header = `<div class="echarts-tooltip-title">${params[0].axisValue}</div>`;
            const items = params
              .map((param: any) => {
                const colorSpan = `<span class="echarts-tooltip-color" style="background-color: ${param.color}"></span>`;
                return `${colorSpan} <span class="echarts-tooltip-name">${
                  param.seriesName
                } :</span> <span class="echarts-tooltip-value">${costFormatter2(
                  updatedCurrency,
                  param.value
                )}</span>`;
              })
              .join('<br/>');

            return `<div class="echarts-tooltip">${header}${items}</div>`;
          },
        },
        legend: {
          data: legend,
          // padding: [20, 0, 0, 0],
          show: selectedService === ALL_VALUE ? true : false,
          bottom: selectedService === ALL_VALUE ? 0 : '-3%',
          left: '5%',
          width: '92%',
          // right: 'auto',
          // align: 'auto',
          // bottom: '80%',
          // orient: 'horizontal',
          // type: 'scroll',
          // right: -100,

          // height: 100,
          // width: 300,

          // left: '1%',
          // top: '20%',
        },

        grid: {
          left: '6%',
          right: '2%',
          bottom: selectedService === ALL_VALUE ? '22%' : '10%',
          // containLabel: true,
        },
        dataZoom: [
          {
            type: 'inside',
            show: true,
            // Enable the scrollable option for the dataZoom
          },
        ],
        xAxis: {
          type: 'category',
          data: dates,
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '${value}', // Format yAxis values with $ sign
          },
        },
        series: series,
      };

      chart.setOption(options);

      const tooltipElement: any = chart
        .getDom()
        .querySelector('.echarts-tooltip');
      if (tooltipElement) {
        tooltipElement.style.maxHeight = '100px'; // Set the desired maximum height
        tooltipElement.style.overflowY = 'auto';
      }

      return () => {
        chart.dispose();
      };
    }, [data]);

    return <div ref={chartRef} style={{ width: '100%', height: '550px' }} />;
  };

  useEffect(() => {
    !servicesList.length && getServiceList();
  }, [selectedAccountInfo]);

  const getServiceCostReport = async () => {
    try {
      setIsTableLoad(true);
      const data = {
        groupby: selectedGroupBy,
        accountId: selectedAccountId,
        subscriptionId: selectedSubscriptionId,
        cloudProvider,
        ...(selectedCurrency !== 'USD' && { selectedCurrency }),
        ...(selectedRegion !== ALL_VALUE &&
          (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
            ? { region: encodeURIComponent(selectedRegion) }
            : { location: encodeURIComponent(selectedRegion) })),
        ...(selectedService !== ALL_VALUE && {
          [cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
            ? 'service'
            : 'category']: selectedService,
        }),
      };

      const result = await config.getBillingSummary(data);

      if (result) {
        setUpdatedCurrency(selectedCurrency);
        const serviceNames: any = Object.keys(result);
        setServicesData(result);

        const newData: any = {};

        for (const key in result) {
          if (key !== 'Total Cost' && result[key].totalCost) {
            newData[key] = result[key];
          }
        }

        setBarChartData(newData);

        const dateColumns: any = Object.keys(result[serviceNames[0]]).filter(
          (date) => date !== 'totalCost'
        );

        setTableDateColumns(dateColumns);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setIsTableLoad(false);
    }
  };
  const getTrendStyles = (color?: string) => {
    const baseStyle: React.CSSProperties = {
      fontWeight: 400,
      position: 'relative',
      ...(color !== 'black' && { paddingRight: '20px' }),
      // paddingRight: `${color === 'black' ? '0' : '20px'}`,
    };

    // Background color for specific trends
    const backgroundColors = {
      green: 'rgba(92, 184, 92, 0.1)',
      red: 'rgba(217, 83, 79, 0.1)',
      orange: 'rgba(250, 144, 0, 0.08)',
    };

    // Text color mapping
    const textColors = {
      green: 'rgba(92, 184, 92, 1)',
      red: 'rgba(217, 83, 79, 1)',
      orange: 'rgba(250, 144, 0, 1)',
      black: 'black',
    };

    // Trend indicator styles
    const trendIndicatorStyle: React.CSSProperties = {
      position: 'absolute',
      // right: '17%',
      top: '57%',
      transform: 'translateY(-50%)',
    };

    // Determine trend based on color
    const getTrend = (color?: string) => {
      switch (color) {
        case 'green':
          return 'up';
        case 'red':
        case 'orange':
          return 'down';
        case 'black':
          return 'neutral';
        default:
          return 'neutral';
      }
    };

    const trend = getTrend(color);

    const trendIndicators = {
      up: <ArrowDownwardIcon />,
      down: <ArrowUpwardIcon />,
      neutral: '',
    };

    return {
      style: {
        ...baseStyle,
        color: color ? textColors[color as keyof typeof textColors] : 'black',
        backgroundColor:
          trend === 'up' && color === 'green'
            ? backgroundColors.green
            : trend === 'down' && color === 'red'
            ? backgroundColors.red
            : trend === 'down' && color === 'orange'
            ? backgroundColors.orange
            : 'transparent',
      },
      trendIndicator:
        trend !== 'neutral'
          ? {
              ...trendIndicatorStyle,
              color:
                trend === 'up'
                  ? 'rgba(92, 184, 92, 1)'
                  : trend === 'down' && color === 'red'
                  ? 'rgba(217, 83, 79, 1)'
                  : 'rgba(250, 144, 0, 1)',
            }
          : {},
      trendSymbol: trendIndicators[trend],
    };
  };

  // const getServiceCostReport = async () => {
  //   try {
  //     setIsTableLoad(true);
  //     // const globalQueryData = {
  //     //   cloudProvider,
  //     //   accountId: selectedAccountId,
  //     //   subscriptionId: selectedSubscriptionId,
  //     //   ...(selectedCurrency !== 'USD' && { selectedCurrency }),
  //     // };
  //     // const data = {
  //     //   startDate: formatDate(startDate?._d),
  //     //   endDate: formatDate(endDate?._d),
  //     //   groupby: selectedGroupBy,
  //     //   ...(selectedRegion !== ALL_VALUE &&
  //     //     (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
  //     //       ? { region: encodeURIComponent(selectedRegion) }
  //     //       : { location: encodeURIComponent(selectedRegion) })),
  //     //   ...(selectedService !== ALL_VALUE && {
  //     //     [cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
  //     //       ? 'service'
  //     //       : 'category']: selectedService,
  //     //   }),
  //     // };
  //     const data = {
  //       // startDate: formatDate(startDate?._d),
  //       // endDate: formatDate(endDate?._d),
  //       groupby: selectedGroupBy,
  //       accountId: selectedAccountId,
  //       subscriptionId: selectedSubscriptionId,
  //       cloudProvider,
  //       ...(selectedCurrency !== 'USD' && { selectedCurrency }),
  //       ...(selectedRegion !== ALL_VALUE &&
  //         (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
  //           ? { region: encodeURIComponent(selectedRegion) }
  //           : { location: encodeURIComponent(selectedRegion) })),
  //       ...(selectedService !== ALL_VALUE && {
  //         [cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
  //           ? 'service'
  //           : 'category']: selectedService,
  //       }),
  //     };
  //     // if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
  //     //   serviceCostParams = {
  //     //     ...(selectedAccountId !== ALL_VALUE
  //     //       ? {
  //     //           ...data,
  //     //           selectedAccounts: selectedAccountId,
  //     //         }
  //     //       : data),
  //     //   };
  //     // } else if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
  //     //   serviceCostParams = {
  //     //     ...(selectedAccountId !== ALL_VALUE &&
  //     //     selectedSubscriptionId === ALL_VALUE
  //     //       ? {
  //     //           ...data,
  //     //           AzureId: selectedAccountId,
  //     //         }
  //     //       : {
  //     //           ...data,
  //     //           SubscriptionId: selectedSubscriptionId,
  //     //         }),
  //     //   };
  //     // }
  //     const result = await config.getServiceWiseCost(data);

  //     if (result) {
  //       setUpdatedCurrency(selectedCurrency);
  //       const serviceNames: any = Object.keys(result);
  //       setServicesData(result);

  //       const newData: any = {};

  //       for (const key in result) {
  //         if (key !== 'Total Cost' && result[key].totalCost) {
  //           newData[key] = result[key];
  //         }
  //       }

  //       setBarChartData(newData);

  //       const dateColumns: any = Object.keys(result[serviceNames[0]]).filter(
  //         (date) => date !== 'totalCost'
  //       );

  //       setTableDateColumns(dateColumns);
  //     }
  //   } catch (e: any) {
  //     if (e === 'Network Error') {
  //       setNetworkError(true);
  //     }
  //     if (e && e.error && e.error.message) {
  //       handleError(e, history);
  //     }
  //   } finally {
  //     setIsTableLoad(false);
  //   }
  // };

  const handleSort = (dateColumn: string) => {
    const newSortDirections: Record<string, 'asc' | 'desc' | null> = {};

    // Set sorting direction for the clicked column
    newSortDirections[dateColumn] =
      sortDirections[dateColumn] === 'asc' ? 'desc' : 'asc';

    // Reset sorting direction for other columns
    Object.keys(sortDirections).forEach((column) => {
      if (column !== dateColumn) {
        newSortDirections[column] = null;
      }
    });

    setSortDirections(newSortDirections);

    // Sort servicesData based on the selected date column and sorting direction
    const servicesArray = Object.entries(servicesData).map(
      ([serviceName, dateCosts]: any) => {
        return {
          serviceName,
          ...dateCosts,
        };
      }
    );
    // Sort servicesArray based on the selected date column and sorting direction
    const sortedServicesArray = [...servicesArray].sort(
      (serviceA, serviceB) => {
        const costA =
          dateColumn === 'totalCost'
            ? serviceA[dateColumn]?.cost
            : serviceA[dateColumn]?.cost || 0;
        const costB =
          dateColumn === 'totalCost'
            ? serviceB[dateColumn]?.cost
            : serviceB[dateColumn]?.cost || 0;

        if (dateColumn === 'totalCost') {
          return newSortDirections[dateColumn] === 'asc'
            ? costA - costB
            : costB - costA;
        } else {
          return newSortDirections[dateColumn] === 'asc'
            ? costA - costB
            : costB - costA;
        }
      }
    );

    // Transform sortedServicesArray back into an object with service names as keys
    const sortedServicesData = sortedServicesArray.reduce((result, service) => {
      const { serviceName, ...dateCosts } = service;
      result[serviceName] = dateCosts;
      return result;
    }, {});

    setServicesData(sortedServicesData);

    const newData: any = {};
    if (sortedServicesData) {
      for (const key in sortedServicesData) {
        if (key !== 'Total Cost' && sortedServicesData[key].totalCost) {
          newData[key] = sortedServicesData[key];
        }
      }
    }
    setBarChartData(newData);
  };

  //CSV download handler

  const downloadCsvHandler = async () => {
    try {
      let serviceCostParams;

      const data = {
        // startDate: formatDate(startDate?._d),
        // endDate: formatDate(endDate?._d),
        groupby: selectedGroupBy,
        ...(selectedCurrency !== 'USD' && { selectedCurrency }),
        ...(selectedRegion !== ALL_VALUE && { region: selectedRegion }),
        ...(selectedService !== ALL_VALUE && {
          [cloudProvider === CLOUD_PROVIDER_SERVICES.AWS
            ? 'service'
            : 'category']: selectedService,
        }),
      };
      if (cloudProvider === CLOUD_PROVIDER_SERVICES.AWS) {
        serviceCostParams = {
          ...(selectedAccountId !== ALL_VALUE
            ? {
                ...data,
                selectedAccounts: selectedAccountId,
              }
            : data),
        };
        const result = await config.getServiceReportCsv(serviceCostParams);
        exportToFile(result.fileBuffer, result.fileName);
      } else if (cloudProvider === CLOUD_PROVIDER_SERVICES.AZURE) {
        serviceCostParams = {
          ...(selectedAccountId !== ALL_VALUE &&
          selectedSubscriptionId === ALL_VALUE
            ? {
                ...data,
                AzureId: selectedAccountId,
              }
            : {
                ...data,
                SubscriptionId: selectedSubscriptionId,
              }),
        };
        const result = await config.getServiceReportCsv(serviceCostParams);
        exportToFile(result.fileBuffer, result.fileName);
      }
    } catch (e: any) {
      if (e === 'Network Error') {
        setNetworkError(true);
      }
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  useEffect(() => {
    getServiceCostReport();
  }, [
    selectedSubscriptionId,
    // selectedAccountInfo,
    selectedAccountId,
    selectedRegion,
    selectedGroupBy,
    // endDate,
    selectedService,
    // selectedCurrency,
  ]);

  if (isNetworkError) {
    throw new Error(ERRORS.NETWORK_ERROR);
  }

  // //Date Picker section

  // const onDateChange = ({ startDate, endDate }: any) => {
  //   if (startDate && endDate) {
  //     if (endDate.isBefore(startDate)) {
  //       // Swap start and end dates if end date is before start date
  //       setStartDate(endDate);
  //       setEndDate(startDate);
  //     } else {
  //       setStartDate(startDate);
  //       setEndDate(endDate);
  //     }
  //   } else {
  //     // Handle single date selection (start date or end date)
  //     setStartDate(startDate);
  //     setEndDate(endDate);
  //   }
  // };

  // const minDate: Moment = moment().subtract(12, 'months');
  // const initialMonth: Moment = moment().subtract(1, 'months');
  // const maxDate: Moment = moment();

  // account selection section
  const onAccountChange = (data: any) => {
    if (data.cloudType === CLOUD_PROVIDER_SERVICES.AWS)
      setSelectedAccountId(data.accountId);
    else if (data.cloudType === CLOUD_PROVIDER_SERVICES.AZURE) {
      if (data.subscriptionName) {
        setSelectedSubscriptionId(data.subscriptionId);
      } else if (data.accountId) {
        setSelectedAccountId(data.accountId);
        setSelectedSubscriptionId(ALL_VALUE);
      }
    }
  };

  // const colSpan: any = tableDateColumns && tableDateColumns?.length + 2;

  const renderSortIndicator = (column: string) => {
    const direction = sortDirections[column];
    return direction === 'asc' ? (
      ' ▲'
    ) : direction === 'desc' ? (
      ' ▼'
    ) : (
      <span style={{ color: 'gray' }}> ▼</span>
    );
  };

  const StatusLegend: React.FC = () => {
    return (
      <div className="statusLegend">
        <div className="statusItem">
          <span className="statusDot decreasedDot"></span>
          <span className="statusLabel">Decreased</span>
        </div>
        <div className="statusItem">
          <span className="statusDot noChangeDot"></span>
          <span className="statusLabel">No Change</span>
        </div>
        <div className="statusItem">
          <span className="statusDot increasedLowDot"></span>
          <span className="statusLabel">Increased ( 0-5% )</span>
        </div>
        <div className="statusItem">
          <span className="statusDot increasedHighDot"></span>
          <span className="statusLabel">Increased ({' >'}5% )</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="app-container">
        <ServiceReportHeader
          services={servicesList}
          activeView={activeView}
          setActiveView={setActiveView}
          onAccountChange={onAccountChange}
          downloadCsvHandler={downloadCsvHandler}
          onTimeFrameChange={(timeFrame) => setSelectedGroupBy(timeFrame)}
          onRegionChange={(region) => setSelectedRegion(region || ALL_VALUE)}
          onServiceChange={(service) =>
            setSelectedService(service || ALL_VALUE)
          }
        />

        {activeView === 'table' && <StatusLegend />}

        {/* The rest of your dashboard content would go here */}
        <div>
          {activeView === 'table' ? (
            <Paper className="table-container">
              <Table
                className="table adminTable adminPanelTable serviceReportTable"
                size="small"
                aria-label="a dense table"
                stickyHeader
              >
                <TableHead className="admintableHead">
                  <TableRow>
                    <TableCell
                      className="sticky-cell adminTableheading"
                      key="empty"
                      style={{ zIndex: '8', minWidth: '170px' }}
                    >
                      Services
                    </TableCell>
                    <TableCell
                      className="sticky-cell adminTableheading"
                      key="totalCost"
                      align="center"
                      style={{ zIndex: '8', cursor: 'pointer' }}
                      onClick={() => handleSort('totalCost')}
                    >
                      Total Cost
                      {renderSortIndicator('totalCost')}
                    </TableCell>
                    {isTableLoad
                      ? null
                      : tableDateColumns &&
                        tableDateColumns?.length > 0 &&
                        tableDateColumns?.map((date) => (
                          <TableCell
                            className="adminTableheading"
                            key={date}
                            align="center"
                            onClick={() => handleSort(date)}
                            style={{ cursor: 'pointer' }}
                          >
                            {date.replace('_', ' ')}
                            {renderSortIndicator(date)}
                          </TableCell>
                        ))}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      className="sticky-cell adminTableheading"
                      key="empty2"
                      style={{ zIndex: 8, position: 'sticky', top: '39.5px' }}
                    >
                      Total Cost
                    </TableCell>
                    <TableCell
                      className="sticky-cell "
                      key="totalCost2"
                      align="center"
                      style={{
                        background: 'rgba(238, 246, 254, 1)',
                        position: 'sticky',
                        top: '39.5px',
                        color: 'rgba(10, 60, 162, 1)',
                        fontWeight: 700,
                        zIndex: 8,
                      }}
                    >
                      {costFormatter2(
                        updatedCurrency,
                        isTableLoad
                          ? 0
                          : servicesData['Total Cost']?.totalCost?.cost
                      )}
                    </TableCell>
                    {isTableLoad
                      ? null
                      : tableDateColumns?.map((date) => (
                          <TableCell
                            key={date}
                            align="center"
                            style={{
                              position: 'sticky',
                              top: '39.5px',
                              background: 'rgba(238, 246, 254, 1)',
                              fontWeight: 600,
                            }}
                          >
                            {costFormatter2(
                              updatedCurrency,
                              servicesData['Total Cost']?.[date]?.cost
                            ) || '-'}
                          </TableCell>
                        ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isTableLoad ? (
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Loading
                          style={{
                            height: 'fit-content',
                            padding: '2rem',
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ) : tableDateColumns &&
                    Object.keys(servicesData)?.length > 0 &&
                    tableDateColumns?.length > 0 ? (
                    Object.entries(servicesData)
                      .filter(([key]) => key !== 'Total Cost')
                      ?.map(([key, value]: any) => (
                        <TableRow key={key}>
                          <TableCell
                            className="sticky-cell"
                            component="th"
                            scope="row"
                            style={{ fontWeight: '600' }}
                          >
                            {key}
                          </TableCell>
                          <TableCell
                            className="sticky-cell"
                            component="th"
                            align="center"
                            style={{
                              background: 'rgba(238, 246, 254, 1)',
                              fontWeight: 600,
                            }}
                          >
                            {costFormatter2(
                              updatedCurrency,
                              value?.totalCost?.cost
                            )}
                          </TableCell>
                          {tableDateColumns?.map((date) => {
                            const cellData = value[date];
                            const {
                              style,
                              trendIndicator,
                              trendSymbol,
                            } = getTrendStyles(cellData?.color);

                            return (
                              <TableCell
                                key={date}
                                align="center"
                                style={cellData?.cost ? style : {}}
                              >
                                {(cellData?.cost &&
                                  costFormatter2(
                                    updatedCurrency,
                                    cellData.cost
                                  )) ||
                                  '-'}
                                {trendSymbol && cellData?.cost ? (
                                  <span style={trendIndicator}>
                                    {trendSymbol}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))
                  ) : (
                    <TableRow key="load">
                      <TableCell colSpan={2} align="center">
                        No Records
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Paper>
          ) : (
            <div style={{ width: '100%', height: '100%' }}>
              {isTableLoad ? (
                <GraphLoader isLoading={isTableLoad} />
              ) : tableDateColumns &&
                barChartData &&
                Object.keys(barChartData)?.length > 0 &&
                tableDateColumns?.length > 0 ? (
                Object.keys(barChartData).length > 0 && (
                  <BarChart data={barChartData} />
                )
              ) : Object.keys(barChartData).length === 0 &&
                !isTableLoad &&
                selectedAccountInfo ? (
                <GraphLoader isLoading={isTableLoad} />
              ) : null}
            </div>
          )}
        </div>
      </div>

      {/*
        <Grid>
          {' '}
          <DateRangePicker
            isDayBlocked={(day: any) => day > new Date()}
            isOutsideRange={() => false} // Disable the default isOutsideRange behavior
            initialVisibleMonth={() => initialMonth}
            hideKeyboardShortcutsPanel={false}
            minDate={minDate}
            maxDate={maxDate}
            startDateId="your_unique_start_date_id"
            startDate={startDate}
            endDate={endDate}
            endDateId="your_unique_end_date_id"
            onDatesChange={onDateChange}
            focusedInput={isFocused}
            onFocusChange={(focusedInput: any) => setIsFocused(focusedInput)}
            displayFormat="YYYY/MM/DD"
            minimumNights={0}
          />
        </Grid>  
      */}
    </>
  );
};

export default ServicesReports;
