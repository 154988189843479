import React, { useMemo } from 'react';
import './Dashboard.css';
import {
  BasicAPIRequest,
  config,
  optionIsAvailableConditionHandler,
} from './config';
import { useFetchData } from '../../hooks/useFetchData';
import SectionCard from '../../components/HealthMonitoring/SectionCard';

const MemoryMeminfo: React.FC<BasicAPIRequest> = (props) => {
  const {
    refreshTrigger,
    endTime,
    startTime,
    job,
    node,
    step,
    primaryKey,
  } = props;

  const apiDependencies = useMemo(
    () => [startTime, endTime, node, job, step, refreshTrigger, primaryKey],
    [startTime, endTime, node, job, step, refreshTrigger, primaryKey]
  );
  const reqBody = { startTime, endTime, node, job, step, primaryKey };

  // const [isModalOpen, setIsModalOpen] = useState(false);
  const ActiveInactiveData = useFetchData({
    fetchData: () => config.getActiveInactive(reqBody),
    dependencies: apiDependencies,
  });

  const CommittedData = useFetchData({
    fetchData: () => config.getCommitted(reqBody),
    dependencies: apiDependencies,
  });

  const ActiveInactiveDetailData = useFetchData({
    fetchData: () => config.getActiveInactiveDetail(reqBody),
    dependencies: apiDependencies,
  });

  const WriteBackDirtyData = useFetchData({
    fetchData: () => config.getWriteBackDirty(reqBody),
    dependencies: apiDependencies,
  });

  const SharedMappedData = useFetchData({
    fetchData: () => config.getSharedMapped(reqBody),
    dependencies: apiDependencies,
  });

  const SlabData = useFetchData({
    fetchData: () => config.getSlab(reqBody),
    dependencies: apiDependencies,
  });

  const VmallocData = useFetchData({
    fetchData: () => config.getVmalloc(reqBody),
    dependencies: apiDependencies,
  });

  const AnonymousData = useFetchData({
    fetchData: () => config.getAnonymous(reqBody),
    dependencies: apiDependencies,
  });

  const KernelCpuData = useFetchData({
    fetchData: () => config.getKernelCpu(reqBody),
    dependencies: apiDependencies,
  });

  const HugePagesSizeData = useFetchData({
    fetchData: () => config.getHugePagesSize(reqBody),
    dependencies: apiDependencies,
  });

  const DirectMapData = useFetchData({
    fetchData: () => config.getDirectMap(reqBody),
    dependencies: apiDependencies,
  });

  const UnevictableMlockedData = useFetchData({
    fetchData: () => config.getUnevictableMlocked(reqBody),
    dependencies: apiDependencies,
  });

  const cards = [
    {
      title: 'Memory Active/Inactive',
      // tooltip: 'sample tooltip content',
      options: optionIsAvailableConditionHandler(
        ActiveInactiveData.data,
        true,
        true
      ),
      loader: ActiveInactiveData.loader,
      error: ActiveInactiveData.error,
    },
    {
      title: 'Memory Committed',
      // tooltip: 'sample tooltip content',
      options: optionIsAvailableConditionHandler(
        CommittedData.data,
        true,
        true
      ), // Non-memory graph
      loader: CommittedData.loader,
      error: CommittedData.error,
    },
    {
      title: 'Memory Active/Inactive Detail',
      options: optionIsAvailableConditionHandler(
        ActiveInactiveDetailData.data,
        true,
        true
      ),
      loader: ActiveInactiveDetailData.loader,
      error: ActiveInactiveDetailData.error,
    },
    {
      title: 'Memory Writeback Dirty',
      options: optionIsAvailableConditionHandler(
        WriteBackDirtyData.data,
        true,
        true
      ),
      loader: WriteBackDirtyData.loader,
      error: WriteBackDirtyData.error,
    },
    {
      title: 'Memory Shared and Mapped',
      options: optionIsAvailableConditionHandler(
        SharedMappedData.data,
        true,
        true
      ),
      loader: SharedMappedData.loader,
      error: SharedMappedData.error,
    },
    {
      title: 'Memory Slab',
      options: optionIsAvailableConditionHandler(SlabData.data, true, true),
      loader: SlabData.loader,
      error: SlabData.error,
    },
    {
      title: 'Memory Vmalloc',
      options: optionIsAvailableConditionHandler(VmallocData.data, true, true),
      loader: VmallocData.loader,
      error: VmallocData.error,
    },
    {
      title: 'Memory Anonymous',
      options: optionIsAvailableConditionHandler(
        AnonymousData.data,
        true,
        true
      ),
      loader: AnonymousData.loader,
      error: AnonymousData.error,
    },
    {
      title: 'Memory Kernel/CPU',
      options: optionIsAvailableConditionHandler(
        KernelCpuData.data,
        true,
        true
      ),
      loader: KernelCpuData.loader,
      error: KernelCpuData.error,
    },
    {
      title: 'Memory HugePages Size',
      options: optionIsAvailableConditionHandler(
        HugePagesSizeData.data,
        true,
        true
      ),
      loader: HugePagesSizeData.loader,
      error: HugePagesSizeData.error,
    },
    {
      title: 'Memory DirectMap',
      options: optionIsAvailableConditionHandler(
        DirectMapData.data,
        true,
        true
      ),
      loader: DirectMapData.loader,
      error: DirectMapData.error,
    },
    {
      title: 'Memory Unevictable and MLocked',
      options: optionIsAvailableConditionHandler(
        UnevictableMlockedData.data,
        true,
        true
      ),
      loader: UnevictableMlockedData.loader,
      error: UnevictableMlockedData.error,
    },
  ];
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '16px',
      }}
    >
      {cards.map((card, index) => (
        <SectionCard card={card} key={index} />
      ))}
    </div>
  );
};

export default MemoryMeminfo;
