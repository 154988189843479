import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleCollapsed } from '../../store/uiSlice';
import { uiState } from '../../store/index';
import { ReactComponent as ToggleArrow } from '../../assets/navigationSvg/arrow-left-icon.svg';

export const ToggleButton: React.FC = () => {
    const dispatch = useDispatch();
    const { collapsed } = useSelector(uiState);
  
    return (
      <div className={`toggle-btn ${collapsed ? 'reverse' : ''}`} onClick={() => dispatch(toggleCollapsed())}>
        <div className="white-circle"></div>
        <ToggleArrow width={30} height={30} />
      </div>
    );
  };
