import { healthMonitoringService } from '../../services';
import { createLineChartOptions } from './EchartsOptionsFactory';

export const durationOptions = [
  { label: 'Last 5 minutes', value: 5 },
  { label: 'Last 15 minutes', value: 15 },
  { label: 'Last 30 minutes', value: 30 },
  { label: 'Last 1 hour', value: 60 },
  { label: 'Last 3 hours', value: 180 },
  { label: 'Last 6 hours', value: 360 },
  { label: 'Last 12 hours', value: 720 },
  { label: 'Last 24 hours', value: 1440 },
  { label: 'Custom', value: null },
];

export const intervalOption = [
  { label: '15 seconds', value: '15s' },
  { label: '30 seconds', value: '30s' },
  { label: '1 minute', value: '1m' },
  { label: '2 minutes', value: '2m' },
  { label: '5 minutes', value: '5m' },
  { label: '10 minutes', value: '10m' },
  { label: '15 minutes', value: '15m' },
  { label: '30 minutes', value: '30m' },
  { label: '1 hour', value: '1h' },
  { label: '3 hours', value: '3h' },
  { label: '6 hours', value: '6h' },
  { label: '12 hours', value: '12h' },
  { label: '24 hours', value: '24h' },
];

export const generateQuickCPUMemDiskDummyData = () => [
  { tooltip: 'Busy CPU', name: 'Busy CPU', unit: '%', data: 10 },
  {
    tooltip: 'Sys Load (5m Avg)',
    name: 'Sys Load (5m Avg)',
    unit: '%',
    data: 60,
  },
  {
    tooltip: 'Sys Load (15m Avg)',
    name: 'Sys Load (15m Avg)',
    unit: '%',
    data: 50,
  },
  { tooltip: 'RAM Used', name: 'RAM Used', unit: '%', data: 30 },
  { tooltip: 'SWAP Used', name: 'SWAP Used', unit: '%', data: 80 },
  { tooltip: 'Root FS Used', name: 'Root FS Used', unit: '%', data: 95 },
  {
    tooltip: 'RootFS Total',
    name: 'RootFS Total',
    unit: '',
    data: 'N/A',
  },
  { tooltip: 'RAM Total', name: 'RAM Total', unit: '', data: 'N/A' },
  { tooltip: 'SWAP Total', name: 'SWAP Total', unit: '', data: 'N/A' },
  { tooltip: 'Uptime', name: 'Uptime', unit: '', data: 'N/A' },
  { tooltip: 'CPU Cores', name: 'CPU Cores', unit: '', data: 'N/A' },
];

export const PANEL_KEYS = {
  QUICK_CPU: 'quick-cpu',
  BASIC_CPU: 'basic-cpu',
  CPU_MEM_NET_DISK: 'cpu-mem-net-disk',
  MEMORY_MEMINFO: 'memory-meminfo',
} as const;

export interface BasicAPIRequest {
  startTime: number | null;
  endTime: number | null;
  node?: string | null;
  job?: string | null;
  step?: string | null;
  refreshTrigger?: number;
  primaryKey: number | null;
}

export interface AllInstanceI {
  primaryKey: number;
}

interface Iconfig {
  getAllInstance: (parmas: AllInstanceI) => Promise<any>; // No parameters for this AP
  getServerHealthCreds: () => Promise<any>;
  getQuickCpuMemoryDisk: (params: BasicAPIRequest) => Promise<any>;
  getBasicCPUUsage: (params: BasicAPIRequest) => Promise<any>;
  getBasicMemoryUsage: (params: BasicAPIRequest) => Promise<any>;
  getBasicNetworkUsage: (params: BasicAPIRequest) => Promise<any>;
  getBasicDiskSpaceUsage: (params: BasicAPIRequest) => Promise<any>;
  getCPUMemoryNetworkDisk: (params: BasicAPIRequest) => Promise<any>;
  getMemoryStack: (params: BasicAPIRequest) => Promise<any>;
  getDiskSpaceUsed: (params: BasicAPIRequest) => Promise<any>;
  getDiskIops: (params: BasicAPIRequest) => Promise<any>;
  getIoUsageReadWrite: (params: BasicAPIRequest) => Promise<any>;
  getIoUtilization: (params: BasicAPIRequest) => Promise<any>;

  //Memory section
  getActiveInactive: (params: BasicAPIRequest) => Promise<any>;
  getCommitted: (params: BasicAPIRequest) => Promise<any>;
  getActiveInactiveDetail: (params: BasicAPIRequest) => Promise<any>;
  getWriteBackDirty: (params: BasicAPIRequest) => Promise<any>;
  getSharedMapped: (params: BasicAPIRequest) => Promise<any>;
  getSlab: (params: BasicAPIRequest) => Promise<any>;
  getVmalloc: (params: BasicAPIRequest) => Promise<any>;
  getAnonymous: (params: BasicAPIRequest) => Promise<any>;
  getKernelCpu: (params: BasicAPIRequest) => Promise<any>;
  getHugePagesSize: (params: BasicAPIRequest) => Promise<any>;
  getDirectMap: (params: BasicAPIRequest) => Promise<any>;
  getUnevictableMlocked: (params: BasicAPIRequest) => Promise<any>;
  addServiceHealthCreds: (parmas: any) => Promise<any>;
}

export const config: Iconfig = {
  getAllInstance: (params: AllInstanceI) =>
    healthMonitoringService.getAllInstance(params),
  getServerHealthCreds: () => healthMonitoringService.getServerHealthCreds(),
  addServiceHealthCreds: (params) =>
    healthMonitoringService.addServerHealthCreds(params),
  getQuickCpuMemoryDisk: (params) =>
    healthMonitoringService.getQuickCpuMemoryDisk(params),
  getBasicCPUUsage: (params) =>
    healthMonitoringService.getBasicCPUUsage(params),
  getBasicMemoryUsage: (params) =>
    healthMonitoringService.getBasicMemoryUsage(params),
  getBasicNetworkUsage: (params) =>
    healthMonitoringService.getBasicNetworkTrafficUsage(params),
  getBasicDiskSpaceUsage: (params) =>
    healthMonitoringService.getBasicDiskSpaceUsage(params),
  getCPUMemoryNetworkDisk: (params) =>
    healthMonitoringService.getCPUMemoryNetworkDisk(params),
  getMemoryStack: (params) => healthMonitoringService.getMemoryStack(params),

  getDiskSpaceUsed: (params) =>
    healthMonitoringService.getDiskSpaceUsed(params),
  getDiskIops: (params) => healthMonitoringService.getDiskIops(params),
  getIoUsageReadWrite: (params) =>
    healthMonitoringService.getIoUsageReadWrite(params),
  getIoUtilization: (params) =>
    healthMonitoringService.getIoUtilization(params),

  // memory section

  getActiveInactive: (params) =>
    healthMonitoringService.getActiveInactive(params),
  getCommitted: (params) => healthMonitoringService.getCommitted(params),
  getActiveInactiveDetail: (params) =>
    healthMonitoringService.getActiveInactiveDetail(params),
  getWriteBackDirty: (params) =>
    healthMonitoringService.getWriteBackDirty(params),
  getSharedMapped: (params) => healthMonitoringService.getSharedMapped(params),
  getSlab: (params) => healthMonitoringService.getSlab(params),
  getVmalloc: (params) => healthMonitoringService.getVmalloc(params),
  getAnonymous: (params) => healthMonitoringService.getAnonymous(params),
  getKernelCpu: (params) => healthMonitoringService.getKernelCpu(params),
  getHugePagesSize: (params) =>
    healthMonitoringService.getHugePagesSize(params),
  getDirectMap: (params) => healthMonitoringService.getDirectMap(params),
  getUnevictableMlocked: (params) =>
    healthMonitoringService.getUnevictableMlocked(params),
};

// Type for each input data item
interface InputDataItem {
  name: string;
  unit: string;
  data: [number, number][];
}

// Type for the series data to be used in ECharts
interface SeriesDataItem {
  name: string;
  data: number[]; // Data values for the series
  color: string; // Color for the series
}

// Common output structure
interface ExtractedSeriesData {
  seriesData: SeriesDataItem[]; // Processed series data for ECharts
  legendData: string[]; // Labels for the ECharts legend
  xAxisData: string[]; // Formatted x-axis labels (e.g., "HH:mm")
  displayUnit: string; // Unit to display on the y-axis
}

export const echartsColorPalette = [
  '#5470c6',
  '#91cc75',
  '#fac858',
  '#ee6666',
  '#73c0de',
  '#3ba272',
  '#fc8452',
  '#9a60b4',
  '#ea7ccc',
  '#336699',
  '#60c0dc',
  '#ffb980',
  '#d87a80',
  '#8d98b3',
  '#e5cf0d',
  '#97b552',
  '#95706d',
  '#dc69aa',
  '#07a2a4',
  '#9a7fd1',
  '#588dd5',
  '#f5994e',
  '#c05050',
  '#59678c',
  '#c9ab00',
  '#7eb00a',
  '#6f5553',
  '#c14089',
  '#2ec7c9',
  '#b6a2de',
];
export const extractMemorySeriesData = (
  data: InputDataItem[]
): ExtractedSeriesData => {
  const seriesData: SeriesDataItem[] = [];
  const legendData: string[] = [];
  const xAxisData: string[] = [];
  const displayUnit = 'B'; // Unit for memory (bytes)

  data?.forEach(({ name, unit, data: metrics }, index) => {
    legendData.push(name);

    const normalizedValues = metrics.map(([timestamp, value]) => ({
      timestamp,
      bytes: unit === 'bytes' ? Number(value) : 0,
    }));

    if (!xAxisData.length && normalizedValues.length > 0) {
      xAxisData.push(
        ...normalizedValues.map(({ timestamp }) => {
          const date = new Date(timestamp * 1000);
          return `${date.getHours()}:${date
            .getMinutes()
            .toString()
            .padStart(2, '0')}:${date
            .getSeconds()
            .toString()
            .padStart(2, '0')}`;
        })
      );
    }

    seriesData.push({
      name,
      data: normalizedValues.map((entry) => entry.bytes),
      color: echartsColorPalette[index % echartsColorPalette.length],
    });
  });

  // Sort seriesData by the last value in data array (descending)
  seriesData.sort((a, b) => {
    const aValue = a.data.length > 0 ? a.data[a.data.length - 1] : 0;
    const bValue = b.data.length > 0 ? b.data[b.data.length - 1] : 0;
    return bValue - aValue; // Descending order
  });

  // Reorder legendData to match seriesData
  const sortedLegendData = seriesData.map((item) => item.name);

  // Re-assign colors based on new order
  seriesData.forEach((series, index) => {
    series.color = echartsColorPalette[index % echartsColorPalette.length];
  });

  return {
    seriesData,
    legendData: sortedLegendData,
    xAxisData,
    displayUnit,
  };
};

export const extractGeneralSeriesData = (
  data: InputDataItem[]
): ExtractedSeriesData => {
  const seriesData: SeriesDataItem[] = [];
  const legendData: string[] = [];
  const xAxisData: string[] = [];
  let displayUnit = '';

  data?.forEach(({ name, unit, data: metrics }, index) => {
    legendData.push(name);

    if (index === 0) {
      displayUnit = unit;
    }

    const seriesValues = metrics?.map(([, value]) => Number(value));

    if (!xAxisData.length && metrics?.length > 0) {
      xAxisData.push(
        ...metrics.map(([timestamp]) => {
          const date = new Date(timestamp * 1000);
          return `${date.getHours()}:${date
            .getMinutes()
            .toString()
            .padStart(2, '0')}:${date
            .getSeconds()
            .toString()
            .padStart(2, '0')}`;
        })
      );
    }

    seriesData.push({
      name,
      data: seriesValues,
      color: echartsColorPalette[index % echartsColorPalette.length],
    });
  });

  // Sort seriesData by the last value in data array (descending)
  seriesData.sort((a, b) => {
    const aValue = a.data.length > 0 ? a.data[a.data.length - 1] : 0;
    const bValue = b.data.length > 0 ? b.data[b.data.length - 1] : 0;
    return bValue - aValue; // Descending order
  });

  // Reorder legendData to match seriesData
  const sortedLegendData = seriesData.map((item) => item.name);

  // Re-assign colors based on new order
  seriesData.forEach((series, index) => {
    series.color = echartsColorPalette[index % echartsColorPalette.length];
  });

  return {
    seriesData,
    legendData: sortedLegendData,
    xAxisData,
    displayUnit,
  };
};

export const NO_DATA_MSG = 'No data available';

const createChartOptions = (isMemoryGraph: boolean, isTableLegend: boolean) => (
  apiData: any
) =>
  apiData &&
  (createLineChartOptions(
    apiData,
    isMemoryGraph,
    isTableLegend
  ) as echarts.EChartOption);

export const optionIsAvailableConditionHandler = (
  data: any,
  isMemoryGraph: boolean,
  isTableLegend: boolean = false
) => {
  if (data && data.message !== NO_DATA_MSG && data.length !== 0) {
    const options = createChartOptions(isMemoryGraph, isTableLegend)(data);
    return options;
  }
  return null;
};
