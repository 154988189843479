import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import TitleHeader from '../../components/MasterComponent/TitleHeader/TitleHeader';
import SearchSelectOption from '../../components/MasterComponent/SelectOptions/SearchSelectOption';
import { ReactComponent as NoDataIcon } from '../../assets/ICONS/document-error-icon.svg';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './Dashboard.css';
import QuickCPUMemoryDisk from './QuickCPUMemoryDisk';
import BasicCPUMemoryDisk from './BasicCPUMemoryDisk';
import { config, durationOptions, intervalOption, PANEL_KEYS } from './config';
import CustomDateRangePicker from '../../components/MasterComponent/DatePicker/DateRangePicker';
import { useFetchData } from '../../hooks/useFetchData';
import CPUMemoryNetworkDisk from './CPUMemoryNetworkDisk';
import { ZoomProvider } from '../../components/HealthMonitoring/ZoomContext';
import CustomTooltip from '../../components/MasterComponent/CustomTooltip';
import RefreshIcon from '@mui/icons-material/Refresh';
import CustomButton from '../../components/MasterComponent/Button/CustomButton';
import { getStartTimeEndTimeUnixInterval } from '../../utils/config';
import MemoryMeminfo from './MemoryMeminfo';
import { VisualizationModalWrapper } from './VisualizationModalWrapper';

interface InstanceData {
  instance: string;
  job: string;
}

export interface JobFilter {
  startTime: number | null;
  endTime: number | null;
  job: string | null;
  node: string | null;
  refreshTrigger: number;
  step: string | null;
  // selectedAccountId: number | null;
  selectedAccountName: string | null;
  primaryKey: number;
}

interface HealthMonitoringDashboardI {
  formCloseRefresh: number;
  setFormCloseRefresh: any;
  accountData: any;
  primaryKey: number;
  handleAccountChange: any;
  selectedAccountName: string;
}

const HealthMonitoringDashboard: React.FC<HealthMonitoringDashboardI> = (
  props
) => {
  const {
    formCloseRefresh,
    setFormCloseRefresh,
    accountData,
    primaryKey,
    handleAccountChange,
    selectedAccountName,
  } = props;
  const [selectedDuration, setSelectedDuration] = useState<number | null>(5);
  const [selectedInterval, setSelectedInterval] = useState<string | null>(
    '15s'
  );
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  // const [formCloseRefresh, setFormCloseRefresh] = useState(1);
  const [selectedJob, setSelectedJob] = useState<string | null>(null);
  const [selectedInstance, setSelectedInstance] = useState<string | null>(null);
  const [expandedAccordions, setExpandedAccordions] = useState<string[]>([
    PANEL_KEYS.QUICK_CPU,
    PANEL_KEYS.BASIC_CPU,
  ]);

  const getAllInstance = useFetchData<InstanceData>({
    fetchData: () => config.getAllInstance({ primaryKey }),
    dependencies: [formCloseRefresh, primaryKey],
    fetchOnMount: false,
  });

  // Memoized options from `getAllInstance` API
  const memoizedInstanceData = useMemo<InstanceData[]>(
    () => getAllInstance.data || [],
    [getAllInstance.data]
  );

  const handleAccordionChange = (panel: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpandedAccordions((prev) =>
      isExpanded ? [...prev, panel] : prev.filter((item) => item !== panel)
    );
  };

  // Set default selections for job and instance
  useEffect(() => {
    if (memoizedInstanceData.length > 0) {
      setSelectedJob(memoizedInstanceData[0].job);
      setSelectedInstance(memoizedInstanceData[0].instance);
    }
  }, [memoizedInstanceData]);

  // Handle `job` selection change
  const handleJobChange = useCallback(
    (_: any, newJob: string | null) => {
      if (newJob) {
        setSelectedJob(newJob);
        const correspondingInstance = memoizedInstanceData.find(
          (item) => item.job === newJob
        )?.instance;
        setSelectedInstance(correspondingInstance || null);
      } else {
        setSelectedJob(null);
        setSelectedInstance(null);
      }
    },
    [memoizedInstanceData]
  );

  // Handle `instance` selection change
  const handleInstanceChange = useCallback(
    (_: any, newInstance: string | null) => {
      if (newInstance) {
        setSelectedInstance(newInstance);
        const correspondingJob = memoizedInstanceData.find(
          (item) => item.instance === newInstance
        )?.job;
        setSelectedJob(correspondingJob || null);
      } else {
        setSelectedInstance(null);
        setSelectedJob(null);
      }
    },
    [memoizedInstanceData]
  );

  const handleStartDateChange = (date: Date) => setStartDate(date);
  const handleEndDateChange = (date: Date) => setEndDate(date);

  // Render loading, error, and no-data states for `getAllInstance`
  const renderOptions = (type: 'job' | 'instance') => {
    if (getAllInstance.loader) {
      return ['Loading...'];
    }

    if (getAllInstance.error) {
      return ['Error: Unable to load data'];
    }

    if (memoizedInstanceData.length === 0) {
      return ['No data available'];
    }

    return memoizedInstanceData.map((item) => item[type]);
  };

  const renderAccountOptions = () => {
    // if (serverHealthCreds.loader) {
    //   return ['Loading...'];
    // }

    // if (serverHealthCreds.error) {
    //   return ['Error: Unable to load data'];
    // }

    if (accountData.length === 0) {
      return ['No data available'];
    }

    return accountData.map((item: any) => item.Title);
  };

  const selectDurationOptions = durationOptions.map((option) => option.label);
  const selectIntervalOptions = intervalOption.map((option) => option.label);

  const handleDurationChange = useCallback((_: any, value: string | null) => {
    const selectedOption = durationOptions.find(
      (option) => option.label === value
    );

    if (selectedOption?.label === 'Custom') {
      setSelectedDuration(null);
      setShowDatePicker(true);
      // Set default custom date range (1 hour before now to now)
      const now = new Date();
      const oneHourBefore = new Date(now.getTime() - 60 * 60 * 1000);
      setStartDate(oneHourBefore);
      setEndDate(now);
    } else {
      setSelectedDuration(selectedOption?.value ?? 5);
      setShowDatePicker(false);
      setStartDate(null);
      setEndDate(null);
    }
  }, []);

  const handleIntervalChange = useCallback((_: any, value: string | null) => {
    const selectedOption = intervalOption.find(
      (option) => option.label === value
    );

    setSelectedInterval(selectedOption?.value ?? '15s');
  }, []);

  const handleRefresh = useCallback(() => {
    setRefreshTrigger((prev) => prev + 1);
  }, [refreshTrigger]);

  // Calculate time range using your utility function
  const { startTime, endTime } = useMemo(() => {
    if (selectedDuration === null && startDate && endDate) {
      // For custom date range

      return {
        startTime: Math.floor(startDate.getTime() / 1000),
        endTime: Math.floor(endDate.getTime() / 1000),
      };
    }
    // For predefined durations
    return getStartTimeEndTimeUnixInterval(Date.now(), selectedDuration ?? 5);
  }, [selectedDuration, startDate, endDate]);

  const commonChildComponentProps: JobFilter = useMemo(
    () => ({
      startTime,
      endTime,
      job: selectedJob,
      node: selectedInstance,
      refreshTrigger,
      step: selectedInterval,
      selectedAccountName,
      primaryKey,
    }),
    [
      startTime,
      endTime,
      selectedJob,
      selectedInstance,
      refreshTrigger,
      selectedInterval,
      selectedAccountName,
      primaryKey,
    ]
  );
  return (
    <>
      <>
        <div
          className="healthMonitoringDashboardContainer"
          style={{ width: '99%', margin: 'auto' }}
        >
          <TitleHeader
            extraContent={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: 'fit-content',
                  gap: '1.5rem',
                  marginRight: '1.5rem',
                }}
              >
                <VisualizationModalWrapper
                  setFormCloseRefresh={setFormCloseRefresh}
                  formCloseRefresh={formCloseRefresh}
                />
                <SearchSelectOption
                  labelName="Account"
                  onChangeSelectHandler={handleAccountChange}
                  selectOptions={renderAccountOptions()}
                  selectedValue={selectedAccountName}
                />
                {/* Show date picker only for custom selection */}
                {showDatePicker && (
                  <CustomDateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onStartDateChange={(date) =>
                      date && handleStartDateChange(date)
                    }
                    onEndDateChange={(date) =>
                      date && handleEndDateChange(date)
                    }
                    visibleDays={90}
                    rangeInDays={7}
                    startLabel="Start Time"
                    endLabel="End Time"
                  />
                )}
                <SearchSelectOption
                  labelName="Duration"
                  onChangeSelectHandler={handleDurationChange}
                  selectOptions={selectDurationOptions}
                  selectedValue={
                    selectDurationOptions.find(
                      (label) =>
                        durationOptions.find(
                          (option) => option.value === selectedDuration
                        )?.label === label
                    ) || ''
                  }
                />
                <SearchSelectOption
                  labelName="Interval"
                  onChangeSelectHandler={handleIntervalChange}
                  selectOptions={selectIntervalOptions}
                  selectedValue={
                    selectIntervalOptions.find(
                      (label) =>
                        intervalOption.find(
                          (option) => option.value === selectedInterval
                        )?.label === label
                    ) || ''
                  }
                />

                <CustomTooltip title="Refresh Data">
                  <CustomButton
                    onClickHandler={handleRefresh}
                    buttonType="outlined"
                  >
                    <RefreshIcon sx={{ fontSize: '2.2rem' }} />
                  </CustomButton>
                </CustomTooltip>
              </div>
            }
          >
            {selectedAccountName ? selectedAccountName : 'Title'}
          </TitleHeader>
          <div className="flex">
            <SearchSelectOption
              labelName="Job"
              onChangeSelectHandler={handleJobChange}
              selectOptions={renderOptions('job')}
              selectedValue={selectedJob}
              disableClearable={true}
            />
            <SearchSelectOption
              labelName="Host"
              onChangeSelectHandler={handleInstanceChange}
              selectOptions={renderOptions('instance')}
              selectedValue={selectedInstance}
              className="ml-10"
              disableClearable={true}
            />
          </div>
          {getAllInstance.loader ? (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="300px"
              marginTop="1rem"
            >
              <CircularProgress size={50} />
              <Typography variant="h6" sx={{ marginTop: 2, color: '#666' }}>
                Fetching data, please wait…
              </Typography>
            </Box>
          ) : getAllInstance.data && getAllInstance.data.length > 0 ? (
            <ZoomProvider>
              <Accordion
                expanded={expandedAccordions.includes(PANEL_KEYS.QUICK_CPU)}
                onChange={handleAccordionChange(PANEL_KEYS.QUICK_CPU)}
                defaultExpanded
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: '16px', fontWeight: 500 }}
                  >
                    Quick CPU / Mem / Disk
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '8px 0 10px 16px' }}>
                  {expandedAccordions.includes(PANEL_KEYS.QUICK_CPU) && (
                    <QuickCPUMemoryDisk {...commonChildComponentProps} />
                  )}
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expandedAccordions.includes(PANEL_KEYS.BASIC_CPU)}
                onChange={handleAccordionChange(PANEL_KEYS.BASIC_CPU)}
                defaultExpanded
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: '16px', fontWeight: 500 }}
                  >
                    Basic CPU / Mem / Disk
                    <span className="panelCount">(4 panels)</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {expandedAccordions.includes(PANEL_KEYS.BASIC_CPU) && (
                    <BasicCPUMemoryDisk {...commonChildComponentProps} />
                  )}
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expandedAccordions.includes(
                  PANEL_KEYS.CPU_MEM_NET_DISK
                )}
                onChange={handleAccordionChange(PANEL_KEYS.CPU_MEM_NET_DISK)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: '16px', fontWeight: 500 }}
                  >
                    CPU / Memory / Network / Disk
                    <span className="panelCount">(6 panels)</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {expandedAccordions.includes(PANEL_KEYS.CPU_MEM_NET_DISK) && (
                    <CPUMemoryNetworkDisk {...commonChildComponentProps} />
                  )}
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expandedAccordions.includes(
                  PANEL_KEYS.MEMORY_MEMINFO
                )}
                onChange={handleAccordionChange(PANEL_KEYS.MEMORY_MEMINFO)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: '16px', fontWeight: 500 }}
                  >
                    Memory Meminfo
                    <span className="panelCount">(12 panels)</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {expandedAccordions.includes(PANEL_KEYS.MEMORY_MEMINFO) && (
                    <MemoryMeminfo {...commonChildComponentProps} />
                  )}
                </AccordionDetails>
              </Accordion>
            </ZoomProvider>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="300px"
              marginTop="1rem"
            >
              <NoDataIcon width={100} height={100} />
              <Typography
                variant="h6"
                sx={{ marginTop: 2, color: '#666', fontWeight: '500' }}
              >
                No data available
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: '#999', marginTop: 1, textAlign: 'center' }}
              >
                Please ensure that the data source is properly configured or try
                again later.
              </Typography>
            </Box>
          )}
        </div>
      </>
    </>
  );
};

export default memo(HealthMonitoringDashboard);
