export const RoutesFun = () => {
  const company = localStorage.getItem('CompanyName');

  //  const company='thinksys';
  return {
    Login: '/login',
    // Home: '/',
    RegistrationLogin: '/registration',
    // RegistrationForm: '/registration/:Id',
    Dashboard: `/${company}/dashboard`,
    IdleInstance: `/${company}/idle-resource`,
    IdleInstanceDetail: `/${company}/idle-resource/:type`,
    IdleInstancewithType: '/' + company + '/idleresource/:type',
    IdleInstancewithResName: '/' + company + '/idleresource/:type/:resource',
    CostForecasting: `/${company}/costforecasting`,
    RightSizing: `/${company}/rightsizing`,
    RightSizingwithType: `/${company}/rightsizing/:type`,
    CostSaving: `/${company}/costsaving`,
    Inventories: `/${company}/inventories`,
    ScheduleVM: `/${company}/schedulevm`,
    Invoice: '/' + company + '/billing',
    TagDetailWithValue: '/' + company + '/billing/tagDetail/:value',
    InventoryDetail: '/' + company + '/inventories/:inventoryId',
    SpendingDetails: '/' + company + '/costforecasting/spending-details/:month',
    ServiceWiseSpendingDetails:
      '/' + company + '/costforecasting/service-wise-spending-details/:service',
    AccountDetails: '/' + company + '/accounts',
    AppDashboard: `/${company}/dashboard`,
    CreateAccount: '/' + company + '/accounts/create-account',
    EditAccount: '/' + company + '/accounts/edit-account/:cloudProvider/:Id',
    ConfigureAccount: '/' + company + '/accounts/configure-account',
    ConfigureAccountNext: '/' + company + '/accounts/configure-account/2',
    UserProfile: '/' + company + '/user-profile',
    ResetPassword: '/resetpassword',
    UsersList: '/' + company + '/userslist',
    SupportPage: '/supportpage',
    EULA: '/eula',
    PrivacyPolicy: '/privacy-policy',
    Pricing: '/pricing',
    Demo: '/schedule-demo',
    HomePage: '/',
    OptimizeSA: '/optimizeSA',
    RightsizingSA: '/rightsizingSA',
    SchedulingSA: '/schedulingSA',
    CostAnalysisSA: '/costAnalysisSA',
    QuickPainlessSA: '/quickPainlessSA',
    SecurityScan: '/' + company + '/securityScan',
    InventoryReports: '/' + company + '/inventoryReports',
    ServicesReports: '/' + company + '/services-reports',
    InventoryResourceReports: '/' + company + '/services-reports/:resourceName',
    NotificationManagement: '/' + company + '/notification-management',
    Billing: '/' + company + '/billing-management',
    PlanDetails: '/' + company + '/billing-management/plan-details',
    UptimeMonitoring: '/' + company + '/uptime+ssl-monitoring',
    UptimeMonitoringWithType: '/' + company + '/uptime+ssl-monitoring/:type',
    UploadedDocuments: '/' + company + '/uploaded-documents',

    //System Health Monitoring
    SystemHealthMonitoring: '/' + company + '/system-health-monitoring',

    //Real User Monitoring
    RealUserMonitoring: '/' + company + '/real-user-monitoring',
    RUMDescription: '/' + company + '/real-user-monitoring/:type',

    TrialPlanConfirmation: '/trial-plan-confirmation',
    HighestConsumedResource: '/' + company + '/highest-consumed-resource',
    RightsizingAndIdle: '/' + company + '/rightsizing&idleInput',

    //Admin Panel
    AdminDashboard: '/admin-dashboard',
    Organization: '/organization',
    Users: '/all-users',
    AllOrganizations: '/all-organizations',
    DocumentUpload: '/Doc-upload',
    AdminPanelBilling: '/admin-panel-billing',
    AdminPanelOrganizationBillingDetails: '/admin-panel-org-billing-details',
    SystemHealth: '/system-health',

    CostManagement: '/costmanagement',
    CloudCheckup: '/cloudcheckup',
    riu: '/riu',
    about: '/about',
    Error403: '/unauthorized',
    AzureSpend: '/azurespend',
    RightsizingDetail: '/rightsizingdetail',
    SchedulingDetail: '/schedulingdetail',

    bfi: '/bfi',
    security: '/security',
    software: '/software',
    compliance: '/compliance',
    healthcare: '/healthcare',
    UploadedDocumentStatic: '/viewdocuments',
  };
};
