import React, { useEffect, useState } from 'react';
import CustomButton from '../../components/MasterComponent/Button/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import AddVisualizationModal, {
  VisualizationForm,
} from '../../components/Modals/HealthMonitoring/AddVisualizationModal';
import { useFetchData } from '../../hooks/useFetchData';
import { config } from './config';

interface VisualizationModalWrapperI {
  setFormCloseRefresh: React.Dispatch<React.SetStateAction<number>>;
  formCloseRefresh: number;
}

export const VisualizationModalWrapper: React.FC<VisualizationModalWrapperI> = ({
  setFormCloseRefresh,
  formCloseRefresh,
}) => {
  const [isVisualizationModalOpen, setIsVisualizationModalOpen] = useState(
    false
  );

  const [shouldSubmit, setShouldSubmit] = useState(false);

  const { fetch, data, loader: isSubmitting } = useFetchData({
    fetchData: (formData: VisualizationForm) =>
      config.addServiceHealthCreds(formData),

    fetchOnMount: false,
  });

  // Handle successful submission
  useEffect(() => {
    if (data) {
      setFormCloseRefresh((prev) => prev + 1);
      closeModal();
      setShouldSubmit(false);
    }
  }, [data, setFormCloseRefresh]);

  const openModal = () => setIsVisualizationModalOpen(true);
  const closeModal = () => {
    setIsVisualizationModalOpen(false);
    setShouldSubmit(false);
  };

  const submitVisualizationFormHandler = async (
    formData: VisualizationForm
  ) => {
    setShouldSubmit(true);
    try {
      await fetch(formData);
    } catch (error) {
      console.error('Submission error:', error);
      setShouldSubmit(false);
    }
  };

  useEffect(() => {
    if (!isSubmitting && shouldSubmit) {
      // Reset form fields here
      closeModal();
    }
  }, [isSubmitting, shouldSubmit, closeModal]);

  return (
    <>
      <CustomButton
        buttonType="contained"
        onClickHandler={openModal}
        startIcon={<AddIcon />}
      >
        Add visualization
      </CustomButton>

      {isVisualizationModalOpen && (
        <AddVisualizationModal
          show={isVisualizationModalOpen}
          headerMsg="Add Visualization"
          submitFunc={submitVisualizationFormHandler}
          onClose={closeModal}
          isSubmitting={isSubmitting}
          shouldSubmit={shouldSubmit}
        />
      )}
    </>
  );
};
