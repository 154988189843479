import { Box, Grid, IconButton, Typography } from '@mui/material';
import { memo, useCallback, useMemo } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Chart from '../../components/MasterComponent/Echarts';
import './Dashboard.css';
import CustomTooltip from '../../components/MasterComponent/CustomTooltip';
import { useFetchData } from '../../hooks/useFetchData';
import {
  BasicAPIRequest,
  // BasicAPIRequest,
  config,
  generateQuickCPUMemDiskDummyData,
} from './config';
import QuickSectionSkeleton from '../../components/HealthMonitoring/QuickSectionSkeleton';

const QuickCPUMemoryDisk: React.FC<BasicAPIRequest> = (props) => {
  const {
    endTime,
    startTime,
    job,
    node,
    refreshTrigger,
    step,
    primaryKey,
  } = props;

  const apiDependencies = useMemo(
    () => [startTime, endTime, node, job, step, refreshTrigger],
    [startTime, endTime, node, job, step, refreshTrigger]
  );

  const quickCpuMemoryDiskData = useFetchData({
    fetchData: () =>
      config.getQuickCpuMemoryDisk({
        startTime,
        endTime,
        job,
        node,
        step,
        // selectedAccountId,
        primaryKey,
      }),
    dependencies: apiDependencies,
  });

  const data =
    quickCpuMemoryDiskData.data?.length > 0
      ? quickCpuMemoryDiskData.data
      : generateQuickCPUMemDiskDummyData();

  const gaugeChartOptions = useCallback((gaugeData: any) => {
    const gaugeOption: echarts.EChartsOption = {
      series: [
        {
          type: 'gauge',
          radius: '100%', // for adjusting the size of the gauge
          progress: {
            show: true,
            width: 25,
            itemStyle: {
              color: (() => {
                const value = Number(gaugeData.data); // Current gauge value
                if (value <= 33) {
                  return 'rgba(50, 172, 45, 0.97)'; // Green for 0-33%
                } else if (value <= 66) {
                  return 'rgba(224, 224, 0, 0.9)'; // Yellow for 33-66%
                } else {
                  return 'rgba(245, 54, 54, 0.9)'; // Red for 66-100%
                }
              })(),
            },
          },
          axisLine: {
            lineStyle: {
              width: 25,
              color: [[1, '#e0e0e0']], // Background color for the gauge
            },
          },
          axisTick: {
            show: false, // Hides the small ticks
          },
          splitLine: {
            show: false, // Hides the split lines
          },
          pointer: {
            show: true,
            width: 3, // Pointer thickness
            itemStyle: {
              color: '#0078DB', // Pointer color
            },
          },
          axisLabel: {
            show: false, // Hides the ticks (calibration) on the gauge
          },
          detail: {
            valueAnimation: true,
            formatter: `{value}${gaugeData.unit}`, // Format the displayed value
            fontSize: 16,
            color: '#333', // Detail text color
            fontWeight: 'bold',
            offsetCenter: [0, '70%'], // Adjusts position to avoid overlap
          },
          data: [
            {
              value: Number(gaugeData.data), // The current value of the gauge
            },
          ],
        },

        {
          type: 'gauge',
          radius: '100%',
          startAngle: 225,
          endAngle: -45, // Only the top half
          axisLine: {
            lineStyle: {
              width: 3,
              type: 'dashed',
              color: [
                [0.33, '#00ff00'], // Green for 0-33%
                [0.66, '#ffff00'], // Yellow for 33-66%
                [1, '#ff0000'], // Red for 66-100%
              ] as any[], // Correct type for `color`
            },
          },

          pointer: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
          axisLabel: { show: false },
          detail: { show: false },
        },
      ],
    };
    return gaugeOption;
  }, []);

  const renderGaugeMetrics = () =>
    data
      .filter(
        (item: any) =>
          item.name.includes('Used') ||
          item.name.includes('Busy') ||
          item.name.includes('Load')
      )
      .map((item: any, index: number) => (
        <Grid
          item
          key={index}
          sx={{
            flex: '1 1 0px', // Allow dynamic width
            // maxWidth: 'fit-content',
            minWidth: '151px', // Set a minimum width
          }}
        >
          <div
            className="sectionCard"
            style={{
              height: '180px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            {/* Title with Tooltip */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                marginBottom: 1,
              }}
            >
              <Typography
                className="quickGaugeTitle"
                variant="subtitle1"
                sx={{ fontWeight: 'bold' }}
              >
                {item.name}
              </Typography>
              <CustomTooltip title={item.tooltip} arrow>
                <IconButton size="small" sx={{ marginLeft: 1 }}>
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </CustomTooltip>
            </Box>

            {/* Gauge Chart */}
            <Chart
              option={gaugeChartOptions(item)}
              style={{
                width: '100%',
                height: '130px',
                marginTop: '5px',
              }}
            />
          </div>
        </Grid>
      ));

  const renderNonGaugeMetrics = () =>
    data
      .filter(
        (item: any) =>
          !item.name.includes('Used') &&
          !item.name.includes('Busy') &&
          !item.name.includes('Load')
      )
      .map((item: any, index: number) => (
        <div
          className={`nonGaugeGridItem sectionCard ${
            item.name === 'Uptime' ? 'uptimeItem' : ''
          }`}
          key={index}
        >
          <div
            className={`  ${item.name === 'Uptime' ? 'uptimeItem' : ''}`}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              marginBottom: 1,
            }}
          >
            <Typography
              variant="subtitle1"
              className="healthMonitoringTitle"
              sx={{ fontWeight: 'bold' }}
            >
              {item.name}
            </Typography>
            <CustomTooltip title={item.tooltip} arrow>
              <IconButton size="small" sx={{ marginLeft: 1 }}>
                <InfoOutlinedIcon fontSize="small" />
              </IconButton>
            </CustomTooltip>
          </div>
          <div className="cardBody">
            <span className="fw-700 font-27px">{item.data}</span>
            <span className="unit">{item.unit}</span>
          </div>
        </div>
      ));
  return (
    <>
      {quickCpuMemoryDiskData.loader ? (
        <QuickSectionSkeleton />
      ) : (
        <Box sx={{ padding: '16px 0 16px 16px', overflowX: 'auto' }}>
          <Grid
            className="quickCPUGridContainer"
            container
            spacing={2}
            sx={{
              // boxSizing: 'border-box',
              flexWrap: 'nowrap',
              maxWidth: '100%',
              justifyContent: 'center', // Center the grid
            }}
          >
            {renderGaugeMetrics()}
            <div className="nonGaugeGridContainer">
              {renderNonGaugeMetrics()}
            </div>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default memo(QuickCPUMemoryDisk);
