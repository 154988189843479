// src/hooks/useSocket.ts
import { useEffect, useState } from 'react';
import { initializeSocket } from '../socket-init';
import { Socket } from 'socket.io-client';

// Generic type for the data received from the event
type EventCallback<T> = (data: T) => void;

const useSocket = <T>(
  event: string,
  callback: EventCallback<T>
): Socket | null => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const newSocket = initializeSocket();
    setSocket(newSocket);

    // Listen for the provided event
    if (newSocket && event && callback) {
      newSocket.on(event, callback);
    }

    // Cleanup: remove the listener on unmount
    return () => {
      if (newSocket && event) {
        newSocket.off(event, callback);
      }
    };
  }, [event, callback]);

  return socket;
};

export default useSocket;
