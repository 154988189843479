import { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';

const useECharts = (option: echarts.EChartsOption) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [chartInstance, setChartInstance] = useState<echarts.ECharts | null>(
    null
  );

  useEffect(() => {
    let instance: echarts.ECharts | null = null;

    if (chartRef.current) {
      // Use existing instance or create a new one
      instance = chartInstance || echarts.init(chartRef.current);
      setChartInstance(instance);
      instance.setOption(option as any);
    }

    const handleResize = () => {
      instance?.resize();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      // Don't dispose here if we're saving the instance
    };
  }, [option, chartInstance]);

  return { chartRef, chartInstance };
};

export default useECharts;
