import React, { ReactNode, Fragment, useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  Tooltip,
  Tabs,
  Tab,
} from '@material-ui/core';
import './ScheduleVM.css';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';

import { rightSizingService } from '../../services';
import '../RightSizing/RightSizing.css';
import { useHistory } from 'react-router';
import { ScheduleVMModal, ResetVMModal } from '../../components';
import { listener } from '../../Socket';

import { useSelector } from 'react-redux';
import { userState, accountState } from '../../store/index';
import {
  CLOUD_PROVIDER_SERVICES,
  PERMISSION_KEY,
  PERMISSION_LEVELS,
} from '../../utils/constants';
import ProtectedComponent from '../../components/ProtectedComponent/ProtectedComponent';
import { handleError } from '../../utils/config';

import { ALL_VALUE } from '../Invoice/config';
import { SelectedAccount } from '../../components/SelectAccount/SelectAccount';
import { formatDate } from '../../components/UrlMonitoring/config';
import Loading from '../../components/Loading/Loading';

const storageKeys = {
  RIGHT_SIZING_ROWS_PER_PAGE: 'Right_Sizing_ROWS_PER_PAGE',
  PREV_GEN_ROWS_PER_PAGE: 'PREV_GEN_ROWS_PER_PAGE,',
  RIGHT_SIZING_PAGE_NO: 'RIGHT_SIZING_PAGE_NO',
  PREV_GEN_PAGE_NO: 'PREV_GEN_PAGE_NO',
  RIGHTSIZING_SELECTED_SUBSCRIPTION: 'RIGHTSIZING_SELECTED_SUBSCRIPTION',
};

const getRightSizingRowsPerPage = () => {
  const rowsPerPage =
    localStorage.getItem(storageKeys.RIGHT_SIZING_ROWS_PER_PAGE) || 10;
  return +rowsPerPage;
};

const _setRightSizingRowsPerPage = (value: string) => {
  localStorage.setItem(storageKeys.RIGHT_SIZING_ROWS_PER_PAGE, value);
};

const getRightSizingPageNo = () => {
  const pageNo = localStorage.getItem(storageKeys.RIGHT_SIZING_PAGE_NO) || 0;
  return +pageNo;
};

const setRightSizingPageNo = (value: string) => {
  localStorage.setItem(storageKeys.RIGHT_SIZING_PAGE_NO, value);
};

export const getSelectedSubscription = (): string => {
  return (
    localStorage.getItem(storageKeys.RIGHTSIZING_SELECTED_SUBSCRIPTION) || 'all'
  );
};

export const setSelectedSubscription = (value: string): void => {
  localStorage.setItem(storageKeys.RIGHTSIZING_SELECTED_SUBSCRIPTION, value);
};

interface notificationState {
  children?: ReactNode;
  nState?: any;
}

interface ScheduleVMProps {
  Count: number;
  CurrencyCode: string;
  CurrentMonthlyCost: number;
  EndBufferTime: string;
  EndTime: string;
  Id: number;
  IsActive: number;
  IsIgnored: number;
  IsScheduled: number;
  Platform: string;
  PossibleMonthlySavings: number;
  ResourceGroup: string;
  ScheduleType: string;
  StartBufferTime: string;
  StartTime: string;
  Status: string;
  SubscriptionId: string;
  SubscriptionName: string;
  Unit: string;
  VMAvgDiskRead: number;
  VMAvgDiskWrite: number;
  VMAvgNetworkIn: number;
  VMAvgNetworkOut: number;
  VMAvgUtilization: number;
  VMIdleEndTime: string;
  VMIdleStartTime: string;
  VMName: string;
  AccountId: string;
  InstanceName: string;
  InstanceId: string;
  createdAt: string;
}

export const ScheduleVM: React.FC<notificationState> = (props) => {
  const { nState } = props;
  const history = useHistory();
  const [loader, setLoader] = React.useState(false);

  const user = useSelector(userState).userData;
  const selectedAccountInfo = useSelector(accountState);
  const selectedAccount = selectedAccountInfo.selectedAccount;
  const cloudProvider = selectedAccountInfo.selectedAccount?.CloudProvider;
  const isAccountSelected = selectedAccountInfo.isAccountSelected;
  const selectedCurrency: string = selectedAccountInfo.defaultCurrency;
  const isAccountActive = selectedAccount?.Credentials;
  const actionPermission = user?.Role?.permissions;

  /*-----Redirect to 403 page---------- */
  // const [pageLoad, setPagDone] = React.useState(false);

  const schedulePermission = actionPermission?.find(
    (obj: any) => obj.Entity.Key === PERMISSION_KEY.Scheduling
  );

  const [isDatePickerVisable, setIsDatePickerVisible] = useState(false);
  const [isTableLoaded, setIsTableLoaded] = useState(false);

  const [scheduleVMData, setScheduleVMData] = React.useState<
    ScheduleVMProps[] | any[]
  >([]);

  const [ignoreVMData, setIgnoreVMData] = React.useState<
    ScheduleVMProps[] | any[]
  >([]);
  const [scheduledVMData, setScheduledVMData] = React.useState<
    ScheduleVMProps[] | any[]
  >([]);
  const [rightSizingPage, setRightSizingPage] = React.useState(
    getRightSizingPageNo
  );
  const [rightSizingRowsPerPage, setRightSizingRowsPerPage] = React.useState(
    getRightSizingRowsPerPage
  );

  const [networkError, setNetworkError] = React.useState('');

  const [value, setValue] = React.useState(0);

  const [openScheduleVMModal, setScheduleVMModal] = React.useState(false);
  const [openCustomScheduleVMModal, setCustomScheduleVMModal] = React.useState(
    false
  );
  const [scheduleVM, setScheduleVM] = React.useState({
    VMName: '',
    InstanceName: '',
    VMIdleEndTime: '',
    VMIdleStartTime: '',
    Id: 0,
  });
  const [customSchedule, setCustomSchedule] = React.useState([]);
  const [vmData, SetVmData] = React.useState({
    Id: 0,
    IsIgnored: 0,
    ResourceGroup: '',
    ResourceName: '',
    SubscriptionId: '',
    InstanceName: '',
    InstanceId: '',
    AccountId: '',
    ScheduleType: '',
  });
  const [openResetVMModal, setResetVMModal] = React.useState(false);
  const [type, setType] = React.useState(0);
  const [selectedAccountId, setSelectedAccountId] = useState(
    selectedAccountInfo.selectedAccountId
  );
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(
    selectedAccountInfo.selectedSubscriptionId
  );
  const [date, setDate] = useState<any>(moment());
  const [isFocused, setIsFocused] = useState<any>(null);

  const handleRightSizingChangePage = (event: unknown, newPage: number) => {
    setRightSizingPageNo(String(newPage));
    setRightSizingPage(newPage);
  };

  const handleRightSizingChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    _setRightSizingRowsPerPage(event.target.value);
    setRightSizingRowsPerPage(+event.target.value);
    setRightSizingPageNo(String(0));
    setRightSizingPage(0);
  };

  const getScheduleVMDetails = async () => {
    try {
      setLoader(true);
      const params = {
        subscriptionId: selectedSubscriptionId,
        accountId: selectedAccountId,
        cloudProvider,
        ...(selectedCurrency !== 'USD' && { selectedCurrency }),
      };
      const rows = await rightSizingService.getScheduleVMDetails(params);
      if (rows) {
        setScheduleVMData(rows);
        setIsTableLoaded(true);
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
      setIsTableLoaded(false);
    }
  };

  const getIgnoredVMDetails = async () => {
    try {
      setLoader(true);
      const params = {
        subscriptionId: selectedSubscriptionId,
        accountId: selectedAccountId,
        cloudProvider,
        ...(selectedCurrency !== 'USD' && { selectedCurrency }),
      };
      const rows = await rightSizingService.getIgnoredVMDetails(params);
      if (rows) {
        setIgnoreVMData(rows);
        setIsTableLoaded(true);
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
      setIsTableLoaded(false);
    }
  };

  const getScheduledVMDetails = async () => {
    try {
      setLoader(true);
      const params = {
        subscriptionId: selectedSubscriptionId,
        accountId: selectedAccountId,
        cloudProvider,
        ...(selectedCurrency !== 'USD' && { selectedCurrency }),
      };
      const rows = await rightSizingService.getScheduledVMDetails(params);
      if (rows) {
        setScheduledVMData(rows);
        setIsTableLoaded(true);
      }
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
      setIsTableLoaded(false);
    }
  };

  const handleScheduleVM = (row: ScheduleVMProps) => {
    try {
      if (row) {
        setScheduleVM({
          VMName:
            selectedAccount?.CloudProvider.toUpperCase() ===
            CLOUD_PROVIDER_SERVICES.AZURE
              ? row.VMName
              : '',
          InstanceName:
            selectedAccount?.CloudProvider.toUpperCase() ===
            CLOUD_PROVIDER_SERVICES.AWS
              ? row.InstanceName
              : '',
          VMIdleEndTime: row.EndBufferTime,
          VMIdleStartTime: row.StartBufferTime,
          Id: row.Id,
        });

        setScheduleVMModal(true);
      }
    } catch (e: any) {
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  const handleCustomSchedule = async () => {
    try {
      const params = {
        subscriptionId: selectedSubscriptionId,
        accountId: selectedAccountId,
        cloudProvider,
      };
      const rows = await rightSizingService.getCustomScheduleVMList(params);
      if (rows) {
        setCustomSchedule(rows);
        setCustomScheduleVMModal(true);
      }
    } catch (e: any) {
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    }
  };

  const handleResetVM = (row: ScheduleVMProps, actiontype: number) => {
    if (row) {
      SetVmData({
        ResourceName:
          selectedAccount?.CloudProvider.toUpperCase() === 'AZURE'
            ? row.VMName
            : '',
        ResourceGroup:
          selectedAccount?.CloudProvider.toUpperCase() === 'AZURE'
            ? row.ResourceGroup
            : '',
        SubscriptionId:
          selectedAccount?.CloudProvider.toUpperCase() === 'AZURE'
            ? row.SubscriptionId
            : '',
        Id: row.Id,
        IsIgnored: row.IsIgnored,
        ScheduleType: row.ScheduleType,
        InstanceName:
          selectedAccount?.CloudProvider.toUpperCase() === 'AWS'
            ? row.InstanceName
            : '',
        InstanceId:
          selectedAccount?.CloudProvider.toUpperCase() === 'AWS'
            ? row.InstanceId
            : '',
        AccountId:
          selectedAccount?.CloudProvider.toUpperCase() === 'AWS'
            ? row.AccountId
            : '',
      });

      setType(actiontype);
      setResetVMModal(true);
    }
  };

  const closeCheckModal = (update?: any) => {
    setScheduleVMModal(false);
    setResetVMModal(false);
    setCustomScheduleVMModal(false);
    if (update) {
      getScheduleVMDetails();
      getScheduledVMDetails();
      getIgnoredVMDetails();
    }
  };

  const handleTabChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: number
  ) => {
    setValue(value);
  };

  const refreshSchedule = () => {
    getScheduleVMDetails();
    getIgnoredVMDetails();
    getScheduledVMDetails();
  };

  useEffect(() => {
    if (isAccountSelected && isAccountActive) {
      getScheduleVMDetails();
      getIgnoredVMDetails();
      getScheduledVMDetails();
    }
  }, [
    selectedAccountId,
    selectedSubscriptionId,
    nState,
    isAccountSelected,
    selectedAccount,
    isAccountActive,
    selectedCurrency,
  ]);

  // useEffect(() => {
  //   setLoader(true);
  //   setPagDone(false);
  //   setTimeout(() => {
  //     const name = userPlans?.includes(FEATURES.SCHEDULE_INSTANCES);
  //     if (name) {
  //       setPagDone(true);
  //       setLoader(false);
  //     }
  //   }, 1000);
  // }, [userPlans]);

  listener('VM Reset', () => {
    getScheduleVMDetails();
    getIgnoredVMDetails();
    getScheduledVMDetails();
  });

  listener('New Notification', () => {
    getScheduleVMDetails();
    getIgnoredVMDetails();
    getScheduledVMDetails();
  });

  if (networkError) {
    throw new Error(networkError);
  }

  const cancelMessage: any = {
    Once: 'Cancel',
    Daily: 'Cancel For A Day',
    Weekly: 'Cancel For A Week',
    BiWeekly: 'Cancel For A FortNight',
    Monthly: 'Cancel For A Month',
  };
  const resumeMessage: any = {
    Once: 'Resume',
    Daily: 'Resume For A Day',
    Weekly: 'Resume For A Week',
    BiWeekly: 'Resume For A FortNight',
    Monthly: 'Resume For A Month',
  };

  // account selection section
  const onAccountChange = (data: any) => {
    if (data.cloudType === CLOUD_PROVIDER_SERVICES.AWS)
      setSelectedAccountId(data.accountId);
    else if (data.cloudType === CLOUD_PROVIDER_SERVICES.AZURE) {
      if (data.subscriptionName) {
        setSelectedSubscriptionId(data.subscriptionId);
      } else if (data.accountId) {
        setSelectedAccountId(data.accountId);
        setSelectedSubscriptionId(ALL_VALUE);
      }
    }
  };

  const fetchPreviousSchedulingData = async (selectedDate: any) => {
    try {
      setLoader(true);
      const endDate: any = moment(selectedDate).add(1, 'days');
      const params = {
        accountId: selectedAccountId,
        subscriptionId: selectedSubscriptionId,
        cloudProvider,
        startDate: formatDate(selectedDate?._d),
        endDate: formatDate(endDate?._d),
      };

      const rows = await rightSizingService.getPrevoiusSchedulingDetails(
        params
      );

      setScheduleVMData(rows);
    } catch (e: any) {
      setNetworkError(e === 'Network Error' ? e : '');
      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };
  //Date Picker section

  const onDateChange = (selectedDate: any) => {
    if (selectedDate) {
      fetchPreviousSchedulingData(selectedDate);
      setDate(selectedDate);
    }
  };

  const currDate: any = moment();

  return (
    <Fragment>
      {/* {loader ? <LoadingScreen /> : ''} */}

      <div>
        <>
          <div className="rsTableHeader">
            <Grid item md={7} sm={7} xs={7}>
              <Tabs
                selectionFollowsFocus
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                aria-label="disabled tabs example"
                className="tabHeading"
              >
                <Tab label="Recommended" value={0} />
                <Tab label="Ignored" value={2} />
                <Tab label="Scheduled" value={3} />
              </Tabs>
            </Grid>

            <Grid item md={5} sm={5} xs={5} className="text-right CustomBtn">
              {selectedAccount ? (
                <Tooltip title="Refresh Schedule" arrow>
                  <button
                    className="btn btn-outline-secondary mr-10"
                    onClick={refreshSchedule}
                  >
                    <i className="bi bi-arrow-clockwise"></i>
                  </button>
                </Tooltip>
              ) : (
                ''
              )}
              <ProtectedComponent
                level={PERMISSION_LEVELS.WRITE}
                permission={schedulePermission}
              >
                <Tooltip
                  title={
                    isAccountSelected
                      ? isAccountActive
                        ? 'Custom Schedule'
                        : 'Selected Account is not Active'
                      : 'No Account has been selected'
                  }
                  arrow
                >
                  <span>
                    <button
                      className="btn btn-outline-primary mr-10"
                      onClick={() => handleCustomSchedule()}
                      disabled={!isAccountActive}
                    >
                      Custom
                    </button>
                  </span>
                </Tooltip>
              </ProtectedComponent>
            </Grid>

            <SelectedAccount onAccountChange={onAccountChange} />
          </div>

          {value === 0 ? (
            <Grid className="flex flex-justifySpaceBetween ">
              {' '}
              <span className="inventoryHeading font-22px fw-300">
                {formatDate(date?._d) === formatDate(currDate?._d)
                  ? null
                  : `Recommendations - ${formatDate(date?._d)}`}
              </span>
              {value === 0 ? (
                <>
                  <span>
                    {isDatePickerVisable ? (
                      <>
                        {formatDate(date?._d) !== formatDate(currDate?._d) ? (
                          <div
                            className="btn btn-primary mr-10"
                            onClick={() => {
                              getScheduleVMDetails();
                              setDate(moment());
                              setIsDatePickerVisible(false);
                            }}
                          >
                            View Today's Recommendations
                          </div>
                        ) : null}

                        <span className="w-15 mr-05  inventoryHeading font-15px fw-500">
                          Select Date:-{' '}
                        </span>
                        <span>
                          {' '}
                          <SingleDatePicker
                            numberOfMonths={1}
                            anchorDirection="right"
                            isDayBlocked={(day: any) =>
                              // new Date().getHours() < 12
                              //   ? day >= moment().add(1, 'days')
                              day >= moment().subtract(1, 'day')
                            }
                            isOutsideRange={() => false}
                            hideKeyboardShortcutsPanel={false}
                            date={date}
                            onDateChange={onDateChange}
                            focused={isFocused}
                            onFocusChange={(focusedInput: any) => {
                              setIsFocused(focusedInput.focused);
                            }}
                            displayFormat="YYYY/MM/DD"
                            id="your_unique_id"
                          />
                        </span>
                      </>
                    ) : (
                      <div
                        className="btn btn-primary mb-10"
                        onClick={() => setIsDatePickerVisible(true)}
                      >
                        View Previous Recommendations
                      </div>
                    )}
                  </span>
                </>
              ) : null}
            </Grid>
          ) : null}

          {selectedAccount && !selectedAccount.CloudProvider ? (
            <div className="text-center mt-40 noRecordsFound">
              No Account has been Selected.
            </div>
          ) : value === 0 && selectedAccount?.CloudProvider ? (
            <Grid item md={12} className="mb-50">
              <TableContainer className="accContainer">
                <Table
                  className="table adminTable adminPanelTable"
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead className="admintableHead">
                    <TableRow>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        {selectedAccount?.CloudProvider.toUpperCase() ===
                        'AZURE'
                          ? 'VM Name'
                          : selectedAccount?.CloudProvider.toUpperCase() ===
                            'AWS'
                          ? 'Instance Name'
                          : 'VM Name'}
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        Current Monthly Cost
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        Possible Monthly Savings
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        Platform
                      </TableCell>
                      {selectedAccount?.CloudProvider.toUpperCase() ===
                      'AZURE' ? (
                        <TableCell
                          className="adminTableheading"
                          component="th"
                          align="left"
                        >
                          Resource Group
                        </TableCell>
                      ) : null}
                      {selectedAccount?.CloudProvider.toUpperCase() ===
                      'AZURE' ? (
                        <TableCell
                          className="adminTableheading"
                          component="th"
                          align="left"
                        >
                          Subscription Name
                        </TableCell>
                      ) : null}
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                        width="80"
                      >
                        Avg Disk Read
                        <br />
                        <span className="fw-400 font-11px">(KB)</span>
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                        width="80"
                      >
                        Avg Disk Write
                        <br />
                        <span className="fw-400 font-11px">(KB)</span>
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                        width="90"
                      >
                        Avg
                        <br />
                        Network IN
                        <br />
                        <span className="fw-400 font-11px">(KB)</span>
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                        width="90"
                      >
                        Avg
                        <br />
                        Network OUT
                        <br />
                        <span className="fw-400 font-11px">(KB)</span>
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                        width="80"
                      >
                        Avg CPU Utilization
                        <br />
                        <span className="fw-400 font-11px">(%)</span>
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        Idle
                        <br />
                        Start Time
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        Idle
                        <br />
                        End Time
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                        width="50"
                      >
                        Status
                      </TableCell>
                      <ProtectedComponent
                        level={PERMISSION_LEVELS.WRITE}
                        permission={schedulePermission}
                      >
                        <TableCell
                          className="adminTableheading"
                          component="th"
                          align="right"
                        >
                          Actions
                        </TableCell>
                      </ProtectedComponent>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!isTableLoaded && loader ? (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <Loading
                            style={{
                              height: 'fit-content',
                              padding: '2rem',
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ) : scheduleVMData.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={20} align="center">
                          No records found
                        </TableCell>
                      </TableRow>
                    ) : (
                      scheduleVMData.map((sizingData) => (
                        <TableRow key={sizingData.Id}>
                          <TableCell scope="row">
                            {selectedAccount?.CloudProvider.toUpperCase() ===
                            'AZURE'
                              ? sizingData.VMName
                              : selectedAccount?.CloudProvider.toUpperCase() ===
                                'AWS'
                              ? sizingData.InstanceName
                              : sizingData.VMName}
                          </TableCell>
                          <TableCell align="left">
                            {sizingData.CurrencyCode}
                            {Number(sizingData.CurrentMonthlyCost).toFixed(2)}
                          </TableCell>
                          <TableCell align="left">
                            {sizingData.CurrencyCode}
                            {sizingData.Type === 1
                              ? `(${Number(sizingData.PossibleMonthlySavings)
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')})`
                              : Number(sizingData.PossibleMonthlySavings)
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </TableCell>
                          <TableCell align="left">
                            {sizingData.Platform}
                          </TableCell>
                          {selectedAccount?.CloudProvider.toUpperCase() ===
                          'AZURE' ? (
                            <TableCell align="left">
                              {sizingData.ResourceGroup}
                            </TableCell>
                          ) : null}
                          {selectedAccount?.CloudProvider.toUpperCase() ===
                          'AZURE' ? (
                            <TableCell align="left">
                              {sizingData.SubscriptionName}
                            </TableCell>
                          ) : null}
                          {selectedAccount?.CloudProvider.toUpperCase() ===
                          'AZURE' ? (
                            <TableCell align="left">
                              {Number(sizingData.VMAvgDiskRead)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </TableCell>
                          ) : (
                            <TableCell align="left">
                              {Number(sizingData.InstanceAvgDiskRead)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </TableCell>
                          )}
                          {selectedAccount?.CloudProvider.toUpperCase() ===
                          'AZURE' ? (
                            <TableCell align="left">
                              {Number(sizingData.VMAvgDiskWrite)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </TableCell>
                          ) : (
                            <TableCell align="left">
                              {Number(sizingData.InstanceAvgDiskWrite)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </TableCell>
                          )}
                          {selectedAccount?.CloudProvider.toUpperCase() ===
                          'AZURE' ? (
                            <TableCell align="left">
                              {Number(sizingData.VMAvgNetworkIn)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </TableCell>
                          ) : (
                            <TableCell align="left">
                              {Number(sizingData.InstanceAvgNetworkIn)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </TableCell>
                          )}
                          {selectedAccount?.CloudProvider.toUpperCase() ===
                          'AZURE' ? (
                            <TableCell align="left">
                              {Number(
                                sizingData.VMAvgNetworkOut.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ','
                                )
                              ).toFixed(2)}
                            </TableCell>
                          ) : (
                            <TableCell align="left">
                              {Number(sizingData.InstanceAvgNetworkOut)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </TableCell>
                          )}
                          {selectedAccount?.CloudProvider.toUpperCase() ===
                          'AZURE' ? (
                            <TableCell align="left">
                              {Number(sizingData.VMAvgUtilization).toFixed(2)}
                            </TableCell>
                          ) : (
                            <TableCell align="left">
                              {Number(
                                sizingData.InstanceAvgUtilization
                              ).toFixed(2)}
                            </TableCell>
                          )}
                          <TableCell align="left">
                            {moment
                              .utc(
                                sizingData.StartBufferTime.split('.')[0]
                                  .split('T')
                                  .join(' ')
                              )
                              .local()
                              .format('hh:mm A')}
                          </TableCell>
                          <TableCell align="left">
                            {moment
                              .utc(
                                sizingData.EndBufferTime.split('.')[0]
                                  .split('T')
                                  .join(' ')
                              )
                              .local()
                              .format('hh:mm A')}
                          </TableCell>
                          <TableCell align="left" width="50">
                            <span
                              className={
                                sizingData.Status == 'Running'
                                  ? 'vmRunning'
                                  : sizingData.Status == 'Stopped'
                                  ? 'vmStopped'
                                  : ''
                              }
                            >
                              {sizingData.Status ? sizingData.Status : '-'}
                            </span>
                          </TableCell>
                          <ProtectedComponent
                            level={PERMISSION_LEVELS.WRITE}
                            permission={schedulePermission}
                          >
                            <TableCell align="right">
                              <div className="d-flex float-r">
                                <Tooltip
                                  title={`Schedule ${
                                    selectedAccount?.CloudProvider.toUpperCase() ===
                                    'AZURE'
                                      ? 'VM'
                                      : 'Instance'
                                  }`}
                                  arrow
                                >
                                  <span
                                    className="mr-10 scheduleBtn"
                                    onClick={() => handleScheduleVM(sizingData)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      fill="#155724"
                                      className="bi bi-stopwatch"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z" />
                                      <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z" />
                                    </svg>
                                  </span>
                                </Tooltip>
                                <Tooltip title="Custom Schedule" arrow>
                                  <span
                                    className="mr-10 scheduleBtn d-none"
                                    onClick={() => handleCustomSchedule()}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      fill="currentColor"
                                      className="bi bi-calendar-week"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                                      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                    </svg>
                                  </span>
                                </Tooltip>
                                <Tooltip
                                  title={`Ignore ${
                                    selectedAccount?.CloudProvider.toUpperCase() ===
                                    'AZURE'
                                      ? 'VM'
                                      : 'Instance'
                                  }`}
                                  arrow
                                >
                                  <span
                                    className="ignoreBtn"
                                    onClick={() => handleResetVM(sizingData, 1)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      fill="#DE0D26"
                                      // fill={
                                      //   scheduledVMList.includes(
                                      //     sizingData.InstanceId
                                      //   )
                                      //     ? '#50504f'
                                      //     : '#DE0D26'
                                      // }
                                      className="bi bi-slash-circle"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                      <path d="M11.354 4.646a.5.5 0 0 0-.708 0l-6 6a.5.5 0 0 0 .708.708l6-6a.5.5 0 0 0 0-.708z" />
                                    </svg>
                                  </span>
                                </Tooltip>
                              </div>
                            </TableCell>
                          </ProtectedComponent>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {scheduleVMData && scheduleVMData.length <= 0 ? null : (
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  component="div"
                  count={scheduleVMData.length}
                  rowsPerPage={rightSizingRowsPerPage}
                  page={rightSizingPage}
                  onChangePage={handleRightSizingChangePage}
                  onChangeRowsPerPage={handleRightSizingChangeRowsPerPage}
                  className="tablePaginaion"
                />
              )}
            </Grid>
          ) : value === 2 ? (
            <Grid item md={12} className="mb-50">
              <TableContainer className="accContainer">
                <Table
                  className="table adminTable adminPanelTable"
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead className="admintableHead">
                    <TableRow>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        {selectedAccount?.CloudProvider.toUpperCase() ===
                        'AZURE'
                          ? 'VM Name'
                          : selectedAccount?.CloudProvider.toUpperCase() ===
                            'AWS'
                          ? 'Instance Name'
                          : 'VM Name'}
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        Current Monthly Cost
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        Possible Monthly Savings
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        Platform
                      </TableCell>
                      {selectedAccount?.CloudProvider.toUpperCase() ===
                      'AZURE' ? (
                        <TableCell
                          className="adminTableheading"
                          component="th"
                          align="left"
                        >
                          Resource Group
                        </TableCell>
                      ) : null}
                      {selectedAccount?.CloudProvider.toUpperCase() ===
                      'AZURE' ? (
                        <TableCell
                          className="adminTableheading"
                          component="th"
                          align="left"
                        >
                          Subscription Name
                        </TableCell>
                      ) : null}
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        Avg Disk Read
                        <br />
                        <span className="fw-400 font-11px">(KB)</span>
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        Avg Disk Write
                        <br />
                        <span className="fw-400 font-11px">(KB)</span>
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        Avg Network IN
                        <br />
                        <span className="fw-400 font-11px">(KB)</span>
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        Avg Network OUT
                        <br />
                        <span className="fw-400 font-11px">(KB)</span>
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        Avg CPU Utilization
                        <br />
                        <span className="fw-400 font-11px">(%)</span>
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        Idle StartTime
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        Idle EndTime
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        Status
                      </TableCell>
                      <ProtectedComponent
                        level={PERMISSION_LEVELS.WRITE}
                        permission={schedulePermission}
                      >
                        <TableCell
                          className="adminTableheading"
                          component="th"
                          align="right"
                        >
                          Actions
                        </TableCell>
                      </ProtectedComponent>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!isTableLoaded && loader ? (
                      <TableRow>
                        <TableCell colSpan={20} align="center">
                          Loading...
                        </TableCell>
                      </TableRow>
                    ) : ignoreVMData.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={20} align="center">
                          No records found
                        </TableCell>
                      </TableRow>
                    ) : (
                      ignoreVMData.map((ignoredData) => (
                        <TableRow key={ignoredData.Id}>
                          <TableCell scope="row">
                            {selectedAccount?.CloudProvider.toUpperCase() ===
                            'AZURE'
                              ? ignoredData.VMName
                              : selectedAccount?.CloudProvider.toUpperCase() ===
                                'AWS'
                              ? ignoredData.InstanceName
                              : ignoredData.VMName}
                          </TableCell>
                          <TableCell align="left">
                            {ignoredData.CurrencyCode}
                            {ignoredData.CurrentMonthlyCost.toFixed(2).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ','
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {ignoredData.CurrencyCode}
                            {ignoredData.Type === 1
                              ? `(${ignoredData.PossibleMonthlySavings.toFixed(
                                  2
                                ).replace(/\B(?=(\d{3})+(?!\d))/g, ',')})`
                              : ignoredData.PossibleMonthlySavings.toFixed(
                                  2
                                ).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </TableCell>
                          <TableCell align="left">
                            {ignoredData.Platform}
                          </TableCell>
                          {selectedAccount?.CloudProvider.toUpperCase() ===
                          'AZURE' ? (
                            <TableCell align="left">
                              {ignoredData.ResourceGroup}
                            </TableCell>
                          ) : null}
                          {selectedAccount?.CloudProvider.toUpperCase() ===
                          'AZURE' ? (
                            <TableCell align="left">
                              {ignoredData.SubscriptionName}
                            </TableCell>
                          ) : null}
                          {selectedAccount?.CloudProvider.toUpperCase() ===
                          'AZURE' ? (
                            <TableCell align="left">
                              {ignoredData.VMAvgDiskRead.toFixed(2).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ','
                              )}
                            </TableCell>
                          ) : (
                            <TableCell align="left">
                              {ignoredData.InstanceAvgDiskRead.toFixed(
                                2
                              ).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </TableCell>
                          )}
                          {selectedAccount?.CloudProvider.toUpperCase() ===
                          'AZURE' ? (
                            <TableCell align="left">
                              {ignoredData.VMAvgDiskWrite.toFixed(2).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ','
                              )}
                            </TableCell>
                          ) : (
                            <TableCell align="left">
                              {ignoredData.InstanceAvgDiskWrite.toFixed(
                                2
                              ).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </TableCell>
                          )}
                          {selectedAccount?.CloudProvider.toUpperCase() ===
                          'AZURE' ? (
                            <TableCell align="left">
                              {ignoredData.VMAvgNetworkIn.toFixed(2).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ','
                              )}
                            </TableCell>
                          ) : (
                            <TableCell align="left">
                              {ignoredData.InstanceAvgNetworkIn.toFixed(
                                2
                              ).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </TableCell>
                          )}
                          {selectedAccount?.CloudProvider.toUpperCase() ===
                          'AZURE' ? (
                            <TableCell align="left">
                              {ignoredData.VMAvgNetworkOut.toFixed(2).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ','
                              )}
                            </TableCell>
                          ) : (
                            <TableCell align="left">
                              {ignoredData.InstanceAvgNetworkOut.toFixed(
                                2
                              ).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </TableCell>
                          )}
                          {selectedAccount?.CloudProvider.toUpperCase() ===
                          'AZURE' ? (
                            <TableCell align="left">
                              {ignoredData.VMAvgUtilization}
                            </TableCell>
                          ) : (
                            <TableCell align="left">
                              {ignoredData.InstanceAvgUtilization}
                            </TableCell>
                          )}
                          <TableCell align="left">
                            {moment
                              .utc(
                                ignoredData.StartBufferTime.split('.')[0]
                                  .split('T')
                                  .join(' ')
                              )
                              .local()
                              .format('hh:mm A')}
                            {/* {moment.utc(ignoredData.StartTime.split('.')[0].split('T').join(' ')).local().format('YYYY-MM-DD HH:mm').split(' ')[1]} */}
                          </TableCell>
                          <TableCell align="left">
                            {moment
                              .utc(
                                ignoredData.EndBufferTime.split('.')[0]
                                  .split('T')
                                  .join(' ')
                              )
                              .local()
                              .format('hh:mm A')}
                            {/* {moment.utc(ignoredData.EndTime.split('.')[0].split('T').join(' ')).local().format('YYYY-MM-DD HH:mm').split(' ')[1]} */}
                          </TableCell>
                          <TableCell align="left">
                            <span
                              className={
                                ignoredData.Status == 'Running'
                                  ? 'vmRunning'
                                  : ignoredData.Status == 'Stopped'
                                  ? 'vmStopped'
                                  : ''
                              }
                            >
                              {ignoredData.Status ? ignoredData.Status : '-'}
                            </span>
                          </TableCell>
                          <ProtectedComponent
                            level={PERMISSION_LEVELS.WRITE}
                            permission={schedulePermission}
                          >
                            <TableCell align="right">
                              <div className="d-flex float-r">
                                <Tooltip title="Move To Recommendation" arrow>
                                  <span
                                    className="ignoreBtn"
                                    onClick={() =>
                                      handleResetVM(ignoredData, 4)
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="19"
                                      height="19"
                                      fill="#155724"
                                      className="bi bi-card-checklist"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                                      <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
                                    </svg>
                                  </span>
                                </Tooltip>
                              </div>
                            </TableCell>
                          </ProtectedComponent>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {ignoreVMData && ignoreVMData.length <= 0 ? null : (
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  component="div"
                  count={ignoreVMData.length}
                  rowsPerPage={rightSizingRowsPerPage}
                  page={rightSizingPage}
                  onChangePage={handleRightSizingChangePage}
                  onChangeRowsPerPage={handleRightSizingChangeRowsPerPage}
                  className="tablePaginaion"
                />
              )}
            </Grid>
          ) : value === 3 && selectedAccount?.CloudProvider ? (
            <Grid item md={12} className="mb-50">
              <TableContainer className="accContainer">
                <Table
                  className="table adminTable adminPanelTable"
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead className="admintableHead">
                    <TableRow>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        {selectedAccount?.CloudProvider.toUpperCase() ===
                        'AZURE'
                          ? 'VM Name'
                          : selectedAccount?.CloudProvider.toUpperCase() ===
                            'AWS'
                          ? 'Instance Name'
                          : 'VM Name'}
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        Monthly Savings
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        Platform
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        {selectedAccount?.CloudProvider.toUpperCase() ===
                        'AZURE'
                          ? 'Resource Group'
                          : selectedAccount?.CloudProvider.toUpperCase() ===
                            'AWS'
                          ? 'Instance Id'
                          : 'Resource Group'}
                      </TableCell>
                      {selectedAccount?.CloudProvider.toUpperCase() ===
                      'AZURE' ? (
                        <TableCell
                          className="adminTableheading"
                          component="th"
                          align="left"
                        >
                          Subscription Name
                        </TableCell>
                      ) : null}
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        Scheduled
                        <br />
                        Start Time
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        Scheduled
                        <br />
                        End Time
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        Type
                      </TableCell>
                      <TableCell
                        className="adminTableheading"
                        component="th"
                        align="left"
                      >
                        Status
                      </TableCell>
                      <ProtectedComponent
                        level={PERMISSION_LEVELS.WRITE}
                        permission={schedulePermission}
                      >
                        <TableCell
                          className="adminTableheading"
                          component="th"
                          align="right"
                        >
                          Actions
                        </TableCell>
                      </ProtectedComponent>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!isTableLoaded && loader ? (
                      <TableRow>
                        <TableCell colSpan={20} align="center">
                          Loading...
                        </TableCell>
                      </TableRow>
                    ) : scheduledVMData.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={20} align="center">
                          No records found
                        </TableCell>
                      </TableRow>
                    ) : (
                      scheduledVMData.map((scheduledData) => (
                        <TableRow key={scheduledData.Id}>
                          <TableCell scope="row">
                            {selectedAccount?.CloudProvider.toUpperCase() ===
                            'AZURE'
                              ? scheduledData.VMName
                              : selectedAccount?.CloudProvider.toUpperCase() ===
                                'AWS'
                              ? scheduledData.InstanceName
                              : '-'}
                          </TableCell>
                          <TableCell align="left">
                            {scheduledData.CurrencyCode}
                            {scheduledData.Type === 1
                              ? `(${scheduledData.Savings?.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ','
                                )})`
                              : scheduledData.Savings?.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ','
                                )}
                          </TableCell>
                          <TableCell align="left">
                            {scheduledData.Platform}
                          </TableCell>
                          <TableCell scope="row">
                            {selectedAccount?.CloudProvider.toUpperCase() ===
                            'AZURE'
                              ? scheduledData.ResourceGroup
                              : selectedAccount?.CloudProvider.toUpperCase() ===
                                'AWS'
                              ? scheduledData.InstanceId
                              : ''}
                          </TableCell>
                          {selectedAccount?.CloudProvider.toUpperCase() ===
                          'AZURE' ? (
                            <TableCell align="left">
                              {scheduledData.SubscriptionName}
                            </TableCell>
                          ) : null}
                          <TableCell align="left">
                            {moment
                              .utc(
                                scheduledData.ScheduledStartTime.split('.')[0]
                                  .split('T')
                                  .join(' ')
                              )
                              .local()
                              .format('YYYY-MM-DD hh:mm A')}
                          </TableCell>
                          <TableCell align="left">
                            {moment
                              .utc(
                                scheduledData.ScheduledEndTime.split('.')[0]
                                  .split('T')
                                  .join(' ')
                              )
                              .local()
                              .format('YYYY-MM-DD hh:mm A')}
                          </TableCell>
                          <TableCell align="left">
                            {scheduledData.Type}
                          </TableCell>
                          <TableCell align="left">
                            <span
                              className={
                                scheduledData.Status == 'Running'
                                  ? 'vmRunning'
                                  : scheduledData.Status == 'Stopped'
                                  ? 'vmStopped'
                                  : ''
                              }
                            >
                              {scheduledData.Status
                                ? scheduledData.Status
                                : '-'}
                            </span>
                          </TableCell>
                          <ProtectedComponent
                            level={PERMISSION_LEVELS.WRITE}
                            permission={schedulePermission}
                          >
                            <TableCell align="right">
                              <div className="d-flex float-r">
                                <Tooltip
                                  title={cancelMessage[scheduledData?.Type]}
                                  arrow
                                >
                                  <span
                                    className={
                                      'mr-10 ignoreBtn ' +
                                      (scheduledData.Status == 'Running'
                                        ? ''
                                        : 'disabled')
                                    }
                                    onClick={() =>
                                      scheduledData.Status == 'Running' &&
                                      handleResetVM(scheduledData, 3)
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      fill="#f7a300"
                                      className="bi bi-calendar-minus"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M5.5 9.5A.5.5 0 0 1 6 9h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5z" />
                                      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                    </svg>
                                  </span>
                                </Tooltip>
                                <Tooltip
                                  title={resumeMessage[scheduledData?.Type]}
                                  arrow
                                >
                                  <span
                                    className={
                                      'mt-12 mr-10 ignoreBtn ' +
                                      (scheduledData.Status == 'Stopped'
                                        ? ''
                                        : 'disabled')
                                    }
                                    onClick={() =>
                                      scheduledData.Status == 'Stopped' &&
                                      handleResetVM(scheduledData, 2)
                                    }
                                    // onClick={() => handleResetVM(scheduledData, 2)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      fill="#856404"
                                      className="bi bi-file-play"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M6 10.117V5.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43z" />
                                      <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                    </svg>
                                  </span>
                                </Tooltip>
                                <Tooltip
                                  title={
                                    !scheduledData?.IsResetStarted
                                      ? 'Cancel Schedule'
                                      : ''
                                  }
                                  arrow
                                >
                                  <span
                                    className={
                                      'position-relative cancelScheduleBtn scheduleBtn' +
                                      (scheduledData?.IsResetStarted
                                        ? ' disabled'
                                        : '')
                                    }
                                    onClick={() =>
                                      !scheduledData?.IsResetStarted &&
                                      handleResetVM(scheduledData, 0)
                                    }
                                  >
                                    <i className="bi bi-arrow-clockwise"></i>
                                    <span className="cIcon">C</span>
                                  </span>
                                </Tooltip>
                              </div>
                            </TableCell>
                          </ProtectedComponent>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {scheduledVMData && scheduledVMData.length <= 0 ? null : (
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  component="div"
                  count={scheduledVMData.length}
                  rowsPerPage={rightSizingRowsPerPage}
                  page={rightSizingPage}
                  onChangePage={handleRightSizingChangePage}
                  onChangeRowsPerPage={handleRightSizingChangeRowsPerPage}
                  className="tablePaginaion"
                />
              )}
            </Grid>
          ) : null}
        </>
      </div>

      {openScheduleVMModal && (
        <ScheduleVMModal
          show={openScheduleVMModal}
          onClose={closeCheckModal}
          scheduleVM={scheduleVM}
          customSchedule={customSchedule}
          customModal={false}
          user={user}
        />
      )}

      {openCustomScheduleVMModal && (
        <ScheduleVMModal
          show={openCustomScheduleVMModal}
          onClose={closeCheckModal}
          scheduleVM={scheduleVM}
          customSchedule={customSchedule}
          customModal={true}
          user={user}
        />
      )}

      {openResetVMModal && (
        <ResetVMModal
          show={openResetVMModal}
          onClose={closeCheckModal}
          vmData={vmData}
          type={type}
        />
      )}
    </Fragment>
  );
};

export default ScheduleVM;
