import React, { useState } from 'react';
import Modal from 'react-modal';
import LoadingScreen from '../../LoadingScreen/LoadingScreen';
import {
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
interface SlackCredsModalInterface {
  onUpdate: { (data: any): void };
  show: boolean;
  onClose?: { (): void };
  editedData: any;
}

const EditSlackCreds: React.FC<SlackCredsModalInterface> = ({
  onClose,
  show,
  onUpdate,
  editedData,
}) => {
  const [loader, setLoader] = useState(false);
  const [editedSlackCreds, setEditedSlackCreds] = useState(editedData);

  const handleFieldChange = (e: any) => {
    setEditedSlackCreds((prevValue: any) => ({
      ...prevValue,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      {loader && <LoadingScreen />}
      <Modal
        isOpen={show}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={true}
        bodyOpenClassName="no-scroll"
        ariaHideApp={false}
        style={{
          overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 },
        }}
        className="fixModal"
      >
        <Grid container spacing={0}>
          <Grid item md={12} sm={12} xs={12} className="modalHeader">
            <div>
              <span className="modalTitle">Update your slack creds</span>
              <span className="modalClose" onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-x-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </span>
            </div>
          </Grid>
          <div className="w-100">
            <div>
              <form
                noValidate
                autoComplete="off"
                className="d-block w-100 bgColor"
              >
                <Grid item md={12} className="modalBody">
                  <Grid
                    container
                    alignItems="flex-end"
                    className="positionRelative bgColor "
                    spacing={3}
                  >
                    <Grid item className="w-100">
                      <FormControl
                        variant="outlined"
                        className="slackInputBox  "
                      >
                        <InputLabel className="inputLegend">
                          ENTER CHANNEL NAME
                        </InputLabel>
                        <OutlinedInput
                          name="channel_name"
                          required
                          value={editedSlackCreds.channel_name}
                          className="outlineInputArea"
                          onChange={(e) => handleFieldChange(e)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item className="w-100">
                      <FormControl
                        variant="outlined"
                        className="slackInputBox  "
                      >
                        <InputLabel className="inputLegend">
                          ENTER WEBHOOK URL
                        </InputLabel>
                        <OutlinedInput
                          name="webhook_url"
                          readOnly
                          required
                          className="outlineInputArea"
                          defaultValue={editedData.webhook_url}
                        />{' '}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>

        <div className="mt-10 modalFooter">
          <button
            type="button"
            className="btn btn-primary ml-10 "
            onClick={() => onUpdate(editedSlackCreds)}
          >
            Save
          </button>
        </div>
      </Modal>
    </>
  );
};

export default EditSlackCreds;
