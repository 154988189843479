import BarChartLoader from './BarChartLoader';

const GraphLoader = (props: any) => {
  const { isLoading } = props;
  return (
    <div
      style={{
        // position: 'relative',
        height: '58vh',
        width: '91%',
        margin: '5rem auto',
      }}
    >
      {/* Y-axis line */}
      <div
        style={{
          // position: 'absolute',
          top: 0,
          left: '0%',
          width: '1px',
          height: '100%',
          background: 'black',
          transform: 'translateX(-50%)',
        }}
      />

      {/* X-axis line */}
      <div
        style={{
          // position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '1px',
          background: 'black',
        }}
      />

      {/* Centered content */}
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {/* Your content */}
        {/* ... */}
        {isLoading ? <BarChartLoader /> : 'No Data Found'}
      </div>
    </div>
  );
};

export default GraphLoader;
