import React, { Fragment } from 'react';
import '../CostManagement/CostManagement.css';

import { useHistory } from 'react-router-dom';
import checkupImg from '../../../assets/images/checkup.png';
import costManagement1 from '../../../assets/images/cfx-cm-1.png';
import costManagement2 from '../../../assets/images/cfx-cm-2.png';
import bestPracticeImg from '../../../assets/images/cfx-cm-best-practice.svg';
import cloudSpendImg from '../../../assets/images/cfx-cm-spend.svg';
import costAllocationImg from '../../../assets/images/cfx-cm-cost-allocation.png';
import billingControlImg from '../../../assets/images/cfx-cm-receipt.svg';
import reportImg from '../../../assets/images/cfx-cm-report.svg';
import invoiceBillingImg from '../../../assets/images/cfx-cm-invoice-billing.svg';
import riu1 from '../../../assets/images/cfx-riu-1.png';
import riu2 from '../../../assets/images/cfx-riu-2.png';
import riu3 from '../../../assets/images/cfx-riu-3.png';
import inventoryTrackingIcon from '../../../assets/images/dns.svg';
import utilIcon from '../../../assets/images/util.svg';
import bestPracticeIcon from '../../../assets/images/bestPractice.svg';
import identifyIcon from '../../../assets/images/identify.svg';
import actionableInsightIcon from '../../../assets/images/actionableInsight.svg';
import transformIcon from '../../../assets/images/transform.svg';
import SupportHeader from '../../../components/Header/SupportHeader';
import { Footer } from '../../../components';
import { RoutesFun } from '../../../schemas';

export const ResourceInventoryUtilization: React.FC = () => {
  const history = useHistory();
  const Routes = RoutesFun();
  return (
    <React.Fragment>
      <div className="outerContainer scrollContainer">
        <SupportHeader />
        <div className="supportBanner riuBanner">
          <div className="innerContainer12 riuBImage">
            <div className="riuBannerText">
              <h1>Resource Inventory and Utilization</h1>
              <p className="font-20px fw-300">
                Now consistently keep track of your resources while ensuring
                their accuracy and eliminating waste with CloudForestX.
              </p>
            </div>
          </div>
        </div>
        <div className="cf-row">
          <div className="innerContainer12 featureSection">
            <h4 className="sectionTitle">
              <span>Our Features</span>
            </h4>
            <div className="cardsCont">
              <div className="cards">
                <span>
                  <img src={inventoryTrackingIcon} width="28" />
                </span>
                <div className="ml-10">
                  <h5 className="fw-400">End-to-End Inventory Monitoring</h5>
                  <p>
                    Enjoy comprehensive governance in one consolidated solution
                    and effectively track assets, manage inventory, and pinpoint
                    changes.
                  </p>
                </div>
              </div>
              <div className="cards">
                <span>
                  <img src={utilIcon} width="28" />
                </span>
                <div className="ml-10">
                  <h5 className="fw-400">Utilization Analytics Solutions</h5>
                  <p className="mt-10">
                    Leverage CloudForestX to get next-level utilization
                    analytics, including CPU, memory, network, and usage
                    statistics for ElastiCache, Azure VMs, RDS, DynamoDB, EC2,
                    and more.
                  </p>
                </div>
              </div>
              <div className="cards">
                <span>
                  <img src={actionableInsightIcon} width="28" />
                </span>
                <div className="ml-10">
                  <h5 className="fw-400">Actionable Insights</h5>
                  <p className="mt-10">
                    CloudForestX provides a dashboard, summary, Heat Map views,
                    and more to help you get a comprehensive insight into
                    individual resources or your entire deployment.
                  </p>
                </div>
              </div>

              <div className="cards">
                <span>
                  <img src={transformIcon} width="28" />
                </span>
                <div className="ml-10">
                  <h5 className="fw-400">Gain Complete Clarity</h5>
                  <p className="mt-10">
                    From optimizing resources to delivering the exactly needed
                    insights for managing any cloud deployment, with
                    CloudForestX, get instant visibility and automation to enjoy
                    superior clarity.
                  </p>
                </div>
              </div>
              <div className="cards">
                <span>
                  <img src={identifyIcon} width="30" />
                </span>
                <div className="ml-10">
                  <h5 className="fw-400">
                    Identify Insufficiently Used Resources
                  </h5>
                  <p className="mt-10">
                    CloudForestX enables users to identify the right sizing and
                    rebalancing opportunities across all of the cloud services.
                  </p>
                </div>
              </div>
              <div className="cards">
                <span>
                  <img src={bestPracticeIcon} width="28" />
                </span>
                <div className="ml-10">
                  <h5 className="fw-400">Best Practices for Usage</h5>
                  <p className="mt-10">
                    Effortlessly identify idle and underutilized resources,
                    unused reserved resources, Reserved Instance mismatches, and
                    more. with hundreds of best practices.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cf-row">
          <div className="innerContainer12 cmSection">
            <div className="contentArea">
              <div>
                <h4>
                  CloudForestX Spot Management For Hassle-Free Spot Instances
                  Management and Optimization
                </h4>
                <p className="w-80">
                  Unwrap the benefits of Spot Instances with CloudForestX's spot
                  instance management and optimization to get excess computing
                  resources capacity at an efficient cost. Enjoy flawless
                  visibility and control over Spot Instances using the
                  CloudForestX platform and efficiently save up to 80% in the
                  Spot Market.
                </p>
                {/* <button className="btn btn-outline-pink mt-20 text-uppercase">
                  Learn More
                </button> */}
              </div>
            </div>
            <div className="contentImage">
              <img src={riu1} width="100%" />
            </div>
          </div>
        </div>

        <div className="cf-row">
          <div className="innerContainer12 osdSection">
            <div className="contentImage">
              <img src={riu2} width="100%" />
            </div>
            <div className="contentArea">
              <div>
                <h4>
                  Complete Command Over Resource Inventory and Utilization
                </h4>
                <p>
                  Attain the right balance between efficiency, performance, and
                  cost-saving with CloudForestX. Get continuous optimization
                  across the cloud to rightsize your fleet and lower costs and
                  effortlessly convert difficulties into clarity.
                </p>
                <button
                  className="btn btn-outline-orange mt-20 text-uppercase"
                  onClick={() => history.push('/book-demo')}
                >
                  Request A Demo
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="cf-row">
          <div className="innerContainer12 osdSection">
            <div className="contentArea">
              <div>
                <h4>One-Stop Destination for IT Costs Management</h4>
                <p>
                  With CloudForestX Finance Manager, financial operations can
                  acquire comprehensive details of the IT costs of the
                  organization’s hybrid cloud infrastructure. Moreover, IT teams
                  responsible for enterprise-wide cloud adoption can leverage
                  CloudForestX Finance Manager with CloudForestX CFx to obtain a
                  full view across the private and public cloud environments for
                  absolute optimization and unparalleled cost control.
                </p>
                {/* <button className="btn btn-outline-orange mt-20 text-uppercase">
                  Learn More
                </button> */}
              </div>
            </div>
            <div className="contentImage">
              <img src={riu3} width="100%" />
            </div>
          </div>
        </div>

        <div className="cf-row">
          <div className="innerContainer12 ccpSection">
            <div className="contentImage">
              <img src={checkupImg} />
            </div>
            <div className="contentArea">
              <div>
                <h4>
                  <span className="text-primary">
                    Complete Cloud Assessment
                  </span>
                </h4>
                <p>
                  Want to save on your Cloud Computing bill immediately? Reach
                  out to us to get a custom Cloud Assessment Report today.
                </p>
                <button
                  className="btn btn-outline-primary mt-20 text-uppercase"
                  onClick={() => history.push(Routes.CloudCheckup)}
                >
                  Request A Free Cloud Assessment
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="footerContainer">
          <div className="innerContainer12">
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResourceInventoryUtilization;
