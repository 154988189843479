import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import accountReducer from './accountSlice';
import uiReducer from './uiSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    account: accountReducer,
    ui: uiReducer,
  },
});

type RootState = ReturnType<typeof store.getState>;

export const userState = (state: RootState) => state.user;
export const accountState = (state: RootState) => state.account;
export const uiState = (state: RootState) => state.ui;

export default store;
