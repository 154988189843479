import React, { useState } from 'react';
import { Modal, Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Chart from '../../MasterComponent/Echarts';
import { ZoomOutMapOutlined } from '@mui/icons-material';
import { useZoomContext } from '../../../hooks/useModalZoomContext';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomTooltip from '../../MasterComponent/CustomTooltip';
import { TableLegend } from '../../../pages/HealthMonitoring/TableLegend';

interface ZoomInModalProps {
  chartOptions: any;
  tooltip?: string;
  title: string;
  modalWidth?: string;
  modalHeight?: string;
  hasTableLegend?: boolean;
  tableData?: any;
  // chartInstance?: any;
  // onChartInit?: any;
  CustomLegend?: any;
}

const ZoomInModal: React.FC<ZoomInModalProps> = ({
  chartOptions,
  modalWidth = '90%',
  modalHeight = '80%',
  title,
  hasTableLegend,
  tableData,
  // chartInstance,
  tooltip,
  // onChartInit,
  CustomLegend,
}) => {
  const [open, setOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const { setZoomedIn } = useZoomContext(); // Access zoom state
  const [chartInstance, setChartInstance] = useState<echarts.ECharts | null>(
    null
  );

  // Handle chart initialization
  const handleChartInit = (chart: echarts.ECharts) => {
    setChartInstance(chart);
  };
  // modalOpenStatusHandler(open);

  const handleOpen = () => {
    setIsClosing(false); // Reset zoom-out state
    setOpen(true);
    setZoomedIn(true);
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setOpen(false);
      setZoomedIn(false);
      setIsClosing(false); // Reset zoom-out state
    }, 300); // Match the animation duration
  };

  return (
    <>
      {/* Zoom In Icon */}
      <IconButton onClick={handleOpen}>
        {/* <ZoomInIcon fontSize={iconSize} /> */}
        <ZoomOutMapOutlined
          className="zoomInIcon"
          // fontSize={iconSize}
          sx={{
            color: 'gray',
            fontSize: '18px',
          }}
        />
      </IconButton>

      {/* Fullscreen Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          className={isClosing ? 'zoomOut' : 'zoomIn'}
          sx={{
            position: 'relative',
            width: modalWidth,
            height: modalHeight,
            backgroundColor: '#fff',
            borderRadius: '12px',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
            animation: 'zoomIn 0.3s ease-in-out',
            overflow: 'hidden',
          }}
        >
          {/* Close Button */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '97%',
              // marginBottom: 1,
              position: 'absolute',
              top: 20,
              left: 33,
              zIndex: 2,
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 'bold',
                display: 'flex',
                color: 'black',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {title}
              {/* {tooltip ? (
                <CustomTooltip key={title} title={tooltip}>
                  <IconButton size="small" sx={{ marginLeft: 1 }}>
                    <InfoOutlinedIcon fontSize="medium" />
                  </IconButton>
                </CustomTooltip>
              ) : (
                ''
              )} */}
            </Typography>

            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                padding: 0,
                top: 0,
                right: 8,
                zIndex: 2,
              }}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
          </Box>

          {/* Chart */}
          <Box sx={{ marginTop: '2rem', height: '95%', padding: '2.5rem' }}>
            {chartOptions ? (
              <>
                <Chart
                  option={chartOptions}
                  style={{
                    minHeight: hasTableLegend ? 'calc(100% - 180px)' : '90%',
                    width: '100%',
                  }}
                  onChartInit={handleChartInit}
                />
                {!hasTableLegend && chartOptions.customLegend && (
                  <CustomLegend
                    legendData={chartOptions.customLegend.data}
                    colors={chartOptions.customLegend.colors}
                    chartInstance={chartInstance}
                  />
                )}
                {hasTableLegend && tableData && (
                  <TableLegend
                    chartInstance={chartInstance}
                    seriesData={tableData.seriesData}
                    isMemoryGraph={tableData.isMemoryGraph}
                    displayUnit={tableData.displayUnit}
                  />
                )}
              </>
            ) : (
              <div style={{ height: '100%' }} className="noDataCenterBox">
                No data available
              </div>
            )}
          </Box>
        </Box>
      </Modal>

      {/* Animation CSS */}
      <style>
        {`
    @keyframes zoomIn {
      from {
        transform: scale(0.5);
        opacity: 0;
      }
      to {
        transform: scale(1);
        opacity: 1;
      }
    }

    @keyframes zoomOut {
      from {
        transform: scale(1);
        opacity: 1;
      }
      to {
        transform: scale(0.5);
        opacity: 0;
      }
    }

    .zoomIn {
      animation: zoomIn 0.3s ease-in-out;
    }

    .zoomOut {
      animation: zoomOut 0.3s ease-in-out;
    }
  `}
      </style>
    </>
  );
};

export default ZoomInModal;
