import { HttpService } from '../services/HttpService/HttpService';

// export async function makeRequest<T>(
//   httpService: HttpService,
//   method: 'get' | 'post' | 'delete' | 'patch',
//   apiEndpoint: string,
//   params?: any,
//   config?: any
// ): Promise<T> {
//   try {
//     const response = await httpService[method](apiEndpoint, params, config);
//     const { data } = response;
//     if (data.sc === 1) {
//       return data.result;
//     }
//     throw new Error(`API request failed with status code: ${data.sc}`);
//   } catch (e) {
//     throw e; // Re-throw the error for the caller to handle
//   }
// }

// Factory function to create makeRequest with encapsulated httpService
export function createRequestUtility(httpService: HttpService) {
  return async function makeRequest<T>(
    method: 'get' | 'post' | 'put' | 'patch' | 'delete',
    apiEndpoint: string,
    params?: any,
    config?: any
  ): Promise<T> {
    try {
      const response = await httpService[method](apiEndpoint, params, config);
      const { data } = response;
      if (data.sc === 1) {
        return data.result;
      }
      throw new Error(`API request failed with status code: ${data.sc}`);
    } catch (e) {
      throw e; // Re-throw the error for the caller to handle
    }
  };
}
