import React, { Fragment } from 'react';
import SupportHeader from '../../../components/Header/SupportHeader';
import { Footer } from '../../../components';

export const PrivacyPolicy: React.FC = () => {
  return (
    <React.Fragment>
      <div className="outerContainer scrollContainer">
        <SupportHeader />
        <div className="supportBanner eula">
          <div className="innerContainer12">
            <h1>Privacy Policy</h1>
          </div>
        </div>
        <div className="supportContent">
          <div className="innerContainer12 p-20">
            <h4>CloudForestx Privacy Policy</h4>

            <h6 className="mt-20">What is covered by this Privacy Policy?</h6>
            <p className="mt-10">
              We at CloudForestx are committed to ensuring our customer and site
              vendors’ privacy and security. Hence, in this Privacy Policy, we
              highlight how we collect and use the information provided to us by
              our customers in connection with their use of our website and use
              of our Services.
            </p>

            <h6 className="mt-20">Page Navigation</h6>
            <ul className="listStyleDash">
              <li>
                Personal Information We Collect About You and How We Collect It
              </li>
              <li>How We Use Your Personal Information</li>
              <li>Sharing Your Information</li>
              <li>
                Third-Party Use of Cookies and Other Tracking Technologies
              </li>
              <li>Keeping Your Information</li>
              <li>Seeing Advertisements for cloudforestxx.com Online</li>
              <li>Links to Other Sites</li>
              <li>Children’s Privacy</li>
              <li>Additional Information for Certain Jurisdictions</li>
              <li>Customers in the EU</li>
              <li>Changes to This Privacy Policy</li>
              <li>Contact Us</li>
            </ul>

            <h4 className="mt-30">
              Personal Information We Collect About You and How We Collect It
            </h4>
            <h6 className="mt-20">Details You Directly Provide to Us</h6>
            <p className="mt-10">
              CloudForestx collects any detail/information you provide to us
              concerning our products (the "Services"), including information
              you provide when you:
            </p>
            <ul className="listStyleDash">
              <li>Create or administer your CloudForestx account;</li>
              <li>Register for a CloudForestx event or webinar;</li>
              <li>Communicate with us via phone, email, or otherwise; or</li>
              <li>
                Complete a form, questionnaire, support ticket, or other
                information request form.
              </li>
            </ul>

            <p className="mt-20">
              The type of information you will provide to us will include:
            </p>
            <ul className="listStyleDash">
              <li>
                Information about your Cloud service provider account, like the
                account holder's name, company name, username, access keys, and
                passwords;
              </li>
              <li>
                You name, email address, physical address, phone number, or
                other critical contact information;
              </li>
              <li>Corporate and financial information;</li>
              <li>
                Payment details, such as credit card and band information.
              </li>
              <li>
                Your organization and contacts details, like colleagues or
                people within the organization.
              </li>
            </ul>

            <h6 className="mt-20">Automatic Information</h6>
            <p className="mt-10">
              CloudForestx automatically collects necessary details as you
              interact with or navigate through our website or Services,
              download our content, open our emails, or click on links in our
              emails, or communicate with us. The types of information
              automatically collected by us include:
            </p>
            <ul className="listStyleDash">
              <li>
                Information about network and connection, like your computer's
                Internet Protocol (IP) address;
              </li>
              <li>Type and version of your browser;</li>
              <li>
                Pages of our website or Service you visit, its time and date,
                and the time spent on different pages;
              </li>
              <li>Your device or computer location.</li>
              <li>Content interaction information, like content downloads;</li>
              <li>Identifiers and details contained in cookies.</li>
            </ul>

            <h6 className="mt-20">Information from Other Sources</h6>
            <p className="mt-10">
              CloudForestx may also collect information from third parties, such
              as our business partners. This includes:
            </p>
            <ul className="listStyleDash">
              <li>
                Information about marketing and sales generation, including your
                name, email address, physical address, phone number, and other
                similar contact details.
              </li>
            </ul>

            <h5 className="mt-20">How We Use Your Personal Information?</h5>
            <p className="mt-10">
              CloudForestx uses the information, including your personal
              information, that we collect about you or that you provide to us
              for the following:
            </p>
            <ul className="listStyleDash">
              <li>To present you our website and its contents;</li>
              <li>Offer, maintain, and enhance our Services;</li>
              <li>
                Assist with internal functions like auditing, data analysis, and
                research to improve the Services;
              </li>
              <li>
                Send notices to you about critical modification in our terms of
                service and policies;
              </li>
              <li>Service marketing and promotion;</li>
              <li>
                Perform our obligations and enforce our rights arising from any
                contracts entered between you and us, including billing and
                collection;
              </li>
              <li>
                Inform you about special offers, the latest products, any new
                updates, and upcoming events. This may be done through email if
                you have said that we can. Note: You can always opt-out of being
                on our mailing list by clicking the "unsubscribe" link in the
                emails.
              </li>
            </ul>

            <h4 className="mt-30">Sharing Your Information</h4>
            <p className="mt-10">
              You need to know that we are not in the business of selling
              information and do not sell/provide your information to third
              parties. We want to gain and maintain your trust, and believe this
              is essential for us to do that.
            </p>

            <p className="mt-20">
              However, we may share your information with:
            </p>
            <ul className="listStyleDash">
              <li>
                Professional service providers, like marketing agencies and
                advertising partners to successfully provide the Services to
                you;
              </li>
              <li>
                Third Parties, whose services are purchased by you through our
                Services. You will be informed when a third-party is involved in
                your transactions, and we share the related information of those
                transactions with that third party;
              </li>
              <li>
                Buyer or other successors. With the continuous development of
                our business, we might sell or buy businesses or services. In
                which case, personal information generally is one of the
                transferred business assets but remains subject to the promises
                made in any pre-existing Privacy Policy;
              </li>
            </ul>

            <p className="mt-20">
              We may also disclose your personal information for the following
              reasons:
            </p>
            <ul className="listStyleDash">
              <li>
                To comply with any court order, law, legal process, as well as
                to respond to any government or regulatory request.
              </li>
              <li>
                To enforce or apply our terms of use and other agreements for
                billing and collection purposes, etc.
              </li>
              <li>
                In case disclosure is required or appropriate to protect
                CloudForestx's, our customers and others’ rights, property, or
                safety.
              </li>
            </ul>

            <h6 className="mt-20">
              Third-Party Use of Cookies and Other Tracking Technologies
            </h6>
            <p className="mt-10">
              <i>Tracking Technologies and Advertising.</i>
            </p>

            <ul className="mt-20">
              <li>
                <b>Cookies:</b> To track the activity on our website and hold
                certain information, CloudForestx uses cookies and similar
                tracking technologies. Cookies are files with a small amount of
                data, consisting of an anonymous unique identifier. These
                cookies are sent to your browser from a website and stored on
                your device. Beacons, tags, and scripts are other tracking
                technologies also used to accumulate and track information and
                to improve and analyze our website. Your browser can be
                instructed to refuse all cookies or to indicate when a cookie is
                being sent. However, if you don’t accept cookies, you may not be
                able to use some portions of our website.
              </li>
              <li>
                <span>
                  <b>Examples of Cookies we use:</b>
                </span>
                <ul>
                  <li>Session Cookies: To operate our website.</li>
                  <li>
                    Preference Cookies: To remember your preferences and various
                    settings.
                  </li>
                  <li>Security Cookies: For security purposes.</li>
                </ul>
              </li>
              <li>
                <b>Do Not Track Signals:</b> We do not support Do Not Track
                (“DNT”), which is a preference that can be set by you in your
                web browser to inform websites that you do not want to be
                tracked. This can be enabled or disabled by visiting the
                Preferences or Settings page on your web browser.
              </li>
              <li>
                <b>Disabling Cookies:</b> You can disable cookies by setting
                your browser to not accept cookies. However, since our
                application relies on cookies for proper operation, disabling
                cookies may lead to a poor experience.
              </li>
            </ul>

            <h4 className="mt-30">Keeping Your Information</h4>
            <p className="mt-10">
              We will keep your information until you are using the Services, if
              it is needed to offer you Services, or (in the case of any contact
              you may have with our support team) it is required to provide
              support-related reporting and trend analysis only.
            </p>
            <p className="mt-20">
              We may also keep some of your information as required, even after
              you have terminated your use of the Services or it is no longer
              needed to provide the Services to you to meet legal or regulatory
              requirements, resolve disputes, prevent fraud and abuse, or
              enforce our terms and conditions.
            </p>

            <h4 className="mt-30">
              Seeing Advertisements for cloudforestx.com Online
            </h4>
            <p className="mt-10">
              We use online advertising to inform you what we are up to and to
              assist you in finding our products.
            </p>
            <p className="mt-20">
              Like many other organizations, we target our CloudForestx banners
              and ads to you when you visit other websites and apps. This is
              done with the help of a variety of digital marketing networks and
              ad exchanges like Google Analytics and Google Adwords, and a range
              of advertising technologies like web beacons, pixels, ad tags,
              cookies, and mobile identifiers.
            </p>

            <h4 className="mt-30">Links to Other Sites</h4>
            <p className="mt-10">
              Our Services or website pages may contain links to other sites
              that are not operated or managed by us. If you click on these
              third-party links, you will be directed to their site. We strongly
              advise you to review the Privacy Policy of the sites you visit, as
              we do not have any control over and assume no responsibility for
              the content, privacy policies, or practices of any third-party
              sites or services.
            </p>

            <h4 className="mt-30">Children’s Privacy</h4>
            <p className="mt-10">
              CloudForestx's Services are not to be used by those under the age
              of 16.
            </p>
            <p className="mt-20">
              We do not knowingly gather any personally identifiable information
              from anyone under the age of 16. If you are a parent or guardian,
              who is aware that your child has offered us their personal
              information, please reach out to us. In case, we become aware that
              we have gathered any personal information from a child, without
              verification or parental consent, we will take the necessary steps
              to completely remove that information from our servers.
            </p>

            <h4 className="mt-30">
              Additional Information for Certain Jurisdictions
            </h4>
            <p className="mt-10">
              <b>Customers in the EU:</b> Please check out the section
              "Customers in the EU" below, as EU citizens may have additional
              personal information rights and choices.
            </p>
            <p className="mt-10">
              <b>Customers in California:</b> Please check out the CloudForestx
              Privacy Notice for California Residents, available here, as
              residents of California may have additional personal information
              rights and choices.
            </p>

            <h4 className="mt-30">Customers in the EU</h4>
            <p className="mt-10">
              <b>Controller of Personal Data:</b> is the data controller
              responsible for your personal data.
            </p>
            <p className="mt-10">
              <b>Personal Data Definition</b> CloudForestx defines personal data
              as any detail or information associated with an identified or
              identifiable natural person. We collect limited information that
              falls under GDPR but is not limited to:
            </p>
            <ul className="listStyleDash">
              <li>First and Last Name</li>
              <li>Email Address</li>
              <li>IP Address</li>
              <li>Phone Number</li>
              <li>Physical Address</li>
              <li>Other Contact Information</li>
            </ul>

            <p className="mt-20">
              <b>Processing:</b> Your personal data is processed by us on one or
              more of the following legal bases:
            </p>
            <ul className="listStyleDash">
              <li>
                As required to enter into a contract with you or a legal entity
                you represent, to carry out our contractual obligations, provide
                our Services, respond to your requests, or provide customer
                support.
              </li>
              <li>
                Where we have a legitimate interest, as defined in this Privacy
                Policy (see How We Use Your Personal Information above);
              </li>
              <li>
                <span>
                  As necessary to comply with the relevant legal obligations,
                  including to:
                </span>
                <ul className="listStyleDash">
                  <li>Respond to lawful requests and orders;</li>
                  <li>With your consent.</li>
                </ul>
              </li>
            </ul>

            <p className="mt-20">
              <b>Data Transfer:</b> Our services are provided from the US, which
              means your data is transferred from the EU to the US. We ensure
              your privacy and rights are protected through the use of the
              European Commission's standard data protection clauses. Find more
              details about these here:{' '}
              <a href="http://eur-lex.europa.eu/legal-content/en/TXT/?uri=CELEX:32010D0087">
                http://eur-lex.europa.eu/legal-content/en/TXT/?uri=CELEX:32010D0087.
              </a>
            </p>

            <p className="mt-10">
              Furthermore, we take all the necessary steps to make sure your
              data is treated securely and as per this Privacy Policy and none
              of your personal information is transferred to an organization or
              a country until there are adequate controls in place including the
              security of your data and other personal information.
            </p>

            <h6 className="mt-20">Your Rights</h6>
            <p className="mt-10">
              If you are located in the EU, you have various rights relating to
              your personal information, such as:
            </p>
            <ul className="listStyleDash">
              <li>
                <b>Right to be informed:</b> You have the right to be provided
                with certain details of the information that defines their
                relationship with the controller.
              </li>
              <li>
                <b>Right to access:</b> You have the right to know about the
                personal data that CloudForestx holds about you, and why and how
                we hold the data.
              </li>
              <li>
                <b>Right to erasure:</b> You have the right to ask CloudForestx
                to restrict your personal data processing, under certain
                conditions.
              </li>
              <li>
                <b>Right to object to processing:</b> You have the right to
                object to the processing of your personal data by CloudForestx,
                under certain conditions.
              </li>
              <li>
                <b>Right to data portability:</b> You have the right to request
                CloudForestx to transfer your data that we have collected to
                another organization, or directly to you, under certain
                conditions.
              </li>
              <li>
                <b>Right to complain to the Data Protection Authority (DPA):</b>{' '}
                European citizens have the right to file a complaint with a
                supervisory authority.
              </li>
            </ul>

            <h6 className="mt-20">Changes to This Privacy Policy</h6>
            <p className="mt-10">
              We might update our Privacy Policy from time to time.
            </p>

            <h4 className="mt-30">Contact Us</h4>
            <p className="mt-10">
              In the case of any questions about this Privacy Policy or the
              information that we collect, please contact us at{' '}
              <b>CloudForestx contact ID.</b>
            </p>
          </div>
        </div>
        <div className="footerContainer">
          <div className="innerContainer12">
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PrivacyPolicy;
