import React, { useMemo } from 'react';
import './Dashboard.css';
import {
  BasicAPIRequest,
  config,
  optionIsAvailableConditionHandler,
} from './config';
import { useFetchData } from '../../hooks/useFetchData';
import SectionCard from '../../components/HealthMonitoring/SectionCard';

const CPUMemoryNetworkDisk: React.FC<BasicAPIRequest> = (props) => {
  const {
    refreshTrigger,
    endTime,
    startTime,
    job,
    node,
    step,
    primaryKey,
  } = props;
  // const [isModalOpen, setIsModalOpen] = useState(false);

  const apiDependencies = useMemo(
    () => [startTime, endTime, node, job, step, refreshTrigger, primaryKey],
    [startTime, endTime, node, job, step, refreshTrigger, primaryKey]
  );
  const reqBody = { startTime, endTime, node, job, step, primaryKey };

  const CPUData = useFetchData({
    fetchData: () => config.getCPUMemoryNetworkDisk(reqBody),
    dependencies: apiDependencies,
  });

  const MemoryStack = useFetchData({
    fetchData: () => config.getMemoryStack(reqBody),
    dependencies: apiDependencies,
  });

  const DiskSpaceUsed = useFetchData({
    fetchData: () => config.getDiskSpaceUsed(reqBody),
    dependencies: apiDependencies,
  });

  const DiskIops = useFetchData({
    fetchData: () => config.getDiskIops(reqBody),
    dependencies: apiDependencies,
  });

  const IOUsageReadWrite = useFetchData({
    fetchData: () => config.getIoUsageReadWrite(reqBody),
    dependencies: apiDependencies,
  });

  const IOUtilization = useFetchData({
    fetchData: () => config.getIoUtilization(reqBody),
    dependencies: apiDependencies,
  });

  const cards = [
    {
      title: 'CPU',
      // tooltip: 'sample tooltip content',
      options: optionIsAvailableConditionHandler(CPUData.data, false, true),
      loader: CPUData.loader,
      error: CPUData.error,
    },
    {
      title: 'Memory Stack',
      options: optionIsAvailableConditionHandler(MemoryStack.data, true, true), // Non-memory graph
      loader: MemoryStack.loader,
      error: MemoryStack.error,
    },
    {
      title: 'Disk Space Used',
      options: optionIsAvailableConditionHandler(
        DiskSpaceUsed.data,
        true,
        true
      ),
      loader: DiskSpaceUsed.loader,
      error: DiskSpaceUsed.error,
    },
    {
      title: 'Disk IOps',
      options: optionIsAvailableConditionHandler(DiskIops.data, false, true),
      loader: DiskIops.loader,
      error: DiskIops.error,
    },
    {
      title: 'I/O Usage Read/Write',
      options: optionIsAvailableConditionHandler(
        IOUsageReadWrite.data,
        true,
        true
      ),
      loader: IOUsageReadWrite.loader,
      error: IOUsageReadWrite.error,
    },
    {
      title: 'I/O Utilization',
      options: optionIsAvailableConditionHandler(
        IOUtilization.data,
        false,
        true
      ),
      loader: IOUtilization.loader,
      error: IOUtilization.error,
    },
  ];
  return (
    <div
      // style={{
      //   display: 'grid',
      //   gridTemplateColumns: '1fr 1fr',
      //   gap: '16px',
      // }}
      style={{
        display: 'grid',
        gridTemplateColumns: 'minmax(500px, 1fr) minmax(500px, 1fr)',
        gap: '16px',
        height: '100%',
      }}
    >
      {cards.map((card, index) => (
        <SectionCard card={card} key={index} />
      ))}
    </div>
  );
};

export default CPUMemoryNetworkDisk;
