/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Popper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  // TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';

import {
  Dispatch,
  SetStateAction,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import './DetailMonitoring.css';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import AdapterDateFns from '@date-io/date-fns';
import { ALL_VALUE } from '../Invoice/config';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { ReactComponent as MotionPaused } from '../../assets/ICONS/motion-paused.svg';
import DeleteModal from '../../components/Modals/DeleteModal/DeleteModal';
import { handleError } from '../../utils/config';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  ERRORS,
  NOTIFICATION_PROVIDER,
  PRIMARY_REGION,
} from '../../utils/constants';
import { useHistory } from 'react-router-dom';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import {
  FetchMonitoring,
  MONITORING_XLX_TIME_RANGE,
  MonitoringXLSReport,
  QuickStatusMonitoringData,
  URLMonitoringData,
  calculateTimeDuration,
  config,
  formatDataToLocalTime,
  parseDateString,
  // quickStatusFormatter,
} from './config';
import UptimeMonitoringModal from '../../components/Modals/UptimeMonitoringModal/UptimeMonitoringModal';
import { submitUrlInterface } from './URLMonitoring';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { userActions } from '../../store/userSlice';
import { RoutesFun } from '../../schemas';
import { TextField } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { URLMonitoringConfigInterface } from './URLAccordionSection';
import { Replay, ZoomOutMapOutlined } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import ZoomInModal from '../../components/Modals/ZoomInModal/ZoomInModal';
import GraphLoader from '../ServicesReports/GraphLoader';
import CustomButton from '../../components/MasterComponent/Button/CustomButton';
import { exportToFile } from '../../utils/formateXLSX';
import MonitoringGraph from '../../components/UrlMonitoring/MonitoringGraph';
import NotificationLogModal from '../../components/Modals/UptimeMonitoringModal/NotificationLog';
import URLGraphLoader from './UrlGraphLoader';
interface DetailURLMonitoringInterface {
  handleUrlDetailMonitoringModal: () => void;
  onCloseUptimeDetailMonitoringModal: () => void;
  onSubmitUrl: (params: submitUrlInterface) => void;
  setLoader: Dispatch<SetStateAction<boolean>>;
  loader: boolean;
  URLData: any;
  orgEmails: string[];
  orgSlackGroups: string[];
  orgGchats: string[];
  webhookUrlMapping: { [key: string]: string };
  gchat_IdMapping: { [key: string]: any };
  email_IdMapping: { [key: string]: any };
  slackChannel_IdMapping: { [key: string]: any };
  showUptimeDetailMonitorigModal: boolean;
  list: any;
  toggleMonitoringLoader: boolean;
  graphLoader: boolean;
  toggledMonitoringItem: string | null;
  monitoringStatusHandler: (key: string) => void;
  monitoringStatusData: any;
  setSelectedRegion: Dispatch<SetStateAction<string>>;
  setGraphLoader: Dispatch<SetStateAction<boolean>>;
  selectedRegion: string;
  environmentLabels: string[];
}

interface DataEntry {
  Status: number;
  Date: string;
  Time: string;
  Reason: string;
  ResponseTime: number;
  Region: string;
  Value: boolean;
  DateTime: string;
}

export interface URLData {
  [region: string]: DataEntry[];
}

const DetailURLMonitoring: React.FC<DetailURLMonitoringInterface> = (props) => {
  const {
    URLData,
    email_IdMapping,
    gchat_IdMapping,
    handleUrlDetailMonitoringModal,
    orgEmails,
    orgGchats,
    orgSlackGroups,
    slackChannel_IdMapping,
    webhookUrlMapping,
    showUptimeDetailMonitorigModal,
    onCloseUptimeDetailMonitoringModal,
    onSubmitUrl,
    monitoringStatusHandler,
    list,
    setLoader,
    toggleMonitoringLoader,
    toggledMonitoringItem,
    monitoringStatusData,
    setSelectedRegion,
    selectedRegion,
    environmentLabels,
    graphLoader,
  } = props;
  const [showZoomInModal, setShowZoomInModal] = useState(false);
  const { clickedData, regionSelected } = URLData;

  const friendlyName = Object.keys(clickedData)[0];
  const URLValue: URLMonitoringData[] = Object.keys(monitoringStatusData).length
    ? monitoringStatusData[friendlyName]
    : clickedData && Object.values(clickedData)[0];

  const [dataURL, setDataURL] = useState<URLData>({});

  const [deleteModal, setDeleteModal] = useState(false);
  const [networkError, setNetworkError] = useState('');
  const [isDetailPageLoad, setIsDetailPageLoad] = useState<boolean>(false);
  const [isQuickStatusLoad, setIsQuickStatusLoad] = useState<boolean>(false);
  const [monitoringDetailData, setMonitoringDetailData] = useState<
    Record<string, any>
  >({});
  const [
    quickStatusData,
    setQuickStatusData,
  ] = useState<QuickStatusMonitoringData>({});
  // const [
  //   formattedQuickStatusData,
  //   setFormattedQuickStatusData,
  // ] = useState<quickStatusInterface>({
  //   avgResponse: 0,
  //   downTime: 0,
  //   firstValue: { region: '', responseTime: 0 },
  //   upTime: 0,
  // });
  const history = useHistory();
  const [
    uptimeMonitoringModalDetails,
    setUptimeMonitoringModalDetils,
  ] = useState<URLMonitoringConfigInterface>({
    monitoringType: '',
    friendlyName: '',
    environment: '',
    webUrl: '',
    monitoringInterval: 60,
    monitoringTimeout: 30,
    monitoringNotificationInterval: 40,
    Region: [],
    IsSSL: false,
    selectedEmail: [],
    selectedSlackGroups: [],
    EscalationThreshold: 1,
    escalationType: '',
    Escalations: [],
    selectedGchats: [],
    acceptedStatuses: [],
    Headers: {},
    Body: {},
    Method: '',
    Port: '',
    refreshTokenAPI: '',
  });
  const dispatch = useDispatch();
  const Routes = RoutesFun();
  const [copied, setCopied] = useState(false);
  // const [sortByRegion, setSortByRegion] = useState(false);
  const [sortedData, setSortedData] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<any>(
    new Date().getTime() - 60 * 60 * 1000
  );
  const [endDate, setEndDate] = useState<any>(new Date().getTime());
  const [intervalStartDate, setIntervalStartDate] = useState<any>(null);
  const [intervalEndDate, setIntervalEndDate] = useState<any>(null);
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [timeSpan, setTimeSpan] = useState<string | null>('1 hour');
  const [selectedLabel, setSelectedLabel] = useState<string | null>(null);
  const [isDateChanged, setIsDateChanged] = useState<boolean>(false);
  const [isGraphIntervalChanged, setIsGraphIntervalChanged] = useState<boolean>(
    false
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [duration, setDuration] = useState(MONITORING_XLX_TIME_RANGE.weekly);
  const [showNotificationLogModal, setNotificationLogModal] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState<number | null>(null);

  const urlId = URLValue?.filter((item) => item.Region === selectedRegion)[0]
    .Id;

  // const [fileType, setFileType] = useState('csv');

  const closeNotificationLogModalHandler = () => setNotificationLogModal(false);
  const openNotificationLogModalHandler = () => setNotificationLogModal(true);

  //download report section

  const handlePopperClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  // const handleFileTypeChange = (event: any) => {
  //   setFileType(event.target.value as string);
  // };

  const handleClickAway = (event: any) => {
    if (anchorEl && anchorEl.contains(event.target as Node)) {
      return;
    }
    setOpen(false);
  };

  const handlePopperChange = (event: any) => {
    setDuration(event.target.value as string);
  };

  const downloadURLReportHandler = async () => {
    const today = new Date();
    let startDate;
    if (duration === MONITORING_XLX_TIME_RANGE.weekly) {
      startDate = today.getTime() - 7 * 24 * 60 * 60 * 1000;
    } else if (duration === MONITORING_XLX_TIME_RANGE.monthly) {
      startDate = today.getTime() - 30 * 24 * 60 * 60 * 1000;
    } else if (duration === MONITORING_XLX_TIME_RANGE.bimonthly) {
      startDate = today.getTime() - 60 * 24 * 60 * 60 * 1000;
    } else {
      startDate = today.getTime() - 90 * 24 * 60 * 60 * 1000;
    }
    const params: MonitoringXLSReport = {
      urlId,
      startDate,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    const response = await config.getMonitoringReport(params);
    exportToFile(response.fileBuffer, `${URLValue[0].FriendlyName}.xlsx`);
  };

  const currentDate = new Date();

  useEffect(() => {
    setSelectedRegion(URLValue[0].Region);
  }, []);

  const selectedEnvironmentHandler = (label: string) => {
    setSelectedLabel(label);
  };

  const startDateChange = (date: MaterialUiPickersDate) => {
    setIntervalStartDate(null);
    setIsDateChanged(true);
    const selectedStartDate = date?.getTime();
    if (selectedStartDate) {
      if (selectedStartDate > Date.now()) {
        setStartDateError('Start date should not exceed current time');
        return;
      } else {
        const maxEndDateFromStartDate = moment(date).add(15, 'days').toDate(); // 15 days from the selected start date

        const currentMaxDate = moment(currentDate).toDate(); // Current date
        const validMaxEndDate = moment(maxEndDateFromStartDate).isAfter(
          currentMaxDate
        )
          ? currentMaxDate // End date cannot exceed current date
          : maxEndDateFromStartDate;

        // Automatically update endDate if it's outside the valid range
        if (selectedStartDate && moment(endDate).isAfter(validMaxEndDate)) {
          setEndDate(validMaxEndDate.getTime()); // Shift endDate to the max allowed value
        }

        // Automatically adjust if startDate is greater than current endDate
        if (selectedStartDate && selectedStartDate >= endDate) {
          setEndDate(validMaxEndDate.getTime());
        }

        // Calculate and set time span between selected start date and end date
        const timeDuration = calculateTimeDuration(selectedStartDate, endDate);

        setSelectedInterval(null);
        setTimeSpan(timeDuration);
        setStartDate(selectedStartDate);
        setStartDateError('');
        setEndDateError('');
      }
    }
  };

  const endDateChange = (date: MaterialUiPickersDate) => {
    const selectedEndDate = date?.getTime();
    if (selectedEndDate) {
      if (Date.now() < selectedEndDate) {
        setEndDateError(
          'End date and time should be less then or equal to current time'
        );
        return;
      } else {
        setIntervalEndDate(null);
        setIsDateChanged(true);
        setSelectedInterval(null);
        const maxEndDate = moment(intervalStartDate || startDate)
          .add(15, 'days')
          .toDate();

        // If the end date is greater than the allowed max date, adjust it
        if (selectedEndDate > maxEndDate.getTime()) {
          setEndDate(maxEndDate.getTime());
        } else {
          setEndDate(selectedEndDate);
        }

        // Calculate and set time span between start date and selected end date
        const timeDuration = calculateTimeDuration(startDate, selectedEndDate);

        setTimeSpan(timeDuration);
        setEndDateError('');
        setStartDateError('');
      }
    }
  };

  const onCloseDeleteModal = () => setDeleteModal(false);
  const onCloseZoomInModal = () => setShowZoomInModal(false);

  const handleRegionChange = (event: SelectChangeEvent<string>) => {
    setSelectedRegion(event.target.value);
  };
  const handleCopy = (webUrl: string) => {
    navigator.clipboard
      .writeText(webUrl)
      .then(() => {
        setCopied(true);
      })
      .catch((error) => {
        console.error('Error copying to clipboard:', error);
      });
  };

  const handleDeleteClick = async () => {
    try {
      setLoader(true);
      const result = await config.deleteURL({
        FriendlyName: friendlyName,
      });

      if (result) {
        dispatch(
          userActions.replaceUserState({
            successMessage: result.message,
          })
        );
        history.push(Routes.UptimeMonitoring);
      }
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
      // fetchUrls();
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    let activeData = true;
    const fetchURLIntervalDetailData = async () => {
      try {
        const startDate1 = new Date().getTime() - 60 * 60 * 1000;
        const endDate1 = new Date().getTime();
        const params: FetchMonitoring = {
          FriendlyName: friendlyName,
          Region: selectedRegion,
          Type: URLValue[0]?.Type,
          TimeRange: `${startDate1}-${endDate1}`,
        };
        const result = await config.fetchMonitoringDetail(params);

        if (activeData) {
          const localConvertedData: any = formatDataToLocalTime(result);
          setDataURL(localConvertedData);
          // setFormattedQuickStatusData(
          //   await quickStatusFormatter(localConvertedData)
          // );
          getLastObjects(localConvertedData);
          setMonitoringDetailData(localConvertedData);
        }
      } catch (e: any) {
        setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

        if (e && e.error && e.error.message) {
          handleError(e, history);
        }
      } finally {
        setIntervalStartDate(new Date().getTime() - 60 * 60 * 1000);
        setIntervalEndDate(new Date().getTime());
      }
    };
    const intervalFunc = setInterval(fetchURLIntervalDetailData, 120000);

    isDateChanged && clearInterval(intervalFunc);
    isGraphIntervalChanged && clearInterval(intervalFunc);

    return () => {
      activeData = false;
      clearInterval(intervalFunc);
    };
  }, [isDateChanged, clickedData, selectedRegion, isGraphIntervalChanged]);

  if (networkError) {
    throw new Error(networkError);
  }

  const getLastObjects = (URLDetailData: any) => {
    const updatedObjects: any = Object.values(URLDetailData)
      .flat()
      .sort((a: any, b: any): any => {
        const dateA: any = parseDateString(`${a.DateTime}`);
        const dateB: any = parseDateString(`${b.DateTime}`);

        return dateB - dateA;
      });

    setSortedData([...updatedObjects]);
  };

  useMemo(() => {
    const timeDuration = calculateTimeDuration(startDate, endDate);
    setTimeSpan(timeDuration);
  }, [intervalStartDate, startDate, endDate]);

  useMemo(() => {
    if (URLValue && URLValue[0].MonitoringLabels)
      setSelectedLabel(
        URLValue[0].MonitoringLabels[0] ? URLValue[0].MonitoringLabels[0] : null
      );
  }, [URLValue]);

  useEffect(() => {
    let activeData = true;

    const fetchQuickStatus = async () => {
      try {
        // setLoader(true);
        setIsQuickStatusLoad(true);

        // Convert startDate and endDate to milliseconds (Unix epoch time)
        const startMillisec = new Date(startDate).getTime();
        const endMillisec = new Date(endDate).getTime();

        const params: FetchMonitoring = {
          FriendlyName: friendlyName,
          Region: selectedRegion,
          Type: URLValue[0]?.Type,
          TimeRange: `${startMillisec}-${endMillisec}`,
        };
        const quickStatusResponse = await config.fetchQuickStatusData(params);
        if (activeData) {
          setQuickStatusData(quickStatusResponse);
        }
      } catch (e: any) {
        setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

        if (e && e.error && e.error.message) {
          handleError(e, history);
        }
      } finally {
        setStartDateError('');
        setEndDateError('');
        setIsQuickStatusLoad(false);
        // setLoader(false);
      }
    };
    fetchQuickStatus();

    return () => {
      activeData = false;
    };
  }, [selectedRegion, clickedData, regionSelected, startDate, endDate]);

  useEffect(() => {
    let activeData = true;

    const fetchURLDetailData = async () => {
      try {
        // setLoader(true);
        setIsDetailPageLoad(true);

        // Convert startDate and endDate to milliseconds (Unix epoch time)
        const startMillisec = new Date(startDate).getTime();
        const endMillisec = new Date(endDate).getTime();

        const params: FetchMonitoring = {
          FriendlyName: friendlyName,
          Region: selectedRegion,
          Type: URLValue[0]?.Type,
          TimeRange: `${startMillisec}-${endMillisec}`,
          Interval: selectedInterval ? String(selectedInterval) : '1',
        };
        const monitoringResponse = await config.fetchMonitoringDetail(params);

        if (activeData) {
          const localConvertedData: any = formatDataToLocalTime(
            monitoringResponse
          );
          // let uniqueData: any;
          // if (localConvertedData) {
          //   const key = Object.keys(localConvertedData)[0];
          //   const value: any = (Object.values(
          //     localConvertedData
          //   )[0] as any).reduce((accumulator: any, current: any) => {
          //     if (
          //       !accumulator.some(
          //         (item: any) => item.DateTime === current.DateTime
          //       )
          //     ) {
          //       accumulator.push(current);
          //     }
          //     return accumulator;
          //   }, []);
          //   uniqueData = { [key]: value };
          // }
          setDataURL(localConvertedData);

          // setFormattedQuickStatusData(
          //   await quickStatusFormatter(localConvertedData)
          // );
          getLastObjects(localConvertedData);
          setMonitoringDetailData(localConvertedData);
        }
      } catch (e: any) {
        setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

        if (e && e.error && e.error.message) {
          handleError(e, history);
        }
      } finally {
        setStartDateError('');
        setEndDateError('');
        setIsDetailPageLoad(false);
        // setLoader(false);
      }
    };
    fetchURLDetailData();

    return () => {
      activeData = false;
    };
  }, [
    selectedRegion,
    clickedData,
    regionSelected,
    startDate,
    endDate,
    selectedInterval,
  ]);

  useMemo(() => {
    setStartDate(new Date().getTime() - 60 * 60 * 1000);
    setEndDate(new Date().getTime());
    setIsDateChanged(false);
    setIsGraphIntervalChanged(false);
    setSelectedInterval(null);
  }, [clickedData]);

  return (
    <>
      {/* {loader && <LoadingScreen />} */}
      <div className="UrlDetailContainer">
        <div className="UrlHeaderBox d-flex space-between">
          <div className="d-flex detailPageTitleBox">
            <div style={{ minWidth: 'fit-content' }}>
              <div>
                {friendlyName}{' '}
                {selectedRegion === ALL_VALUE ? (
                  ''
                ) : (
                  <span className="urlRegionSection">({selectedRegion})</span>
                )}
              </div>
              <div
                className="detailPageURL"
                onMouseEnter={() => setCopied(false)}
                onClick={() => handleCopy(URLValue[0].WebUrl)}
              >
                <Tooltip title={!copied ? 'Copy to clipboard' : 'Copied!'}>
                  {URLValue[0].WebUrl}
                </Tooltip>
              </div>
            </div>

            <Select
              className="ml-10"
              value={selectedRegion}
              id="demo-simple-select-standard"
              onChange={handleRegionChange}
              IconComponent={ArrowDropDownIcon}
              sx={{
                '& .MuiSelect-select': {
                  padding: '0',
                },
                '& .MuiSelect-icon': {
                  fontSize: 32,
                  marginRight: '-8px',
                },
              }}
              defaultValue={
                URLValue?.some((obj: any) => obj['Region'] === PRIMARY_REGION)
                  ? PRIMARY_REGION
                  : URLValue[0].Region
              }
              renderValue={() => null} // Hide the selected value
            >
              {/* <MenuItem value="all">All</MenuItem> */}
              {URLValue.map((obj: any, index: number) => (
                <MenuItem key={index} value={obj.Region}>
                  {obj.Region}
                </MenuItem>
              ))}
            </Select>
            <div className="datePickerContainer">
              <div className="cDateTime">
                <div className="dateInputBoxWrapper">
                  <div>
                    <MuiPickersUtilsProvider utils={AdapterDateFns}>
                      <DateTimePicker
                        TextFieldComponent={(props) => (
                          <TextField
                            {...props}
                            variant="outlined"
                            className="dateInputBox"
                          />
                        )}
                        label="Start Date Time"
                        value={
                          intervalStartDate ? intervalStartDate : startDate
                        }
                        format="dd/MM/yyyy, hh:mm a"
                        onChange={startDateChange}
                        maxDate={currentDate}
                        minDate={moment(currentDate)
                          .subtract(90, 'days')
                          .toDate()}
                        // error={!!startDateError}
                        disableFuture
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  {startDateError && (
                    <div className="helperText">{startDateError}</div>
                  )}
                </div>
                <div className="dateInputBoxWrapper">
                  <div>
                    <MuiPickersUtilsProvider utils={AdapterDateFns}>
                      <DateTimePicker
                        TextFieldComponent={(props) => (
                          <TextField
                            {...props}
                            variant="outlined"
                            className="dateInputBox"
                          />
                        )}
                        label="End Date Time"
                        value={intervalEndDate ? intervalEndDate : endDate}
                        format="dd/MM/yyyy, hh:mm a"
                        onChange={endDateChange}
                        maxDate={moment(intervalStartDate || startDate)
                          .add(15, 'days')
                          .toDate()}
                        // minDate={moment(currentDate)
                        //   .subtract(7, 'days')
                        //   .toDate()}
                        minDate={intervalStartDate || startDate}
                        // error={!!endDateError}
                        disableFuture
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  {endDateError && (
                    <div className="helperText">{endDateError}</div>
                  )}
                </div>
              </div>
              <CustomButton
                buttonType="contained"
                onClickHandler={openNotificationLogModalHandler}
                endIcon={<NotificationsIcon />}
              >
                Notification Logs
              </CustomButton>
            </div>
          </div>
          <div className="rightDetailButtons">
            {toggledMonitoringItem === friendlyName &&
            toggleMonitoringLoader ? (
              <>
                <IconButton
                  onClick={() => monitoringStatusHandler(friendlyName)}
                >
                  <PendingOutlinedIcon color="action" />{' '}
                  <Typography variant="body1">Switching...</Typography>
                </IconButton>
              </>
            ) : !URLValue[0].IsMonitoring ? (
              <Tooltip title="Start Monitoring">
                <IconButton
                  onClick={() => monitoringStatusHandler(friendlyName)}
                >
                  <PlayCircleOutlineIcon />{' '}
                  <Typography variant="body1">Play</Typography>
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Pause Monitoring">
                <IconButton
                  onClick={() => monitoringStatusHandler(friendlyName)}
                >
                  <MotionPaused />
                  <Typography variant="body1">Pause</Typography>
                </IconButton>
              </Tooltip>
            )}
            <IconButton
              onClick={() => {
                const Emails: string[] = [];
                const SlackGroups: string[] = [];
                const GchatGroups: string[] = [];
                const Escalations: string[] = [];
                let escalationType = '';

                if (URLValue[0].UrlNotifications.length) {
                  URLValue[0].UrlNotifications.forEach((row: any) => {
                    if (row.NotificationType === NOTIFICATION_PROVIDER.EMAIL)
                      Emails.push(row.Value);
                    if (row.NotificationType === NOTIFICATION_PROVIDER.SLACK)
                      SlackGroups.push(row.FriendlyName);
                    if (row.NotificationType === NOTIFICATION_PROVIDER.GCHAT)
                      GchatGroups.push(row.Value);

                    if (
                      row.NotificationType ===
                        NOTIFICATION_PROVIDER.ESCALATION_EMAIL ||
                      row.NotificationType ===
                        NOTIFICATION_PROVIDER.ESCALATION_GCHAT ||
                      row.NotificationType ===
                        NOTIFICATION_PROVIDER.ESCALATION_SLACK
                    ) {
                      Escalations.push(row.Value);
                      escalationType =
                        row.NotificationType ===
                        NOTIFICATION_PROVIDER.ESCALATION_EMAIL
                          ? 'Email'
                          : row.NotificationType ===
                            NOTIFICATION_PROVIDER.ESCALATION_GCHAT
                          ? 'Gchat'
                          : 'Slack';
                    }
                  });
                }
                setUptimeMonitoringModalDetils({
                  monitoringType: URLValue[0].Type,
                  friendlyName: URLValue[0].FriendlyName,
                  environment: URLValue[0].MonitoringLabels
                    ? URLValue[0].MonitoringLabels[0]
                    : '',
                  webUrl: URLValue[0].WebUrl,
                  monitoringInterval: URLValue[0].Frequency,
                  monitoringTimeout: URLValue[0].Timeout,
                  monitoringNotificationInterval:
                    URLValue[0].NotificationInterval,
                  IsSSL: URLValue[0].IsSSL,
                  Region:
                    URLValue?.reduce((acc: string[], currUrlData: any) => {
                      acc.push(currUrlData.Region);
                      return acc;
                    }, []) || [],
                  selectedEmail: Emails,
                  escalationType,
                  Escalations,
                  EscalationThreshold: URLValue[0].EscalationThreshold,
                  selectedSlackGroups: SlackGroups,
                  selectedGchats: GchatGroups,
                  acceptedStatuses: URLValue[0].acceptedStatuses || [],
                  IsMonitoring: URLValue[0].IsMonitoring,
                  UrlId: URLValue[0].Id,
                  Headers: URLValue[0]?.Headers
                    ? Object.keys(URLValue[0]?.Headers).length
                      ? URLValue[0].Headers
                      : {
                          Authorization: '--------',
                        }
                    : {},
                  Method: URLValue[0].Method,
                  Body: URLValue[0].Body,
                  Port: URLValue[0].Port,
                  refreshTokenAPI: URLValue[0].refreshTokenAPI,
                });
                handleUrlDetailMonitoringModal();
              }}
            >
              <BorderColorOutlinedIcon />
              <Typography variant="body1">Edit</Typography>
            </IconButton>
            <IconButton onClick={() => setDeleteModal(true)}>
              <DeleteIcon />
              <Typography variant="body1">Delete</Typography>
            </IconButton>
          </div>
        </div>
        <div className="UrlStatsContainer">
          <div className="UrlStatsSubsection">
            <p>Latest Response</p>
            <p>
              (
              {Object.keys(quickStatusData)?.length > 0
                ? Object.keys(quickStatusData)[0]
                : '-'}
              )
            </p>
            {isQuickStatusLoad ? (
              <p className="loading">...</p>
            ) : (
              <p>
                {Object.values(quickStatusData)?.length > 0 &&
                Object.values(quickStatusData)[0][0].latestResonse?.ResponseTime
                  ? Object.values(
                      quickStatusData
                    )[0][0].latestResonse.ResponseTime.toFixed(2)
                  : '0.00'}
                ms
              </p>
            )}
          </div>
          <div className="UrlStatsSubsection">
            <p>Avg. Response</p>
            <p>({timeSpan})</p>
            {isQuickStatusLoad ? (
              <p className="loading">...</p>
            ) : (
              <p>
                {Object.values(quickStatusData)?.length > 0 &&
                Object.values(quickStatusData)[0][0].responsetime
                  ? Number(
                      Object.values(quickStatusData)[0][0].responsetime
                    ).toFixed(2)
                  : '0.00'}
                ms
              </p>
            )}
          </div>
          <div className="UrlStatsSubsection">
            <p>Up Time</p>
            <p>({timeSpan})</p>

            {isQuickStatusLoad ? (
              <p className="loading">...</p>
            ) : (
              <p>
                {Object.values(quickStatusData)?.length > 0 &&
                Object.values(quickStatusData)[0][0].avg_value_true
                  ? Number(
                      Object.values(quickStatusData)[0][0].avg_value_true
                    ).toFixed(2)
                  : '0.00'}
                %
              </p>
            )}
          </div>
          <div className="UrlStatsSubsection">
            <p>Down Time</p>
            <p>({timeSpan})</p>
            {isQuickStatusLoad ? (
              <p className="loading">...</p>
            ) : (
              <p>
                {Object.values(quickStatusData)?.length > 0 &&
                Object.values(quickStatusData)[0][0].avg_value_false
                  ? Number(
                      Object.values(quickStatusData)[0][0].avg_value_false
                    ).toFixed(2)
                  : '0.00'}
                %
              </p>
            )}
          </div>
          <div className="UrlStatsSubsection">
            <p>Cert Exp.</p>
            <p>
              {URLValue[0].SSLExpiry
                ? moment()
                    .add(
                      +URLValue[0].SSLExpiry,
                      +URLValue[0].SSLExpiry === 1 ? 'day' : 'days'
                    )
                    .format('DD-MM-YYYY')
                : '(DD-MM-YYYY)'}
            </p>
            {isQuickStatusLoad ? (
              <p className="loading">...</p>
            ) : (
              <p>
                {URLValue[0].SSLExpiry
                  ? +URLValue[0].SSLExpiry +
                    ` ${URLValue[0].SSLExpiry === 1 ? 'Day' : 'Days'}`
                  : '-'}
              </p>
            )}
          </div>
          {/* Repeat subsections as needed */}
        </div>
        <div className="GraphContainer">
          <div className="flex-end align-center toggleBtnGroup">
            <ToggleButtonGroup
              value={selectedInterval}
              exclusive
              onChange={(e: any) => {
                e.target.value === ''
                  ? setIsGraphIntervalChanged(false)
                  : setIsGraphIntervalChanged(true);
                setSelectedInterval(
                  e.target.value === '' ? null : Number(e.target.value)
                );
              }}
              aria-label="Time Interval"
              style={{ marginRight: '1rem' }}
            >
              {[
                { value: '15', label: '15m' },
                { value: '30', label: '30m' },
                { value: '60', label: '1h' },
              ].map((button) => (
                <ToggleButton
                  key={button.value}
                  value={button.value}
                  aria-label={`${button.label}`}
                  sx={{
                    backgroundColor:
                      selectedInterval && selectedInterval === +button.value
                        ? '#e0f3ff'
                        : 'transparent', // Highlight selected button
                  }}
                >
                  {+button.value < 60
                    ? `${+button.value}m`
                    : `${+button.value / 60}h`}
                </ToggleButton>
              ))}

              <ToggleButton value="" aria-label="Reset">
                <Replay sx={{ fontSize: '1.6rem' }} />
              </ToggleButton>
            </ToggleButtonGroup>
            <ZoomOutMapOutlined
              onClick={() => setShowZoomInModal(true)}
              className="zoomInIcon cursor-pointer"
              sx={{
                fontSize: '25px',
                borderRadius: '10px',
                color: 'gray',
              }}
            />
          </div>
          {graphLoader ||
          isDetailPageLoad ||
          !Object.values(dataURL).flat().length ? (
            <URLGraphLoader isLoading={graphLoader || isDetailPageLoad} />
          ) : (
            dataURL && (
              <MonitoringGraph
                urlData={dataURL}
                grid={{
                  left: '2%',
                  right: '2%',
                  bottom: '0%',
                  top: '10%',
                  containLabel: true,
                }}
                style={{
                  width: '100%',
                  height: '350px',
                  marginTop: '-1rem',
                  padding: 0,
                }}
              />
            )
          )}
        </div>
        <div className="urlDropDownBtn">
          <Tooltip title="URL Report">
            <CustomButton
              buttonType="contained"
              // endIcon={<ArrowDropDownIcon />}
              onClickHandler={handlePopperClick}
              style={{
                float: 'right',
                margin: '1rem 0',
                width: '5rem',
                justifyContent: 'center',
              }}
            >
              <DownloadIcon />
            </CustomButton>
          </Tooltip>
          {/* <CustomButton
            buttonType="outlined"
            // endIcon={<ArrowDropDownIcon />}
            onClickHandler={handlePopperClick}
            style={{ float: 'right', margin: '1rem 0' }}
          >
            Monitoring Report
          </CustomButton> */}
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement="top-start"
            disablePortal={false}
          >
            <Paper style={{ padding: '16px', minWidth: '200px' }}>
              <p className="durationHeader">Select duration</p>
              <div className="mt-30 urlDropDownBtn">
                <FormControl fullWidth>
                  <InputLabel
                    // style={{ fontSize: '16px' }}
                    id="demo-multiple-name-label"
                  >
                    Monitor Type
                  </InputLabel>
                  <Select
                    style={{ fontSize: '16px' }}
                    inputProps={{ style: { fontSize: 16 } }}
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    value={duration}
                    onChange={handlePopperChange}
                    input={<OutlinedInput label="Monitor Type" />}
                    onClick={(event) => event.stopPropagation()}
                  >
                    <MenuItem value={MONITORING_XLX_TIME_RANGE.weekly}>
                      One week
                    </MenuItem>
                    <MenuItem value={MONITORING_XLX_TIME_RANGE.monthly}>
                      One month
                    </MenuItem>
                    <MenuItem value={MONITORING_XLX_TIME_RANGE.bimonthly}>
                      Two months
                    </MenuItem>
                    <MenuItem value={MONITORING_XLX_TIME_RANGE.trimonthly}>
                      Three months
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div style={{ textAlign: 'right' }}>
                <CustomButton
                  buttonType="contained"
                  onClickHandler={downloadURLReportHandler}
                  endIcon={<DownloadIcon />}
                >
                  Download
                </CustomButton>
                {/* <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DownloadIcon />}
                  onClick={(event) => event.stopPropagation()}
                >
                  Download
                </Button> */}
              </div>
            </Paper>
          </Popper>
          {open && (
            <div
              style={{ position: 'fixed', inset: 0 }}
              onClick={handleClickAway}
            ></div>
          )}
        </div>
        <TableContainer className="accContainer urlTableContainer URLMonitoringTable">
          <Table
            stickyHeader
            className="table adminTable adminPanelTable"
            size="small"
            aria-label="sticky table"
          >
            <TableHead className="admintableHead">
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>Region</TableCell>
                <TableCell>Date Time</TableCell>
                <TableCell>Response Time</TableCell>
                <TableCell>Message</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {isDetailPageLoad ? (
                <>
                  <TableCell>Loading...</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </>
              ) : Object.keys(monitoringDetailData).length > 0 ? (
                sortedData?.length > 0 ? (
                  sortedData.map((value: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell className="urlStatus">
                        {value.Reason === 'Maintenance' ? (
                          <div className="urlMaintenance">Maintenance</div>
                        ) : value.Value ? (
                          <div className="urlUp">Up</div>
                        ) : (
                          <div className="urlDown">Down</div>
                        )}
                      </TableCell>
                      <TableCell>{value.Region}</TableCell>
                      <TableCell>{value.DateTime}</TableCell>
                      <TableCell>{value.ResponseTime} ms</TableCell>
                      <TableCell>{value.Reason}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <>
                    <TableCell>No data found</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </>
                )
              ) : (
                <>
                  <TableCell>No data found</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onClose={onCloseDeleteModal}
          metaData={friendlyName}
          deleteFunction={handleDeleteClick}
        />
      )}
      {showNotificationLogModal && (
        <NotificationLogModal
          showNotificationLogModal={showNotificationLogModal}
          closeNotificationLogModalHandler={closeNotificationLogModalHandler}
          urlId={urlId}
        />
      )}
      {showUptimeDetailMonitorigModal && (
        <>
          <UptimeMonitoringModal
            show={showUptimeDetailMonitorigModal}
            onClose={onCloseUptimeDetailMonitoringModal}
            uptimeMonitoringDetails={uptimeMonitoringModalDetails}
            emails={orgEmails}
            slackGroups={orgSlackGroups}
            onSubmit={onSubmitUrl}
            webhookUrlMapping={webhookUrlMapping}
            SlackChannel_Id={slackChannel_IdMapping}
            GchatChannel_Id={gchat_IdMapping}
            Email_Id={email_IdMapping}
            list={list}
            gchats={orgGchats}
            environmentLabels={environmentLabels}
            selectedEnvironmentHandler={selectedEnvironmentHandler}
            selectedLabel={
              Array.isArray(selectedLabel)
                ? selectedLabel.flat()[0]
                : selectedLabel
            }
          />
        </>
      )}

      {showZoomInModal && (
        <ZoomInModal
          show={showZoomInModal}
          onClose={onCloseZoomInModal}
          metaData={dataURL}
          selectedInterval={selectedInterval}
          setIsGraphIntervalChanged={setIsGraphIntervalChanged}
          setSelectedInterval={setSelectedInterval}
          // URLValue={URLValue}
        />
      )}
    </>
  );
};

export default memo(DetailURLMonitoring);
