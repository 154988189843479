import React, { Fragment } from 'react';
import './CostManagement.css';

import { useHistory } from 'react-router-dom';
import checkupImg from '../../../assets/images/checkup.png';
import costManagement1 from '../../../assets/images/cfx-cm-1.png';
import costManagement2 from '../../../assets/images/cfx-cm-2.png';
import bestPracticeImg from '../../../assets/images/cfx-cm-best-practice.svg';
import cloudSpendImg from '../../../assets/images/cfx-cm-spend.svg';
import costAllocationImg from '../../../assets/images/cfx-cm-cost-allocation.png';
import billingControlImg from '../../../assets/images/cfx-cm-receipt.svg';
import reportImg from '../../../assets/images/cfx-cm-report.svg';
import invoiceBillingImg from '../../../assets/images/cfx-cm-invoice-billing.svg';
import SupportHeader from '../../../components/Header/SupportHeader';
import { Footer } from '../../../components';
import { RoutesFun } from '../../../schemas';

export const CostManagement: React.FC = () => {
  const history = useHistory();
  const Routes = RoutesFun();
  return (
    <React.Fragment>
      <div className="outerContainer scrollContainer">
        <SupportHeader />
        <div className="supportBanner cmBanner">
          <div className="innerContainer12 cmBImage">
            <div className="cmBannerText">
              <h1>Cloud Cost Management</h1>
              <p className="font-20px fw-300">
                Your one-stop solution for cloud cost management and allocation,
                spend optimization, and saving money.
              </p>
            </div>
          </div>
        </div>
        <div className="cf-row">
          <div className="innerContainer12 featureSection">
            <h4 className="sectionTitle">
              <span>Our Features</span>
            </h4>
            <div className="cardsCont">
              <div className="cards">
                <span>
                  <img src={cloudSpendImg} width="28" />
                </span>
                <div className="ml-10">
                  <h5 className="fw-400">Improved Cloud Spend</h5>
                  <p>
                    Leverage CloudForestX's predictive analytics and resource
                    purchasing recommendations to ensure maximum savings on
                    cloud spend.
                  </p>
                </div>
              </div>
              <div className="cards">
                <span>
                  <img src={billingControlImg} width="28" />
                </span>
                <div className="ml-10">
                  <h5 className="fw-400">Governed Cloud Billing</h5>
                  <p className="mt-10">
                    Now have complete control over your cloud cost with
                    CloudForestX's comprehensive billing analytics. Get advance
                    alerting, cloud governance, and more.
                  </p>
                </div>
              </div>
              <div className="cards">
                <span>
                  <img src={costAllocationImg} width="24" />
                </span>
                <div className="ml-10">
                  <h5 className="fw-400">Cost Distribution</h5>
                  <p className="mt-10">
                    Monitor your cloud spend across different cloud services and
                    providers, with CloudForestX's prominent features like Cost
                    Changes Report. Get instant and detailed reports for
                    comprehensive visibility.
                  </p>
                </div>
              </div>

              <div className="cards">
                <span>
                  <img src={reportImg} width="28" />
                </span>
                <div className="ml-10">
                  <h5 className="fw-400">
                    Right-Sizing Report and RI Rebalancer
                  </h5>
                  <p className="mt-10">
                    From identifying wasted resources to resource re-sizing
                    recommendations, and continuous money optimization across
                    different cloud platforms like AWS, Azure, and Google Cloud
                    Platform, with CloudForestX actionable insights for improved
                    results.
                  </p>
                </div>
              </div>
              <div className="cards">
                <span>
                  <img src={bestPracticeImg} width="28" />
                </span>
                <div className="ml-10">
                  <h5 className="fw-400">600+ Cost Best Practices</h5>
                  <p className="mt-10">
                    Whether you want to identify idle resources or continuously
                    monitor AWS, Azure, and Google Cloud Platform,
                    CloudForestX's 600+ Best Practices checks help you do it
                    all, including identifying unused instances and VMs, EC2
                    Reserved Instance mismatch, and more.
                  </p>
                </div>
              </div>
              <div className="cards">
                <span>
                  <img src={invoiceBillingImg} width="24" />
                </span>
                <div className="ml-10">
                  <h5 className="fw-400">Quick Invoicing &amp; Billing</h5>
                  <p className="mt-10">
                    Creating and scheduling invoices for different accounts,
                    departments, application owners, business units, and more is
                    hassle-free with CloudForestX.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cf-row">
          <div className="innerContainer12 cmSection">
            <div className="contentArea">
              <div>
                <h4>
                  <span className="text-pink">CloudForestX for</span>
                  <span>Unparallel Management of Cloud Cost</span>
                </h4>
                <p className="w-80">
                  CloudForestX CFx helps users get a comprehensive understanding
                  of every billing detail, enabling them to plan and manage
                  future cloud expenses.
                </p>
                {/* <button
                  className="btn btn-outline-pink mt-20 text-uppercase"
                  onClick={() => history.push('/book-demo')}
                >
                  Request A Demo
                </button> */}
              </div>
            </div>
            <div className="contentImage">
              <img src={costManagement1} width="100%" />
            </div>
          </div>
        </div>

        <div className="cf-row">
          <div className="innerContainer12 osdSection">
            <div className="contentImage">
              <img src={costManagement2} width="100%" />
            </div>
            <div className="contentArea">
              <div>
                <h4>
                  <span className="text-orange">One-Stop Destination</span>
                  <span>for IT Cost Management</span>
                </h4>
                <p>
                  With CloudForestX Finance Manager, financial operations can
                  acquire comprehensive details of the IT costs of the
                  organization’s hybrid cloud infrastructure. Moreover, IT teams
                  responsible for enterprise-wide cloud adoption can leverage
                  CloudForestX Finance Manager with CloudForestX CFx to obtain a
                  full view across the private and public cloud environments for
                  absolute optimization and unparalleled cost control.
                </p>
                {/* <button className="btn btn-outline-orange mt-20 text-uppercase">
                  Learn More
                </button> */}
              </div>
            </div>
          </div>
        </div>

        <div className="cf-row">
          <div className="innerContainer12 ccpSection">
            <div className="contentArea">
              <div>
                <h4>
                  <span className="text-primary">
                    Complete Cloud Assessment
                  </span>
                </h4>
                <p>
                  Want to save on your Cloud Computing bill immediately? Reach
                  out to us to get a custom Cloud Assessment Report today.
                </p>
                <button
                  className="btn btn-outline-primary mt-20 text-uppercase"
                  onClick={() => history.push(Routes.CloudCheckup)}
                >
                  Request A Free Cloud Assessment
                </button>
              </div>
            </div>
            <div className="contentImage">
              <img src={checkupImg} className="flip-horizontal" />
            </div>
          </div>
        </div>
        <div className="footerContainer">
          <div className="innerContainer12">
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CostManagement;
