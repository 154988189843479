import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useFetchData } from '../../hooks/useFetchData';
import { config } from './config';
import { VisualizationModalWrapper } from './VisualizationModalWrapper';
import { Box, CircularProgress, Typography } from '@mui/material';
import HealthMonitoringDashboard from './Dashboard';

interface AccountsDataI {
  Id: number;
  companyId: number;
  Title: string;
  URL: string;
  UserName: string;
}

const AddServerHealthVisualizationPage: React.FC = () => {
  const [formCloseRefresh, setFormCloseRefresh] = useState(1);

  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(
    null
  );
  const [selectedAccountName, setSelectedAccountName] = useState<string | null>(
    null
  );
  const serverHealthCreds = useFetchData({
    fetchData: () => config.getServerHealthCreds(),
    dependencies: [formCloseRefresh, selectedAccountId],
    fetchOnMount: false,
  });

  const memoizedAccountsData = useMemo<AccountsDataI[]>(
    () => serverHealthCreds.data || [],
    [serverHealthCreds.data, selectedAccountId]
  );

  const handleAccountChange = useCallback(
    (_: any, account) => {
      const selectedObj =
        memoizedAccountsData.find((option: any) => option.Title === account) ||
        memoizedAccountsData[0];
      setSelectedAccountName(account ?? memoizedAccountsData[0].Title);
      setSelectedAccountId(selectedObj.Id);
    },
    [memoizedAccountsData]
  );
  useEffect(() => {
    if (
      selectedAccountId === null &&
      selectedAccountName === null &&
      memoizedAccountsData.length > 0
    ) {
      setSelectedAccountId(memoizedAccountsData[0]?.Id);
      setSelectedAccountName(memoizedAccountsData[0]?.Title);
    }
  }, [selectedAccountId, memoizedAccountsData]);

  return serverHealthCreds.loader ? (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="300px"
      marginTop="1rem"
    >
      <CircularProgress size={50} />
      <Typography variant="h6" sx={{ marginTop: 2, color: '#666' }}>
        Fetching data, please wait…
      </Typography>
    </Box>
  ) : !serverHealthCreds.data?.length ? (
    // Empty State UI
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="70vh"
      textAlign="center"
    >
      <Typography variant="h3" sx={{ color: '#666', fontWeight: '500' }}>
        Welcome to System Health Monitoring
      </Typography>
      <Typography
        variant="h5"
        sx={{ color: '#999', marginTop: 2, maxWidth: '600px' }}
      >
        It looks like you haven't added any visualizations yet. Click the button
        below to get started and monitor your system's health metrics.
      </Typography>
      <Box sx={{ marginTop: 4 }}>
        <VisualizationModalWrapper
          setFormCloseRefresh={setFormCloseRefresh}
          formCloseRefresh={formCloseRefresh}
        />
      </Box>
    </Box>
  ) : selectedAccountId && selectedAccountName ? (
    <HealthMonitoringDashboard
      formCloseRefresh={formCloseRefresh}
      setFormCloseRefresh={setFormCloseRefresh}
      accountData={memoizedAccountsData}
      primaryKey={selectedAccountId}
      selectedAccountName={selectedAccountName}
      handleAccountChange={handleAccountChange}
    />
  ) : (
    <div>Internal Error</div>
  );
};

export default memo(AddServerHealthVisualizationPage);
