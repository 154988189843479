import React, { Fragment, useEffect } from 'react';
import SupportHeader from '../../../components/Header/SupportHeader';
import { Footer } from '../../../components';

export const EULA: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <div className="outerContainer scrollContainer">
        <SupportHeader />
        <div className="supportBanner eula">
          <div className="innerContainer12">
            <h1>End User License Agreement</h1>
          </div>
        </div>
        <div className="supportContent">
          <div className="innerContainer12 p-20">
            <h5>CloudForestx Inc. EULA</h5>

            <p className="mt-10">
              This End User License Agreement (“Agreement”) describes the legal
              contract between you, as the end-user of CloudForet's services
              (“You” and “Your”), and CloudForestx Inc., along with its
              subsidiaries and affiliates (“CloudForestx”) concerning access to
              and use of CloudForestx’s software-as-a-service or self-hosted
              image, as applicable (the “Services”), and any associated
              materials or documentation (“Documentation”) made available
              through CloudForestx’s websites, including www.cloudforestx.com,
              as well as any support provided by CloudForestx (“Support”).
            </p>

            <p className="text-uppercase mt-20">
              WE PROVIDE THE SERVICES SOLELY ON THE TERMS AND CONDITIONS DEFINED
              IN THIS AGREEMENT AND ON THE CONDITION THAT YOU ACCEPT AND COMPLY
              WITH THEM. BY LOGGING INTO THE SERVICES, YOU ACCEPT THIS AGREEMENT
              AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS AND YOU
              REPRESENT AND WARRANT THAT: (I) YOU ARE OF LEGAL AGE TO ENTER INTO
              A BINDING AGREEMENT AND (II) IF YOU REPRESENT A COMPANY OR OTHER
              LEGAL ENTITY, YOU HAVE THE RIGHT, POWER, AND AUTHORITY TO ENTER
              INTO THIS AGREEMENT ON BEHALF OF SUCH ENTITY AND BIND THE ENTITY
              TO THESE TERMS. IF YOU DO NOT AGREE TO THE TERMS OF THIS
              AGREEMENT, WE WILL NOT LICENSE THE SERVICES AND DOCUMENTATION TO
              YOU AND YOU MUST NOT USE THE SERVICES AND DOCUMENTATION.
            </p>

            <ol>
              <li>
                <h6>GRANT AND SCOPE OF LICENSE</h6>
                <ol className="my-0">
                  <li>
                    <b>General:</b> CloudForestx offers You a non-exclusive,
                    non-transferable, non-sublicensable, limited license to use
                    the Services and Documentation, solely for Your personal or
                    internal use and subject to and conditioned on Your payment
                    of fees and compliance with the terms and conditions of this
                    Agreement.
                  </li>
                </ol>
              </li>

              <li className="mt-20">
                <h6>ACCOUNT ACCESS</h6>
                <ol>
                  <li className="my-0">
                    You must set up an account with CloudForestx (the "Account")
                    to access and use the Services. You are responsible for
                    maintaining the confidentiality of Your Login Credentials.
                    You are responsible and liable for all uses of the Services
                    and Documentation through access thereto provided by You,
                    directly or indirectly. Specifically, and without limiting
                    the generality of the preceding, You are responsible and
                    liable for all actions and failures to take required actions
                    concerning the Services and Documentation by You and Your
                    Authorized Users or by any other person to whom You or Your
                    Authorized Users may directly or indirectly provide access
                    to or use of the Services and/or Documentation, whether such
                    access or use is permitted by or in violation of this
                    Agreement. Authorized Users refers to Your employees,
                    consultants, contractors, and agents, (a) who are authorized
                    by You to access and use the Services under rights granted
                    to You under this Agreement; and (b) for whom access to the
                    Services has been purchased hereunder.
                  </li>

                  <li className="mt-10">
                    You must notify us immediately if You become aware that Your
                    Account is being used without authorization. If Your access
                    to the Services was previously terminated by CloudForestx,
                    You may not register for a new Account nor may You designate
                    other individuals to use an Account on Your behalf.
                  </li>
                </ol>
              </li>

              <li className="mt-20">
                <h6>AGE RESTRICTION</h6>
                <p className="mt-10">
                  You affirm that You are either more than 18 years of age, or
                  an emancipated minor, or possess legal parental or guardian
                  consent and are fully able and competent to enter into the
                  terms, conditions, obligations, affirmations, representations,
                  and warranties outlined in this Agreement, and to abide by and
                  comply with this Agreement. In any case, You affirm that You
                  are at least 13 years of age. If You are under 13 years of
                  age, You may not use the Services without the assistance of a
                  parent or guardian.
                </p>
              </li>

              <li className="mt-20">
                <h6>GENERAL RESTRICTIONS ON USE</h6>
                <p className="mt-10">
                  In connection with Your use of the Services, You agree not to,
                  nor to allow or facilitate a third party to: (a) copy, modify,
                  or create a derivative work of the Services or Documentation;
                  (b) reverse engineer, reverse assemble, or otherwise attempt
                  to discover any source code of the Services; (c) sell, assign,
                  sublicense, grant a security interest in, or otherwise
                  transfer any right in or access to the Services, or any other
                  Documentation available through the Services without the prior
                  written permission of CloudForestx; (d) license, sub-license,
                  sell, resell, rent, lease, transfer, distribute, timeshare, or
                  otherwise make any of it available for access by
                  third-parties, except as may otherwise be expressly stated in
                  a Master Services Agreement or associated Service Schedule;
                  (e) circumvent or manipulate any applicable fee structure,
                  billing process, or fees owed either to us or to our third
                  party providers; (f) use the Services for illegal purposes or
                  for promotion of dangerous activities; (g) interfere with or
                  disrupt the Services or servers or networks connected to the
                  Services, or disobey any requirements, procedures, policies or
                  regulations of networks connected to the Services; (h) attempt
                  to gain unauthorized access to the Services, other accounts,
                  computer systems, or networks connected to the Services
                  through hacking, password mining, phishing or any other means;
                  (i) upload, post, email, transmit, distribute or otherwise
                  make available any material that contains viruses, computer
                  code, or any other technologies that may harm CloudForestx or
                  the interests, information, or property of CloudForestx
                  customers or limit the functionality of any software, hardware
                  or other equipment; (j) use it in excess of contractual usage
                  limits, including as set forth in an associated Service
                  Schedule, or in a manner that circumvents usage limits or
                  technological access control measures; (k) circumvent, disable
                  or otherwise interfere with security-related features of the
                  Services or features that prevent or restrict use or copying
                  of any Documentation or enforce limitations on use of the
                  Services; (l) remove any proprietary notices from the Services
                  or Documentation; (m) use the Services or Documentation in any
                  manner or for any purpose that infringes, misappropriates, or
                  otherwise violates any intellectual property right or other
                  right of any person, or that violates any applicable law; (n)
                  access or use the Services or Documentation for purposes of
                  competitive analysis of the Services or Documentation, the
                  development, provision, or use of a competing software service
                  or product, or any other purpose that is to CloudFrorest
                  detriment or commercial disadvantage; (o) or use the Services
                  and Documentation in any manner other than as permitted by
                  this Agreement.
                </p>
              </li>

              <li className="mt-20">
                <h6>USE VERIFICATION</h6>
                <p className="mt-10">
                  CloudForestx may remotely review Your use of the Services at
                  any time. If CloudForestx determines that You have exceeded
                  its permitted access and use rights to the Services,
                  CloudForestx will notify You within seven (7) days, thereafter
                  You shall either: (a) disable any unpermitted use, or (b)
                  purchase additional Services commensurate with Your actual
                  use. CloudForestx reserves the right to charge You for actual
                  usage during the period of unpermitted use.
                </p>
              </li>

              <li className="mt-20">
                <h6>OWNERSHIP OF THE SERVICES</h6>
                <ol>
                  <li className="mt-0">
                    <b>Services and Documentation:</b> All rights, title, and
                    interest in and to the Services and Documentation, including
                    all intellectual property rights, are and will remain with
                    CloudForestx. For all Third-Party Materials (as defined in
                    Section 14) appearing in the Services, the applicable
                    third-party providers own all rights, title, and interest,
                    including all intellectual property rights, in and to the
                    Third-Party Materials. You have no right, license, or
                    authorization concerning the Services, Documentation, or
                    Third-Party Materials, except as expressly defined in this
                    Agreement.
                  </li>

                  <li className="mt-10">
                    <b>Customer Data:</b> Customer Data means any data that You
                    upload to the Services under "Your Account" or that You
                    otherwise transfer, process, use, or store in connection
                    with Your Account. CloudForestx acknowledges that, as
                    between CloudForestx and You, You own all rights, title, and
                    interest, including all intellectual property rights, in and
                    to the Customer Data. You hereby grant to CloudForestx a
                    non-exclusive, royalty-free, worldwide license to reproduce,
                    distribute, and otherwise use and display the Customer Data
                    and perform all acts concerning the Customer Data as may be
                    necessary for CloudForestx to provide the Services to You.
                  </li>

                  <li className="mt-10">
                    <b>Resultant Data:</b> Resultant Data refers to any data and
                    information related to Your use of the Services that
                    CloudForestx uses in an aggregated and anonymized manner,
                    including the compiled statistical and performance
                    information related to the provision and operation of the
                    Services. CloudForestx uses this data, without limitation,
                    to improve the performance of the Services or develop
                    important updates. CloudForestx never uses it to re-identify
                    You. Furthermore, You hereby unconditionally and irrevocably
                    grant CloudForestx an assignment of all rights, title, and
                    interest in and to the Resultant Data, including all
                    intellectual property rights relating thereto.
                  </li>
                </ol>
              </li>

              <li className="mt-20">
                <h6>DATA PROTECTION</h6>
                <ol>
                  <li>
                    <b>Privacy Policy:</b> In the course of Your accessing
                    and/or using the Services and receiving Support,
                    CloudForestx may obtain information about You, or You may
                    provide certain personal information to CloudForestx. All
                    uses of Your personal information will be treated under
                    CloudForestx’s Privacy Policy available at{' '}
                    <a href="https://www.cloudforestx.com/privacy-policy">
                      https://www.cloudforestx.com/privacy-policy
                    </a>{' '}
                    which is incorporated by reference and forms an integral
                    part of this Agreement. The Privacy Policy is subject to
                    change at CloudForestx’s discretion; however, CloudForestx
                    policy changes will not result in a material reduction in
                    the level of protection provided for the Customer Data You
                    provide to CloudForestx in connection with Your receipt of
                    the Services.
                  </li>

                  <li className="mt-10">
                    <b>Personal Data:</b> In the event, You provide Your
                    Personal Data, as defined by Regulation (EU) 2016/679
                    (General Data Protection Regulation) to CloudForestx,
                    CloudForestx’s Data Processing Agreement (the “DPA”),
                    incorporated herein by reference, will apply, and describes
                    the parties’ respective roles for the processing and control
                    of Personal Data. When providing the Services to you,
                    CloudForestx will act as a data processor and will act on
                    Your instruction concerning the treatment of Your Personal
                    Data, as specified in this Agreement and the DPA. The
                    Exhibits in the DPA define the administrative, physical,
                    technical, and other safeguards applied to Personal Data
                    residing in the Services environment and describe other
                    aspects of system management applicable to the Services.
                  </li>
                </ol>
              </li>

              <li className="mt-20">
                <h6>PAYMENT</h6>
                <ol>
                  <li>
                    <b>Payment of Fees:</b> You will pay to CloudForestx all
                    license fees, at a rate of <b>3.5%</b> with a monthly
                    minimum of <b>$1,000 (One-Thousand Dollars)</b> or
                    CloudForestx’s published pricing, whichever is greater (the
                    “Fees”), no later than thirty <b>(30) days</b> after You
                    receive an invoice unless You have a written agreement with
                    express pricing terms executed by an authorized CloudForestx
                    representative. CloudForestx retains the right to change the
                    published pricing at any time with notice to users. All
                    amounts are stated, billed, and collected in US dollars.
                  </li>

                  <li className="mt-10">
                    <b>Taxes:</b> All Fees and other amounts payable by You
                    under this Agreement are exclusive of taxes and similar
                    assessments. You are responsible for all sales, use, and
                    excise taxes, and any other similar taxes, duties, and
                    charges of any kind imposed by any federal, state, or local
                    governmental or regulatory authority on any amounts payable
                    by You hereunder, other than any taxes imposed on
                    CloudForestx's income.
                  </li>

                  <li className="mt-10">
                    <b>Credits:</b> If You believe You’re entitled to a credit
                    on Your Fees for the Services, submit a credit request no
                    later than 60 days from the date of the applicable invoice.
                  </li>

                  <li className="mt-10">
                    <b>CloudForestx Reseller End Users:</b> If You access
                    CloudForestx through a CloudForestx Reseller, your Reseller
                    will establish any terms and conditions for pricing,
                    invoicing, and payment. A CloudForestx Reseller means an
                    entity authorized by CloudForestx to resell the Services to
                    You. To avoid doubt, a CloudForestx Reseller is not an
                    authorized CloudForestx representative.
                  </li>
                </ol>
              </li>

              <li className="mt-20">
                <h6>TERM AND TERMINATION</h6>
                <ol>
                  <li>
                    <b>Term:</b> This Agreement is effective upon your logging
                    into the Services until it is terminated as per Section 8.
                  </li>

                  <li className="mt-10">
                    <b>Termination By CloudForestx:</b> CloudForestx may
                    terminate this Agreement, effective on written notice to
                    You, in care of non-payment or if You breach any of Your
                    obligations under this Agreement. CloudForestx may, directly
                    or indirectly, suspend, terminate, or otherwise deny Your or
                    any of your Authorized End Users’, or any other person’s
                    access to or use of all or any part of the Services or
                    Documentation, without incurring any resulting obligation or
                    liability, if: (a) CloudForestx receives a judicial or other
                    governmental demand or order, subpoena, or law enforcement
                    request that expressly or by reasonable implication requires
                    CloudForestx to do so; (b) CloudForestx believes that: (i)
                    You or any of Your Authorized End Users, have failed to
                    comply with any term of this Agreement, or accessed or used
                    the Services beyond the scope of the rights granted or for a
                    purpose not authorized under this Agreement or in any manner
                    that does not comply with any instruction or requirement of
                    the Documentation; (ii) You or any Authorized User is, has
                    been, or is likely to be involved in any fraudulent,
                    misleading, or unlawful activities relating to or in
                    connection with any of the Services; or (c) this Agreement
                    expires or is terminated. This Section 8(b) does not limit
                    any of CloudForestx's other rights or remedies, whether at
                    law, in equity, or under this Agreement.
                  </li>

                  <li className="mt-10">
                    <b>Termination By You:</b> You can terminate Your use of the
                    services by providing at least 30 days’ written notice of
                    Your intent to terminate only if You have a monthly
                    subscription with CloudForestx. In case you have a separate,
                    written agreement with CloudForestx, specifying termination
                    rights, You may terminate as per the terms of that
                    agreement. If You access CloudForestx through a CloudForestx
                    Reseller, your Reseller will be responsible for establishing
                    the terms and conditions, if any, upon which You may
                    terminate Your use of the Services.
                  </li>

                  <li className="mt-10">
                    <b>Effect of Termination:</b> Once the Agreement is
                    terminated, the license granted will also be terminated, and
                    You shall cease using the Services and Documentation. No
                    expiration or termination shall affect Your obligation to
                    pay all Fees that may have become due before such expiration
                    or termination or entitle You to any refund.
                  </li>
                </ol>
              </li>

              <li className="mt-20">
                <h6>CONFIDENTIALITY</h6>
                <p className="mt-10">
                  From time to time during the Term, each Party may disclose or
                  make available to the other Party details about its business
                  affairs, products, confidential intellectual property, trade
                  secrets, and other sensitive or proprietary information,
                  whether orally or in written, electronic, or other form and
                  whether or not marked, designated or otherwise identified as
                  “confidential” (collectively, “Confidential Information”). To
                  avoid doubt and without limitation, CloudForestx roadmaps and
                  information and documentation about CloudForestx's security
                  architecture (e.g. SOC 2 reports) is the Confidential
                  Information of CloudForestx. Confidential Information does not
                  include information that at the time of disclosure is: (a) in
                  the public domain; (b) known to the Receiving Party at the
                  time of disclosure; (c) rightfully obtained by the Receiving
                  Party on a non-confidential basis from a third party; or (d)
                  independently developed by the Receiving Party. The Receiving
                  Party shall not disclose the disclosing Party’s Confidential
                  Information to any person or entity, except to the Receiving
                  Party’s employees who have a need to know the Confidential
                  Information for the Receiving Party to exercise its rights or
                  perform its obligations hereunder and who are bound by
                  confidentiality restrictions no less restrictive than those
                  applying to the Receiving Party hereunder. Notwithstanding the
                  foregoing, each Party may disclose Confidential Information to
                  the limited extent required (i) to comply with the order of a
                  court or other governmental body, or as otherwise necessary to
                  comply with applicable law, provided that the Party making the
                  disclosure supporting the order will, to the extent legally
                  permitted, give the Disclosing Party prompt notice and
                  cooperate with the Disclosing Party to obtain an appropriate
                  protective order or other appropriate remedies at the
                  Disclosing Party’s expense; or (ii) to establish a Party’s
                  rights under this Agreement, including to make required court
                  filings. Upon termination of this Agreement, the Receiving
                  Party will, at the Disclosing Party’s request and option,
                  delete or return any Confidential Information in the Receiving
                  Party’s possession. Any Confidential Information returned to
                  the Disclosing Party will be returned in a format mutually
                  agreed to by the Parties. Notwithstanding anything in this
                  Agreement to the contrary, the Disclosing Party may retain any
                  Confidential Information required for it to comply with its
                  legal or regulatory obligations, which Confidential
                  Information will remain subject to the terms of this Agreement
                  until deleted. Each Party’s obligations of non-disclosure
                  concerning Confidential Information are effective as of your
                  logging into the Services and will expire two years from the
                  date first disclosed to the Receiving Party; provided,
                  however, concerning any Confidential Information that
                  constitutes a trade secret (as determined under applicable
                  law), such obligations of non-disclosure will survive the
                  termination or expiration of this Agreement for as long as
                  such Confidential Information remains subject to trade secret
                  protection under applicable law.
                </p>
              </li>

              <li className="mt-20">
                <h6>CUSTOMER WARRANTIES</h6>
                <p className="mt-5">You warrant and represent that:</p>

                <ol>
                  <li>
                    The information provided to us by You as part of Your
                    Account registration is true, accurate, current, and
                    complete, and You agree to maintain and promptly update such
                    information to keep it true, accurate, current, and
                    complete.
                  </li>

                  <li className="mt-10">
                    All Customer Data supplied by You or otherwise accessed by
                    CloudForestx through the provision of the Services is the
                    sole and exclusive property of You or that You have secured
                    all authorizations and rights to use the Customer Data as
                    applicable.
                  </li>

                  <li className="mt-10">
                    The Customer Data does not breach any relevant laws,
                    regulations, or codes; and infringe the intellectual
                    property rights of any third party.
                  </li>

                  <li className="mt-10">
                    You have obtained the necessary consent to transfer or
                    permit access to Customer Data, containing personally
                    identifiable information or Personal Data, as per the
                    applicable privacy and data protection laws.
                  </li>
                </ol>
              </li>

              <li className="mt-20">
                <h6>CLOUDFORESTx WARRANTIES &amp; DISCLAIMER</h6>
                <ol>
                  <li>
                    <b>Warranties:</b> CloudForestx does not make any
                    representations or guarantees regarding uptime or
                    availability of the Services except as specifically
                    identified in the Service Level Agreement published at
                    website (eg:
                    <a href="https://www.cloudforestx.com/service-license">
                      https://www.cloudforestx.com/service-license
                    </a>
                    ). The remedies defined in the Service Level Agreement are
                    Your sole remedies and CloudForestx’s sole liability under
                    the limited warranty defined in Section 11(a). THE FOREGOING
                    WARRANTY DOES NOT APPLY, AND CLOUDFORESTx STRICTLY DISCLAIMS
                    ALL WARRANTIES, CONCERNING ANY THIRD-PARTY MATERIALS.
                  </li>

                  <li>
                    <b>DISCLAIMER OF WARRANTIES:</b> EXCEPT FOR THE LIMITED
                    WARRANTY DEFINED IN SECTION 11(a) ABOVE, THE SERVICES AND
                    ALL OTHER DETAILS INCLUDING INFORMATION, DOCUMENTATION, AND
                    MATERIALS RELATED ARE PROVIDED “AS IS'' AND “AS AVAILABLE,”
                    AND CLOUDFORESTx HEREBY DISCLAIMS ALL WARRANTIES, EITHER
                    EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY
                    IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                    PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AND ALL
                    WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR TRADE
                    PRACTICE. EXCEPT FOR THE LIMITED WARRANTY DEFINED IN SECTION
                    11(a), CLOUDFORESTx DOES NOT WARRANT THAT THE SERVICES,
                    DOCUMENTATION, OR ANY MATERIALS AVAILABLE ON OR THROUGH THE
                    SERVICES OR THROUGH CLOUDFORESTx ARE OR WILL BE ACCURATE,
                    CURRENT, ERROR-FREE, VIRUS-FREE, RELIABLE, OR APPROPRIATE
                    FOR ANY PARTICULAR USE TO WHICH YOU OR ANY THIRD PARTY MAY
                    CHOOSE TO PUT THEM, THAT THE SYSTEM IS OR WILL BE AVAILABLE
                    ON AN UNINTERRUPTED AND ERROR-FREE BASIS, OR THAT DEFECTS
                    WILL BE CORRECTED. CLOUDFORESTx IS NOT THE PROVIDER OF AND
                    MAKES NO WARRANTIES FOR ANY THIRD-PARTY MATERIALS.
                  </li>
                </ol>
              </li>

              <li className="mt-20">
                <h6>DAMAGES; LIMITATION OF LIABILITY</h6>
                <ol>
                  <li>
                    <b>DAMAGES:</b> CLOUDFORESTx WILL NOT BE LIABLE UNDER THIS
                    AGREEMENT UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING
                    BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT
                    LIABILITY, AND OTHERWISE, FOR ANY (a) INDIRECT, INCIDENTAL,
                    CONSEQUENTIAL, EXEMPLARY, ENHANCED, PUNITIVE, OR SPECIAL
                    DAMAGES; (b) INCREASED COSTS, DIMINUTION IN VALUE OR LOST
                    BUSINESS, PRODUCTION, REVENUES, OR PROFITS; (c) LOSS OF
                    GOODWILL OR REPUTATION; (d) USE, INABILITY TO USE, LOSS,
                    INTERRUPTION, DELAY OR RECOVERY OF ANY DATA, OR BREACH OF
                    DATA OR SYSTEM SECURITY; OR (e) COST OF REPLACEMENT GOODS OR
                    SERVICES, IN EACH CASE REGARDLESS OF WHETHER OR NOT SUCH
                    DAMAGES WERE FORESEEABLE OR WHETHER OR NOT CLOUDFORESTx WAS
                    ADVISED THAT SUCH DAMAGES WERE LIKELY OR POSSIBLE.
                  </li>

                  <li className="mt10">
                    <b>LIMITATION OF LIABILITY:</b> IN NO EVENT WILL THE
                    AGGREGATE LIABILITY OF CLOUDFORESTx TO YOU FOR ANY AND ALL
                    CLAIMS ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT,
                    THE SERVICES, OR THE USE OF THE SERVICES EXCEED ONE HUNDRED
                    U.S. DOLLARS (U.S.D. $100.00). YOU ACKNOWLEDGE THAT THIS
                    LIMITATION OF LIABILITY IS AN ESSENTIAL TERM BETWEEN YOU AND
                    CLOUDFORESTx RELATING TO THE PROVISION OF THE SERVICES AND
                    CLOUDFORESTx WOULD NOT PROVIDE THE SERVICES TO YOU WITHOUT
                    THIS LIMITATION. YOU AGREE THAT CLOUDFORESTx WILL NOT BE
                    LIABLE TO YOU OR ANY OTHER PARTY FOR ANY TERMINATION OF YOUR
                    ACCESS TO THE SERVICES OR DELETION OF YOUR ACCOUNT OR
                    DOCUMENTATION.
                  </li>

                  <li className="mt-10">
                    Without limiting the foregoing, under no circumstances
                    either Party will be held liable for any delay or failure in
                    performance resulting directly or indirectly from forces of
                    nature, or causes beyond its reasonable control, including
                    internet failures, computer equipment failures,
                    telecommunication equipment failures, other equipment
                    failures, electrical power failures, strikes, labor
                    disputes, riots, insurrections, civil disturbances,
                    shortages of labor or materials, fires, floods, storms,
                    explosions, acts of God, war, governmental actions, orders
                    of domestic or foreign courts or tribunals, or
                    non-performance of third parties.
                  </li>
                </ol>
              </li>

              <li className="mt-20">
                <h6>INDEMNIFICATION:</h6>
                <p className="mt-10">
                  To the extent permitted by applicable law, You agree to
                  indemnify, hold harmless, and, at CloudForestx’s option,
                  defend CloudForestx, its officers, directors, employees, and
                  agents, from and against all claims, damages, obligations,
                  losses, liabilities, costs or debt, and expenses (including
                  but not limited to reasonable attorney’s fees) arising from
                  (i) Your use of and access to the Services in breach of this
                  Agreement; (ii) Your violation of any term of this Agreement;
                  or (iii) Your violation of any third party right, including
                  any copyright, property, or privacy right. If CloudForestx
                  elects to have You defend CloudForestx, You may not settle any
                  claim unless it unconditionally releases CloudForestx of all
                  liability and is limited to the payment of money damages,
                  which You agree to pay. This defense and indemnification
                  obligation will survive this Agreement and Your use of the
                  Services.
                </p>
              </li>

              <li className="mt-20">
                <h6>MISCELLANEOUS:</h6>
                <ol>
                  <li>
                    <b>Modification of Terms:</b> CloudForestx may change this
                    Agreement from time to time. Any such changes will become
                    effective when posted on www.cloudforestx.com. If You object
                    to such changes, Your sole recourse will be to cease using
                    the Services. Continued use of the Services following the
                    posting of any such changes will indicate Your
                    acknowledgment of such changes and Your agreement to be
                    bound by the revised Agreement, inclusive of such changes.
                  </li>

                  <li className="mt-10">
                    <b>Export Regulation:</b> The Services and Documentation may
                    be subject to US export control laws, including without
                    limitation the Export Control Reform Act and its associated
                    regulations. You shall not, directly or indirectly, export,
                    re-export, or release the Services or Documentation to, or
                    make the Services or Documentation accessible from, any
                    jurisdiction or country to which export, re-export, or
                    release is prohibited by law, rule, or regulation. You shall
                    comply with all applicable federal laws, regulations, and
                    rules, and complete all required undertakings (including
                    obtaining any necessary export license or other governmental
                    approval), before exporting, re-exporting, releasing, or
                    otherwise making the Services or Documentation available
                    outside the US.
                  </li>

                  <li className="mt-10">
                    <b>Third-Party Web Sites and Content:</b> The Services may
                    contain links to third-party websites or services that
                    Customers may use that are not owned or controlled by
                    CloudForestx (“Third-Party Materials”). CloudForestx has no
                    control over and assumes no responsibility for the
                    Third-Party Materials. CloudForestx makes no warranties
                    regarding such Third-Party Materials and will not be liable
                    for any loss or damage caused by Your use of or reliance on
                    such Third-Party Materials. CloudForestx does not endorse
                    any Third-Party Materials included in the Services.
                  </li>

                  <li className="mt-10">
                    <b>Electronic Communications:</b> The communications between
                    You and CloudForestx will be primarily electronic. For
                    contractual purposes, you (a) consent to receive
                    communications from CloudForestx in an electronic form and
                    (b) agree that all terms and conditions, agreements,
                    notices, disclosures, and other communications that
                    CloudForestx provides to you electronically have the same
                    effect as if they were provided in writing and signed by
                    CloudForestx and You in ink. The foregoing does not affect
                    your non-waivable rights.
                  </li>

                  <li className="mt-10">
                    <b>No Third-Party Beneficiaries:</b> There are no
                    third-party beneficiaries of this Agreement, and this
                    Agreement shall not provide any third person or entity with
                    any remedy, claim, liability, reimbursement, the claim of
                    action, or other legal or equitable rights.
                  </li>

                  <li className="mt-10">
                    <b>Arbitration:</b> This Agreement will be subject to and
                    construed per the laws of the State of New York, excluding
                    its rules regarding conflicts of law. Any controversy or
                    claim between the parties arising out of or relating to this
                    Agreement or the breach hereof shall be settled by binding
                    arbitration per the Commercial Arbitration Rules of the
                    American Arbitration Association, and judgment upon the
                    award rendered by the arbitrator(s) may be entered in any
                    court having jurisdiction thereof. The arbitration shall be
                    held in the city of Rochester, New York, and You may
                    participate in the arbitration via remote teleconference.
                    EACH PARTY IRREVOCABLY AND UNCONDITIONALLY WAIVES ANY RIGHT
                    IT MAY HAVE TO A TRIAL BY JURY IN RESPECT OF ANY LEGAL
                    ACTION ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE
                    TRANSACTIONS CONTEMPLATED HEREBY.
                  </li>

                  <li className="mt-10">
                    <b>Conflicting Terms:</b> Except as otherwise specifically
                    provided in this Agreement, in the event of a conflict
                    between the terms and conditions of this Agreement and any
                    separate services agreement (“Services Agreement”) executed
                    by You and an authorized CloudForestx representative, as
                    they apply to the relationship between CloudForestx and You,
                    the terms and conditions of that separate Services Agreement
                    shall govern. In the event of any conflict between the terms
                    and conditions of this Agreement and the DPA, the terms and
                    conditions of the DPA shall govern.
                  </li>

                  <li className="mt-10">
                    <b>Severability:</b> If any term or provision of this
                    Agreement is invalid, illegal, or unenforceable in any
                    jurisdiction, such invalidity, illegality, or
                    unenforceability shall not affect any other term or
                    provision of this Agreement or invalidate or render
                    unenforceable such term or provision in any other
                    jurisdiction.
                  </li>

                  <li className="mt-10">
                    <b>Survival:</b> The provisions defined in the following
                    sections, and any other right or obligation of the parties
                    in this Agreement that, by its nature, should survive
                    termination or expiration of this Agreement, will survive
                    any expiration or termination of this Agreement: Section 4,
                    Section 8(c), Section 9, Section 11(b), Section 12, Section
                    13, and this Section 15.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
        <div className="footerContainer">
          <div className="innerContainer12">
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EULA;
