import React, { memo, useRef, useState } from 'react';
import { CustomModal } from '../CustomModal/CustomModal';
import { addModalProp } from '../../../pages/RUM/AddSiteModal';
import CustomTextField from '../../MasterComponent/Input/CustomTextField';

export interface VisualizationForm {
  title: string;
  url: string;
  username: string;
  password: string;
}

const AddVisualizationModal: React.FC<addModalProp> = (props) => {
  const titleRef = useRef<HTMLInputElement | null>(null);
  const urlRef = useRef<HTMLInputElement | null>(null);
  const usernameRef = useRef<HTMLInputElement | null>(null);
  const passRef = useRef<HTMLInputElement | null>(null);
  const [isValidTitle, setIsValidTitle] = useState(true);
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [isValidUsername, setIsValidUsername] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);

  const { show, onClose, headerMsg, submitFunc } = props;

  const submitFunction = () => {
    const regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    const title = titleRef?.current?.value || '';
    const url = urlRef?.current?.value || '';
    const username = usernameRef?.current?.value || '';
    const password = passRef?.current?.value || '';

    if (!regex.test(url)) setIsValidUrl(false);
    else setIsValidUrl(true);
    if (!title.length) setIsValidTitle(false);
    else setIsValidTitle(true);
    if (!username.length) setIsValidUsername(false);
    else setIsValidUsername(true);
    if (!password.length) setIsValidPassword(false);
    else setIsValidPassword(true);

    if (regex.test(url) && title.length && username.length && password.length) {
      const visualizationFormData: VisualizationForm = {
        title,
        url,
        username,
        password,
      };
      submitFunc(visualizationFormData);
    }
  };

  return (
    <CustomModal
      show={show}
      onClose={onClose}
      headerMsg={headerMsg}
      isSubmitBtnDisabled={false}
      submitFunc={submitFunction}
    >
      <div className="addVisualizationContainer">
        <CustomTextField inputRef={titleRef} label="Title" />
        {!isValidTitle && <div className="regMsg"> Title is required.</div>}
        <CustomTextField inputRef={urlRef} label="Prometheus URL" />
        {!isValidUrl && <div className="regMsg"> Enter a valid URL.</div>}
        <CustomTextField inputRef={usernameRef} label="User Name" />
        {!isValidUsername && (
          <div className="regMsg"> Username is required.</div>
        )}
        <CustomTextField inputRef={passRef} label="Password" />
        {!isValidPassword && (
          <div className="regMsg"> Password is required.</div>
        )}
      </div>
    </CustomModal>
  );
};

export default memo(AddVisualizationModal);
