import { Grid } from '@material-ui/core';
import React, { Dispatch, SetStateAction } from 'react';
import Modal from 'react-modal';
import './ZoomInModal.css';
import '../../../pages/UptimeMonitoring/DetailMonitoring.css';
import { Replay, ZoomInMapOutlined } from '@mui/icons-material';
import GraphLoader from '../../../pages/ServicesReports/GraphLoader';
import MonitoringGraph from '../../UrlMonitoring/MonitoringGraph';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

interface deleteModal {
  show: boolean;
  onClose?: { (): void };
  metaData: any;
  setSelectedInterval: Dispatch<SetStateAction<number | null>>;
  selectedInterval: number | null;
  setIsGraphIntervalChanged: Dispatch<SetStateAction<boolean>>;
  // URLValue: any;
}

const ZoomInModal: React.FC<deleteModal> = ({
  show,
  onClose,
  metaData,
  selectedInterval,
  setIsGraphIntervalChanged,
  setSelectedInterval,
  // URLValue,
}) => {
  return (
    <Modal
      isOpen={show}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      bodyOpenClassName="no-scroll"
      ariaHideApp={false}
      style={{ overlay: { background: 'rgba(0,0,0,.5)', zIndex: 9999 } }}
      className="fixModal zoomInModalWidth"
    >
      <Grid container spacing={0}>
        <Grid item md={12} sm={12} xs={12} className="">
          <div className="flex-end align-center p-10 toggleBtnGroup">
            <ToggleButtonGroup
              value={selectedInterval}
              exclusive
              onChange={(e: any) => {
                e.target.value === ''
                  ? setIsGraphIntervalChanged(false)
                  : setIsGraphIntervalChanged(true);
                setSelectedInterval(
                  e.target.value === '' ? null : Number(e.target.value)
                );
              }}
              aria-label="Time Interval"
              style={{ marginRight: '1rem' }}
            >
              {[
                { value: '15', label: '15m' },
                { value: '30', label: '30m' },
                { value: '60', label: '1h' },
              ].map((button) => (
                <ToggleButton
                  key={button.value}
                  value={button.value}
                  aria-label={`${button.label}`}
                  sx={{
                    backgroundColor:
                      selectedInterval && selectedInterval === +button.value
                        ? '#e0f3ff'
                        : 'transparent', // Highlight selected button
                  }}
                >
                  {+button.value < 60
                    ? `${+button.value}m`
                    : `${+button.value / 60}h`}
                </ToggleButton>
              ))}

              <ToggleButton value="" aria-label="Reset">
                <Replay sx={{ fontSize: '1.6rem' }} />
              </ToggleButton>
            </ToggleButtonGroup>
            <ZoomInMapOutlined
              className="zoomOutIcon cursor-pointer"
              sx={{
                fontSize: '25px',
                borderRadius: '10px',
                color: 'gray',
              }}
              onClick={onClose}
            />
          </div>
        </Grid>
        <div className="zoomInModalGraphContainer">
          {!Object.values(metaData).flat().length ? (
            <GraphLoader isLoading={false} />
          ) : (
            <MonitoringGraph
              urlData={metaData}
              grid={{
                left: '2%',
                right: '2%',
                bottom: '0%',
                height: '98%',
                top: '2%',
                containLabel: true,
              }}
              style={{
                width: '100%',
                height: '85%',
                marginTop: '-1rem',
                padding: 0,
              }}
            />
          )}
        </div>
      </Grid>
    </Modal>
  );
};

export default ZoomInModal;
