import React, { useCallback } from 'react';
import * as echarts from 'echarts';
import { Box, Divider, LinearProgress } from '@mui/material';
import styles from './ApdexMetricCard.module.css';
import Chart from '../../MasterComponent/Echarts';
import CustomTooltip from '../../MasterComponent/CustomTooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ApdexDataI } from '../../../pages/RUM/Definition';

interface StatusProps {
  label: string;
  percentage: number;
  color: string;
}

const ApdexScore: React.FC<{ apdexObj: ApdexDataI }> = (props: {
  apdexObj: ApdexDataI;
}) => {
  const {
    apdexScore,
    frustratedCount,
    frustratedPercentage,
    satisfiedCount,
    satisfiedPercentage,
    satisfiedThreshold,
    secondsWiseData,
    toleranceCount,
    tolerancePercentage,
    toleranceThreshold,
  } = props.apdexObj;

  const StatusItem: React.FC<StatusProps> = ({ label, percentage, color }) => {
    return (
      <>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100px' }}>
          <Box
            sx={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: color,
              mr: 1,
            }}
          />
          <div className={styles.statLabel}>{label}</div>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <div
            className={styles.statValue}
            style={{ marginTop: 0, minWidth: '26px' }}
          >
            {(percentage ? percentage : 0).toFixed(2)}%
          </div>
          <Box sx={{ width: '100%', ml: 5 }}>
            <LinearProgress
              variant="determinate"
              value={percentage}
              sx={{
                height: '8px',
                borderRadius: '5px',
                backgroundColor: '#e0e0e0',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: color,
                },
              }}
            />
          </Box>
        </Box>
      </>
    );
  };

  const apdexHandler = useCallback(() => {
    const apdexOption: echarts.EChartsOption = {
      series: [
        {
          name: 'Apdex Score',
          type: 'gauge',
          startAngle: 90,
          endAngle: -270,
          radius: '70rem',
          pointer: {
            show: false,
          },
          progress: {
            show: true,
            overlap: true,
            roundCap: true,
            clip: false,
            itemStyle: {
              color: '#0078db',
            },
          },
          axisLine: {
            lineStyle: {
              width: 10,
              color: [[1, '#e0e0e0']],
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          data: [
            {
              value: +apdexScore * 100,
              name: 'APDEX SCORE',
              title: {
                offsetCenter: ['0%', '20%'],
              },
              detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '-25%'],
                fontSize: 30,
                color: '#066bc2',
                fontWeight: 'bold',
              },
            },
          ],
          title: {
            fontSize: 14,
            fontWeight: 'bold',
            color: '#aaaaaa',
          },
          detail: {
            formatter: (value: number) => (value / 100).toFixed(2),
            fontSize: 14,
            color: '#333',
          },
        },
      ],
    };

    return apdexOption;
  }, []);

  const loadTimeDistributionHandler = useCallback(() => {
    const loadTimeDistributionOption: echarts.EChartsOption = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: (params: any) => {
          const loadTime = params[0].axisValueLabel;
          const pageviews = params[0].data.value;
          const secondValue = parseInt(loadTime.replace('s', ''), 10);

          let apdexMessage = '';
          if (secondValue <= satisfiedThreshold) {
            apdexMessage = 'Satisfied';
          } else if (secondValue <= toleranceThreshold) {
            apdexMessage = 'Tolerating';
          } else {
            apdexMessage = 'Frustrated';
          }

          return `
    <div style="padding: 5px; border-radius: 5px; ">
      <div style="font-weight: bold; font-size: 16px; margin-bottom: 8px; width: fit-content; ">
        ${secondValue}s - ${secondValue + 1}s load time
      </div>
      <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 8px;">
        <p style="color: #0078db;font-weight: 600">Pageviews:</p>
        <p style="font-weight: bold; color: #333;">${pageviews}</p>
      </div>
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <p style="font-weight: 600">Apdex:</p>
        <p style="font-weight: bold; color: ${
          apdexMessage === 'Satisfied'
            ? 'green'
            : apdexMessage === 'Tolerating'
            ? 'orange'
            : 'red'
        };">${apdexMessage}</p>
      </div>
    </div>
  `;
        },
      },
      grid: {
        left: '3%',
        right: '2%',
        bottom: '0%',
        top: '20%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',

        data: Object.keys(secondsWiseData).map((key) => `${key}s`),
      },
      yAxis: {
        type: 'value',
        name: 'Pageviews',
        nameTextStyle: {
          fontWeight: 'bold',
        },
        minInterval: 1,
        axisLabel: {
          formatter: '{value}',
        },
      },
      series: [
        {
          name: 'Satisfied',
          type: 'bar',
          stack: 'total',
          data: Object.entries(secondsWiseData).map(([second, count]) => {
            const secondValue = parseInt(second, 10);
            let color = '#66BB6A'; // Default to satisfied (green)

            if (
              secondValue > satisfiedThreshold &&
              secondValue <= toleranceThreshold
            ) {
              color = '#FFA726'; // Tolerating (orange)
            } else if (secondValue > toleranceThreshold) {
              color = '#EF5350'; // Frustrated (red)
            }

            return {
              value: count,
              itemStyle: {
                color: color,
              },
            };
          }),
          barWidth: 18,
        },
      ],
    };
    return loadTimeDistributionOption;
  }, []);

  return (
    <Box className={styles.container}>
      {/* Apdex Donut Chart */}
      <div style={{ width: '43rem' }}>
        <div
          className="section-title descriptionCardTitle"
          style={{
            textAlign: 'left',
            marginBottom: '10px',
            paddingBottom: '0px',
            width: '8rem',
          }}
        >
          Apdex
          <CustomTooltip
            title={{
              html: `
                                <p>Apdex (Application Performance Index) measures the satisfaction of your site’s visitors by evaluating their load times.</p> 
                      
                                  
                                  <p>It’s a performance index that ranges from 0 to 1, with higher values indicating better user experience.</p>
                      `,
            }}
          >
            <HelpOutlineIcon />
          </CustomTooltip>
        </div>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            width: '100%',
            marginTop: '9%',
          }}
        >
          <Box className={styles.chartContainer}>
            <Chart
              option={apdexHandler()}
              style={{ height: '180px', width: '15rem' }}
            />
          </Box>

          {/* Percentage Indicators */}
          <Box className={styles.percentageContainer}>
            <StatusItem
              label="Satisfied"
              percentage={satisfiedPercentage}
              color="green"
            />
            <StatusItem
              label="Tolerating"
              percentage={tolerancePercentage}
              color="orange"
            />
            <StatusItem
              label="Frustrated"
              percentage={frustratedPercentage}
              color="red"
            />
          </Box>
        </Box>
      </div>
      <Divider orientation="vertical" variant="middle" flexItem />
      {/* Load Time Distribution Chart */}
      <div>
        <div
          className="section-title descriptionCardTitle"
          style={{
            textAlign: 'left',
            marginBottom: '10px',
            paddingBottom: '0px',
            width: '34rem',
          }}
        >
          Load time distribution by pageviews
          <CustomTooltip title="This period displays the distribution of pageviews alongside the corresponding load times.">
            <HelpOutlineIcon />
          </CustomTooltip>
        </div>
        <Box className={styles.loadTimeChartContainer}>
          <div className={styles.distributionStatsContainer}>
            <div className={styles.statItem}>
              <div className={styles.percentageRow} style={{ marginBottom: 0 }}>
                <div className={`${styles.dot} ${styles.satisfiedDot}`} />
                <span className={styles.statLabel} style={{ fontSize: '18px' }}>
                  Satisfied
                </span>
              </div>
              <div>
                <div className={styles.statValue}>
                  {satisfiedCount.toLocaleString()}
                </div>
              </div>
            </div>
            <div className={styles.statItem}>
              <div className={styles.percentageRow} style={{ marginBottom: 0 }}>
                <div className={`${styles.dot} ${styles.toleratingDot}`} />
                <span className={styles.statLabel} style={{ fontSize: '18px' }}>
                  Tolerating
                </span>
              </div>
              <div>
                <div className={styles.statValue}>
                  {toleranceCount.toLocaleString()}
                </div>
              </div>
            </div>
            <div className={styles.statItem}>
              <div className={styles.percentageRow} style={{ marginBottom: 0 }}>
                <div className={`${styles.dot} ${styles.frustratedDot}`} />
                <span className={styles.statLabel} style={{ fontSize: '18px' }}>
                  Frustrated
                </span>
              </div>
              <div>
                <div className={styles.statValue}>
                  {frustratedCount.toLocaleString()}
                </div>
              </div>
            </div>
          </div>
          <Chart
            option={loadTimeDistributionHandler()}
            style={{ height: '150px' }}
          />
        </Box>
      </div>
    </Box>
  );
};

export default ApdexScore;
