import React, { memo, useEffect, useState } from 'react';
import { Box, IconButton, Paper, Tooltip } from '@mui/material';
import * as echarts from 'echarts';
import './Dashboard.css';
import RUMChart from '../../components/MasterComponent/Echarts';
import CustomButton from '../../components/MasterComponent/Button/CustomButton';
import { useHistory } from 'react-router-dom';
import { RoutesFun } from '../../schemas';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ERRORS } from '../../utils/constants';
import { handleError } from '../../utils/config';
import { config, MONITORING_DATA_TYPE } from './config';
import { RUMClientsData } from './interfaces';
import { AddSiteModal, SiteData } from './AddSiteModal';
import { ScriptModal } from './scriptSnippetModal';
import moment from 'moment';
import TitleHeader from '../../components/MasterComponent/TitleHeader/TitleHeader';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteModal from '../../components/Modals/DeleteModal/DeleteModal';
import { useDispatch } from 'react-redux';
import { userActions } from '../../store/userSlice';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CustomTooltip from '../../components/MasterComponent/CustomTooltip';
import useSocket from '../../hooks/useSocket';
import SkeletonRUMCard from '../../components/RUM/DashboardSkeleton';
const RUMDashboard: React.FC = () => {
  const history = useHistory();
  const Routes = RoutesFun();
  const dispatch = useDispatch();
  const [networkError, setNetworkError] = useState('');
  const [loader, setLoader] = useState<boolean>(false);
  const [RUMData, setRUMData] = useState<RUMClientsData[]>();
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showScriptModal, setShowScriptModal] = useState<boolean>(false);
  const [showCopyScriptModal, setShowCopyScriptModal] = useState<boolean>(
    false
  );
  const [script, setScript] = useState<string>('');
  const [showCopiedScript, setCopiedScript] = useState<string>('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedClientName, setSelectedClientName] = useState('');
  const [selectedClientId, setSelectedClientId] = useState('');
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  useSocket('RumNotification', (socketData: any) => {
    // Update the RUMData state with real-time data
    const updatedData = RUMData?.map((client) => {
      if (client.clientId === socketData.clientId) {
        return { ...client, ...socketData.activeSession[0] };
      }
      return client;
    });

    setRUMData(updatedData);
  });

  const onCloseDeleteModal = () => setDeleteModal(false);

  const seriesData = (client: RUMClientsData, dataType: string) => {
    if (dataType === MONITORING_DATA_TYPE.ACTIVE_SESSION) {
      return client.sessionsData.allSessions.map((item: any) => ({
        // value: [new Date(item.time).getTime(), item.sessionCount],
        value: [item.time, item.sessionCount],
      }));
    } else {
      return client.loadTimeData.loadTimeAllData.map((item: any) => ({
        // value: [
        //   new Date(item.time).getTime(),
        //   (item?.loadTime / 1000).toFixed(2),
        // ],
        value: [item.time, (item?.loadTime / 1000).toFixed(2)],
      }));
    }
  };

  if (networkError) {
    throw new Error(networkError);
  }

  const detailHandler = (value: RUMClientsData) => {
    history.push({
      pathname:
        Routes.RealUserMonitoring +
        `/${value.name.trim().toLowerCase().replace(/\s+/g, '-')}`,
      state: { data: value },
    });
  };

  const RUMDataHandler = async () => {
    try {
      setLoader(true);
      const result = await config.getRUMData({});
      if (result) setRUMData(result);
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };
  const addWebsiteHandler = async () => {
    setShowAddModal(true);
  };
  const addSiteCloseHandler = async () => {
    setShowAddModal(false);
  };
  const scriptModalCloseHandler = () => {
    setShowScriptModal(false);
    RUMDataHandler();
  };

  const closeCopyScriptHandler = () => setShowCopyScriptModal(false);
  const addSiteSubmitHandler = async (siteData: SiteData) => {
    try {
      const addSiteResult = await config.addRumSite(siteData);
      if (addSiteResult && addSiteResult.script) {
        const scriptTag = `<script src="${addSiteResult.script}" async></script>`;
        setScript(scriptTag);
      }
    } catch (error) {
    } finally {
      setShowAddModal(false);
      setShowScriptModal(true);
    }
  };
  const getScriptHandler = async (client: string) => {
    try {
      const response = await config.getScript({ client });
      if (response) {
        setCopiedScript(
          `<script src="${response.data?.script}" async></script>`
        );
        setShowCopyScriptModal(true);
      }
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    RUMDataHandler();
  }, []);

  const graphOptionHandler = (client: RUMClientsData, dataType: string) => {
    const option: echarts.EChartsOption = {
      tooltip: {
        trigger: 'axis',
        formatter: (params: any) => {
          const date = moment(params[0]?.value[0]);
          return `<div style="color: #0078db; font-weight: bold;">${date.format(
            'DD-MM-YYYY'
          )} ${date.format('LT')}</div><br/><div style="color: #0078db;">${
            dataType === MONITORING_DATA_TYPE.ACTIVE_SESSION
              ? params[0]?.value[1]
              : `${params[0]?.value[1]}s`
          } ${
            dataType === MONITORING_DATA_TYPE.ACTIVE_SESSION ? 'sessions' : ''
          }</div>`;
        },
        confine: true,
        enterable: true,
        borderColor: '#0078db', // Blue border
        borderWidth: 1,
        textStyle: {
          color: '#0078db', // Blue text color
        },
      },
      confine: true,
      enterable: true,
      xAxis: {
        type: 'time',
        splitNumber: 6,
        axisLabel: {
          formatter: (value: number) => {
            const date = new Date(value);

            return date.toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
            });
          },
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: `{value}${
            dataType === MONITORING_DATA_TYPE.LOADTIME ? 's' : ''
          }`,
        },
      },
      series: [
        {
          type: 'line',
          smooth: true,
          data: seriesData(client, dataType),
          showSymbol: false, // Hide the symbols (points) on the line
        },
      ],
      dataZoom: [
        {
          type: 'inside',
          show: true,
          start: 0,
          end: 100,
          // Enable the scrollable option for the dataZoom
        },
      ],
      color: ['#0078db'],
      grid: {
        left: '3%',
        right: '3%',
        bottom: '3%',
        containLabel: true,
      },
      graphic: (
        dataType === MONITORING_DATA_TYPE.ACTIVE_SESSION
          ? client.sessionsData.allSessions.length === 0
          : client.loadTimeData.loadTimeAllData.length === 0
      )
        ? {
            type: 'text',
            left: 'center',
            top: 'middle',
            style: {
              text: 'No Records',
              align: 'center',
              fontSize: 15,
            },
          }
        : [],
    };
    return option;
  };
  const handleDeleteClick = async () => {
    try {
      setIsBtnDisabled(true);
      const params = {
        client: selectedClientId,
      };
      const result = await config.deleteRumClient(params);
      if (result) {
        dispatch(
          userActions.replaceUserState({
            successMessage: result.message,
          })
        );
      }
    } catch (e: any) {
      setNetworkError(e === ERRORS.NETWORK_ERROR ? e : '');

      if (e && e.error && e.error.message) {
        handleError(e, history);
      }
    } finally {
      setIsBtnDisabled(false);
      setDeleteModal(false);
      RUMDataHandler();
    }
  };

  const rumFieldData = (data: any, unit: string) =>
    data === null || data === undefined ? 'No data' : data?.toFixed(2) + unit;

  const infoIcon = (
    <HelpOutlineIcon
      style={{
        fontSize: '16px',
        cursor: 'pointer',
        color: '#666',
      }}
    />
  );

  return (
    <div className="RUMContainer">
      <TitleHeader
        extraContent={
          <CustomButton
            buttonType="contained"
            onClickHandler={addWebsiteHandler}
          >
            ADD WEBSITE
          </CustomButton>
        }
      >
        Real User Monitoring
      </TitleHeader>

      <div className="RUMDashboardCardContainer">
        {loader ? (
          <SkeletonRUMCard />
        ) : (
          <>
            {RUMData &&
              RUMData.map((client: RUMClientsData) => {
                return (
                  <Box key={client.clientId} className="dashboard-container">
                    <div className="RUMCardHeader">
                      <h5>
                        {client.name}
                        <a
                          href={client.domain}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="RUMdomain-link"
                        >
                          ({client.domain})
                          <OpenInNewIcon
                            style={{ fontSize: '14px', marginLeft: '4px' }}
                          />
                        </a>
                      </h5>
                      <div className="rumCardRightHeader">
                        <CustomButton
                          buttonType="outlined"
                          onClickHandler={() => detailHandler(client)}
                          style={{ fontSize: '12px' }}
                        >
                          VIEW DETAILS
                        </CustomButton>
                        <Tooltip title="copy script">
                          <IconButton
                            onClick={() => getScriptHandler(client.clientId)}
                          >
                            <ContentCopyIcon fontSize="large" />
                          </IconButton>
                        </Tooltip>
                        <IconButton
                          onClick={() => {
                            setSelectedClientName(client.name);
                            setSelectedClientId(client.clientId);
                            setDeleteModal(true);
                          }}
                        >
                          <DeleteIcon fontSize="large" />
                        </IconButton>
                      </div>
                    </div>
                    <Paper className="dashboard-card d-flex">
                      <div>
                        <div className="activeLoadBoxes">
                          <div>
                            <p
                              className="card-title flex"
                              style={{
                                justifyContent: 'space-between',
                                width: '14rem',
                                alignItems: 'end',
                              }}
                            >
                              Active Sessions{' '}
                              <CustomTooltip title="The number of sessions with at least one interaction in the past 5 minutes.">
                                {infoIcon}
                              </CustomTooltip>
                            </p>
                            <p className="card-value font-35px">
                              {client.sessionsData.activeSessions}
                            </p>
                          </div>
                          <div className="comparison">
                            <div>
                              <span>1 hour ago</span>
                              <span className="colon">:</span>
                              <span>{client.sessionsData.hourAgo}</span>
                            </div>
                            <div>
                              <span>1 day ago</span>
                              <span className="colon">:</span>
                              <span>{client.sessionsData.dayAgo}</span>
                            </div>
                            <div>
                              <span>1 week ago</span>
                              <span className="colon">:</span>
                              <span>{client.sessionsData.weekAgo}</span>
                            </div>
                          </div>
                        </div>
                        <RUMChart
                          option={graphOptionHandler(
                            client,
                            MONITORING_DATA_TYPE.ACTIVE_SESSION
                          )}
                          style={{ height: '20rem' }}
                        />
                      </div>
                      <div className="breakLine"></div>
                      <div>
                        <div className="activeLoadBoxes">
                          <div>
                            <p
                              className="card-title flex"
                              style={{
                                justifyContent: 'space-between',
                                width: '10.5rem',
                                alignItems: 'end',
                              }}
                            >
                              Load Time{' '}
                              <CustomTooltip title="The time it takes to download and display all synchronous assets on your page. This is measured from the start of navigation to the start of the load event.">
                                {infoIcon}
                              </CustomTooltip>
                            </p>
                            <p className="card-value font-35px">
                              {rumFieldData(
                                +client.loadTimeData.loadTime / 1000,
                                's'
                              )}
                            </p>
                          </div>
                          <div className="comparison">
                            <div>
                              <span>1 hour ago</span>
                              <span className="colon">:</span>
                              <span>
                                {rumFieldData(
                                  +client.loadTimeData.hourAgo / 1000,
                                  's'
                                )}
                              </span>
                            </div>
                            <div>
                              <span>1 day ago</span>
                              <span className="colon">:</span>
                              <span>
                                {rumFieldData(
                                  +client.loadTimeData.dayAgo / 1000,
                                  's'
                                )}
                              </span>
                            </div>
                            <div>
                              <span>1 week ago</span>
                              <span className="colon">:</span>
                              <span>
                                {rumFieldData(
                                  +client.loadTimeData.weekAgo / 1000,
                                  's'
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                        <RUMChart
                          option={graphOptionHandler(
                            client,
                            MONITORING_DATA_TYPE.LOADTIME
                          )}
                          style={{ height: '20rem' }}
                        />
                      </div>
                      <div className="breakLine"></div>

                      <div className="RUMSecondSection">
                        <div className="RUMSecSubSection">
                          <div>
                            <p className="card-title">
                              Pageviews/Min (NOW){' '}
                              <span>
                                <CustomTooltip title="Shows the load on your site server.">
                                  {infoIcon}
                                </CustomTooltip>
                              </span>
                            </p>
                            <p className="card-value font-22px">
                              {Number(client.pageViewData.views).toFixed(2)}
                            </p>
                          </div>
                          <div className="comparison">
                            <div>
                              1 hour ago:{' '}
                              <span className="ml-5">
                                {Number(client.pageViewData?.hourAgo).toFixed(
                                  2
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="middleBreakLine"></div>
                        <div className="RUMSecSubSection">
                          <div>
                            <p className="card-title">
                              Bounce Rate (NOW){' '}
                              <span>
                                <CustomTooltip title="The percentage of visitors who leave your site after viewing just one page.">
                                  {infoIcon}
                                </CustomTooltip>
                              </span>
                            </p>
                            <p className="card-value font-22px">
                              {rumFieldData(
                                client.bounceRateData.bounceRate,
                                '%'
                              )}
                            </p>
                          </div>
                          <div className="comparison">
                            <div>
                              1 hour ago:{' '}
                              <span className="ml-5">
                                {rumFieldData(
                                  client.bounceRateData.hourAgo,
                                  '%'
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* Adding Apdex Score Section */}
                        <div className="middleBreakLine"></div>

                        <div className="RUMSecSubSection">
                          <div>
                            <p className="card-title">
                              Apdex Score{' '}
                              <span>
                                <CustomTooltip
                                  title={{
                                    html: `
                                <p>Apdex (Application Performance Index) measures the satisfaction of your site’s visitors by evaluating their load times.</p> 
                      
                                  
                                  <p>It’s a performance index that ranges from 0 to 1, with higher values indicating better user experience.</p>
                      `,
                                  }}
                                >
                                  {infoIcon}
                                </CustomTooltip>
                              </span>
                            </p>
                            <p className="card-value font-22px">
                              {rumFieldData(client.apdexData.apdexScore, '')}
                              {/* {rumFieldData(client.apdexScore, '')} Assuming apdexScore is available */}
                            </p>
                          </div>

                          <div className="comparison">
                            <div style={{ alignItems: 'end' }}>
                              1 hour ago:{' '}
                              <span className="ml-5">
                                {rumFieldData(client.apdexData.hourAgo, '')}
                              </span>
                            </div>
                            {/* {rumFieldData(client.apdexScoreData.hourAgo, '')} Assuming apdexScoreData is available */}
                          </div>
                        </div>
                      </div>
                    </Paper>
                  </Box>
                );
              })}
          </>
        )}
      </div>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onClose={onCloseDeleteModal}
          metaData={selectedClientName}
          deleteFunction={handleDeleteClick}
          isDisabled={isBtnDisabled}
        />
      )}
      {showAddModal && (
        <AddSiteModal
          show={showAddModal}
          headerMsg="Add Site"
          onClose={addSiteCloseHandler}
          submitFunc={addSiteSubmitHandler}
        />
      )}

      {showCopyScriptModal && (
        <ScriptModal
          headerMsg="Code snippet"
          show={showCopyScriptModal}
          onClose={closeCopyScriptHandler}
          scriptTag={showCopiedScript}
        />
      )}

      {showScriptModal && (
        <ScriptModal
          headerMsg="Code snippet"
          show={showScriptModal}
          onClose={scriptModalCloseHandler}
          scriptTag={script}
        />
      )}
    </div>
  );
};

export default memo(RUMDashboard);
