import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import './SupportPage.css';
import { EULA } from './EULA/EULA';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import Pricing from './Pricing/Pricing';
import Demo from './Demo/Demo';
import CostManagement from './CostManagement/CostManagement';
import RIU from './resourceInventoryUtilization/riu';
import RegistrationLogin from '../Registration/RegistrationLogin';
import ResetPassword from '../ResetPassword/ResetPassword';
import CloudCheckup from './CloudCheckup/CloudCheckup';
import AzureSpend from './AzureSpend/AzureSpend';
import RightsizingDetail from './RightsizingDetail/RightsizingDetails';
import SchedulingDetail from './SchedulingDetail/SchedulingDetail';
import OptimizeSA from '../SupportPages/Home/MultipleHomePages/HomeOptimizeSA';
import RightsizingSA from '../SupportPages/Home/MultipleHomePages/HomeRightsizingSA';
import SchedulingSA from '../SupportPages/Home/MultipleHomePages/HomeSchedulingSA';
import CostAnalysisSA from '../SupportPages/Home/MultipleHomePages/HomeCostAnalysisSA';
import QuickPainlessSA from '../SupportPages/Home/MultipleHomePages/HomeQuickPainlessSA';
import ReactGA from 'react-ga';
// import UploadedDocumentStatic from './UploadedDocumentsStatic/UploadedDocumentStatic';
import { RoutesFun } from '../../schemas';
const LoginPage = lazy(() => import('../LoginPage/LoginPage'));
const UploadedDocumentStatic = lazy(
  () => import('./UploadedDocumentsStatic/UploadedDocumentStatic')
);
ReactGA.initialize('UA-213427208-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export const SupportPage: React.FC = () => {
  const scrollEvent = () => {
    // console.log('scrollEvent', window.scrollY, window.scrollX);
  };
  const Routes = RoutesFun();
  // useEffect(() => {
  //   //   (function(h,o,t,j,a,r){
  //   //     h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  //   //     h._hjSettings={hjid:2756836,hjsv:6};
  //   //     a=o.getElementsByTagName('head')[0];
  //   //     r=o.createElement('script');r.async=1;
  //   //     r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  //   //     a.appendChild(r);
  //   //   })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  //   // hotjar.initialize(2756836, 6);
  // }, []);

  return (
    <React.Fragment>
      <div className="outerContainer scrollContainer" onScroll={scrollEvent}>
        <Suspense fallback>
          <Switch>
            <Route exact path={Routes.EULA} component={EULA} />
            <Route
              exact
              path={Routes.PrivacyPolicy}
              component={PrivacyPolicy}
            />
            <Route exact path={Routes.Pricing} component={Pricing} />
            <Route exact path={Routes.Demo} component={Demo} />

            <Route exact path={Routes.HomePage} component={LoginPage} />
            <Route exact path={Routes.OptimizeSA} component={OptimizeSA} />
            <Route
              exact
              path={Routes.RightsizingSA}
              component={RightsizingSA}
            />
            <Route exact path={Routes.SchedulingSA} component={SchedulingSA} />
            <Route
              exact
              path={Routes.CostAnalysisSA}
              component={CostAnalysisSA}
            />
            <Route
              exact
              path={Routes.QuickPainlessSA}
              component={QuickPainlessSA}
            />

            <Route
              exact
              path={Routes.CostManagement}
              component={CostManagement}
            />
            <Route exact path={Routes.riu} component={RIU} />
            <Route
              exact
              path={Routes.RegistrationLogin}
              component={RegistrationLogin}
            />
            <Route exact path={Routes.Login} component={LoginPage} />
            <Route
              exact
              path={Routes.ResetPassword}
              component={ResetPassword}
            />
            <Route exact path={Routes.CloudCheckup} component={CloudCheckup} />
            <Route exact path={Routes.AzureSpend} component={AzureSpend} />
            <Route
              exact
              path={Routes.RightsizingDetail}
              component={RightsizingDetail}
            />
            <Route
              exact
              path={Routes.SchedulingDetail}
              component={SchedulingDetail}
            />
            <Route
              exact
              path={Routes.UploadedDocumentStatic}
              component={UploadedDocumentStatic}
            />
          </Switch>
        </Suspense>
      </div>
    </React.Fragment>
  );
};

export default SupportPage;
