import React from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import RegistrationForm from './pages/Registration/RegistrationForm';
import { Dashboard } from './components/Dashboard/Dashboard';
import { RoutesFun } from './schemas/index';
import { ProtectedRoute } from './components/AuthenticatedRoute/AuthenticatedRoute';
import CostSaving from './pages/CostSaving/CostSaving';
import { UserProfile } from './pages/UserProfile/UserProfile';
import {
  AccountDetailsPage2,
  CreateAccountPage,
  ConfigureAccountPage,
} from './pages';
import { ConfigureAccountNextPage } from './pages/Accounts/ConfigureAccounts/ConfigureAccountNext';
import SupportPage from './pages/SupportPages/SupportPage';
import Error404 from './pages/Errors/404';
import Error403 from './pages/Errors/403';
import UploadedDocumentStatic from './pages/SupportPages/UploadedDocumentsStatic/UploadedDocumentStatic';

export const Router: React.FC = () => {
  const Routes = RoutesFun();
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={Routes.HomePage} component={SupportPage} />
        <Route exact path={Routes.Login} component={SupportPage} />
        <Route exact path={Routes.ResetPassword} component={SupportPage} />
        <Route exact path={Routes.RegistrationLogin} component={SupportPage} />
        {/* <Route
          exact
          path={Routes.RegistrationForm}
          component={RegistrationForm}
        /> */}
        <ProtectedRoute exact path={Routes.CostSaving} component={Dashboard} />
        <ProtectedRoute
          exact
          path={Routes.SpendingDetails}
          component={Dashboard}
        />
        <Route exact path={Routes.UserProfile} component={UserProfile} />
        <ProtectedRoute exact path={Routes.UsersList} component={Dashboard} />
        <ProtectedRoute
          exact
          path={Routes.ServiceWiseSpendingDetails}
          component={Dashboard}
        />
        <ProtectedRoute
          exact={true}
          path={Routes.CostForecasting}
          component={Dashboard}
        />
        <ProtectedRoute
          exact
          path={[
            Routes.IdleInstance,
            Routes.IdleInstancewithType,
            Routes.IdleInstancewithResName,
          ]}
          component={Dashboard}
        />
        <ProtectedRoute
          exact
          path={Routes.IdleInstanceDetail}
          component={Dashboard}
        />
        <ProtectedRoute
          exact
          path={Routes.TagDetailWithValue}
          component={Dashboard}
        />
        <ProtectedRoute exact path={Routes.Inventories} component={Dashboard} />
        <ProtectedRoute
          exact
          path={Routes.InventoryDetail}
          component={Dashboard}
        />
        <ProtectedRoute
          exact
          path={[Routes.RightSizing, Routes.RightSizingwithType]}
          component={Dashboard}
        />
        <ProtectedRoute exact path={Routes.ScheduleVM} component={Dashboard} />
        <ProtectedRoute exact path={Routes.Invoice} component={Dashboard} />
        <ProtectedRoute
          exact
          path={Routes.SecurityScan}
          component={Dashboard}
        />
        <ProtectedRoute
          exact
          path={Routes.InventoryReports}
          component={Dashboard}
        />
        <ProtectedRoute
          exact
          path={Routes.ServicesReports}
          component={Dashboard}
        />
        <ProtectedRoute
          exact
          path={Routes.InventoryResourceReports}
          component={Dashboard}
        />
        <ProtectedRoute
          exact
          path={Routes.AppDashboard}
          component={Dashboard}
        />
        <ProtectedRoute exact path={Routes.CostSaving} component={CostSaving} />
        <ProtectedRoute
          exact={true}
          path={Routes.AccountDetails}
          component={AccountDetailsPage2}
        />
        //--------------------------------
        <ProtectedRoute
          exact={true}
          path={'/accounts2'}
          component={AccountDetailsPage2}
        />
        //------------------------------------------
        <ProtectedRoute
          exact={true}
          path={Routes.CreateAccount}
          component={CreateAccountPage}
        />
        <ProtectedRoute
          exact={true}
          path={Routes.EditAccount}
          component={CreateAccountPage}
        />
        <ProtectedRoute
          exact={true}
          path={Routes.ConfigureAccount}
          component={ConfigureAccountPage}
        />
        <ProtectedRoute
          exact={true}
          path={Routes.ConfigureAccountNext}
          component={ConfigureAccountNextPage}
        />
        <ProtectedRoute
          exact={true}
          path={Routes.Error403}
          component={Error403}
        />
        <ProtectedRoute
          exact={true}
          path={Routes.NotificationManagement}
          component={Dashboard}
        />
        <ProtectedRoute
          exact={true}
          path={Routes.Billing}
          component={Dashboard}
        />
        <ProtectedRoute
          exact={true}
          path={Routes.PlanDetails}
          component={Dashboard}
        />
        <ProtectedRoute
          exact={true}
          path={[Routes.UptimeMonitoring, Routes.UptimeMonitoringWithType]}
          component={Dashboard}
        />
        <ProtectedRoute
          exact={true}
          path={[Routes.SystemHealthMonitoring]}
          component={Dashboard}
        />
        <ProtectedRoute
          exact={true}
          path={[Routes.RealUserMonitoring, Routes.RUMDescription]}
          component={Dashboard}
        />
        <ProtectedRoute
          exact={true}
          path={Routes.HighestConsumedResource}
          component={Dashboard}
        />
        <ProtectedRoute
          exact={true}
          path={Routes.RightsizingAndIdle}
          component={Dashboard}
        />
        <Route
          exact={true}
          path={Routes.UploadedDocuments}
          component={Dashboard}
        />
        {/* //admin panel */}
        <ProtectedRoute
          exact={true}
          path={Routes.AdminDashboard}
          component={Dashboard}
        />
        <ProtectedRoute
          exact={true}
          path={Routes.Organization}
          component={Dashboard}
        />
        <ProtectedRoute
          exact={true}
          path={Routes.AllOrganizations}
          component={Dashboard}
        />
        <ProtectedRoute
          exact={true}
          path={Routes.DocumentUpload}
          component={Dashboard}
        />
        <ProtectedRoute
          exact={true}
          path={Routes.AdminPanelBilling}
          component={Dashboard}
        />
        <ProtectedRoute
          exact={true}
          path={Routes.AdminPanelOrganizationBillingDetails}
          component={Dashboard}
        />
        <ProtectedRoute
          exact={true}
          path={Routes.SystemHealth}
          component={Dashboard}
        />
        <ProtectedRoute
          exact={true}
          path={Routes.TrialPlanConfirmation}
          component={Dashboard}
        />
        {/* <Route path={Routes.EULA} component={SupportPage} />
        <Route path={Routes.PrivacyPolicy} component={SupportPage} />
        <Route path={Routes.Pricing} component={SupportPage} />
        <Route path={Routes.Demo} component={SupportPage} />
        <Route exact path={Routes.HomePage} component={SupportPage} />
        <Route exact path={Routes.OptimizeSA} component={SupportPage} />
        <Route exact path={Routes.RightsizingSA} component={SupportPage} />
        <Route exact path={Routes.SchedulingSA} component={SupportPage} />
        <Route exact path={Routes.CostAnalysisSA} component={SupportPage} />
        <Route exact path={Routes.QuickPainlessSA} component={SupportPage} />
        <Route path={Routes.CostManagement} component={SupportPage} />
        <Route exact path={Routes.riu} component={SupportPage} />
        <Route exact path={Routes.CloudCheckup} component={SupportPage} />
        <Route exact path={Routes.AzureSpend} component={SupportPage} />
        <Route exact path={Routes.RightsizingDetail} component={SupportPage} />
        <Route exact path={Routes.SchedulingDetail} component={SupportPage} />
        <Route exact path={Routes.about} component={About} />
        <Route exact path={Routes.bfi} component={BusinessFocusedIT} />
        <Route exact path={Routes.security} component={Security} />
        <Route exact path={Routes.software} component={Software} />
        <Route exact path={Routes.compliance} component={Compliance} />
        <Route exact path={Routes.healthcare} component={Healthcare} /> */}
        <Route
          exact
          path={Routes.UploadedDocumentStatic}
          component={UploadedDocumentStatic}
        />
        <Route component={Error404} />
      </Switch>
    </BrowserRouter>
  );
};

export default React.memo(Router);
